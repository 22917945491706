import axios from 'axios'
import keyMirror from 'lib/keyMirror'
import {initPhoneCallCheck} from "lib/phoneCheck"

export const types = keyMirror({
    REQUEST_SEND: null,
    REQUEST_OK: null,
    REQUEST_ERROR: null,

    LOAD_SCHEDULE_REQUEST: null,
    LOAD_SCHEDULE_OK: null,
    LOAD_SCHEDULE_ERROR: null,
}, 'venue')

const getModelUrl = (id) => {
    return API_URL + '/v2/venues/' + id
}

export const sendRequest = (venueId, isCommission, data, pageType) => {
    return function (dispatch) {
        dispatch({
            type: types.REQUEST_SEND
        })
        let postData = {
            date: data.date,
            event_type: data.eventType,
            guests: data.guests,
            food: data.food,
            budget: data.budget,
            //drinks      : data.drinks,
            name: data.name,
            details: data.details,
            page_type: pageType == 'profile' ? 1 : 2,
            phone: data.phone,
            whatsapp: data.whatsapp,
            viber: data.viber,
            telegram: data.telegram,
            line: data.line,
            viaPhone: +data.viaPhone,
            viaWhatsApp: +data.viaWhatsApp,
            viaViber: +data.viaViber,
            viaLine: +data.viaLine,
            viaTelegram: +data.viaTelegram,
        }
        return new Promise(function (resolve, reject) {
            let url = getModelUrl(venueId) + '/request?model_type=' + data.modelType + '&model_id=' + data.roleId + '&city_id=' + data.city.id
            axios.post(url, postData, {withCredentials: true})
                .then(response => {
                    dispatch({
                        type: types.REQUEST_OK,
                    })
                    let goalParams = {
                        vendor_type: 'venue',
                        vendor_id: venueId,
                        domain: SITE_DOMAIN,
                    }
                    window.reachGoal('inquireSubmit', goalParams)
                    if (isCommission) {
                        window.reachGoal('totalComVenue', goalParams)
                    }
                    resolve(response.data)
                })
                .catch(() => {
                    dispatch({
                        type: types.REQUEST_ERROR
                    })
                })
        })
    }
}

let schedules = {}
let scheduleRooms = {}
export const getSchedules = (id) => {
    return function (dispatch) {
        if (schedules[id] && scheduleRooms[id]) {
            dispatch({
                schedule: schedules[id],
                rooms: scheduleRooms[id],
                type: types.LOAD_SCHEDULE_OK,
            })
        }
        dispatch({
            type: types.LOAD_SCHEDULE_REQUEST
        })

        axios.get(getModelUrl(id) + '/schedule', {withCredentials: true})
            .then(response => {
                schedules[id] = {}
                scheduleRooms[id] = response.data.rooms
                response.data.rooms.map(function (room) {
                    for (var date in room.dates) if (room.dates.hasOwnProperty(date)) {
                        if (!schedules[id][date]) {
                            schedules[id][date] = {
                                0: response.data.rooms.length,
                            }
                        }
                        var status = room.dates[date]
                        if (!schedules[id][date][status]) {
                            schedules[id][date][status] = 1
                        } else {
                            schedules[id][date][status]++
                        }
                        schedules[id][date][0]--
                    }
                })
                dispatch({
                    schedule: schedules[id],
                    rooms: response.data.rooms,
                    type: types.LOAD_SCHEDULE_OK,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_SCHEDULE_ERROR
                })
            })
    }
}

export const viewInfoGoal = (viewInfo, type, conversionData) => {
    if (!viewInfo.goal || !viewInfo.goal[type]) {
        return
    }
    if (type === 'phone' && viewInfo.goal['phone2']) {
        if (window && typeof window.ga === 'function') {
            ga(function (tracker) {
                conversionData.gaClientId = tracker.get('clientId')
            })
        }
        if (window && typeof window.yaCounter) {
            conversionData.yaClientId = window.yaCounter.getClientID()
        }
        conversionData.isMobile = 1
        axios.post(API_URL + '/v2/conversions', conversionData, {withCredentials: true})
            .then(response => {
                conversionData.id = response.data.id
                return new Promise((resolve) => resolve(conversionData))
            }).then(conversionData => {
            initPhoneCallCheck(viewInfo.goal['phone2'], viewInfo.goalParam, () => {
                if (viewInfo.goal['phone2'].indexOf('phoneComVenueClick2') > -1) {
                    axios.post(API_URL + '/v2/conversions', {
                        ...conversionData,
                        isConfirmed: 1
                    }, {withCredentials: true})
                }
            })
        })

    }
    viewInfo.goal[type].forEach(goal => {
        window.reachGoal(goal, viewInfo.goalParam)
    })
}

export const onVenueCall = (model) => {
    const modelName = model.reducerName
    const modelId = model.id
    const params = {
        vendor_type: modelName,
        vendor_id: modelId,
        domain: SITE_DOMAIN
    }
    let goals2 = ['phoneClick2']
    window.reachGoal('showContacts', params)
    window.reachGoal('phoneClick', params)
    let goal2Callback = () => {
    }
    if (model.commission) {
        goals2.push('phoneComVenueClick2')
        let conversionData = {
            phone: model.phone.length ? model.phone[0] : null,
            channel: model.phoneChannel,
            isMobile: 1,
        }
        if (window && typeof window.ga === 'function') {
            ga(function (tracker) {
                conversionData.gaClientId = tracker.get('clientId')
            })
        }
        if (window && typeof window.yaCounter) {
            conversionData.yaClientId = window.yaCounter.getClientID()
        }
        axios.post(API_URL + '/v2/conversions', conversionData, {withCredentials: true})
            .then(response => {
                conversionData.id = response.data.id
                return new Promise((resolve) => resolve(conversionData))
            }).then(conversionData => {
            goal2Callback = () => {
                axios.post(API_URL + '/v2/conversions', {...conversionData, isConfirmed: 1}, {withCredentials: true})
            }
            // conversionData
            initPhoneCallCheck(goals2, params, goal2Callback)
        })
        window.reachGoal('phoneComVenueClick', params)
        const goal = ((model.commission === 4 || model.commission === 5) ? 'showContactsPayPerCallVenue' : 'showComVenueContacts')
        window.reachGoal(goal, {
            vendor_type: modelName,
            vendor_id: modelId,
            domain: SITE_DOMAIN
        })
        if (goal === 'showComVenueContacts') {
            window.reachGoal('totalComVenue', params)
        }
    }
}

export const switchSpec = (venueId, specId, cancelToken) => {
    return function (dispatch) {
        return axios.put(getModelUrl(venueId) + '/switchSpec', {specId: specId}, {
            cancelToken: cancelToken,
            withCredentials: true
        })
            .then(response => {
                return response.data
            })
            .catch((e) => {
            })
    }
}
