import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import t from 't'

import Footer from 'components/filter/bubble/footer'
import Header from 'components/filter/bubble/header'

const Item = (props) => {
    if (props.item.key === 0) return null

    return (
        <a className={'filter_color-' + props.item.label + (props.selected ? ' _selected' : '')}
            onClick={() => props.onChange(props.filter, props.item.key)}/>)
}

const ColorBubble = (props) => {
    const filter = props.filter
    let items = []
    let counts = 0
    _.map(filter.items, (item) => {
        let selected = item.key && filter.changes.indexOf(item.key) >= 0
        if (selected) {
            counts++
        }
        items.push(<Item
            key={filter.label + item.key}
            filter={filter}
            item={item}
            selected={selected}
            onChange={props.onChange}
        />)
    })
    return (
        <div className="filterBubble">
            <a className="filterBubble_close" onClick={() => props.onToggle(props.filter.label)}/>
            {counts ?
                <a className="filterBubble_reset" onClick={() => props.resetFilter(props.filter.label)}>{t('Сбросить')}</a>
            : null}
            <div className="filterBubble_content">
                <Header caption={filter.name} counts={counts}/>
                {props.filter.changed ?
                    <Footer label={props.filter.label} applyFilter={props.applyFilter} onToggle={props.onToggle}/>
                : null}
                <div className="filterBubble_body-colors">{items}</div>
            </div>
        </div>
    )
}

ColorBubble.propTypes = {
    filter: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    applyFilter: PropTypes.func.isRequired,
}

export default ColorBubble
