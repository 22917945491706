import React from 'react'
import PropTypes from 'prop-types'

const Video = (props) => {
    return (
        <div className="main main-flex">
            <div className="main-flex-content">
                <div className="ppMedia">
                    <div className="ppMedia_stage">
                        <div className="ppMedia_media-video" dangerouslySetInnerHTML={{
                             __html: '<iframe class="ppMedia_frame" src="' + props.videoLink + '" allowfullscreen/>'
                         }}/>)
                    </div>
                </div>
            </div>
        </div>)
}

Video.propTypes = {
    videoLink: PropTypes.string.isRequired,
}

export default Video



