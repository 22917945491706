import {combineReducers} from 'redux'
import _ from 'lodash'

import album from 'reducers/album'
import viewInfo from 'reducers/viewInfo'

import {types} from 'actions/shop'

import Meta from 'models/meta'
import Shop from 'models/shop'
import ShopBranch from 'models/shopBranch'
import MapMarker from 'models/mapMarker'

const initialState = {
    list: [],
    loading: false,
    meta: null,
    model: null,
    markers: [],
}

function shop(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_LIST_REQUEST:
            return {
                ...state,
                list: [],
                loading: true,
            }
        case types.LOAD_MORE_REQUEST:
        case types.LOAD_MODEL_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case types.LOAD_LIST_OK:
            return {
                ...state,
                list: _.map(action.list, item => new Shop(item)),
                loading: false,
                meta: new Meta(action.meta),
            }
        case types.LOAD_MORE_OK:
            return {
                ...state,
                list: _.union(state.list, _.map(action.list, item => new Shop(item))),
                loading: false,
                meta: new Meta(action.meta),
            }

        case types.LOAD_MODEL_OK:
            return {
                ...state,
                loading: false,
                model: new Shop(action.model),
            }

        case types.LOAD_MARKERS_OK:
            return {
                ...state,
                markers: _.flatten(_.map(
                    action.list,
                    item => _.map(
                        _.filter(item.all_branches, (branch) => branch.city.id===action.cityId),
                        branch => new MapMarker({
                            model: new ShopBranch(branch, item)
                        })
                    )
                )),
                loading: false
            }

        case types.LOAD_MORE_ERROR:
        case types.LOAD_LIST_ERROR:
        case types.LOAD_MODEL_ERROR:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
}

const ShopReducer = combineReducers({
    shop,
    album,
    viewInfo,
})

export default ShopReducer