import {types} from 'actions/titles'
import {types as interfaceTypes} from 'actions/main'

const initialState =
    {
        title: '',
        keywords: '',
        description: '',
        h1: '',
        h1text : '',
        bottom: '',
        canonical: '',
        robots: '',
        vk_code: '',
        qa: [],
        cache: {},
    }

const setTitles = (state, titles, url) => {
    let newCache = state.cache || {}
    newCache[url] = titles
    return {
        ...state,
        ...titles,
        cache: newCache
    }
}
    
const TitlesReducer = (state = false, action) => { // изначально state=false, чтобы при ошибке подгрузки сео из interface можно было запросить его отдельно
    switch (action.type) {
        case interfaceTypes.LOAD_INTERFACE_OK:
            if (action.titles) {
                // move to index.js
                // if (typeof window !== 'undefined' && action.titles.vk_code) {
                //     (window.Image ? (new Image()) : document.createElement('img')).src = location.protocol + '//vk.com/rtrg?r=' + action.titles.vk_code
                // }
                return setTitles(state, action.titles, action.url)
            } else {
                return state
            }
        case types.LOAD_OK:
            if (typeof window !== 'undefined' && action.vk_code) {
                (window.Image ? (new Image()) : document.createElement('img')).src = location.protocol + '//vk.com/rtrg?r=' + action.vk_code
            }
            return setTitles(state, action.titles, action.url)
        case types.LOAD_REQUEST:
        case types.LOAD_ERROR:
            return state
        default:
            return state
    }
}

export default TitlesReducer