import {LOCATION_CHANGE} from 'react-router-redux'

import {types} from 'actions/contact'

import Car from 'models/car'
import Place from 'models/place'
import Restaurant from 'models/restaurant'
import Venue from 'models/venue'
import Shop from 'models/shop'
import ShopBranch from 'models/shopBranch'
import User from 'models/user'

const initialState = {
    loading: true,
    model: null,

    address: '',
    branches: [],
    branchNum: 0,
    coords: [],
    links: [],
    phones: [],
    messengers: [],
}

let cache = {
    links: [],
    phones: [],
    messengers: [],
}

const sortContacts = (unsorted, contacts, branch) => {
    for (let i = 0; unsorted[i]; i++) {
        let contact = unsorted[i]
        contact.value += ''
        switch (contact.key) {
            case 'address' :
                if (!branch) {
                    contacts.address = contact.value
                }
                break
            case 'phone' :
                if (branch) {
                    branch.phones.push(contact.value)
                } else {
                    if (cache.phones.indexOf(contact.value) >= 0) {
                        break
                    }
                    contacts.phones.push(contact.value)
                }
                cache.phones.push(contact.value)
                break
            case 'email' :
            case 'url'   :
                if (cache.links.indexOf(contact.value) >= 0) {
                    break
                }
                let link = {
                    id: contact.id,
                    url: contact.value,
                    text: contact.value.replace('http://', '').replace('https://', '').replace(/\/.*$/, ''),
                }

                if (contact.key === 'email') {
                    link.social = 'email'
                    link.url = 'mailto:' + link.url
                } else if (/vk.com|vkontakte.ru/i.test(link.url)) {
                    link.social = 'vk'
                } else if (/facebook.com/i.test(link.url)) {
                    link.social = 'fb'
                } else if (/twitter.com/i.test(link.url)) {
                    link.social = 'tw'
                } else if (/plus.google.com/i.test(link.url)) {
                    link.social = 'gplus'
                } else if (/odnoklassniki.ru/i.test(link.url)) {
                    link.social = 'ok'
                } else if (/instagram.com/i.test(link.url)) {
                    link.social = 'ig'
                } else {
                    link.social = 'site'
                }

                if ((link.social !== 'email') && (!/http(s|):\/\//.test(link.url))) {
                    link.url = 'http://' + link.url
                }
                contacts.links.push(link)
                cache.links.push(contact.value)
                break
            case 'telegram' :
            case 'viber'    :
            case 'whatsapp' :
            case 'line'     :
                if (cache.messengers.indexOf(contact.key + '_' + contact.value) >= 0) {
                    break
                }
                var messenger = {
                    id : contact.id,
                    name : null,
                    chat : null,
                    call : null,
                    text : contact.value,
                }
                if (contact.key == 'telegram') {
                    messenger.name = 'Telegram'
                    messenger.chat = 'tg://resolve?domain=' + contact.value
                } else if (contact.key == 'viber') {
                    messenger.name = 'Viber'
                    messenger.chat = 'viber://chat?number=%2B' + contact.value.replace(/[^\d]/g, '')
                } else if (contact.key == 'whatsapp') {
                    messenger.name = 'WhatsApp'
                    messenger.chat = 'https://wa.me/' + contact.value
                } else if (contact.key == 'line') {
                    messenger.name = 'Line'
                    messenger.call = 'http://line.me/ti/p/~' + contact.value
                }
                contacts.messengers.push(messenger)
                cache.messengers.push(contact.key + '_' + contact.value)
                break
        }
    }
}

const grabContacts = (model) => {
    let result = {
        phones: [],
        address: '',
        coords: [],
        links: [],
        messengers: [],
        branches: [],
    }

    cache.phones = []
    cache.links = []
    cache.messengers = []

    if (model.branches) {
        if (model.branches.length) {
            for (let b = 0; model.branches[b]; b++) {
                let branch = new ShopBranch(model.branches[b], model)
                branch.phones = []
                sortContacts(branch.contacts, result, branch)
                result.branches.push(branch)
            }
        } else {
            let branchInfo = {
                name: model.name,
                coords: model.coords,
                address: model.address,
                working_hours: model.working_hours,
                phones: [],
            }
            sortContacts(model.contacts, result, branchInfo)
            result.branches.push(branchInfo)
        }
    } else if (model.contacts) {
        sortContacts(model.contacts, result)
        if (model.coords) {
            result.coords = model.coords
        }
    }

    return result
}

const getModel = (entityType, model) => {
    switch (entityType) {
        case 'users':
            return new User(model)
        case 'restaurants':
            return new Restaurant(model)
        case 'venues':
            return new Venue(model)
        case 'cars':
            return new Car(model)
        case 'places':
            return new Place(model)
        case 'shops':
            return new Shop(model)
    }
}

const ContactReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_MODEL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case types.LOAD_MODEL_OK:
            return {
                ...state,
                ...grabContacts(action.model),
                model: getModel(action.entityType, action.model),
                loading: false
            }
        case types.LOAD_MODEL_ERROR:
            return {
                ...state,
                loading: false,
            }

        case types.SWITCH_BRANCH:
            return {
                ...state,
                branchNum: +action.branchNum,
            }

        case LOCATION_CHANGE:
            return initialState

        default:
            return state
    }
}
export default ContactReducer
