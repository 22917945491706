import React from 'react'
import Scroller from 'components/block/scroller'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {connect} from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'
import t from 't'

import {putComponentToPopup} from 'actions/popup'

import ImageContainer from 'containers/block/image'
import Link from 'components/block/link'
import Video from 'components/block/gallery/video'
import LinkLight from "components/block/linkLight"
import Picture from "components/block/picture"
import SimpleImg from "components/block/simpleImg"

const GalleryMedia = ({
                          media,
                          link,
                          link_target,
                          height,
                          showVideo,
                          isVisible,
                          isForceVisible,
                          screenHeight,
                          imgClassName
                      }) => {
    if (!height) {
        height = Math.max(170, Math.min(250, Math.round(screenHeight / 3)))
    }
    if (!imgClassName) {
        imgClassName = "gallery_img"
    }
    if (isVisible === false) {
        return <img className={imgClassName} src={null} width={1} height={height} alt=""/>
    }
    const pixelRatio = typeof window.devicePixelRatio !== 'undefined' ? window.devicePixelRatio : 1
    const isNumber = _.isNumber(media)
    if (media.isImg || isNumber) {
        return <ImageContainer id={isNumber ? media : media.id} height={height} link={link} linkTarget={link_target}
                               className={imgClassName} classNameLink="gallery_media" isForceVisible={isForceVisible}/>
    } else {
        return (
            <VisibilitySensor partialVisibility={true}
                              offset={{bottom: -(screenHeight * 0.3), top: -50, left: -50, right: -100}}>
                {({isVisible}) => {
                    media.isVisible = isForceVisible ? true : (media.isVisible || isVisible)
                    if (!media || !media.sizes || !media.sizes.original) {
                        console.log('BAD MEDIA', media)
                        return null
                    }
                    let originalSize = media.sizes.original.split('x')
                    let width = Math.round(height * originalSize[0] / originalSize[1])
                    let src = null
                    if (media.isVisible) {
                        if (media.cover_url) {
                            src = media.cover_url
                        } else {
                            let params = [
                                'w' + Math.round(width * pixelRatio),
                                'h' + Math.round(height * pixelRatio)
                            ]
                            if (media.type !== 'image') {
                                params.push('n')
                            }
                            if (pixelRatio >= 2) {
                                params.push('l90')
                            } else {
                                params.push('l90')
                            }
                            params.push('e31')
                            if (media.original_url) {
                                src = media.original_url.replace('=s0', '') + '=' + params.join('-')
                            } else if (media.preview_url) {
                                src = media.preview_url[Object.keys(media.preview_url)[0]].replace(/=.*$/, '') + '=' + params.join('-')
                            }
                        }
                    }
                    if (link === false) {
                        if (media.type == 'video_embed') {
                            return (
                                <a className={'gallery_media' + (media.type == 'video_embed' ? '-video' : '')}
                                   onClick={() => showVideo(media.video_link)}>
                                    <Picture className={imgClassName} src={src} width={width} height={height} alt=""/>
                                </a>)
                        } else {
                            return <Picture className={imgClassName} src={src} width={width} height={height}
                                            className="gallery_media" alt=""/>
                        }
                    } else {
                        if (typeof link === 'string') {
                            return <LinkLight to={link}
                                              className={'gallery_media' + (media.type == 'video_embed' ? '-video' : '')}
                                              target={link_target}>
                                <Picture className={imgClassName} src={src} width={width} height={height} alt=""/>
                            </LinkLight>
                        } else {
                            return <Link to={link ? link : media}
                                         className={'gallery_media' + (media.type == 'video_embed' ? '-video' : '')}
                                         target={link_target}>
                                <Picture className={imgClassName} src={src} width={width} height={height} alt=""/>
                            </Link>
                        }
                    }
                }}
            </VisibilitySensor>)
    }
}

const Gallery = (props) => {
    if (!props.media || !props.media.length) return null

    let classname = "gallery" + (props.className ? " " + props.className : "")
    let list = []
    let isForceVisible = props.isForceVisible || false
    const screenHeight = typeof window === 'undefined' ? 0 : (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)
    const imgHeight = Math.max(170, Math.min(250, Math.round(screenHeight / 3)))
    const imgClassName = props.imgClassName || "gallery_img"
    _.map(props.media, function (media, index) {
        if (media.getPic === undefined && media.url === undefined && (media.isImg === undefined || !media.isImg) && !_.isNumber(media)) {
            return null
        }
        if (isForceVisible && index > 1) isForceVisible = false
        if (media.url && media.width && media.height) {
            let link = props.isUseMediaLink && media.link ? media.link : props.link
            if (link) {
                list.push(<LinkLight key={index} to={link}
                                     className={'gallery_media' + (media.isVideo ? '-video' : '')}
                                     target={props.link_target}><SimpleImg className={imgClassName} height={imgHeight}
                                                                           simpleImg={media}
                                                                           isCrop={false}
                                                                           isForceVisible={isForceVisible}/></LinkLight>)
            } else {
                list.push(<SimpleImg key={index} className={imgClassName} height={imgHeight} simpleImg={media}
                                     isCrop={false} isForceVisible={isForceVisible}/>)
            }
        } else {
            list.push(<GalleryMedia
                key={index}
                media={media}
                link={props.link}
                link_target={props.link_target ? props.link_target : null}
                height={props.height}
                showVideo={props.showVideo}
                isForceVisible={isForceVisible}
                screenHeight={screenHeight}
            />)
        }
    })

    return (
        <Scroller className={classname} isWide={true}>
            {props.panoramaID && props.showPanorama ?
                <a className="cardRoom_panorama" onClick={() => props.showPanorama(props.panoramaID)}>{t('3D-обзор')}</a>
            : null}
            {list}
        </Scroller>
    )
}

Gallery.propTypes = {
    media: PropTypes.array,
    className: PropTypes.string,
    link: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.bool,
    ]),
    height: PropTypes.number,
    isForceVisible: PropTypes.bool,
    isVisible: PropTypes.bool,
    isUseMediaLink: PropTypes.bool,
    link_target: PropTypes.string,
}

function mapStateToProps(state, ownProps) {
    return {}
}

function mapDispatchToProps(dispatch, ownProps) {
    let state = ownProps.state || {}
    return {
        showVideo: (videoLink) => dispatch(putComponentToPopup(t('Видео'), <Video videoLink={videoLink}/>)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)
