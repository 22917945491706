"use strict";

import AppDispatcher from "dispatcher/AppDispatcher"
import PhotoViewerConstants from "constants/PhotoViewerConstants"

const PhotoViewerActions = {
  on: function(photo, photos) {
    AppDispatcher.dispatch({
      actionType: PhotoViewerConstants.PHOTO_VIEWER_ON,
      initialPhoto: photo,
      photos: photos
    });
  },

  off: function() {
    AppDispatcher.dispatch({ actionType: PhotoViewerConstants.PHOTO_VIEWER_OFF });
  }
}

export default PhotoViewerActions