import React from 'react'
import PropTypes from 'prop-types'

const Error50x = (props) => {
    let lang = 'en'
    switch (props.domain.replace(/^(?:[^\.]+\.)?([^\.]{4,}\.\w{2,3}(?:\.\w{2,3})?)(?:\:\d+)?$/, '$1')) {
        case 'gorko.ru':
        case 'belwed.com':
        case 'kzwed.com':
        case 'wedpro.com.ua':
            lang = 'ru'
            break
        default:
            lang = 'en'
            break
    }

    const text = {
        name: {
            'en': 'Соming back soon',
            'ru': 'Скоро вернемся'
        },
        text: {
            'en': 'Service is temporary unavailable. Try it again later.',
            'ru': 'Сервер недоступен. Попробуйте обновить страницу позже'
        },
        btn: {
            'en': 'Reload',
            'ru': 'Повторить'
        },
    }

    let exception = ''
    if (document.location.search === '?utm_debug=1') {
        exception = props.exception
    }
    console.log(props.exception);

    const number = 502
    return (
        <div className="pError-50x">
            <div className="pError_number">
                {number}
            </div>
            <p className="pError_name">{text.name[lang]}</p>
            <div className="pError_text">{text.text[lang]}</div>
            <a className="button pError_button" onClick={() => document.location.reload()}>{text.btn[lang]}</a>
            <div className="pError_balloon"/>
            {exception?<div>{exception.name + ":" + exception.message + "\n" + exception.stack}</div>:null}
        </div>
    )
}

Error50x.propTypes = {
    domain: PropTypes.string
}

export default Error50x

