import axios from 'axios'
import keyMirror from 'lib/keyMirror'
import React from "react"
import _ from 'lodash'

export const types = keyMirror({
    LOAD_LANG_T: null,

    LOAD_INTERFACE_REQUEST: null,
    LOAD_INTERFACE_OK: null,
    LOAD_INTERFACE_ERROR: null,

    LOAD_DIRECTORY_STRUCTURE_REQUEST: null,
    LOAD_DIRECTORY_STRUCTURE_OK: null,
    LOAD_DIRECTORY_STRUCTURE_ERROR: null,

    TOGGLE_MENU: null,
    SELECT_MENU_GROUP: null,

    LOAD_HELP_REQUEST: null,
    LOAD_HELP_OK: null,
    LOAD_HELP_ERROR: null,
}, 'main');

const getDefaultLang = () => {
    return 'en'
}

export const loadApp = (url, loadTitles = false, headers = null) => {
    return (dispatch, getState) => {
        if (getState().main.directory) return new Promise((resolve) => resolve(false))
        return dispatch(loadInterface(url, loadTitles, headers))
            .then((phpHeaders) => {
                return dispatch(loadLang(getState().main.locale.lang||getDefaultLang())).then(() => new Promise((resolve) => resolve(phpHeaders)))
            })
    }
}


export const loadLang = (lang) => {
    return function (dispatch, getState) {
        if (typeof LANG_T !== 'undefined' && !_.isEmpty(LANG_T)) {
            dispatch({
                type: types.LOAD_LANG_T,
                data: LANG_T,
                location: typeof document !== 'undefined' ? document.location : null,
            })
            return new Promise((resolve) => resolve(true)) // для серверного рендеринга тут нужно будет что-то сделать
        } else {
            return axios.get(__webpack_public_path__ + lang + '.' + __GIT_REVISION__ + '.json', {})
                .then(response =>
                    dispatch({
                        type: types.LOAD_LANG_T,
                        data: response.data,
                        location: typeof document !== 'undefined' ? document.location : null,
                    })
                )
        }
    }
}

export const loadInterface = (url, loadTitles = false, headers = null) => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_INTERFACE_REQUEST
        })
        if (typeof INTERFACE !== 'undefined' && INTERFACE) {
            dispatch({
                ab_test: INTERFACE.ab_test,
                auth: INTERFACE.auth,
                banner: INTERFACE.banner,
                channel: INTERFACE.channel,
                city: INTERFACE.city,
                currency: INTERFACE.currency,
                directory: INTERFACE.directory,
                error: INTERFACE.error,
                favorites: INTERFACE.auth ? INTERFACE.auth.favorites : INTERFACE.favorites,
                hash: INTERFACE.hash,
                is_discount: INTERFACE.is_discount,
                locale: INTERFACE.locale,
                seed: INTERFACE.seed,
                titles: INTERFACE.titles,
                top_menu: INTERFACE.top_menu,
                type: types.LOAD_INTERFACE_OK,
                site: INTERFACE.site,
                url: url,
            })
            dispatch(lastAct())
            return new Promise((resolve) => resolve(true)) // для серверного рендеринга тут нужно будет что-то сделать
        } else {
            if ('development' === process.env.NODE_ENV) {
                if (document.location.origin === 'http://gorko.ru:8000') {
                    url = url.replace('http://gorko.ru:8000', 'https://samara.gorko.ru')
                } else if (document.location.origin === 'http://wedding.net:8000') {
                    url = url.replace('http://wedding.net:8000', 'https://delhi.wedding.net')
                } else if (document.location.origin === 'http://centerwedding.com:8000') {
                    url = url.replace('http://centerwedding.com:8000', 'https://www.centerwedding.com')
                } else if (document.location.origin === 'http://wedpro.com.ua:8000') {
                    url = url.replace('http://wedpro.com.ua:8000', 'https://kiev.wedpro.com.ua')
                } else if (document.location.origin === 'http://belwed.com:8000') {
                    url = url.replace('http://belwed.com:8000', 'https://minsk.belwed.com')
                }
            }
            // url = url.replace(/google=test&?/, '') // может потом еще пригодится
            return axios.get(API_URL + '/v2/interface?url=' + encodeURIComponent(url) + (loadTitles ? '&titles=1' : ''), {
                withCredentials: true,
                headers: headers
            })
                .then(response => {
                    dispatch({
                        ab_test: response.data.ab_test,
                        auth: response.data.auth,
                        banner: response.data.banner,
                        channel: response.data.channel,
                        city: response.data.city,
                        currency: response.data.currency,
                        directory: response.data.directory,
                        error: response.data.error,
                        favorites: response.data.auth ? response.data.auth.favorites : response.data.favorites,
                        hash: response.data.hash,
                        is_discount: response.data.is_discount,
                        locale: response.data.locale,
                        seed: response.data.seed,
                        site: response.data.site,
                        titles: response.data.titles,
                        top_menu: response.data.top_menu,
                        type: types.LOAD_INTERFACE_OK,
                        url: url,
                    })
                    dispatch(lastAct())
                    return new Promise((resolve) => resolve(response.headers))
                })
        }
    }
}
const loadDirectoryStructure = (cityId) => {
    return function (dispatch) {
        dispatch({
            type: types.LOAD_DIRECTORY_STRUCTURE_REQUEST
        })
        return axios.get(API_URL + '/v2/directory?city_id=' + cityId, {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_DIRECTORY_STRUCTURE_OK,
                    directory: response.data.directory,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_DIRECTORY_STRUCTURE_ERROR
                })
            })
    }
}

export const toggleMenu = () => {
    return function (dispatch, getState) {
        dispatch({
            type: types.TOGGLE_MENU,
            location: getState().router.location,
        })
    }
}
export const selectMenuGroup = (id) => {
    return {type: types.SELECT_MENU_GROUP, id: id}
}

export const goToFullVersion = () => {
    return function (dispatch, getState) {
        let date = new Date()
        date.setDate(date.getDate() + 7);
        document.cookie = 'is_mobile=0; domain=' + getState().main.site.domain + '; path=/; secure; expires=' + date.toGMTString()
        window.caches.keys().then(function (cacheNames) {
            return Promise.all(
                cacheNames.map(function (cacheName) {
                    return window.caches.delete(cacheName)
                })
            )
        })
        document.location.reload()
    }
}

export const loadHelp = () => {
    return (dispatch, getState) => {
        if (getState().main.site.show_helpscout && !window.Beacon && !getState().main.isHelpLoading) {
            !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
            window.Beacon('init', getState().main.site.show_helpscout)
        }
        return new Promise(resolve => resolve(1))
    }
}

export const openHelp = () => {
    return function (dispatch, getState) {
        if (!getState().main.site.show_helpscout) {
            return false
        }
        dispatch(loadHelp()).then(v => {
            if (typeof window.Beacon !== 'undefined') {
                if (getState().auth.user) {
                    const user = getState().auth.user
                    window.Beacon('identify', {
                        name: user.name,
                        company: user.company,
                        email: user.email ? user.email : '',
                        id: user.id,
                        role: user.role ? user.role.name : '',
                        url: user.profile_url,
                        _gorkoIds: user.extra_auth && user.extra_auth.users ? user.extra_auth.users.map(function (u) {
                            return u.id
                        }).join(', ') : '',
                        _socials: user.extra_auth && user.extra_auth.socials ? user.extra_auth.socials.join(', ') : '',
                        bill: user.bill_display && user.bill_free && user.bill_hold ? user.bill_display + ' (free: ' + user.bill_free + ', hold:' + user.bill_hold + ')' : '',
                        city: user.city ? (user.city.id + ':' + user.city.name) : '',
                        city_additional: user.additional_cities ? user.additional_cities.map(function(city) { return city.id + ':' + city.name}).join(', ') : '',
                        join: user.join ? user.join.split(' ')[0] : null,
                        last_act: user.last_act ? user.last_act.split(' ')[0] : null,
                        pro: user.top ? 1 : 0,
                    })
                }
                window.Beacon('open')
            }
        })
    }
}

export const lastAct = () => {
    return function (dispatch, getState) {
        if (getState().auth.isAuth) {
            return axios.post(API_URL + '/v2/my/lastAct', {}, {withCredentials: true})
        }
    }
}