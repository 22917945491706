import BaseModel from 'models/baseModel'
import User from 'models/user'
export default class Topic extends BaseModel {
    constructor(data, langUrlNotEmpty) {
        if (data.last_comment_user) {
            data.last_comment_user = new User(data.last_comment_user)
        }
        super(data)
        this.langUrlNotEmpty = langUrlNotEmpty
    }
    get link() {
        return this.langUrlNotEmpty + '/topic/' + this.id + '/'
    }

    get hasUpdates() {
        return false // :(
    }

    get lastReadLink() {
        return this.link + 'LastRead/';
    }
    get lastMyLink() {
        return this.link + 'LastMy/';
    }
}
