import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,

    LOAD_MORE_REQUEST: null,
    LOAD_MORE_OK: null,
    LOAD_MORE_ERROR: null,
}, 'follower')

const getListUrl = (entityType, modelId) => {
    return API_URL + '/v2/'+ entityType + '/' + modelId + '/favorites'
}

export const loadFollowers = (entityType, modelId, params = null) => {
    return function (dispatch, getState) {
        if (getState().follower.loading) {
            return false
        }
        let typeRequest = types.LOAD_LIST_REQUEST
        let typeOk = types.LOAD_LIST_OK
        let typeError = types.LOAD_LIST_ERROR

        if (getState().follower.meta) {
            typeRequest = types.LOAD_MORE_REQUEST
            typeOk = types.LOAD_MORE_OK
            typeError = types.LOAD_MORE_ERROR
            if (getState().follower.meta.isLastPage) {
                return false
            }
            params['page'] = getState().follower.meta.nextPage
        }

        dispatch({
            type: typeRequest
        })

        axios.get(getListUrl(entityType, modelId), {params: params})
            .then(response => {
                dispatch({
                    type: typeOk,
                    list: response.data.favorites,
                    meta: response.data.meta,
                })
            })
            .catch(() => {
                dispatch({
                    type: typeError
                })
            })
    }
}