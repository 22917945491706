export const GoogleGtag = (gtag_id) => {
    if (!gtag_id) return;

    // let s, t;
    // s = document.createElement('script');
    // s.type = 'text/javascript';
    // s.src = 'https://www.googletagmanager.com/gtag/js?id=' + gtag_id;
    // t = document.getElementsByTagName('script')[0];
    // t.parentNode.insertBefore(s, t);
    //
    // window.dataLayer = window.dataLayer || [];
    // window.gtag = function(){dataLayer.push(arguments);}
    // window.gtag('js', new Date());
    // window.gtag('config', gtag_id);
    window.dataLayer = window.dataLayer || [];
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer',gtag_id);
}

export const YandexMetrika = (metrikaId, yaParams, auth, webvisor = false) => {
    if (!metrikaId) return;
    // (function (d, w, c) {
    //     (w[c] = w[c] || []).push(function () {
    //         try {
    //             w.yaCounter = new Ya.Metrika2({
    //                 id: metrikaId,
    //                 clickmap: true,
    //                 trackLinks: true,
    //                 accurateTrackBounce: true,
    //                 webvisor: webvisor,
    //                 params: yaParams || {}
    //             })
    //         } catch (e) {
    //         }
    //     })
    //
    //     var n = d.getElementsByTagName("script")[0],
    //         s = d.createElement("script"),
    //         f = function () {
    //             n.parentNode.insertBefore(s, n)
    //         }
    //     s.type = "text/javascript"
    //     s.async = true
    //     s.src = "https://mc.yandex.ru/metrika/tag.js"
    //
    //     if (w.opera == "[object Opera]") {
    //         d.addEventListener("DOMContentLoaded", f, false)
    //     } else {
    //         f()
    //     }
    // })(document, window, "yandex_metrika_callbacks2");

    (function (d, w, c) {
        (w[c] = w[c] || []).push(function() {
            try {
                w.yaCounter = new Ya.Metrika({
                    id:metrikaId,
                    clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true,
                    params: yaParams || {},
                });
            } catch(e) { }
        });

        var n = d.getElementsByTagName("script")[0],
        s = d.createElement("script"),
        f = function () { n.parentNode.insertBefore(s, n); };
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://mc.yandex.ru/metrika/watch.js";

        if (w.opera == "[object Opera]") {
        d.addEventListener("DOMContentLoaded", f, false);
    } else { f(); }
    })(document, window, "yandex_metrika_callbacks");

    if (auth.isAuth) {
        document.addEventListener('yacounter'+metrikaId+'inited', function () {
            yaCounter.reachGoal('User:' + auth.user.role.key);
        });
    }
}

export const facebookPixel = (auth) => {
    !function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
    }(window,
        document, 'script', '//connect.facebook.net/en_US/fbevents.js')

    fbq('init', '740418249420691')
    fbq('track', "PageView")
    if (auth.isAuth) {
        fbq('track', 'ViewContent', {content_type: 'User:' + auth.user.role.key});
    }
}

export const metaPixel = () => {
    !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js')

    fbq('init', '492424296410734')
    fbq('track', 'PageView')
}

export const mailRu = () => {
    let _tmr = window._tmr || (window._tmr = []);
    _tmr.push({id: "3193583", type: "pageView", start: (new Date()).getTime()});
    (function (d, w, id) {
        if (d.getElementById(id)) return;
        var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
        ts.src = "https://top-fwz1.mail.ru/js/code.js";
        var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
        if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
    })(document, window, "topmailru-code");
}

export const vk = () => {
    !function () {
        var t = document.createElement("script")
        t.type = "text/javascript", t.async = !0, t.src = "https://vk.com/js/api/openapi.js?168", t.onload = function () {
            VK.Retargeting.Init("VK-RTRG-788719-JXzj"), VK.Retargeting.Hit()
        }, document.head.appendChild(t)
    }()
}

export const tiktok = () => {
    !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
        ttq.load('C257IRNMU8Q03RAI3D70');
        ttq.page();
    }(window, document, 'ttq');
}
