import {types} from 'actions/forum'
import _ from 'lodash'

import Community from 'models/community'
import CommunityGroup from 'models/communityGroup'
import Meta from 'models/meta'
import Topic from 'models/topic'
import User from 'models/user'

const initialState = {
    communities: [],
    communityLoading: false,
    groups: [],
    moderators: [],

    topics: {},
    topicsList: [],
    topicsLoading: false,
    topicsMeta: null,

    topicTooltip: null,
}

function fillTopics(state, topics, langUrlNotEmpty) {
    _.each(topics, topic => {
        state.topics[topic.id] = new Topic(topic, langUrlNotEmpty)
    })

    return state.topics
}

const ForumReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_COMMUNITIES_REQUEST:
            return {
                ...state,
                communityLoading: true,
            }
        case types.LOAD_COMMUNITIES_OK:
            return {
                ...state,
                communities: _.map(action.communities, (item) => new Community(item)),
                groups: _.map(action.groups, (item) => new CommunityGroup(item)),
                moderators: _.map(action.moderators, (item) => new User(item)),
                communityLoading: false,
            }
        case types.LOAD_COMMUNITIES_ERROR:
            return {
                ...state,
                communityLoading: false,
            }


        case types.LOAD_TOPICS_REQUEST:
            return {
                ...state,
                topicsLoading: true,
            }
        case types.LOAD_TOPICS_OK:
            return {
                ...state,
                topicModels: fillTopics(state, action.topics, action.langUrlNotEmpty),
                topicsList: action.meta.page===1 ? _.map(action.topics, topic => topic.id) : _.union(state.topicsList, _.map(action.topics, topic => topic.id)),
                topicsLoading: false,
                topicsMeta: new Meta(action.meta),
            }
        case types.LOAD_TOPICS_ERROR:
            return {
                ...state,
                topicsLoading: false,
            }
        case types.RESET_TOPICS_LIST:
            return {
                ...state,
                topicsMeta: null,
                topicsList: [],
            }
        case types.TOGGLE_TOPIC_TOOLTIP:
            return {
                ...state,
                topicTooltip: action.topicId===state.topicTooltip ? null : action.topicId,
            }
        default:
            return state
    }
}
export default ForumReducer