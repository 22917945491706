import React from 'react'
import PropTypes from 'prop-types'
import t from 't'
import _ from 'lodash'

import Link from 'components/block/link'
import MapOverlay from 'components/block/mapOverlay'
import MapMarker from 'models/mapMarker'

const renderFeedback = (text, feedback, props) => {
    let isActive = (props.viewInfo.model.feedback === feedback)
    return <a key={'feedback_' + feedback} className={'button-border button-centered' + (isActive ? ' _active' : '')}
              onClick={() => props.setFeedback(feedback)} dangerouslySetInnerHTML={{__html: text}}/>
}

const BigContact = ({name, text, chatUrl, callUrl, onClick}) => {
    let firstUrl = chatUrl || callUrl || null
    return (
        <div className="ppContacts_big">
            <span className="ppContacts_big_name">{name}</span>
            {firstUrl !== null ? <a className="color-black" href={firstUrl} onClick={onClick}>{text}</a> : text}
            {chatUrl ? <a className="ppContacts_action-chat" href={chatUrl} onClick={onClick}/> : null}
            {callUrl ? <a className="ppContacts_action-call" href={callUrl} onClick={onClick}/> : null}
        </div>)
}

const Contact = (props) => {
    let socials = []
    let sites = []
    let phones = []
    let branch
    let markers = []

    _.map(props.contact.links, (link, i) => {
        if (link.social === 'site') {
            sites.push(<p key={'link_' + i} className="ppContacts_link"><a href={link.url} onClick={() => props.logClick(link.id)}
                                                                           target="_blank" rel="noopener">{link.text}</a></p>)
        } else {
            socials.push(<a key={'social_' + i} className={'ppContacts_social-' + link.social} href={link.url}
                            title={link.text} target="_blank" rel="noopener" onClick={() => props.logClick(link.id)}/>)
        }
    })

    let branchAddress = null
    if (props.contact.branches.length) {
        branch = props.contact.branches[props.contact.branchNum]
        let branches = []
        _.map(props.contact.branches, (branch, i) => {
            branches.push(<option key={'branch_' + i} value={i}>{branch.address}</option>)
            markers.push(new MapMarker({
                model: {
                    ...branch,
                    reducerName : props.model?.reducerName ?? null,
                },
                isActive: i === props.contact.branchNum,
            }))
        })
        if (props.contact.branches.length > 1) {
            branchAddress = <select className="ppContacts_select" value={props.contact.branchNum}
                                    onChange={(ev) => props.switchBranch(ev.target.value)}>{branches}</select>
        } else if (branch.address) {
            branchAddress = <p className="location_address">{branch.address}</p>
        }
    } else {
        markers.push(new MapMarker({
            model: {
                ...props.contact,
                reducerName : props.model?.reducerName ?? null,
            },
            isActive: true
        }))
    }

    let bigs = []
    let phonesInfo = branch ? branch.phones : props.contact.phones
    if (phonesInfo.length) {
        bigs.push(<BigContact key="big_phone" name={t('Телефон')} text={phonesInfo[0]} callUrl={'tel:' + phonesInfo[0].replace(/[^\d\+]/g, '')} onClick={() => reachGoal('phoneClick')}/>)
        for (let i = 1; phonesInfo[i]; i++) {
            phones.push(<p key={'phone_' + i} className="ppContacts_phone">
                <a className="ppContacts_phone_number" href={'tel:' + phonesInfo[i].replace(/[^\d\+]/g, '')} onClick={() => reachGoal('phoneClick')}>{phonesInfo[i]}</a>
            </p>)
        }
    }

    _.map(props.contact.messengers, (messenger, i) => {
        bigs.push(<BigContact key={'big_' + (i+1)} name={messenger.name} text={messenger.text} callUrl={messenger.call} chatUrl={messenger.chat} onClick={() => props.logClick(messenger.id)}/>)
    })

    let feedbacks = null
    if (!props.isAuth || props.authUser.can_feedback) {
        feedbacks = []
        feedbacks.push(renderFeedback(t('Все прошло хорошо'), 1, props))
        feedbacks.push(renderFeedback(t('Не отвечает на звонки'), 2, props))
        if (props.modelName === 'user') {
            feedbacks.push(renderFeedback(t('Цены не действительны'), 3, props))
            if (props.model.busy.length) {
                feedbacks.push(renderFeedback(t('Даты оказались занятыми'), 4, props))
            }
        }
    }

    let landmark = null
    if (props.model.landmark) {
        landmark = <b>{props.model.landmark}</b>
    }

    return (
        <div className="ppContacts">
            {props.modelName === 'user' ? (
                <div className="ppContacts_top ppUser">
                    <img className="avatar" src={props.model.avatar_url} height="100" width="100" alt=""/>
                    <p className="ppContacts_name">{props.model.name}</p>
                    <p className="ppUser_rolecity">{props.model.role.name}, {props.city.name}</p>
                    {bigs ?
                        <div className="ppContacts_bigs">{bigs}</div>
                    : null}
                    <p className="ppContacts_text"
                       dangerouslySetInnerHTML={{__html: t('Не забудьте сказать, что нашли информацию на <i>{site_name}</i> : )')}}/>

                    {props.isAuth ?
                        <Link className="ppContacts_pm" to={props.model.pmLink} data-params={{id: props.model.id}}>
                            <img className="avatar" src={props.authUser.avatar_url} height="30"
                                 width="30" alt=""/>{t('Отправьте сообщение')}
                        </Link> : null }
                </div>
            ) : null}

            {props.modelName === 'car' ?
                <div className="ppContacts_top">
                    <div className="ppContacts_head">
                        {props.objectName ?
                            <p className="ppContacts_objectName">{props.objectName}</p>
                        : null}
                        <p className="ppContacts_name">{props.model.user.name}</p>
                    </div>
                    {bigs ?
                        <div className="ppContacts_bigs">{bigs}</div>
                    : null}
                    <p className="ppContacts_text"
                       dangerouslySetInnerHTML={{__html: t('Не забудьте сказать, что нашли информацию на <i>{site_name}</i> : )')}}/>

                    {props.isAuth && props.model.pmLink ?
                        <Link className="ppContacts_pm" to={props.model.pmLink}
                              data-params={{id: props.model.id}}>
                            <img className="avatar" src={props.authUser.avatar_url} height="30"
                                 width="30" alt=""/>{t('Отправьте сообщение')}
                        </Link> : null }
                </div>
                : null}

            {props.modelName === 'restaurant' || props.modelName === 'venue' ?
                <div className="ppContacts_top">
                    <div className="ppContacts_head">
                        <p className="ppContacts_name">{props.model.name}</p>
                        {props.contact.address ? <p className="location_address">{props.contact.address}{landmark ?' – ' : null}{landmark}</p> : null}
                        {props.contact.address && markers.length ? <a className="button-border button-centered ppContacts_onMap"
                           onClick={() => props.showOnMap(props.model.name, markers, {overlay: MapOverlay})}>{t('Показать на карте')}</a> : null}
                    </div>
                    {bigs ?
                        <div className="ppContacts_bigs">{bigs}</div>
                    : null}
                    <p className="ppContacts_text"
                       dangerouslySetInnerHTML={{__html: t('Не забудьте сказать, что нашли информацию на <i>{site_name}</i> : )')}}/>

                    {props.isAuth && props.model.pmLink ?
                        <Link className="ppContacts_pm" to={props.model.pmLink}
                              data-params={{id: props.model.id}}>
                            <img className="avatar" src={props.authUser.avatar_url} height="30"
                                 width="30" alt=""/>{t('Отправьте сообщение')}
                        </Link> : null }
                </div>
                : null}

            {props.modelName === 'place' ?
                <div className="ppContacts_top">
                    <div className="ppContacts_head">
                        <p className="ppContacts_name">{props.model.name}</p>
                        <p className="location_address">{props.contact.address}{landmark ?' – ' : null}{landmark}</p>
                        <a className="button-border button-centered ppContacts_onMap"
                           onClick={() => props.showOnMap(props.model.name, markers, {overlay: MapOverlay})}>{t('Показать на карте')}</a>
                    </div>
                    {bigs ?
                        <div className="ppContacts_bigs">{bigs}</div>
                    : null}
                    <p className="ppContacts_text"
                       dangerouslySetInnerHTML={{__html: t('Не забудьте сказать, что нашли информацию на <i>{site_name}</i> : )')}}/>
                </div>
                : null }

            {props.modelName === 'shop' ?
                <div className="ppContacts_top">
                    <div className="ppContacts_head">
                        <p className="ppContacts_name">{branch.name || props.model.name}</p>
                        {branchAddress}
                        <a className="button-border button-centered ppContacts_onMap"
                           onClick={() => props.showOnMap(branch.name || props.model.name, markers, {overlay: MapOverlay})}>{t('Показать на карте')}</a>
                    </div>
                    {bigs ?
                        <div className="ppContacts_bigs">{bigs}</div>
                    : null}
                    <p className="ppContacts_text"
                       dangerouslySetInnerHTML={{__html: t('Не забудьте сказать, что нашли информацию на <i>{site_name}</i> : )')}}/>

                    {props.isAuth ?
                        <Link className="ppContacts_pm" to={props.model.pmLink}
                              data-params={{id: props.model.id}}>
                            <img className="avatar" src={props.authUser.avatar_url} height="30"
                                 width="30" alt=""/>{t('Отправьте сообщение')}
                        </Link> : null }
                </div>
                : null}

            {feedbacks ?
                <div className="ppContacts_feedback">
                    <p className="ppContacts_caption">{t('Поделитесь результатами общения')}</p>
                    {feedbacks}
                    <p className="ppContacts_text">{t('Ваш отзыв о результатах звонка анонимный. Служит для улучшения качества услуг {site_name}')}</p>
                </div>
                : null}
            {!(props.model.commission && (props.model.commission === 4 || props.model.commission === 5))
            && (phones.length || sites.length || socials.length) ?
                <div className="popup_footer ppContacts_footer">
                    <p className="ppContacts_caption">{t('Дополнительно')}</p>
                    {phones}
                    {sites}
                    <div className="ppContacts_socials">{socials}</div>
                </div>
                : null}
        </div>
    )
}

Contact.propTypes = {
    authUser: PropTypes.object,
    contact: PropTypes.object,
    model: PropTypes.object,
    viewInfo: PropTypes.object,
}

export default Contact
