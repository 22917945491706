import React from 'react'
import {connect} from 'react-redux'
import t from 't'

import {close} from 'actions/notice'
import {putComponentToPopup} from 'actions/popup'

import AuthPopup from 'containers/auth'

import Notice from 'components/notice/notice'
import Link from 'components/block/link'

class NoticeContainer extends React.Component {
    render() {
        if (!this.props.show) return null
        let text = this.props.text

        if(text.indexOf('<auth>') !== -1) {
            let before = text.split(/<auth>/i)
            if (before[1]) {
                let after = before[1].split(/<\/auth>/gi)
                text = [<span key="before">{before[0]}</span>,
                    <a onClick={this.props.authPopup} key="link">{after[0]}</a>, <span key="after">{after[1]}</span>]
            }
        } else if (text.indexOf('<Link') !== -1) {
            let before = text.split(/<Link[^>]*>/i)
            if (before[1]) {
                let after = before[1].split(/<\/Link>/i)
                let matches = text.match(/<Link to="([^"]+)"/);
                text = [<span key="before">{before[0]}</span>,
                    <Link key="link" to={matches[1]}>{after[0]}</Link>, <span key="after">{after[1]}</span>]
            }
        }
        return <Notice text={text} close={this.props.close}/>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        text: state.notice.text,
        show: state.notice.show,
    }
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        authPopup: () => {
            dispatch(close())
            dispatch(putComponentToPopup(t('Вход на {site_name}'), <AuthPopup/>))
        },
        close: () => dispatch(close()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoticeContainer)