import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import _ from 'lodash'

import {loadList, getList} from "actions/entity"
import Map from 'components/block/map'
import MapOverlay from 'components/venueCard/venueMapOverlay'

export const GoogleMarker = (props) => {
    return (
        <div className={'venueMap_marker' + (props.isActive ? ' _active' : '')}>
            <div className="venueMap_marker_prices">
                <p className="venueMap_marker_price">
                    <b className="venueMap_marker_cost">{props.data.model.priceRange}</b>
                </p>
            </div>
            {props.data.isFav ?
                <div className="venueMap_marker_favorite"></div>
            : null}
        </div>)
}

export const YMapTemplate = {
    regular :
        '<div class="venueMap_marker">' +
            '<div class="venueMap_marker_prices">' +
                '<p class="venueMap_marker_price">' +
                    '<b class="venueMap_marker_cost">{{ properties.data.model.priceRange|default:"" }}</b>' +
                '</p>' +
            '</div>' +
            '{% if properties.data.isFav %}' +
                '<div class="venueMap_marker_favorite"></div>' +
            '{% endif %}' +
        '</div>',
    active  :
        '<div class="venueMap_marker _active">' +
            '<div class="venueMap_marker_prices">' +
                '<p class="venueMap_marker_price">' +
                    '<b class="venueMap_marker_cost">{{ properties.data.model.priceRange|default:"" }}</b>' +
                '</p>' +
            '</div>' +
            '{% if properties.data.isFav %}' +
                '<div class="venueMap_marker_favorite"></div>' +
            '{% endif %}' +
        '</div>',
}

let scrollTop = 0
class VenueMap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: props.list,
        }
    }

    componentDidMount() {
        this.onBoundsChange()
        let $body = document.body || document.getElementsByTagName('body')[0]
        let $html = document.html || document.getElementsByTagName('html')[0]
        let $filtersArea = document.getElementById('filters_area')
        scrollTop = $html.scrollTop
        $filtersArea.classList.add('_sticky')
        $filtersArea.classList.add('_dontCheck')
        $body.classList.add('venueMap_opened')
        $html.classList.add('venueMap_opened')
        $body.style.overflow = 'hidden'
        $html.style.overflow = 'hidden'
        window.reachGoal('na-karte')
        reachGoal('microgoal_map', null, ['ya'], 'gorko.ru')
    }

    componentWillUnmount() {
        let $body = document.body || document.getElementsByTagName('body')[0]
        let $html = document.html || document.getElementsByTagName('html')[0]
        let $filtersArea = document.getElementById('filters_area')
        $filtersArea.classList.remove('_sticky')
        $filtersArea.classList.remove('_dontCheck')
        $body.classList.remove('venueMap_opened')
        $html.classList.remove('venueMap_opened')
        $body.style.overflow = 'initial'
        $html.style.overflow = 'initial'
        if (scrollTop) {
            $html.scrollTop = scrollTop
            scrollTop = null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.listParam, this.props.listParam)) {
            this.onBoundsChange()
        }
    }

    onBoundsChange(data) {
        let activeMarker = data && data.activeMarker || null
        this.props.getList(data && data.bounds)
        .then((list) => {
            if (activeMarker !== null) {
                let hasActiveMarker = false
                for (let v=0; list[v]; v++) {
                    if (list[v].id == activeMarker.model.id) {
                        hasActiveMarker = true
                        break
                    }
                }
                if (!hasActiveMarker) {
                    list.push(activeMarker.model)
                }
            }
            this.setState({list:list})
        })
    }

    markerClickCheck(mousePos, markerPos) {
        return mousePos[0] >= markerPos[0] - 40 && mousePos[0] <= markerPos[0] + 40 &&
               mousePos[1] >= markerPos[1] - 16 && mousePos[1] <= markerPos[1] + 16
    }

    render() {
        const self = this
        const state = self.state
        const props = self.props
        let params = {
            directory    : props.directory,
            overlay      : MapOverlay,
            buttons      : ['contacts', 'topage'],
            GoogleMarker : GoogleMarker,
            YMapTemplate : YMapTemplate,
            entityParam  : props.entityParam,
            controls     : ['zoomControl'],
            YMapIconSize : [96, 30],
        }
        let markers = []
        state.list.forEach((venue) => {
            if (!venue || !venue.coordinate || !venue.priceRange) {
                return
            }
            markers.push({
                lat      : venue.coordinate.latitude,
                lng      : venue.coordinate.longitude,
                model    : venue,
                isActive : false,
            })
        })

        return (
            <div className={"venueMap _" + props.mapEngine}>
                {markers.length?
                    <Map markers={markers} params={params} isList={true} buttons={params.buttons} onBoundsChange={(data) => self.onBoundsChange(data)} markerClickCheck={this.markerClickCheck} />
                : <div className="map"/>}
                <a className="venueMap_close" onClick={props.close}/>
            </div>)
    }
}

VenueMap.propTypes = {
}

function mapStateToProps(state, ownProps) {
    return {
        list: [],
        state: state,
        mapEngine: state.main.site.map_engine,
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        dispatch: dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    const dispatch = dispatchProps.dispatch
    let props = {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
    }

    props.getList = (bbox) => {
        return new Promise(function(resolve, reject) {
            let params = Object.assign({...ownProps.listParam}, {'perPage':1000, 'page': 1}) // все сразу загружаем, поэтому page=1
            let entityParam = Object.assign({...ownProps.entityParam}, {'misc':'map'})
            dispatch(loadList('venueCard', params, entityParam))
            .then(() => {
                let list = getList(stateProps.state, 'venueCard', params, entityParam)
                let coordsCache = {}
                list.forEach((venue) => {
                    if (!venue || !venue.coordinate || !venue.coordinate.latitude || !venue.coordinate.longitude) {
                        return
                    }
                    const key = venue.coordinate.latitude + '_' + venue.coordinate.longitude
                    if (!coordsCache[key]) {
                        coordsCache[key] = 0
                    }
                    coordsCache[key]++
                    if (coordsCache[key] > 1) {
                        venue.coordinate.latitude += 0.00005 + 0.000001*(coordsCache[key]-1)
                        venue.coordinate.longitude += 0.00005 + 0.000001*(coordsCache[key]-1)
                    }
                })
                resolve(list)
            })
        })
    }

    return props
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(VenueMap)
