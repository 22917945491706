import React from 'react'
import PropTypes from 'prop-types'
import t from 't'
import _ from 'lodash'

import Footer from 'components/filter/bubble/footer'
import Header from 'components/filter/bubble/header'
import Suggest from 'components/block/suggest'

import classNames from 'classnames'

export const SelectBubble = (props) => {
    let filter = props.filter
    if (!filter.items.length) {
        return null
    }

    const onClick = (key) => {
        props.onChange(filter, key)
        if (props.goal) {
            reachGoal(props.goal, null, ['ya'], 'gorko.ru')
        }
    }

    let items = []
    _.map(filter.items, (item) => {
        let className = {
            filter_item : true,
        }
        if (filter.changes.indexOf(item.key) >= 0 || (!filter.changes.length && item.key === -1)) {
            className._selected = true
        }
        items.push(<a
            key={filter.label + '_' + item.key}
            className={classNames(className)}
            onClick={() => onClick(item.key)}>{item.text}</a>)
    })

    return (
        <div className="filterBubble">
            <div className="filterBubble_content">
                <a className="filterBubble_close" onClick={() => props.onToggle(props.filter.label)}/>
                <Header caption={filter.name}/>
                <div className="filterBubble_body">{items}</div>
            </div>
        </div>)
}

SelectBubble.propTypes = {
    filter: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    goal: PropTypes.string,
}

export default SelectBubble