import React, { Suspense } from 'react'
import BigLoading from "components/block/bigLoading"

const LazyMap = React.lazy(() => import('components/block/lazyMap'))
const MapEdit = React.lazy(() => import('components/block/mapEdit'))

class Map extends React.Component {
    render() {
        return <Suspense fallback={<BigLoading/>}>
            {!this.props.isEdit ? <LazyMap {...this.props}/> : <MapEdit {...this.props}/>}
        </Suspense>
    }
}
export default Map