import t from 't'
import _ from 'lodash'

import BaseModel from 'models/baseModel'

import Media from 'models/media'
import ShopBranch from 'models/shopBranch'
import User from 'models/user'
import MapMarker from 'models/mapMarker'

export default class Shop extends BaseModel {
    constructor(data) {
        if (data.user) {
            data.user = new User(data.user)
        }
        if (data.media) {
            data.media = _.map(data.media, (media) => new Media(media))
        }
        if (data.branches) {
            data.branches = _.map(data.branches, (branch) => new ShopBranch(branch, data))
        }
        super(data)
    }

    get reducerName() {
        return 'shop'
    }

    get entityType() {
        return 'shops'
    }

    get cityName() {
        return this.firstBranch ? this.firstBranch.city.name : null
    }

    get firstBranch() {
        return this.branches && this.branches.length ? this.branches[0] : null
    }

    get pmLink() {
        return this.user.pmLink
    }

    get link() {
        return this.url
    }

    get mapMarkers() {
        let markers = []
        let isActive = false
        _.each(this.branches, branch => {
                if (branch.coords && branch.coords.latitude && branch.coords.longitude && branch.address) {
                    markers.push(new MapMarker({
                        model: branch,
                        isActive: isActive ? false : isActive = true
                    }))
                }
            }
        )
        return markers
    }
    
    tabs(checkActivePage) {
        const baseLink = this.link.replace(/https?:\/\/[^\/]+/, '')
        let tabs = [
            {
                text: t('Обзор'),
                to: baseLink,
                active: checkActivePage(),
            }
        ]
        if (this.is_has_dress) {
            tabs.push({
                text: t('Платья'),
                to: baseLink + 'dress/',
                active: checkActivePage('dress'),
            })
        }
        if (this.is_has_collections) {
            tabs.push({
                text: t('Коллекции'),
                to: baseLink + 'collection/',
                active: checkActivePage('collection'),
            })
        }
        if (this.is_has_albums) {
            tabs.push({
                text: t('Альбомы'),
                to: baseLink + 'reports/',
                active: checkActivePage('reports'),
            })
        }
        tabs.push({
            text: t('Обсуждения'),
            to: baseLink + 'comment/',
            active: checkActivePage('comment'),
        })
        return tabs;
    }
}