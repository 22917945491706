import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    OPEN: null,
    CLOSE: null,
}, 'share')

export const open = (url, title, image, text) => {
    return {
        type: types.OPEN,
        image: image,
        text: text,
        title: title,
        url: url,
    }
}

export const close = () => {
    return {
        type: types.CLOSE
    }
}