import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

import Favorite from 'components/directory/favorite'
import Gallery from 'components/block/gallery'
import Link from 'components/block/link'
import CarPrices from 'components/car/carPrices'

const CarCard = (props) => {
    const card = props.card;
    return (
        <div className="card cardCar">
            {props.favorites ?
                <Favorite isFav={card.isFav(props.favorites)} toggleFavorite={props.toggleFavorite} modelId={card.id}/>
                : null}
            <Link className="card_name" to={card} target="_blank">{card.name}</Link>
            <Gallery media={card.media} link={card} />
            <div className="cardCar_props">
                {card.params.param_length ?
                    <p className={'cardCar_prop-length'}>{card.params.param_length.value_shortText}</p>
                : null}
                {card.params.param_capacity ? <p className={'cardCar_prop-capacity'}>{card.params.param_capacity.value_shortText}</p> : null}
                {card.params.param_year ? <p className={'cardCar_prop-year'}>{card.params.param_year.value}</p> : null}
            </div>
            {card.viewer_price_in_list ?
                <CarPrices car={card} className="cardCar_rent" dateClassName="cardCar_freeDate"/>
            : null}

            <Link to={card} className="card_more">{t('Больше информации')}</Link>
        </div>
    )
}

CarCard.propTypes = {
    card: PropTypes.object.isRequired,
    showOnMap: PropTypes.func,
    toggleFavorite: PropTypes.func,
    favorites: PropTypes.array,
};

export default CarCard;
