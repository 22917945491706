import {types} from 'actions/language'

import _ from 'lodash'

const initialState =
    {
        languages: [],
        sites: [],
    }

const LanguageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_OK:
            return {
                ...state,
                languages: _.filter(action.sites, site => site.site_domain === action.currentSiteDomain),
                sites: _.filter(action.sites, site => site.site_domain !== action.currentSiteDomain),
            }
        case types.LOAD_REQUEST:
        case types.LOAD_ERROR:
            return state
        default:
            return state
    }
}

export default LanguageReducer