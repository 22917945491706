import {Route, Switch, withRouter} from "react-router"
import React, { Suspense } from 'react'
import {connect} from 'react-redux'

const ApplicationFlux = React.lazy(() => import('components/ApplicationFlux'))
const ForumRouters = React.lazy(() => import('routes/ForumRouters'))
const MediaRouters = React.lazy(() => import('routes/MediaRouters'))
const PagesRouters = React.lazy(() => import('routes/PagesRouters'))

import Application from 'containers/application'
import directoryRoutes from 'routes/directoryRoutes'

//
const DressContainer = React.lazy(() => import('containers/dress'))
const MyContainer = React.lazy(() => import('containers/my/my')) 
const MyAlbum = React.lazy(() => import('containers/my/album')) 
const UserPageContainer = React.lazy(() => import('containers/userPageContainer'))

const Routes = (props) => {
    if (!props.directory) return null
    return <Suspense fallback={null}>
        <Switch>
            {directoryRoutes(props.directory, props.langUrl)}
            <MediaRouters path={props.langUrlNotEmpty + "/:mediaType(photos|videos)/:feedType(new|popular|discussed|best|my|favorites)/"} {...props}/>
            <MediaRouters path={props.langUrlNotEmpty + "/:mediaType(photos|videos)/"} {...props}/>
            <MediaRouters path={props.langUrlNotEmpty + "/album/:id/"} exact={true} {...props}/>
            <MediaRouters path={props.anyCityInPath + props.langUrl + "/album/:id/"} exact={true} {...props}/>
            <MediaRouters path={props.langUrlNotEmpty + "/album/:albumId/:mediaId/"} {...props}/>
            <MediaRouters path={props.anyCityInPath + props.langUrl + "/album/:albumId/:mediaId/"} {...props}/>
            <MediaRouters path={props.langUrlNotEmpty + "/wedding/:type/"} {...props}/>
            <MediaRouters path={props.langUrlNotEmpty + "/wedding/"} {...props}/>
            <ApplicationFlux path={props.langUrlNotEmpty + "/pm/"} langUrlNotEmpty={props.langUrlNotEmpty}/>
            <ApplicationFlux path={props.langUrlNotEmpty + "/forum/feed/"} langUrlNotEmpty={props.langUrlNotEmpty}/>
            <Application path={props.langUrlNotEmpty + "/forum/"} exact={true}>
                <ForumRouters path={props.langUrlNotEmpty + "/forum/"} exact={true} {...props}/>
            </Application>
            <Application path={props.langUrlNotEmpty + "/forum/pro/"} exact={true}>
                <ForumRouters path={props.langUrlNotEmpty + "/forum/pro/"} exact={true} {...props}/>
            </Application>
            <Application path={props.langUrlNotEmpty + "/forum/:slug/"} exact={true}>
                <ForumRouters path={props.langUrlNotEmpty + "/forum/:slug/"} exact={true} {...props}/>
            </Application>
            <ApplicationFlux path={props.langUrlNotEmpty + "/topic/:id/LastRead/"} exact={true} langUrlNotEmpty={props.langUrlNotEmpty}/>
            <ApplicationFlux path={props.langUrlNotEmpty + "/topic/:id/LastMy/"} exact={true} langUrlNotEmpty={props.langUrlNotEmpty}/>
            <ApplicationFlux path={props.langUrlNotEmpty + "/topic/:id/"} langUrlNotEmpty={props.langUrlNotEmpty}/>
            <Application path={props.cityInPath + props.langUrl + "/"} exact={true}>
                <PagesRouters path={props.cityInPath + props.langUrl + "/"} exact={true} {...props}/>
            </Application>
            <Application path={props.langUrl + "/"} exact={true}>
                <PagesRouters path={props.langUrl + "/"} exact={true} {...props}/>
            </Application>
            <Application path={props.langUrlNotEmpty + "/blog/"} exact={true}>
                <PagesRouters path={props.langUrlNotEmpty + "/blog/"} exact={true} {...props}/>
            </Application>
            <Application path={props.langUrlNotEmpty + "/rules/"} exact={true}>
                <PagesRouters path={props.langUrlNotEmpty + "/rules/"} exact={true} {...props}/>
            </Application>
            <Application path={props.langUrlNotEmpty +"/page/:id/"} exact={true}>
                <PagesRouters path={props.langUrlNotEmpty +"/page/:id/"} exact={true} {...props}/>
            </Application>
            <Application path={props.anyCityInPath + props.langUrl + "/user/:id/"}>
                <Route path={props.anyCityInPath + props.langUrl + "/user/:id/"}
                       render={(props) => <UserPageContainer directory={{type: 'users', modelName: 'user'}}
                                                         reducerName="user" id={props.match.params.id}/>}/>
            </Application>
            <Application path={props.langUrlNotEmpty + "/dress/:id/"} exact={true}>
                <Route path={props.langUrlNotEmpty + "/dress/:id/"} exact={true}
                       render={(innerProps) => <DressContainer type="dressmodel" id={innerProps.match.params.id}/>}/>
            </Application>
            <Application path={props.langUrlNotEmpty + "/shopDress/:id/"} exact={true}>
                <Route path={props.langUrlNotEmpty + "/shopDress/:id/"} exact={true}
                       render={(innerProps) => <DressContainer type="shopdress" id={innerProps.match.params.id}/>}/>
            </Application>
            <Application path={props.langUrlNotEmpty + "/my/photoalbum/:id/"}>
                <Route path={props.langUrlNotEmpty + "/my/photoalbum/:id/"} render={(innerProps) => <MyAlbum {...innerProps}/>}/>
            </Application>
            <Application path={props.langUrlNotEmpty + "/my/:type/"}>
                <Route path={props.langUrlNotEmpty + "/my/:type/"} render={(innerProps) => <MyContainer {...innerProps}/>}/>
            </Application>
            <Application path={props.langUrlNotEmpty + "/my/"}>
                <Route path={props.langUrlNotEmpty + "/my/"} render={(innerProps) => <MyContainer {...innerProps}/>}/>
            </Application>
    </Switch>
    </Suspense>
}


function mapStateToProps(state) {
    return {
        directory: state.main.directory,
        anyCityInPath: state.main.site.is_city_in_path ? '/:cityDomain' : '',
        cityInPath: state.main.site.is_city_in_path ? '/' + state.main.city.domain : '',
        langUrl: state.main.locale.url,
        langUrlNotEmpty: state.main.locale.url_not_empty,
    }
}

export default withRouter(connect(mapStateToProps)(Routes))
