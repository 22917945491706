import React from 'react'
import PropTypes from 'prop-types'
import t from't'

const FilterFooter = (props) => {
    const onApply = () => {
        props.applyFilter(props.label)
        if (props.goal) {
            reachGoal(props.goal, null, ['ya'], 'gorko.ru')
        }
    }
    return (
        <div className="filterBubble_foot">
            <a className="button-centered" onClick={onApply}>{t('Применить')}</a>
        </div>)
}

FilterFooter.propTypes = {
    label: PropTypes.string.isRequired,
    applyFilter: PropTypes.func.isRequired,
}

export default FilterFooter