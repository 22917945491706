import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import t from 't'
import _ from 'lodash'

import {toggleFavorite} from 'actions/favorite'
import {putComponentToPopup} from 'actions/popup'
import {onVenueCall} from 'actions/venue'
import {viewInfo} from 'actions/viewInfo'

import Contact from 'containers/block/contact'
import Link from 'components/block/link'
import LinkLight from 'components/block/linkLight'

const Room = ({room, isSingle}) => {
    let coverWidth = 132
    let coverHeight = 100
    if (window.screen.availWidth >= 410 || isSingle) {
        coverWidth = 176
        coverHeight = 132
    }
    return (<div className="mapBalloon_room">
            <LinkLight to={room.url} target="_blank" className="mapBalloon_room_cover"><img className="mapBalloon_cover" src={room.media[0].url} width={coverWidth} height={coverHeight}/></LinkLight>
            <div className="mapBalloon_room_info">
                <p className="mapBalloon_room_name">{room.name}</p>
                {room.param.map((param, i) => {
                    return <Fragment key={i}>
                        <p className="mapBalloon_param_name">{param.name}</p>
                        <p className="mapBalloon_param_value">{param.text}</p>
                    </Fragment>
                })}
            </div>
        </div>)
}

const Param = (props) => {
    return (
        <div className="cardRoom_prop">
            <span className="cardRoom_prop_name">
                <span className="cardRoom_prop_text">{props.param.name}</span>
            </span>
            <span className="cardRoom_prop_value">
                <span className="cardRoom_prop_text">{props.param.text}</span>
            </span>
        </div>)
}

const VenueMapOverlay = (props) => {
    const model = props.model
    if (model.room == undefined) {
        return <div />
    }
    let buttons = []
    _.map(props.buttons, (buttonName, i) => {
        if (buttonName === 'topage') {
            if (model.url) {
                buttons.push(<LinkLight key="button_topage" className="venueMap_button-link" to={model.url} target="_blank">{t('Подробнее')}</LinkLight>)
            } else {
                buttons.push(<Link key="button_topage" className="venueMap_button-link" to={model} target="_blank">{t('Подробнее')}</Link>)
            }
        } else if (buttonName === 'contacts') {
            if (model.phone && model.contactSchema.main_method_mobile == 'call') {
                buttons.push(<a key="button_contact" className="venueMap_button-contact" href={"tel:" + model.phone} onClick={props.onVenueCall}>{t('Позвонить')}</a>)
            } else {
                buttons.push(<a key="button_contact" className="venueMap_button-contact" onClick={props.showContacts}>{t('Контакты')}</a>)
            }
        }
    })

    let ratingClass = ''
    if (model.rating && model.rating.value !== null) {
        ratingClass = 'mapBalloon_rating'
        if (model.rating.impression > 0) {
            ratingClass += '-good'
        } else if (model.rating.impression < 0) {
            ratingClass += '-bad'
        } else {
            ratingClass += '-neutral'
        }
    }

    return (<div className="mapBalloon">
            <a className={"favorite" + (props.isFav ? ' _fav' : '')} onClick={() => props.toggleFavorite(model.id, props.isFav)}></a>
            <div className="mapBalloon_name">
                {model.rating && model.rating.value !== null ?
                    <span className={ratingClass}>{model.rating.value}</span>
                : null}
                {model.url ?
                    <LinkLight className="color-black" to={model.url} target="_blank">{model.name}</LinkLight>
                :
                    <Link className="color-black" to={model} target="_blank">{model.name}</Link>
                }
            </div>
            {model.room && model.room.length ?
                <div className="mapBalloon_rooms">
                    {model.room.map(room => <Room key={room.id} model={model} room={room} isSingle={model.room.length == 1}/>)}
                </div>
            : null}
            {buttons.length ?
                <div className="mapBalloon_actions">{buttons}</div>
            : null}
        </div>)
}

VenueMapOverlay.propTypes = {
    model: PropTypes.object.isRequired,
}

function mapStateToProps(state, ownProps) {
    const directory = ownProps.params && ownProps.params.directory
    let modelName = directory.type == 'venues' ? 'venue' : 'restaurant'
    let favorites = state.favorite && state.favorite.list[modelName] ? state.favorite.list[modelName] : []
    return {
        isFav : favorites.includes(ownProps.model.id),
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    let model = ownProps.model
    let modelName = ownProps.model.reducerName
    const modelId = ownProps.model.shopId || ownProps.model.id
    const directory = ownProps.params && ownProps.params.directory
    if (!modelName) {
        modelName = directory.type == 'venues' ? 'venue' : 'restaurant'
    }
    return {
        dispatch: dispatch,
        showContacts: () => {
            let model = {
                ...ownProps.model,
                entityType  : directory.type,
                reducerName : modelName,
            }
            dispatch(putComponentToPopup(t('Контакты'), <Contact model={model} mapButtons={ownProps.buttons}/>, 'contact'))
        },
        onVenueCall: () => {
            dispatch(viewInfo(modelName, modelId, {'phone': 1}))
                .then(() => {
                    onVenueCall(model)
                })
        },
        toggleFavorite: (modelId, isFav) => {
            let modelName = directory.type == 'venues' ? 'venue' : 'restaurant'
            dispatch(toggleFavorite(directory.type, modelName, modelId, isFav))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VenueMapOverlay)
