import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import t from 't'

import Scroller from "components/block/scroller"
import SimpleImg from "components/block/simpleImg"

class Banner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            width: typeof window === 'undefined' ? 0 : Math.min((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth), 800)
        }
    }

    handleResize() {
        this.setState({
            width: typeof window === 'undefined' ? 0 : Math.min((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth), 800)
        })
    }
    componentDidMount() {
        window.addEventListener('resize', () => this.handleResize())
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.handleResize())
    }

    render() {
        const self = this
        const props = self.props
        if (!props.banner || !props.banner.length) {
            return null
        }
        let banner = props.banner[props.bannerNum]
        return <a key={banner.id} className="hero" href={banner.link} target="_blank"><SimpleImg className="hero_cover" isCrop={true} isAutoWidth={true} isForceVisible={false} simpleImg={banner.img}/></a>
    }
}

function mapStateToProps(state, ownProps) {
    let banner = []
    if (ownProps && ownProps.banner && ownProps.banner.length) {
        banner = ownProps.banner
    } else {
        banner = state.main.banner
    }
    return {
        banner : banner,
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        dispatch : dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner)
