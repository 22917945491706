export const initPhoneCallCheck = (goals, params, callback) => {
    // Трекаем их в метрику и аналитику когда:
    //
    // Происходит событие phoneClick и phoneComVenueClick соответвенно
    // Ни в какой момент в течении 15 секунд после этого браузер не активен. Если активен - события phoneClick2 и phoneComVenueClick2 уже не могут сработать.
    // В какой-то момент времени после этого браузер опять активен - в этот момент и трекаются события phoneClick2 и phoneComVenueClick2
    const callTimer = 15000 // время разговора
    const waitTimer = 5000 // время чтобы нажать кнопку звонка
    let checkHideTimeout = null
    let checkShowTimeout = null
    let listener = () => {
        // если waitTimer ms не срабатывает скрытие экрана - не стали звонить
        checkHideTimeout = setTimeout(() => {
            document.removeEventListener('visibilitychange', listener)
        }, waitTimer)
        if (document.visibilityState === 'visible') {
            // вернулись на страницу, звонок завершен, останавливаем таймер checkShowTimeout, удаляем лиснер
            console.log('pageshow')
            clearTimeout(checkShowTimeout)
            document.removeEventListener('visibilitychange', listener)
        } else {
            // Предположительный звонок, останавливаем таймер checkHideTimeout, запускаем checkShowTimeout
            console.log('pagehide')
            clearTimeout(checkHideTimeout)
            checkShowTimeout = setTimeout(() => {
                goals.forEach((goal) => {
                    reachGoal(goal, params)
                })
                callback()
            }, callTimer)
        }
    }
    document.addEventListener('visibilitychange', listener)
}