import BaseModel from 'models/baseModel'

export default class Directory extends BaseModel {

    get key() {
        return this.model.key
    }

    get title() {
        return this.model.plural
    }

    get apiUrl() {
        return API_URL + this.api_path
    }

    get path() {
        let re = new RegExp('https?://[^/]*')
        return this.url.replace(re, '')
    }

    get myFavoritesCount() {
        return this.model.my_favorites_count
    }

    cityLink(city, curCity) {
        return this.url.replace(curCity.domain, city.domain)
    }

    get modelName() {
        switch (this.type) {
            case 'users':
                return 'user'
            case 'places':
                return 'place'
            case 'cars':
                return 'car'
            case 'restaurants':
                return 'restaurant'
            case 'shops':
                return 'shop'
            case 'venues':
                return 'venue'
            default:
                return ''
        }
    }

    get link() {
        return this.url
    }
}