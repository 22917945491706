import React from 'react'
import {connect} from 'react-redux'
import t from 't'
import PropTypes from 'prop-types'

import {close} from 'actions/popup'

const Category = (props) => {
    let name = props.category.model.plural
    if (props.search) {
        let reg = new RegExp('(.*?)(' + props.search + ')(.*?)', 'i')
        name = name.replace(reg, "$1<b>$2<b>$3")
        name = name.split('<b>')
    }
    return (
        <a className={"ppSuggest_item" + (props.isCurrent ? ' _cur' : '')} onClick={() => props.onSelect(props.category)}>
            {(typeof name).toLowerCase() === 'string' ?
                name
            :
                <span>{name[0]}<b>{name[1]}</b>{name[2]}</span>
            }
        </a>)
}

class Categories extends React.Component
{
    constructor(props) {
        super(props)
        this.state = {
            search: '',
        }
    }

    search(text) {
        this.setState({
            search: text,
        })
    }

    onSelect(category) {
        if (this.props.onSelect) {
            this.props.onSelect(category)
        }
        this.props.close()
    }

    render() {
        const self = this
        const state = self.state
        const props = self.props

        let items = []
        props.list.map((category, i) => {
            if ( state.search && !category.model.name.toLowerCase().includes(state.search.toLowerCase()) ) {
                return
            }
            let isCurrent = props.current && props.current.type == category.type && props.current.model.id == category.model.id
            items.push(<Category key={i} category={category} isCurrent={isCurrent} search={state.search} onSelect={(category) => self.onSelect(category)}/>)
        })
        return (
            <div className="ppSuggest">
                <div className="ppSuggest_head">
                    <div className={"ppSuggest_search" + (!state.search ? ' _empty' : '')}>
                        <input className="ppSuggest_field" placeholder={t('Выберите раздел для поиска')}
                            onInput={(event) => this.search(event.target.value)}
                        />
                        {state.search ?
                            <a className="ppSuggest_reset" onClick={(ev) => {
                                var $search = ev.target.previousElementSibling
                                var event = new Event('input', {
                                    bubbles: true,
                                    cancelable: true,
                                })
                                $search.value = ''
                                $search.dispatchEvent(event)
                            }}></a>
                        : null}
                    </div>
                    <a className="ppSuggest_close" onClick={() => props.close()}>{t('Закрыть')}</a>
                </div>
                <div className="ppSuggest_items">{items}</div>
            </div>)
    }
}

function mapStateToProps(state, ownProps) {
    return {

    }
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        close: () => dispatch(close()),
        dispatch: dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories)