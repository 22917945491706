import axios from 'axios'

export const skipActual = () => {
    return new Promise(function(resolve, reject) {
        axios.post(API_URL + '/v2/my/ignoreBusyEvent', {}, {withCredentials: true})
        .then(response => {
            resolve(true)
        })
        .catch(() => {
            resolve(false)
        })
    })
}
