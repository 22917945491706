import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

const ShowMore = (props) => {
    if (!props.meta || !props.meta.totalPages || props.meta.isLastPage) return null
    let nextPage = props.nextPage
    if (!nextPage) {
        nextPage = props.meta.nextPage
        if (props.isPassNextPage && !props.meta.nextPage) {
            nextPage = props.meta.page + 1
        }
    }
    return (
        <a onClick={props.loading ? null : (props.isPassNextPage ? () => props.loadData({page: nextPage}) : props.loadData.bind(this))}
           className={'button-centered showMore' + (props.loading ? ' _loading' : '')}>{t('Показать ещё')}</a>
    )
}

ShowMore.propTypes = {
    meta: PropTypes.object,
    nextPage: PropTypes.number,
    loadData: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isPassNextPage: PropTypes.bool, //todo в идеале всегда нужно передавать следующую страницу
}

export default ShowMore

