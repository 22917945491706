import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from "lodash"

const LinkLight = ({children, to, className, onClick, target, isSaveMediaReturnUrl, returnLocation}) => {
    if ('development' === process.env.NODE_ENV) {
        let re = new RegExp('https?:\/\/[^.]*.?' + document.location.hostname + '\/')
        to = to.replace(re, 'http://' + document.location.hostname + ':8000/')
        target = '_self'
    }

    if (to.indexOf('http') === -1 || to.indexOf(document.location.origin) !== -1) { // если в ссылке нет http или если есть и это origin
        return <RouterLink className={className} onClick={onClick}
                           to={to.replace(document.location.origin, '')}
                           target={target === '_blank' ? target : null}>{children}</RouterLink>
    } else {
        if (isSaveMediaReturnUrl) {
            return <a href={to} className={className} target={target ? target : null} onClick={() => {
                let date = new Date()
                date.setTime(date.getTime() + (30 * 1000))
                document.cookie = 'media_returnUrl=' + document.location.href + '; domain=.' + SITE_DOMAIN + '; path=/; secure; expires=' + date.toGMTString()
            }}>{children}</a>
        } else {
            return <a href={to} className={className} target={target ? target : null}>{children}</a>
        }
    }
}

LinkLight.propTypes = {
    className: PropTypes.string,
    isSaveMediaReturnUrl: PropTypes.bool,
    onClick: PropTypes.func,
    target: PropTypes.string,
    to: PropTypes.string.isRequired,
}

export default LinkLight
