import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import t from 't'
import {Redirect} from 'react-router'
import {connect} from 'react-redux'

import Banner from "containers/block/banner"
import BottomLinks from 'components/directory/bottomLinks'
import CallButton from 'components/directory/callButton'
import Card from 'components/car/card'
import CuttedText from 'components/block/cuttedText'
import CarRentalCard from 'components/car/carRentalCard'
import EmptyFavorites from 'components/block/emptyFavorites'
import EmptyList from 'components/block/emptyList'
import Filters from 'components/filter/filters'
import Pagination from 'components/block/pagination'
import ShowMore from 'components/block/showMore'
import BigLoading from 'components/block/bigLoading'
import Link from 'components/block/link'

import Loading from "components/block/loading"
import ReactProgressiveList from "react-progressive-list"

class CarList extends React.Component {
    render() {
        let props = this.props
        const isFavPage = props.queryString.indexOf('is_fav=1') !== -1
        const isCompany = props.location.indexOf('carrental') !== -1
        if (isCompany && props.queryString.indexOf('type=auto') === -1 && props.queryString.indexOf('type=limousine') === -1) {
            return <Redirect to='/carrental/?type=auto'/>
        }
        const carKey = props.queryString.indexOf('type=auto') !== -1 ? 'auto' : 'limousine'
        const carDirectory = isCompany ? _.filter(props.directoryList, (directory) => directory.type === 'cars' && directory.model.key === carKey)[0] : props.directory
        const carRentalDirectory = isCompany ? props.directory : _.filter(props.directoryList, (directory) => directory.type === 'users' && directory.model.key === 'car')[0]

        let switcherText
        let switcherLink
        if (isCompany) {
            if (carDirectory.model.key == 'auto') {
                switcherText = t('Смотреть автомобили')
            } else {
                switcherText = t('Смотреть лимузины')
            }
            switcherLink = carDirectory.url
        } else {
            if (carDirectory.model.key == 'auto') {
                switcherText = t('Смотреть автомобильные компании')
            } else {
                switcherText = t('Смотреть лимузинные компании')
            }
            switcherLink = carRentalDirectory.url + '?type=' + carDirectory.model.key
        }

        const countCars = props.list.length
        let list = [...props.list]
        if (!isFavPage && props.banner.length) {
            list.splice(2, 0, {
                id: 'banner',
                num : 0,
            })
            if (props.banner.length > 1) {
                list.splice(5, 0, {
                    id: 'banner',
                    num : 1,
                })  
            }
        }
        return (
            <div className='main'>
                <div className="catalog">
                    <Link className="catalog_switcher" to={switcherLink}>{switcherText}</Link>
                    {!isFavPage ?
                        <Filters filters={props.filters} changeFilters={props.changeFilters}
                                 toggleShowFilters={props.toggleShowFilters}
                                 showCalendar={props.showCalendar}
                                 applyFilter={props.applyFilter}
                                 resetFilter={props.resetFilter}
                                 expandFilterGroup={props.expandFilterGroup}/>
                        : null}
                    {props.h1 ? <h1>{props.h1}</h1> : null}
                    {props.h1text ?
                        <CuttedText text={props.h1text}/>
                        : null}

                    {countCars ? <ReactProgressiveList
                        initialAmount={(props.isLanding || !props.h1text) && countCars>1 ? 2 : 1}
                        progressiveAmount={5}
                        renderItem={(index) => list[index] ? (list[index].id === 'banner' ? <Banner key="banner" bannerNum={list[index].num}/> :
                            (isCompany ?
                            <CarRentalCard key={list[index].id} card={list[index]} favorites={props.favorites}
                                           toggleFavorite={props.toggleFavorite} carDirectory={carDirectory}/>
                            :
                            <Card key={list[index].id} card={list[index]} favorites={props.favorites}
                                  toggleFavorite={props.toggleFavorite}/>)
                        ): null}
                        renderLoader={() => <Loading isNoBorder={true}/>}
                        rowCount={list.length}
                        useWindowScroll={true}
                        scrollBuffer={250}
                    /> : (props.loading || props.meta === null ? <BigLoading/> : null)}

                    {countCars ?
                        <ShowMore meta={props.meta} loadData={props.loadMoreData} loading={props.loading}/> : null}
                    {countCars ?
                        <Pagination meta={props.meta} loadData={props.loadData} loading={props.loading}
                                    useGet={true}/> : null}

                    {props.links.length ?
                        <BottomLinks links={props.links}/>
                        : null}
                    {props.bottom ?
                        <div className="catalog_text" dangerouslySetInnerHTML={{__html: props.bottom}}/>
                        : null}

                    {props.meta && props.meta.totalPages === 0 ? isFavPage ?
                        <EmptyFavorites directoryName={props.directory.model.plural}/> : <EmptyList/> : null}

                    {props.directory.inquire ?
                        <CallButton phone={props.directory.inquire.phone} inquire={props.directory.inquire.inquire}/>
                        : null}
                </div>
            </div>
        )
    }
}

CarList.propTypes = {
    directory: PropTypes.object.isRequired,
    favorites: PropTypes.array,
    list: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loadData: PropTypes.func.isRequired,
    loadMoreData: PropTypes.func.isRequired,
    meta: PropTypes.object,
    toggleFavorite: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {
    return {
        banner : state.main.banner,
    }
}

export default connect(mapStateToProps)(CarList)

