import React from 'react'
import t from 't'

import Link from 'components/block/link'

export const SelectionButton = () => {
    const onClick = (ev) => {
        window.scrollTo(0, 0)
    }
    let url = document.location.href
    if (url.includes('choice=')) {
        url = document.location.href.replace(/(&|)choice=1/, '')
        return (
            <div className="filter-selection">
                <Link to={url} className="filter_selector" onClick={onClick}>{t('Все')}</Link>
                <span className="filter_selector-gorko _selected">{t('Выбор {site_name}')}</span>
            </div>)
    } else {
        url += (url.includes('?') ? '&' : '?') + 'choice=1'
        return (
            <div className="filter-selection">
                <span className="filter_selector _selected">{t('Все')}</span>
                <Link to={url} className="filter_selector-gorko" onClick={onClick}>{t('Выбор {site_name}')}</Link>
            </div>)
    }
}

SelectionButton.propTypes = {
}

export default SelectionButton
