import t from 't'
import _ from 'lodash'
import BaseModel from 'models/baseModel'

import Media from 'models/media'
import Room from 'models/room'
import User from 'models/user'

export default class Venue extends BaseModel {
    constructor(data) {
        if (data.user) {
            data.user = new User(data.user)
        }
        if (data.covers) {
            data.covers = _.map(data.covers, (media) => new Media(media))
        }
        if (data.rooms) {
            data.rooms = _.map(data.rooms, (room) => new Room(room))
        }
        super(data)
    }
    get reducerName() {
        return 'venue'
    }
    get entityType() {
        return 'venues'
    }
    get accountText() {
        return this.account_text;
    }
    getCoverUrl(size) {
        return this.cover_url[size] ? this.cover_url[size] : (typeof this.cover_url === 'string' ? this.cover_url : this.cover_url[Object.keys(this.cover_url)[0]])
    }

    get cityName() {
        return this.city.name
    }

    get isPro() {
        return this.top;
    }

    get panoramaLink() {
        return this.panorama_url;
    }

    get reviewsCount() {
        return this.reviews_counts ? this.reviews_counts.total : 0
    }

    get reviewsCounts() {
        return this.reviews_counts;
    }

    get roomsCountText() {
        return this.rooms_count_text;
    }

    get link() {
        return this.url
    }

    get linkSpec() {
        return this.spec_url
    }

    get pmLink() {
        return this.user ? this.user.pmLink : null
    }
    
    tabs(checkActivePage) {
        let tabs = [
            {
                text: t('Обзор'),
                to: this.link,
                active: checkActivePage(''),
            },
            {
                text: t('Фото и видео'),
                to: this.link + 'gallery/',
                active: checkActivePage('gallery'),
            },
            {
                text: t('Обсуждения'),
                to: this.link + 'comment/',
                active: checkActivePage('comment'),
            }
        ]

        if (this.reviewsCount) {
            tabs.push({
                text: t('Отзывы'),
                to: this.link + 'rating/',
                active: checkActivePage('rating'),
            })
        }
        if (this.panoramaLink) {
            tabs.push({
                text: t('Панорамы'),
                to: this.link + 'panorama/',
                active: checkActivePage('panorama'),
            })
        }
        return tabs
    }
}