import React from 'react'
import PropTypes from 'prop-types'

const Notice = (props) => {
    return <div className="notifies">
        <div className="notify">
            {props.text}
            <a className="notify_close" onClick={() => props.close()}/>
        </div>
    </div>
}

Notice.propTypes = {
    close: PropTypes.func,
    text: PropTypes.any,
}

export default Notice