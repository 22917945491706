import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_REQUEST: null,
    LOAD_OK: null,
    LOAD_ERROR: null,
}, 'city')

const PER_PAGE = 10
export const searchCities = (name, city = null) => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_REQUEST
        })
        if (!city && city!==false) {
            city = getState().main.city
        }
        let url = name ?
            API_URL + '/v2/cities?name=' + name + '&per_page=' + PER_PAGE + '&fields=url' :
            API_URL + '/v2/cities?name=&per_page=' + PER_PAGE + '&fields=url&-id=' + city.id + '&region_id=' + city.region_id
        return axios.get(url, {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_OK,
                    cities: name ? response.data.cities : (response.data.user_city ? [response.data.user_city] : []),
                    neighbors: name ? [] : response.data.cities,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_ERROR
                })
            })
    }
}

export const search = (partOfName, limit = 7) => {
    return new Promise(function(resolve, reject) {
        axios.get(API_URL + '/v2/cities?name=' + partOfName + '&per_page=' + limit + '&only_cities=1', {withCredentials: true})
        .then(response => {
            resolve(response.data.cities)
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
            resolve([])
        })
    })
}


let districtsCache = {}
export const districtsFormatForField = (districts, cityIdForSave = null) => {
    let list = []
    if (cityIdForSave && districtsCache[cityIdForSave] === undefined) {
        districtsCache[cityIdForSave] = districts
    }
    districts.forEach(district => {
        list.push({
            value : district.id,
            text  : district.name,
        })
        if (district?.children?.length) {
            district.children.forEach(subDistrict => {
                list.push({
                    text  : ' – ' + subDistrict.name,
                    value : subDistrict.id,
                })
            })
        }
    })
    return list
}

export const getDistricts = (cityId, formatted = false) => {
    return new Promise(function(resolve, reject) {
        if (!cityId) {
            resolve([])
            return
        }
        if (districtsCache[cityId]) {
            if (formatted) {
                resolve(districtsFormatForField(districtsCache[cityId]))
            } else {
                resolve(districtsCache[cityId])
            }
            return
        }
        const url = API_URL + '/v2/cities/' + cityId + '/districts'
        axios.get(url, {withCredentials: true})
        .then(response => {
            districtsCache[cityId] = []
            let children = {}
            response.data.districts.forEach(district => {
                if (!district.parent_id) {
                    districtsCache[cityId].push(district)
                    if (!children[district.id]) {
                        children[district.id] = []
                    }
                    district.children = children[district.id]
                } else {
                    if (!children[district.parent_id]) {
                        children[district.parent_id] = []
                    }
                    children[district.parent_id].push(district)
                }
            })
            children = null
            if (formatted) {
                resolve(districtsFormatForField(districtsCache[cityId]))
            } else {
                resolve(districtsCache[cityId])
            }
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
            resolve([])
        })
    })
}

