import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import _ from 'lodash'

const Link = (props) => {
    let to = props.to
    let toPopup = false
    if (typeof to === 'undefined') {
        if ('development' === process.env.NODE_ENV) {
            console.log({badLink: props})
        }
        return null
    }
    let isSaveReturnUrl = false
    if (typeof to !== 'string') {
        if (to.modelName) {
            if (to.modelName === 'Media') {
                toPopup = true
                to = to.link
                if (to.indexOf(document.location.origin) === -1) {
                    isSaveReturnUrl = true
                }
            } else if (to.modelName === 'Car') {
                toPopup = true
                to = to.link
            } else if (to.modelName === 'Community') {
                toPopup = true
                to = SITE_URL + props.langUrlNotEmpty + to.path
            } else if (to.modelName === 'Dress') {
                toPopup = true
                to = to.link
            } else if (to.modelName === 'Album') {
                to = to.link
            }
        } else {
            to = to.link || to.url
        }
    }
    if (props.state && props.state.form === 'modal') {
        toPopup = true
        let re = new RegExp('https?:\/\/[^.]*.?' + props.siteDomain)
        to = to.replace(re, '') + '?form=modal'
    }
    if ('development' === process.env.NODE_ENV) {
        let re = new RegExp('https?:\/\/[^.]*.?' + props.siteDomain + '\/')
        to = to.replace(re, 'http://' + props.siteDomain + ':8000/')
    }
    if (!props.isError && (to.indexOf('http') === -1 || to.indexOf(document.location.origin) !== -1)) { // если в ссылке нет http или если есть и это origin
        if (toPopup) {
            return <a href={to} className={props.className} onClick={(event) => {
                event.preventDefault()
                props.pushLink(to.replace(document.location.origin, ''), props.location)
            }}>{props.children}</a>
        } else {
            return <RouterLink className={props.className} onClick={props.onClick}
                               to={to.replace(document.location.origin, '')}
                               target={props.target==='_blank' ? props.target : null}>{props.children}</RouterLink>
        }
    } else {
        if (isSaveReturnUrl) {
            return <a href={to} className={props.className} target={props.target ? props.target : null} onClick={() => {
                let date = new Date()
                date.setTime(date.getTime() + (30 * 1000))
                document.cookie = 'media_returnUrl=' + document.location.href + '; domain=.' + SITE_DOMAIN + '; path=/; secure; expires=' + date.toGMTString()
            }}>{props.children}</a>
        } else {
            return <a href={to} className={props.className}
                      target={props.target ? props.target : null}>{props.children}</a>
        }
    }
}

Link.propTypes = {
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]).isRequired,
    className: PropTypes.string,
    state: PropTypes.object,
}

function mapStateToProps(state, ownProps) {
    return {
        location: ownProps.history.location,
        siteDomain: state.main.site.domain,
        langUrlNotEmpty: state.main.locale.url_not_empty,
        isError: !!state.main.error,
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    let state = ownProps.state || {}
    return {
        pushLink: (link, returnLocation) => ownProps.history.push(link, _.merge(state, {
            returnLocation: returnLocation,
            x: window.pageXOffset,
            y: window.pageYOffset
        })),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Link))
