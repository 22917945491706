import _ from 'lodash'
import {types} from 'actions/filters'
import {LOCATION_CHANGE} from 'react-router-redux'
import dayjs from 'dayjs'

const initialState = {
    list: [],
    friendlyUrls: [],
    baseUrl: '',
    queryString: '',
    loaded: false,
    opened : null,
    isOverlay: false,
    dateChanged: false,
    date: null,
    params : null,
    loadedKey: null,
}

const checkDepends = (filters) => {
    return _.map(filters, filter => {
        let disabled = false
        if (filter.depends) {
            disabled = []
            for (let label in filter.depends) if (filter.depends.hasOwnProperty(label)) {
                disabled.push(label)
                for (let i=0; filters[i]; i++) {
                    let dependFilter = filters[i]
                    if (dependFilter.label != label) {
                        continue
                    }
                    let valueIndex = dependFilter.selected.indexOf(filter.depends[label])
                    if (valueIndex > 0 || valueIndex === 0) {
                        disabled.pop()
                        break
                    }
                }
            }
        }
        filter.disabled = (disabled !== false && disabled.length)
        return filter
    })
}

function getFiltersQueryStringAndSelected(inFriendlyUrls, inQueryString, inFilters) {
    inQueryString = decodeURIComponent(inQueryString)
    let selected = {}
    let friendlyUrls = []
    if (inFriendlyUrls) {
        friendlyUrls = inFriendlyUrls.filter(function (url) {
            if ('development' === process.env.NODE_ENV) {
                return url.url.replace(/https:\/\/[^\/]+/, '') === decodeURI(document.location.pathname)
            } else {
                return url.url === document.location.origin + decodeURI(document.location.pathname)
            }
        })
    }
    let queryString = []
    if (friendlyUrls.length) {
        queryString = friendlyUrls[0].params.split('&')
    } else {
        queryString = inQueryString.split('&')
    }
    _.each(queryString, (qItem) => {
        if (qItem) {
            const splited = qItem.split('=')
            const label = splited[0]
            let filter = _.filter(inFilters, {label: label})[0]
            selected[label] = splited[1].split(',')
            if (filter && (filter.type === 'select' || filter.type === 'suggest')) {
                selected[label] = _.map(selected[label], value => {
                    return /\d+/.test(value) ? +value : value
                })
                _.map(filter.items, item => {
                    if (selected[label].indexOf(item.key) >= 0 && item.items && item.items.length) {
                        _.map(item.items, subItem => {
                            selected[label].push(subItem.key)
                        })
                    }
                })
            }
        }
    })
    // page выбирается отдельно src/scripts/containers/directoryList.js:59
    let comCheck = document.location.search.match(/(?:\&|\?)com=([\w\-]+)/)
    if (comCheck) {
        if (!queryString.includes('com=' + comCheck[1])) {
            queryString.push('com=' + comCheck[1])
        }
    }
    let isFavCheck = document.location.search.match(/(?:\&|\?)is_fav=([\w\-]+)/)
    if (isFavCheck) {
        if (!queryString.includes('is_fav=' + isFavCheck[1])) {
            queryString.push('is_fav=' + isFavCheck[1])
        }
    }
    let isFirstIdCheck = document.location.search.match(/(?:\&|\?)first_id=([\w\-]+)/)
    if (isFirstIdCheck) {
        if (!queryString.includes('first_id=' + isFirstIdCheck[1])) {
            queryString.push('first_id=' + isFirstIdCheck[1])
        }
    }

    const dateFilter = _.filter(inFilters, {label: 'date'})
    let selectedDate = selected['date'] || (dateFilter.length ? dateFilter[0].current : null) || null
    if (selectedDate !== null) {
        if (Array.isArray(selectedDate)) {
            selectedDate = selectedDate[0]
        }
        let current = dayjs(selectedDate).format('YYYY-MM-DD')
        let today = dayjs().format('YYYY-MM-DD')
        if (current >= today) {
            selected['date'] = [current]
        } else if (selected['date']) {
            delete selected['date']
        }
    }

    return [queryString, selected]
}

const FiltersReducer = (state = initialState, action) => {
    let selected = {}
    let queryString = []
    switch (action.type) {
        case types.RESET_FILTERS:
            return initialState
        case types.LOAD_FILTERS_OK:
            [queryString, selected] = getFiltersQueryStringAndSelected(action.friendlyUrls, action.queryString, action.filters)
            return {
                ...state,
                loaded: true,
                list: checkDepends(_.map(action.filters, (filter) => {
                    if (filter.label === 'date' && state.date) {
                        return {
                            ...filter,
                            selected: [state.date],
                            items: _.map(filter.items, item => {
                                return {...item, key: item.key}
                            }),
                            changed: false,
                        }
                    } else {
                        return {
                            ...filter,
                            selected: selected[filter.label] ? selected[filter.label] : (filter.default ? filter.default : []),
                            items: _.map(filter.items, item => {
                                return {...item, key: item.key}
                            }),
                            changed: false,
                        }
                    }
                })),
                loadedKey: action.loadedKey,
                friendlyUrls: action.friendlyUrls,
                baseUrl: action.baseUrl,
                queryString: queryString
            }

        case LOCATION_CHANGE:
            [queryString, selected] = getFiltersQueryStringAndSelected(state.friendlyUrls, action.payload.search.replace('?',''), state.list)
            return {
                ...state,
                list: checkDepends(_.map(state.list, (filter) => {
                    return {
                        ...filter,
                        selected : selected[filter.label] ? selected[filter.label] : (filter.default ? filter.default : []),
                        items    : _.map(filter.items, item => {return {...item, key: item.key}}),
                        changed  : false,
                    }
                })),
                queryString: queryString
            }

        case types.LOAD_FILTERS_REQUEST:
        case types.LOAD_FILTERS_ERROR:
            return {
                ...state,
                loaded: false,
                list: [],
            }

        case types.CHECK_OVERLAY:
            return {
                ...state,
                isOverlay: action.isOverlay,
            }

        case types.LOAD_SUGGEST_OK:
            _.each(action.queryString.split('&'), (item) => {
                if (item) {
                    const splited = item.split('=')
                    selected[splited[0]] = splited[1].split(',')
                }
            })
            return {
                ...state,
                loaded: true,
                list: _.map(state.list, (filter) => {
                    if (filter.label == action.label) {
                        return {
                            ...filter,
                            items: action.result,
                            selected: selected[filter.label] ? selected[filter.label] : [],
                        }
                    } else {
                        return filter
                    }
                })
            }
        case types.LOAD_SUGGEST_REQUEST:
        case types.LOAD_SUGGEST_ERROR:
            return {
                ...state,
                list: _.map(state.list, (filter) => {
                    if (filter.label == action.label) {
                        filter.items = []
                        filter.selected = []
                    }
                    return filter
                })
            }
        case types.TOGGLE_SHOW:
            const isOpen = state.opened !== action.label
            return {
                ...state,
                list : _.map(state.list, filter => {
                    if (filter.label === action.label) {
                        if (isOpen) {
                            filter.changes = filter.selected.slice()
                        } else {
                            filter.changes = []
                        }
                    }
                    filter.changed = false
                    return filter
                }),
                params : action.params || null,
                opened: isOpen ? action.label : null
            }

        case types.CHANGE:
            console.log(action.value)
            return {
                ...state,
                list: _.map(state.list, filter => {
                    if (filter.label === action.filter.label) {
                        if (filter.type === 'date' || filter.type === 'suggest') {
                            filter.changes = [action.value]
                        } else if (filter.multiple === false) {
                            filter.changes = [action.value]
                        } else {
                            let curItem = null
                            let parentItem = null
                            for (let i=0; filter.items[i] && curItem === null; i++) {
                                let item = filter.items[i]
                                if (item.key == action.value) {
                                    curItem = item
                                    break
                                }
                                if (item.items && item.items.length) {
                                    for (let j=0; item.items[j]; j++) {
                                        if (item.items[j].key == action.value) {
                                            curItem = item.items[j]
                                            parentItem = item
                                            break
                                        }
                                    }
                                }
                            }
                            if (!curItem) {
                                return filter
                            }
                            if (action.value === -1) {
                                filter.changes = [+action.value]
                            } else if (filter.changes.indexOf(action.value) < 0) {
                                filter.changes = _.pull(filter.changes, -1)
                                filter.changes.push(action.value)
                                if (curItem.items && curItem.items.length) {
                                    _.map(curItem.items, item => {
                                        if (filter.changes.indexOf(item.key) < 0) {
                                            filter.changes.push(+item.key)
                                        }
                                    })
                                }
                            } else {
                                filter.changes = _.pull(filter.changes, action.value)
                                if (curItem.items && curItem.items.length) {
                                    _.map(curItem.items, item => {
                                        if (filter.changes.indexOf(item.key) >= 0) {
                                            filter.changes = _.pull(filter.changes, item.key)
                                        }
                                    })
                                } else if (parentItem !== null) {
                                    filter.changes = _.pull(filter.changes, parentItem.key)
                                }
                            }
                            filter.changes = _.sortedUniq(filter.changes.sort())
                        }
                        if (filter.currentSelect) {
                            filter.currentSelect.changes = filter.changes
                        }
                    }
                    filter.changed = true
                    return filter
                }),
            }

        case types.APPLY:
            let nextFilter = null
            let nextOpened = null
            let applyList = checkDepends(_.map(state.list, filter => {
                if (filter.label === action.label) {
                    filter.selected = filter.changes.slice()
                    if (filter.next) {
                        nextOpened = filter.next
                    }
                }
                return filter
            }))
            if (nextOpened) {
                applyList = _.map(state.list, filter => {
                    if (filter.label === nextOpened) {
                        if (!filter.selected || !filter.selected.length) {
                            filter.changes = []
                            nextFilter = filter
                        } else {
                            nextOpened = null
                        }
                    }
                    return filter
                })
            }
            return {
                ...state,
                opened : nextOpened,
                params : action.params || null,
                list   : applyList,
            }

        case types.RESET:
            return {
                ...state,
                list : _.map(state.list, filter => {
                    if (filter.label === action.label) {
                        filter.selected = []
                    }
                    return filter
                }),
                opened: null
            }

        case types.SET_FILTERS:
            return {
                ...state,
                list : _.map(state.list, filter => {
                    if (typeof action.filters[filter.label] !== 'undefined' && (filter.label!=='date' || action.filters[filter.label]!==0)) {
                        filter.selected = typeof action.filters[filter.label] === 'object' ? action.filters[filter.label] : [action.filters[filter.label]]
                    }
                    return filter
                }),
                isOverlay: false, // сейчас это используется для простановки связки data+price в оверлее
            }

        case types.EXPAND:
            return {
                ...state,
                list: _.map(state.list, filter => {
                    if (filter.label === action.label) {
                        _.map(filter.items, item => {
                            if (!filter.expanded) {
                                filter.expanded = []
                            }
                            if (filter.expanded.indexOf(action.key) < 0) {
                                filter.expanded.push(action.key)
                            }
                            return item
                        })
                    }
                    return filter
                }),
            }

        case types.SET_QUERYSTRING:
            return {
                ...state,
                queryString: action.queryString
            }

        case types.DATE_CHANGE:
            if (action.changed) {
                return {
                    ...state,
                    dateChanged: action.changed,
                }
            } else {
                return {
                    ...state,
                    list: _.map(state.list, filter => {
                        if (filter.label === 'date') {
                            if (action.date) {
                                filter.current = action.date
                                filter.selected = [action.date]
                            } else {
                                filter.current = null
                                filter.selected = []
                            }
                        }
                        return filter
                    }),
                    date: action.date,
                    dateChanged: action.changed,
                }
            }

        default:
            return state
    }
}
export default FiltersReducer
