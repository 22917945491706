import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

export default class CuttedText extends React.Component {
    constructor(props) {
        super(props)
        this.state = {opened: props.opened || false}
    }

    toggle() {
        this.setState({
            opened: !this.state.opened
        })
    }

    render() {
        if (!this.props.text) return null
        let shortText = null
        let endOfText = null
        if (this.props.text.length > 600) {
            endOfText = this.props.text.replace(/^.{600}.*?[\s\.\,]/g,'')
            if (endOfText == '') {
                endOfText = null
            } else {
                shortText = this.props.text.replace(endOfText, '')
            }
        }
        if (endOfText === null) {
            shortText = this.props.text
        }

        let className = ['cuttedText']
        if (!this.state.opened) {
            className.push('_collapsed')
        }
        if (this.props.className) {
            className.push(this.props.className)
        }

        return (
            <div className={className.join(' ')}>             
                <span dangerouslySetInnerHTML={{__html: shortText}}/>
                {endOfText !== null ?
                    <span className="cuttedText_end" dangerouslySetInnerHTML={{__html: endOfText}}/>
                : null}
                {endOfText !== null && !this.state.opened ?
                    <a onClick={this.toggle.bind(this)} className="cuttedText_more">{t('Дальше…')}</a>
                : null}
            </div>
        )
    }
}

CuttedText.propTypes = {
    text: PropTypes.string,
    opened: PropTypes.bool,
}
