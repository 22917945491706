import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import t from 't'

import BigLoading from 'components/block/bigLoading'
import Card from 'components/place/card'
import CuttedText from 'components/block/cuttedText'
import EmptyFavorites from 'components/block/emptyFavorites'
import EmptyList from 'components/block/emptyList'
import Filters from 'components/filter/filters'
import MapOverlay from 'components/block/mapOverlay'
import Pagination from 'components/block/pagination'
import ShowMore from 'components/block/showMore'

class PlaceList extends React.Component {
    render() {
        let props = this.props

        const isFavPage = props.queryString.indexOf('is_fav=1') !== -1

        return (
            <div className='main'>
                <div className="catalog">
                    {props.meta && props.meta.totalPages > 0 ?
                        <a onClick={() => props.showOnMap(props.markers, {overlay: MapOverlay})}
                           className="catalog_onMap">{t('Показать на карте')}</a> : null}
                    {!isFavPage ?
                        <Filters filters={props.filters} changeFilters={props.changeFilters}
                                 toggleShowFilters={props.toggleShowFilters}
                                 applyFilter={props.applyFilter}
                                 showCalendar={props.showCalendar}
                                 resetFilter={props.resetFilter}
                                 expandFilterGroup={props.expandFilterGroup}/>
                        : null}
                    {props.h1 ? <h1>{props.h1}</h1> : null}
                    {props.h1text ?
                        <CuttedText text={props.h1text}/>
                        : null}

                    <div className="cards">
                        {props.list.length ? _.map(props.list, function (place) {
                            return (
                                <Card key={place.id} card={place} showOnMap={props.showOnMap}
                                      favorites={props.favorites}
                                      toggleFavorite={props.toggleFavorite} openInNewWindow={props.openInNewWindow}/>
                            )
                        }) : (props.loading ? <BigLoading/> : null)}
                    </div>

                    {props.list.length ?
                        <ShowMore meta={props.meta} loadData={props.loadMoreData} loading={props.loading}/> : null}
                    {props.list.length ? <Pagination meta={props.meta} loadData={props.loadData} loading={props.loading}
                                                     useGet={true}/> : null}

                    {props.bottom ?
                        <div className="catalog_text" dangerouslySetInnerHTML={{__html: props.bottom}}/>
                        : null}

                    {props.meta && props.meta.totalPages === 0 ? isFavPage ?
                        <EmptyFavorites directoryName={props.directory.model.plural}/> : <EmptyList/> : null}
                </div>
            </div>
        )
    }
}

PlaceList.propTypes = {
    directory: PropTypes.object.isRequired,
    favorites: PropTypes.array,
    list: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loadData: PropTypes.func.isRequired,
    loadMoreData: PropTypes.func.isRequired,
    meta: PropTypes.object,
    showOnMap: PropTypes.func.isRequired,
    toggleFavorite: PropTypes.func.isRequired,
}

export default PlaceList

