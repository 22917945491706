import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

import {GoogleMarker, YMapTemplate} from 'components/venueCard/venueMap'
import MapOverlay from 'components/venueCard/venueMapOverlay'

export const MapButton = (props) => {
    let className = ['filter-map']
    if (props.className) {
        className.push(props.className)
    }
    return <a className={className.join(' ')} onClick={() => props.showAllOnMap()}>{t('На карте')}</a>
}

MapButton.propTypes = {
}

export default MapButton
