import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

/* Корона
const Discount = (props) => {
    const today = dayjs().format('YYYY-MM-DD')
    if (today < '2020-04-02' || today > '2020-04-15') {
        return null
    }
    if (!props.user || !props.user.allow_pro_status || !props.user.allow_pro_status.allow_pro) {
        return null
    }
    if (document.location.href.indexOf('gorko.ru') < 0) {
        return null
    }
    return (
        <a className="discountCrn" href="https://www.gorko.ru/my/billingPro/">
            <div className="discountCrn_left">
                <div className="discountCrn_pro">PRO</div>
                <div className="discountCrn_info">
                    <div className="discountCrn_value">со скидкой 25%</div>
                    <div className="discountCrn_date">до 15 апреля</div>
                    <span className="discountCrn_more">Подробнее</span>
                </div>
                <div className="discountCrn_triangles1">
                    <div className="discountCrn_triangle-border"/>
                </div>
                <div className="discountCrn_triangles2">
                    <div className="discountCrn_triangle-border"/>
                </div>
                <div className="discountCrn_triangles3"/>
            </div>
            <div className="discountCrn_right">
                <div className="discountCrn_decor"/>
            </div>
        </a>
    )
}
*/

/* Чёрный ноябрь
const Discount = (props) => {
    if (document.location.hostname.indexOf('gorko.ru') < 0 || !this.props.authUser || !this.props.authUser.role.pro) {
        return null
    }
    return (
        <a className="discount-bnov" href="https://www.gorko.ru/my/billingPro/">
            <div className="discount_bnov_logo"></div>
            <div className="discount_bnov_caption">Скидка на покупку PRO</div>
            <div className="discount_bnov_text">каждый день до конца месяца!</div>
        </a>)
}
*/

/* BigSale */
const Discount = (props) => {
    if (!props.is_discount) {
        return null
    }
    if (!props.user || !props.user.allow_pro_status || !props.user.allow_pro_status.allow_pro) {
        return null
    }
    if (document.location.href.indexOf('gorko.ru') < 0) {
        return null
    }
    const isWhite = document.location.href.includes('/my/')
    return (
        <div className={"discountBigsale_bg" + (isWhite ? '-white' : '')}>
            <a className="discountBigsale" href="https://www.gorko.ru/my/billingPro/">
                <div className="discountBigsale_logo"/>
                <div className="discountBigsale_info">
                    <div className="discountBigsale_text"><span className="discountBigsale_percents">20%</span></div>
                    <div className="discountBigsale_text">на покупку PRO</div>
                </div>
            </a>
        </div>
    )
}

Discount.propTypes = {
    user: PropTypes.object,
}

function mapStateToProps(state, ownProps) {
    return {
        user : state.auth ? state.auth.user : null,
        is_discount : state.main.is_discount,
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    let state = ownProps.state || {}
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Discount)
