import axios from 'axios'
import keyMirror from 'lib/keyMirror'
import {notice} from 'actions/notice'
import t from 't'
import {close} from 'actions/popup'

export const types = keyMirror({
    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,
    LOAD_MORE_REQUEST: null,
    LOAD_MORE_OK: null,
    LOAD_MORE_ERROR: null,

    LOAD_MODEL_REQUEST: null,
    LOAD_MODEL_OK: null,
    LOAD_MODEL_ERROR: null,

    REQUEST_SEND: null,
    REQUEST_OK: null,
    REQUEST_ERROR: null,
}, 'dress')

const MEDIA_LIMIT = 1
const PER_PAGE = 30

const getModelUrl = (type, id) => {
    return API_URL + '/v2/dresses/?' + (type === 'dressmodel' ? 'model_id' : 'id') + '=' + id
}

const getUrl = (cityId, directory, filters, page, per_page, shopId) => {
    return directory.apiUrl +
        "&fields=media" +
        "&set_city=" + cityId +
        "&media_limit=" + MEDIA_LIMIT +
        "&preview_size=0x200x0,260x400x1" +
        "&page=" + page +
        "&per_page=" + per_page +
        "&seed=" + SEED +
        (shopId ? '&shop_id=' + shopId : '') +
        '&' + filters
}

export const loadData = (directory, filters = '', page=1, perPage = null, shopId = null) => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_LIST_REQUEST
        })
        if (!perPage) {
            perPage = PER_PAGE
        }
        return axios.get(getUrl(getState().main.city.id, directory, filters, page, perPage, shopId), {withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.dresses,
                    links: response.data.links || [],
                    meta: response.data.meta,
                    type: types.LOAD_LIST_OK,
                    shopId: shopId
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_LIST_ERROR
                })
            })
    }
}

export const loadMoreData = (directory, filters = '', shopId) => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_MORE_REQUEST
        })

        const page = getState().dress.dress.meta.nextPage

        return axios.get(getUrl(getState().main.city.id, directory, filters, page, PER_PAGE, shopId), {withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.dresses,
                    meta: response.data.meta,
                    type: types.LOAD_MORE_OK,
                    shopId: shopId
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_MORE_ERROR
                })
            })
    }
}

export const loadModel = (type, id, params = null) => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_MODEL_REQUEST
        })

        params.set_city = getState().main.city.id

        axios.get(getModelUrl(type, id), {params: params})
            .then(response => {
                dispatch({
                    model: response.data.dress,
                    type: types.LOAD_MODEL_OK,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_MODEL_ERROR
                })
            })
    }
}

export const sendRequest = (modelId, data) => {
    console.log(data);
    return function (dispatch) {
        dispatch({
            type: types.REQUEST_SEND
        })
        let postData = {
            salon       : data.salon,
            size        : data.size,
            name        : data.name,
            email       : data.email,
            phone       : data.phone,
            description : data.description,
            'send[]'    : data.viaPhone ? "phone" : null,
        }
        axios.post(API_URL + '/v2/shopDresses/' + modelId + '/request', postData, {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.REQUEST_OK,
                })
                dispatch(notice(t('Запрос успешно отправлен')))
                dispatch(close())
            })
            .catch(() => {
                dispatch({
                    type: types.REQUEST_ERROR
                })
            })
    }
}

