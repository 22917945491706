import React from 'react'

import Link from 'components/block/link'

export const FavoritesButton = () => {
    let classname = ['filter-favorite']
    let url = document.location.origin + document.location.pathname
    let query = []
    if (!document.location.search.includes('is_fav')) {
        query.push('is_fav=1')
    } else {
        classname.push('_active')
    }
    const match = document.location.search.match(/type=(auto|limousine)/)
    if (match) {
        query.push('type=' + match[1])
    }
    if (query.length) {
        url += '?' + query.join('&')
    }
    const onClick = (ev) => {
        window.scrollTo(0, 0)
        reachGoal('microgoal_favourites_list', null, ['ya'], 'gorko.ru')
    }

    return <Link to={url} className={classname.join(' ')} onClick={onClick}></Link>
}

FavoritesButton.propTypes = {
}

export default FavoritesButton
