import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import {setTitles} from 'lib/titles'
import {getTitles} from 'actions/titles'
import {putComponentToPopupDialog} from 'actions/popupDialog'

import Calendar from 'components/block/calendar'
import Discount from 'components/block/discount'
import Notification from 'components/block/notification'
import Header from 'components/main/header'
import Notice from 'containers/block/notice'
import Hint from 'containers/block/hint'
import PhoneCountries from 'containers/block/phoneCountries'
import Popup from 'containers/block/popup'
import PopupDialog from 'containers/block/popupDialog'
import PopupLight from 'containers/block/popupLight'
import PopupDialogActual from 'components/popupDialog/popupDialogActual'
import SharePopup from 'containers/block/sharePopup'
import SiteSearch from 'containers/block/siteSearch'

import Footer from 'components/main/footer'
import Error40x from 'components/error/40x'
import PhotoViewer from 'components/Common/PhotoViewer'
import BigLoading from 'components/block/bigLoading'

class Application extends React.Component {
    componentDidMount() {
        this.props.getTitles()
        this.props.getActual()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.pageUrl !== this.props.pageUrl) {
            this.props.getTitles(nextProps.pageUrl)
        }
    }


    render() {
        if (this.props.loading) return <BigLoading/>
        if (typeof window !== 'undefined' && this.props.titles) setTitles(this.props.titles)

        return (
            <div className="application">
                <Calendar />
                <Popup/>
                <PopupDialog/>
                <PopupLight/>
                <PhoneCountries/>
                <Notice/>
                <PhotoViewer/>
                <SharePopup/>
                <Hint/>
                {this.props.error ?
                    <div className="body">
                        <Header/>
                        <Error40x number={this.props.error.code}/>
                    </div>
                    :
                    <div className="body">
                        <Header isLanding={this.props.isLanding} landingImage={this.props.landingImage}/>
                        <Notification />
                        <Discount />
                        {this.props.children ? this.props.children : <BigLoading/>}
                        {this.props.children ? <Footer/> : null}
                    </div>
                }
                <SiteSearch directory={this.props.directory}/>
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    let isLanding = false
    let landingImage = null
    if (ownProps.directory) {
        let checkUtmContent = document.location.search.match(/utm_landing=1/i)
        isLanding = (
                ownProps.directory.modelName === 'restaurant'
                ||
                ownProps.directory.modelName === 'venue'
                ||
                (ownProps.directory.modelName === 'user' && ownProps.directory.model.key !== 'car')
            )
            &&
            checkUtmContent
        if (isLanding && ownProps.directory.landing) {
            landingImage = ownProps.directory.landing.img_mobile || null
        }
    }
    return {
        authUser: state.auth.user,
        isBusyActualEvent: state.auth && state.auth.user ? state.auth.user.is_busy_actual_event : false,
        error: state.main.error,
        loading: !state.main.city || !state.auth.isChecked,
        titles: state.titles,
        pageUrl: typeof document !== 'undefined' ? document.location.href : null, // todo state.router.location.href
        isLanding: isLanding,
        landingImage: landingImage,
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        dispatch: dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    const dispatch = dispatchProps.dispatch
    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        getTitles: (pageUrl = stateProps.pageUrl) => dispatch(getTitles(pageUrl)),
        getActual: () => new Promise((resolve) => resolve(stateProps.isBusyActualEvent)).then(needActual => {
            if (needActual === true) {
                dispatch(putComponentToPopupDialog(null, <PopupDialogActual/>))
            }
        }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(Application))
