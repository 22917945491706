import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import t from 't'
import {connect} from 'react-redux'

import Banner from "containers/block/banner"
import BigLoading from 'components/block/bigLoading'
import CallButton from 'components/directory/callButton'
import Card from 'components/shop/card'
import CuttedText from 'components/block/cuttedText'
import EmptyFavorites from 'components/block/emptyFavorites'
import EmptyList from 'components/block/emptyList'
import Filters from 'components/filter/filters'
import MapOverlay from 'components/block/mapOverlay'
import Pagination from 'components/block/pagination'
import ShowMore from 'components/block/showMore'

import Link from 'components/block/link'

class ShopList extends React.Component {
    render() {
        let props = this.props
        const isFavPage = props.queryString.indexOf('is_fav=1') !== -1
        const dressDirectory = props.directory.model.key === 'dress'
            ? _.filter(props.directoryList, (directory) => directory.type === 'dress').pop()
            : null

        let list = [...props.list]
        if (!isFavPage && props.banner.length) {
            list.splice(2, 0, {
                id: 'banner',
                num: 0,
            })
            if (props.banner.length > 1) {
                list.splice(5, 0, {
                    id: 'banner',
                    num: 1,
                })
            }
        }
        return (
            <div className="main">
                <div className="catalog">
                    {dressDirectory ? <Link className="catalog_switcher"
                                            to={dressDirectory.url}>{t('Свадебные платья')}</Link> : null}
                    {props.meta && props.meta.totalPages > 0 ?
                        <a onClick={() => props.showOnMap(props.markers, {overlay: MapOverlay})}
                           className="catalog_onMap">{t('Показать на карте')}</a> : null}
                    {!isFavPage ?
                        <Filters filters={props.filters} changeFilters={props.changeFilters}
                                 toggleShowFilters={props.toggleShowFilters}
                                 applyFilter={props.applyFilter}
                                 showCalendar={props.showCalendar}
                                 resetFilter={props.resetFilter}
                                 expandFilterGroup={props.expandFilterGroup}/>
                        : null}
                    {props.h1 ? <h1>{props.h1}</h1> : null}
                    {props.h1text ?
                        <CuttedText text={props.h1text}/>
                        : null}

                    {list.length ? _.map(list, function (shop) {
                        if (shop.id === 'banner') {
                            return <Banner key={'banner' + shop.num} bannerNum={shop.num}/>
                        } else {
                            return <Card key={shop.id} card={shop} favorites={props.favorites}
                                         showOnMap={props.showOnMap}
                                         toggleFavorite={props.toggleFavorite} openInNewWindow={props.openInNewWindow}/>
                        }
                    }) : (props.loading || props.meta === null ? <BigLoading/> : null)}

                    {list.length ?
                        <ShowMore meta={props.meta} loadData={props.loadMoreData} loading={props.loading}/> : null}
                    {list.length ? <Pagination meta={props.meta} loadData={props.loadData} loading={props.loading}
                                               useGet={true}/> : null}

                    {props.bottom ?
                        <div className="catalog_text" dangerouslySetInnerHTML={{__html: props.bottom}}/>
                        : null}

                    {props.meta && props.meta.totalPages === 0 ? isFavPage ?
                        <EmptyFavorites directoryName={props.directory.model.plural}/> : <EmptyList/> : null}

                    {props.directory.inquire ?
                        <CallButton phone={props.directory.inquire.phone} inquire={props.directory.inquire.inquire}/>
                        : null}
                </div>
            </div>
        )
    }
}

ShopList.propTypes = {
    directory: PropTypes.object.isRequired,
    favorites: PropTypes.array,
    list: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    loadData: PropTypes.func.isRequired,
    loadMoreData: PropTypes.func.isRequired,
    meta: PropTypes.object,
    showOnMap: PropTypes.func.isRequired,
    toggleFavorite: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {
    return {
        banner: state.main.banner,
    }
}

export default connect(mapStateToProps)(ShopList)
