import axios from 'axios'
import keyMirror from 'lib/keyMirror'
import React from "react"
import t from 't'
import _ from 'lodash'

import {notice} from 'actions/notice'
import {close} from 'actions/popup'

export const types = keyMirror({
    LOAD_PROVIDERS_REQUEST: null,
    LOAD_PROVIDERS_OK: null,
    LOAD_PROVIDERS_ERROR: null,

    FROM_BALANCE_REQUEST: null,
    FROM_BALANCE_OK: null,
    FROM_BALANCE_ERROR: null,
}, 'payment');

export const getProviders = (label, sum, purpose) => {
    let query = []
    if (label == 'cities') {
        for (let field in purpose) if (purpose.hasOwnProperty(field)) {
            let values = []
            for (let key in purpose[field]) if (purpose[field].hasOwnProperty(key)) {
                values.push(field + '[' + key + ']=' + purpose[field][key])
            }
            query.push(field + '=' + encodeURIComponent(values.join('&')))
        }
    } else if (label == 'user') {
        label = purpose.label
    }
    query = query.join('&')
    return function(dispatch, getState) {
        dispatch({
            type: types.LOAD_PROVIDERS_REQUEST
        })
        axios.get(API_URL + '/v2/bill/providers?label=' + label + '&sum=' + sum + '&' + query, {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_PROVIDERS_OK,
                    data: response.data,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_PROVIDERS_ERROR
                })
            })
    }
}

export const payFromBalance = (label, sum, purpose) => {
    return function(dispatch, getState) {
        dispatch({
            type: types.FROM_BALANCE_REQUEST
        })
        let postData = {
            sum   : sum,
            label : label,
        }
        let url
        if (label == 'user') {
            url = API_URL + '/v2/my/buyPro'
            postData.label = purpose.label
        } else if (label == 'cities') {
            url = API_URL + '/v2/bill/cities'
            for (let field in purpose) if (purpose.hasOwnProperty(field)) {
                postData[field] = purpose[field]
            }
        } else if (label == 'restaurantPro' || label == 'venuePro') {
            url = API_URL + '/v2/' + label + 's/' + payment.object.id + '/buyPro'
        } else if (label == 'limousine') {
            url = API_URL + '/v2/bill/carsPro'
        }

        axios.post(url, postData, {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.FROM_BALANCE_OK,
                    data: response.data,
                })
                dispatch(notice(t('Запрос успешно отправлен')))
                dispatch(close())
                window.location.reload()
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.FROM_BALANCE_ERROR
                })
            })
    }
}

