import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,
    LOAD_MORE_REQUEST: null,
    LOAD_MORE_OK: null,
    LOAD_MORE_ERROR: null,
}, 'review')

const getUrl = (entityType, modelId) => {
    return API_URL + '/v2/' + entityType + '/' + modelId + '/reviews'
}

export const loadData = (entityType, modelId, params = null) => {
    return function (dispatch, getState) {
        let typeRequest = types.LOAD_LIST_REQUEST
        let typeOk = types.LOAD_LIST_OK
        let typeError = types.LOAD_LIST_ERROR

        if (!params.page) {
            // TODO nicht тут явно не user
            if (getState().user.review.meta) {
                typeRequest = types.LOAD_MORE_REQUEST
                typeOk = types.LOAD_MORE_OK
                typeError = types.LOAD_MORE_ERROR
                // TODO nicht тут явно не user
                params.page = getState().user.review.meta.nextPage
            } else {
                params.page = 1
            }
        }

        dispatch({
            type: typeRequest
        })

        axios.get(getUrl(entityType, modelId), {params: params})
            .then(response => {
                dispatch({
                    list: response.data.reviews,
                    marks: response.data.marks,
                    meta: response.data.meta,
                    type: typeOk,
                })
            })
            .catch(() => {
                dispatch({
                    type: typeError
                })
            })
    }
}

export const loadReviewsSent = (modelId, params = null) => {
    return function (dispatch, getState) {
        let typeRequest = types.LOAD_LIST_REQUEST
        let typeOk = types.LOAD_LIST_OK
        let typeError = types.LOAD_LIST_ERROR

        if (!params.page) {
            if (getState().user.review.meta) {
                typeRequest = types.LOAD_MORE_REQUEST
                typeOk = types.LOAD_MORE_OK
                typeError = types.LOAD_MORE_ERROR
                params.page = getState().user.review.meta.nextPage
            } else {
                params.page = 1
            }
        }

        dispatch({
            type: typeRequest
        })

        axios.get(API_URL + '/v2/users/' + modelId + '/reviewsSent', {params: params})
            .then(response => {
                dispatch({
                    list: response.data.reviews,
                    meta: response.data.meta,
                    type: typeOk,
                })
            })
            .catch(() => {
                dispatch({
                    type: typeError
                })
            })
    }
}