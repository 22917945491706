import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import t from 't'
import _ from 'lodash'
import cx from 'classnames'

import Button from 'components/main/menuButton'
import Scroller from 'components/block/scroller'
import SubMenu from 'components/main/subMenu'

class Menu extends React.Component {
    getUserMenu() {
        if (!this.props.userMenu) return [[]]

        let mobile = []
        let webGroup = []
        for (let i = 0; this.props.userMenu[i]; i++) {
            if (this.props.userMenu[i].hasMobile) {
                mobile.push({
                    name: this.props.userMenu[i].title,
                    url: this.props.userMenu[i].url
                })
            } else {
                webGroup.push({
                    name: this.props.userMenu[i].title,
                    url: this.props.userMenu[i].url
                })
            }
        }
        if (this.props.isAuth) {
            mobile.push({
                name: t('Переписка'),
                url: SITE_URL + this.props.langUrlNotEmpty + '/pm/',
                count: this.props.authUser.new_pm_count
            })
            mobile.push({
                name: t('Выйти'),
                onClick: this.props.logout
            })
            if (this.props.authUser && (this.props.authUser.role.pro || this.props.authUser.role.type === 'restaurant')) {
                mobile.unshift({
                    name: t('Моя страница'),
                    url: this.props.authUser.link
                })
            }
        }
        return [mobile, webGroup]
    }

    componentDidMount() {
        let scroller = ReactDOM.findDOMNode(this).getElementsByClassName('scroller')[0]
        if (scroller.scrollWidth > scroller.clientWidth) {
            let buttonWidth = scroller.querySelector('[class|="mainMenu_button"]').clientWidth + 1
            let visibleCount = Math.round(scroller.clientWidth / buttonWidth)
            let delta = scroller.clientWidth - visibleCount * buttonWidth
            scroller.style.paddingLeft = Math.round(buttonWidth / 2 + delta) + 'px'
        }
    }

    render() {
        if (!this.props.menu) return null

        let buttons = []
        let subMenuDesktop = null
        let subMenuMobile = null

        let userMenu = {
            id: 'user',
            icon: 'user',
            name: t('Профиль'),
            isCurrent: (this.props.currentMenuGroup === 'user'),
        }
        if (userMenu.isCurrent) {
            [subMenuMobile, subMenuDesktop] = this.getUserMenu()
        }
        buttons.push(<Button
            key="user"
            menu={userMenu}
            selectMenuGroup={this.props.selectMenuGroup}
            isCurrent={userMenu.isCurrent}
            isAuth={this.props.isAuth}
            authUser={this.props.authUser}
            authPopup={this.props.authPopup}
            count={this.props.authUser && !userMenu.isCurrent ? this.props.authUser.new_pm_count : 0}
        />)

        _.map(_.filter(this.props.menu, (item) => item.is_mobile || item.submenu), (menu) => {
            let isCurrent = (this.props.currentMenuGroup === menu.id)
            if (isCurrent) {
                if (menu.is_mobile) {
                    subMenuMobile = menu.submenu
                } else {
                    subMenuDesktop = menu.submenu
                }
            }
            if (menu.url || (menu.submenu && menu.submenu.length)) {
                buttons.push(<Button
                    key={menu.id}
                    menu={menu}
                    selectMenuGroup={this.props.selectMenuGroup}
                    isCurrent={isCurrent}
                />)
            }
        })

        let otherSubMenu = _.filter(this.props.menu, (item) => !item.is_mobile && !item.submenu)
        if (otherSubMenu) {
            let otherMenu = {
                id: 'other',
                icon: 'other',
                name: t('Прочее'),
                isCurrent: (this.props.currentMenuGroup === 'other'),
            }
            if (otherMenu.isCurrent) {
                subMenuDesktop = otherSubMenu
            }
            if (otherSubMenu && otherSubMenu.length) {
                buttons.push(<Button
                    key="other"
                    menu={otherMenu}
                    selectMenuGroup={this.props.selectMenuGroup}
                    isCurrent={this.props.currentMenuGroup === otherMenu.id}
                />)
            }
        }

        let classname = {
            mainMenu : true,
        }
        if (document.location.hostname.indexOf('centerwedding.com') >= 0) {
            classname['mainMenu-cwed'] = true
        }

        return (
            <div className={cx(classname)}>
                <Scroller>{buttons}</Scroller>
                {subMenuDesktop || subMenuMobile ?
                    <SubMenu key="a2" desktop={subMenuDesktop} mobile={subMenuMobile} location={this.props.location} favoritesByPath={this.props.favoritesByPath}/> : null}
            </div>
        )
    }
}

Menu.propTypes = {
    authPopup: PropTypes.func.isRequired,
    authUser: PropTypes.object,
    currentMenuGroup: PropTypes.string.isRequired,
    favoritesByPath: PropTypes.object.isRequired,
    isAuth: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    menu: PropTypes.array.isRequired,
    selectMenuGroup: PropTypes.func.isRequired,
    userMenu: PropTypes.array.isRequired,
}

export default Menu