import BaseModel from 'models/baseModel'

export default class AuthUser extends BaseModel {
    get isPro() {
        return this.is_pro;
    }

    get avatar() {
        return this.avatar_url;
    }

    get link() {
        return this.profile_url
    }
}