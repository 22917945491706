import axios from 'axios'
import keyMirror from 'lib/keyMirror'

import t from 't'
import {close} from 'actions/popup'
import {notice} from 'actions/notice'

export const types = keyMirror({
    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,
    LOAD_MORE_REQUEST: null,
    LOAD_MORE_OK: null,
    LOAD_MORE_ERROR: null,

    LOAD_CAR_LIST_REQUEST: null,
    LOAD_CAR_LIST_OK: null,
    LOAD_CAR_LIST_ERROR: null,
    LOAD_CAR_MORE_REQUEST: null,
    LOAD_CAR_MORE_OK: null,
    LOAD_CAR_MORE_ERROR: null,

    LOAD_LIMOUSINE_LIST_REQUEST: null,
    LOAD_LIMOUSINE_LIST_OK: null,
    LOAD_LIMOUSINE_LIST_ERROR: null,
    LOAD_LIMOUSINE_MORE_REQUEST: null,
    LOAD_LIMOUSINE_MORE_OK: null,
    LOAD_LIMOUSINE_MORE_ERROR: null,

    REQUEST_SEND: null,
    REQUEST_OK: null,
    REQUEST_ERROR: null,
}, 'car')

const PER_PAGE = 20

const getCarListUrl = () => {
    return API_URL + '/v2/cars/'
}

const getUrl = (directory, filters, page, per_page) => {
    return directory.apiUrl +
        "&fields=params,viewer_price_in_list,media" +
        "&in_list=1" +
        "&cover_size=0x200x0" +
        "&preview_size=0x200x0,266x200x1" +
        "&page=" + page +
        "&per_page=" + per_page +
        "&seed=" + SEED +
        '&' + filters
}

export const loadData = (directory, filters = '', page=1, per_page=null) => {
    return function (dispatch) {
        dispatch({
            type: types.LOAD_LIST_REQUEST
        })
        if (!per_page) {
            per_page = PER_PAGE
        }
        return axios.get(getUrl(directory, filters, page, per_page), {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_LIST_OK,
                    list: response.data.cars,
                    links: response.data.links,
                    meta: response.data.meta,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_LIST_ERROR
                })
            })
    }
}

export const loadMoreData = (directory, filters = '') => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_MORE_REQUEST
        })

        const page = getState().car.car.meta.nextPage

        return axios.get(getUrl(directory, filters, page, PER_PAGE), {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_MORE_OK,
                    list: response.data.cars,
                    meta: response.data.meta,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_MORE_ERROR
                })
            })
    }
}

export const loadCarList = (params = null) => {
    params.type_id = 1 // StaticList Car id
    return function (dispatch, getState) {
        let typeRequest = types.LOAD_CAR_LIST_REQUEST
        let typeOk = types.LOAD_CAR_LIST_OK
        let typeError = types.LOAD_CAR_LIST_ERROR

        if (params.page !== 1 && getState().car.car.car_meta) {
            typeRequest = types.LOAD_CAR_MORE_REQUEST
            typeOk = types.LOAD_CAR_MORE_OK
            typeError = types.LOAD_CAR_MORE_ERROR
            params['page'] = getState().car.car.car_meta.nextPage
        }

        if (!params.page) {
            params.page = 1
        }

        dispatch({
            type: typeRequest
        })

        axios.get(getCarListUrl(), {params: params})
            .then(response => {
                dispatch({
                    list: response.data.cars,
                    meta: response.data.meta,
                    type: typeOk,
                })
            })
            .catch(() => {
                dispatch({
                    type: typeError
                })
            })
    }
}

export const loadLimousineList = (params = null) => {
    params.type_id = 2 // StaticList Limousine id
    return function (dispatch, getState) {
        let typeRequest = types.LOAD_LIMOUSINE_LIST_REQUEST
        let typeOk = types.LOAD_LIMOUSINE_LIST_OK
        let typeError = types.LOAD_LIMOUSINE_LIST_ERROR
        params.page = 1

        if (getState().car.car.limousine_meta) {
            typeRequest = types.LOAD_LIMOUSINE_MORE_REQUEST
            typeOk = types.LOAD_LIMOUSINE_MORE_OK
            typeError = types.LOAD_LIMOUSINE_MORE_ERROR
            params['page'] = getState().car.car.limousine_meta.nextPage
        }

        dispatch({
            type: typeRequest
        })

        axios.get(getCarListUrl(), {params: params})
            .then(response => {
                dispatch({
                    list: response.data.cars,
                    meta: response.data.meta,
                    type: typeOk,
                })
            })
            .catch(() => {
                dispatch({
                    type: typeError
                })
            })
    }
}


export const sendRequest = (modelId, data) => {
    return function (dispatch) {
        dispatch({
            type: types.REQUEST_SEND
        })
        let postData = {
            date        : data.date,
            name        : data.name,
            email       : data.email,
            phone       : data.phone,
            description : data.description,
            'send[]'    : data.viaPhone ? "phone" : null,
        }
        axios.post(getModelUrl(modelId) + '/request', postData, {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.REQUEST_OK,
                })
                dispatch(notice(t('Запрос успешно отправлен')))
                dispatch(close())
            })
            .catch(() => {
                dispatch({
                    type: types.REQUEST_ERROR
                })
            })
    }
}
