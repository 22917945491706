"use strict";

import AppDispatcher from "dispatcher/AppDispatcher"
import PhotoViewerConstants from "constants/PhotoViewerConstants"
import Constants from "constants/Constants"
import BaseStore from "lib/Store"

var isEnabled = false;
var photos = [];
var initialPhoto = null;

var PhotoViewerStore = BaseStore.extend({
  isOn: function() {
    return isEnabled;
  },

  getPhotos: function() {
    return photos;
  },

  getInitialPhoto: function() {
    return initialPhoto;
  }
});

AppDispatcher.registerHandler(PhotoViewerConstants.PHOTO_VIEWER_ON, function(payload) {
  isEnabled = true;
  initialPhoto = payload.initialPhoto;
  photos = payload.photos;
  PhotoViewerStore.emitChange();
});

AppDispatcher.registerHandler(PhotoViewerConstants.PHOTO_VIEWER_OFF, function() {
  isEnabled = false;
  initialPhoto = null;
  photos = [];
  PhotoViewerStore.emitChange();
});

AppDispatcher.registerHandler(Constants.CHANGE_PAGE, function() {
  isEnabled = false;
  PhotoViewerStore.emitChange();
});

export default PhotoViewerStore;
