import React from 'react'
import PropTypes from 'prop-types'

import Favorite from 'components/directory/favorite'
import Link from 'components/block/link'

const DressCard = (props) => {
    const card = props.card;
    return (
        <div className="cardDress">
            <div className="card-half">
                {props.allFavorites ?
                    <Favorite isFav={card.isFav(props.allFavorites)} toggleFavorite={(modelId, isFav) => props.toggleDressFavorite(modelId, isFav, card.is_upload)} modelId={card.is_upload ? card.id : card.model_id}/>
                    : null}
                <Link to={card}>
                    <img className="card_photo" src={card.photos[0]['260x400x1']} alt=""/>
                    <p className="cardDress_price">{card.price.value_display}</p>
                    <p className="card_name">{card.name}</p>
                    <p className="card_name">{card.brand_name}</p>
                </Link>
            </div>
        </div>
    )
}

DressCard.propTypes = {
    card: PropTypes.object.isRequired,
    showOnMap: PropTypes.func,
    toggleDressFavorite: PropTypes.func,
    allFavorites: PropTypes.object,
};

export default DressCard;
