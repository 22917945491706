import React from 'react'
import PropTypes from "prop-types"
import {connect} from 'react-redux'
import _ from 'lodash'

import VisibilitySensor from 'react-visibility-sensor'

import LazyImage from "containers/block/lazyImage"
import {loadEntity, getEntity} from "actions/entity"
import {putComponentToPopup} from "actions/popup"
import t from "t"
import Video from "components/block/gallery/video"

class ImageContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
        }
    }

    componentDidMount() {
        if (!this.props.img && this.props.isForceVisible && this.props.id) {
            this.props.loadEntity(this.props.id)
        }
    }

    render() {
        const screenHeight = typeof window === 'undefined' ? 0 : (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)
        const img = this.props.img
        let width = this.props.width
        let height = this.props.height
        if (img && width && !height) {
            height = Math.round(img.height * width / img.width)
        }
        if (!width && height) {
            width = Math.round(height * 4 / 3)
        }

        if (!this.props.id) {
            return <img className={this.props.className} src={window.CDN + '/p/p'+(width||0)+'x'+(height||0)+'x1/special/no_img.png'} width={width||1} height={height||1} alt={this.props.alt||''}/>
        }

        let $placeholder = <img className={this.props.className} src={null} width={width||1} height={height||1} alt={this.props.alt||''}/>
        if (this.props.link !== false) {
            $placeholder = <a className={this.props.classNameLink||null}>{$placeholder}</a>
        }
        return <VisibilitySensor partialVisibility={true} onChange={(isVisible) => {this.setState({visible: isVisible})}}
                                 active={!this.state.visible}
                                 offset={{bottom: -(screenHeight * 0.3), top: -50, left: -100, right: -100}}>
            {({isVisible}) => {
                return isVisible ? <LazyImage {...this.props} isCrop={!!(this.props.width && this.props.height)} alt={this.props.alt||''}/> : $placeholder
            }}
        </VisibilitySensor>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        img: getEntity(state, 'img', ownProps.id, {}),
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        loadEntity: (entityId) => {
            return dispatch(loadEntity('img', entityId, {}))
        },
        showVideo: (videoLink) => dispatch(putComponentToPopup(t('Видео'), <Video videoLink={videoLink}/>)),
    }
}

ImageContainer.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string.isRequired,
    classNameLink: PropTypes.string,
    height: PropTypes.number,
    id: PropTypes.number,
    isForceVisible: PropTypes.bool.isRequired,
    link: PropTypes.any.isRequired,
    linkTarget: PropTypes.string,
    width: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageContainer)
