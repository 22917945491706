import React from 'react'
import {connect} from 'react-redux'
import t from 't'

import {close} from 'actions/share'
import {notice} from 'actions/notice'

import SharePopup from 'components/share/popup'

class SharePopupContainer extends React.Component {
    render() {
        return <SharePopup {...this.props}/>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        show: state.share.show,
        image: state.share.image,
        text: state.share.text,
        title: state.share.title,
        url: state.share.url,
        socials: state.main.site.share_socials,
    }
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        copy: (ev) => {
            ev.preventDefault()
            document.querySelector('#share_link').select()
            document.execCommand('Copy')
            dispatch(close())
            dispatch(notice(t('Ссылка скопирована в буфер')))
        },
        close: () => {
            dispatch(close())
        },
        share: (ev) => {
            ev.preventDefault()
            window.open(ev.target.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SharePopupContainer)