import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_REQUEST: null,
    LOAD_OK: null,
    LOAD_ERROR: null,
}, 'language')

export const loadData = () => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_REQUEST
        })

        return axios.get(API_URL + '/v2/sites?url=' + encodeURIComponent(document.location.href), {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_OK,
                    sites: response.data.sites,
                    currentSiteDomain: getState().main.site.domain,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_ERROR
                })
            })
    }
}

