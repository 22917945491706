import {types as authTypes} from 'actions/auth'
import {types as interfaceTypes} from 'actions/main'
import {types} from 'actions/favorite'

import _ from 'lodash'

const initialState =
    {
        list: {},
        byPath: {},
    };

const fillFavorites = (favorites) => {
    let filled = {};
    _.each(favorites, (favorite) => {
        let reducer = favorite.model_name.toLowerCase();
        if (!filled[reducer]) {
            filled[reducer] = [];
        }
        filled[reducer].push(favorite.model_id);
    });
    return filled;
};

const addFavorite = (list, modelName, modelId) => {
    if (!list[modelName]) {
        list[modelName] = [];
    }
    list[modelName].push(modelId);
    list[modelName] = _.uniq(list[modelName]);
    return list;
};

const removeFavorite = (list, modelName, modelId) => {
    if (!list[modelName]) {
        list[modelName] = [];
    } else {
        list[modelName] = _.pull(list[modelName], modelId);
    }
    list[modelName] = _.uniq(list[modelName]); // без этой строчки не будет рендера при удалении из избранного
    return list;
};

const fillByPath = (directory) => {
    let list = {}
    _.forEach(directory, item => {
        if (typeof item.my_favorites_count !== 'undefined') {
            list[item.url.replace(/https?:\/\/[^\/]+([^\?]+)(?:\?.*)?/, '$1')] = item.my_favorites_count
        }
    })
    return list
}

const FavoriteReducer = (state = initialState, action) => {
    switch (action.type) {
        case interfaceTypes.LOAD_INTERFACE_OK:
            return {
                ...state,
                list: fillFavorites(action.favorites),
                byPath: fillByPath(action.directory),
            };
        case authTypes.CHECK_AUTH_OK:
            return {
                ...state,
                list: action.user ? fillFavorites(action.user.favorites) : {}
            };
        case authTypes.CHECK_AUTH_REQUEST:
            return {
                ...state,
                list: {},
            };
        case authTypes.CHECK_AUTH_ERROR:
            return {
                ...state,
                list: {},
            };

        case types.FAVORITE_DIFF:
            let newByPath = state.byPath
            if (typeof newByPath[action.path] !== 'undefined') {
                newByPath[action.path] += action.diff
            }
            return {
                ...state,
                byPath: newByPath,
            }

        case types.ADD_FAVORITE_OK:
            return {
                ...state,
                list: addFavorite(state.list, action.modelName, action.modelId),
            };
        case types.REMOVE_FAVORITE_OK:
            return {
                ...state,
                list: removeFavorite(state.list, action.modelName, action.modelId),
            };
        case types.ADD_FAVORITE_REQUEST:
        case types.ADD_FAVORITE_ERROR:
        case types.REMOVE_FAVORITE_REQUEST:
        case types.REMOVE_FAVORITE_ERROR:
            return state;

        default:
            return state
    }
}
export default FavoriteReducer