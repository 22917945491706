import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

const SharePopup = (props) => {
    if (!props.show) return null
    let title = props.title ? ('' + props.title).replace(/#/g, '%23') : null
    return <div className="share _opened">
        <div className="share_content">
            <p className="share_caption">{t('Поделиться')}</p>
            <div className="share_socials">
                {props.socials.includes('vk') ? <a className="socialButton-vk" href={'https://vk.com/share.php?url=' + props.url.replace(/#/g, '%23') + (title ? '&title=' + title : '') + (props.image ? '&image=' + props.image : '')} onClick={props.share}/> : null}
                {props.socials.includes('x') ? <a className="socialButton-x" href={'https://www.twitter.com/share?url=' + props.url.replace(/#/g, '%23') + (title ? '&text=' + title.replace('{', '').replace('}', '') : '')} onClick={props.share}/> : null}
                {props.socials.includes('fb') ? <a className="socialButton-fb" href={'https://www.facebook.com/sharer/sharer.php?u=' + props.url.replace(/#/g, '%23') + (props.text ? '&quote=' + props.text.replace(/#/g, '%23') : '')} onClick={props.share}/> : null}
            </div>
            <p className="share_link">
                <input id="share_link" className="share_url" type="text" value={props.url} readOnly={true}/>
                <a onClick={props.copy} href={props.url}>{t('Скопировать ссылку')}</a>
            </p>
            <a className="button-gray button-wide button-big" onClick={props.close}>{t('Отмена')}</a>
        </div>
    </div>
}

SharePopup.propTypes = {
    close: PropTypes.func,
    copy: PropTypes.func,
    isOpen: PropTypes.bool,
    share: PropTypes.func,
    url: PropTypes.string,
}

export default SharePopup
