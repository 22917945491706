import {types} from 'actions/search'

const initialState =
    {
        isActive: false,
    };

const SearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TOGGLE_ACTIVE:
            return {
                ...state,
                isActive: action.active,
            };
        default:
            return state
    }
}

export default SearchReducer