import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import t from 't'
import dayjs from 'dayjs'

import {close} from 'actions/calendar'

const today = {
    day   : new Date().getDate(),
    month : new Date().getMonth() + 1,
    year  : new Date().getFullYear(),
}

let monthName = null

const DummyDay = ({days}) => {
    return <div className="calendar_day-dummy" days={days}/>
}

const Day = ({day, isSelected, isDisabled, pickDate, dummy, month, year, status}) => {
    if (day) {
        let classname = ['calendar_day']
        if (status && (typeof status).toLowerCase() == 'object') {
            Object.keys(status).map(busy => {
                const count = status[busy]
                if (count) {
                    classname.push('_status' + busy)
                }
            })
        } else if (status !== undefined && status !== null) {
            classname.push('_status' + status)
        }
        let $selected = null
        if (isSelected) {
            $selected = <div className="calendar_selected"/>
        }
        if (!isSelected && pickDate) {
            let pickParams = {
                day   : day,
                month : month,
                year  : year,
                date  : year + '-' + ('' + month).padStart(2, '0') + '-' + ('' + day).padStart(2, '0'),
            }
            return <a className={classname.join(' ')} onClick={() => pickDate(pickParams)}>{day}{$selected}</a>
        } else {
            if (isDisabled || !isSelected) {
                classname.push('_disabled')
                return <span className={classname.join(' ')}>{day}{$selected}</span>
            } else {
                return <a className={classname.join(' ')}>{day}{$selected}</a>
            }
        }
    } else {
        return null
    }
}

const Dow = ({dow}) => {
    // январь 2018 начинался с понедельника
    return <div className="calendar_dow">{dayjs('2018-01-' + dow).format("dd")}</div>
}

const Month = ({month, year, selected, pickDate, borders, schedules, defaultStatus}) => {
    let days = dayjs(year + '-' + month, 'YYYY-MM').daysInMonth()
    let dow = +dayjs(year + '-' + month + '-01', 'YYYY-MM-DD').format('d')
    if (dow === 0) {
        dow = 7
    }

    let $days = []
    if (dow > 1) {
        $days.push(<DummyDay key="dummy_before" days={dow - 1}/>)
    }
    const hasSchedule = schedules && Object.keys(schedules).length
    for (let day=1; day<=days; day++) {
        let date = dayjs(year + '-' + month + '-' + day, 'YYYY-MM-DD').format('YYYY-MM-DD')
        let isSelected = selected?.date === date
        let status = null
        if (hasSchedule) {
            if (schedules[date] !== undefined) {
                status = schedules[date]
            } else if (defaultStatus || defaultStatus === 0) {
                // todo этот last date будет неактуален после смены свободных дат на занятые
                status = date>schedules.lastDate ? schedules.defaultAfterLast : defaultStatus
            }
        }
        let disabled = false
        if (borders?.min && borders.min.date > date) {
            disabled = true
        }
        if (!disabled && borders?.max && borders.max.date < date) {
            disabled = true
        }
        if (!disabled) {
            $days.push(<Day key={'day_' + day} day={day} isSelected={isSelected} pickDate={pickDate} month={month} year={year} status={status}/>)
        } else { // disabled day
            $days.push(<Day key={'day_' + day} day={day} isSelected={isSelected} isDisabled={disabled}/>)
        }
    }

    let $dows = []
    for (let d=1; d<=7; d++) {
        $dows.push(<Dow key={'dow_' + d} dow={d == 7 ? 0 : d}/>)
    }
    return (
        <div className="calendar_month">
            {$dows}
            {$days}
        </div>)
}

const HeadArrows = ({month, year, borders, setCurrent}) => {
    if (year != today.year) {
        monthName[month] += ', ' + year
    }

    const changeMonth = (dir) => {
        let current = {
            month : month + dir,
            year  : year,
        }
        if (current.month < 1) {
            current.month = 12
            current.year--
        } else if (current.month > 12) {
            current.month = 1
            current.year++
        }
        setCurrent(current)
    }

    return (
        <div className="calendar_head">
            {!borders || borders.min.year < year || (borders.min.year == year && borders.min.month < month )  ?
                <a className="calendar_arrow-prev" onClick={() => changeMonth(-1)}/>
            : null}
            <span className="calendar_monthName">{monthName[month]}</span>
            {!borders || borders.max.year > year || (borders.max.year == year && borders.max.month > month )  ?
                <a className="calendar_arrow-next" onClick={() => changeMonth(1)}/>
            : null}
        </div>)
}

const HeadSelects = ({month, year, borders, setCurrent}) => {
    const changeMonth = (newMonth) => {
        setCurrent({
            month : newMonth,
            year  : year,
        })
    }
    const changeYear = (newYear) => {
        setCurrent({
            month : month,
            year  : newYear,
        })
    }

    let $years = []
    for (let y=borders.max.year; y>=borders.min.year; y--) {
        $years.push(<option value={y}>{y}</option>)
    }
    let $monthes = []
    for (let m=1; m<=12; m++) {
        $monthes.push(<option value={m}>{monthName[m]}</option>)
    }
    return (
        <div className="calendar_head-select">
            <select className="calendar_selector-year" value={year} onChange={(ev) => changeYear(ev.target.value)}>{$years}</select>
            <select className="calendar_selector-month" value={month} onChange={(ev) => changeMonth(ev.target.value)}>{$monthes}</select>
        </div>)
}

const Calendar = ({show, params, close}) => {
    if (!show) {
        return null
    }

    const [current, setCurrent] = useState({})

    if (monthName === null) {
        monthName = [null]
        for (let m=1; m<=12; m++) {
            monthName[m] = dayjs('2000-' + m, 'YYYY-MM').format('MMMM')
        }
    }
    useEffect(() => {

        if (params.selected) {
            const [y, m, d] = params.selected.split('-')
            setCurrent({
                month : +m,
                year  : +y,
            })
        } else {
            setCurrent({
                month : today.month,
                year  : today.year,
            })
        }
    }, [])

    const onPick = (date) => {
        if (params.onPick) {
            params.onPick(date)
        }
        close()
    }

    const onReset = () => {
        if (params.onReset) {
            params.onReset()
        }
        close()
    }

    let selected = null
    if (params.selected) {
        const [y, m, d] = params.selected.split('-')
        selected = {
            day   : +d,
            month : +m,
            year  : +y,
            date  : params.selected,
        }
    }

    let schedules = null
    let defaultStatus = null
    if (params.schedules) {
        if (Array.isArray(params.schedules)) {
            schedules = {}
            params.schedules.forEach(schedule => {
                const month = +schedule.date.split('-')[1]
                if (props.month == month) {
                    schedules[schedule.date] = 3
                }
            })
            defaultStatus = 0
        } else  {
            schedules = params.schedules
            if (typeof params.schedules.default !== 'undefined') {
                defaultStatus = params.schedules.default
            }
        }
    }
    let borders = {
        min : today,
        max : {
            day   : today.day,
            month : today.month,
            year  : today.year + 10,
        },
    }
    if (params.borders) {
        let border = params.borders.split('~');
        ['min', 'max'].forEach((label, i) => {
            let date = border[i]
            if (date == 'now') {
                borders[label] = today
            } else {
                const [y, m, d] = date.split('-')
                borders[label] = {
                    day   : +d,
                    month : +m,
                    year  : +y,
                }
            }
        })
    }
    ['min', 'max'].forEach(label => {
        borders[label].date = borders[label].year + '-'+ (borders[label].month + '').padStart(2, '0') + '-' + (borders[label].day + '').padStart(2, '0')
    })

    let $head = null
    if (!params.noheader) {
        if (borders.max.year - borders.min.year < 15) {
            $head = <HeadArrows month={current.month} year={current.year} borders={borders} setCurrent={setCurrent} />
        } else {
            $head = <HeadSelects month={current.month} year={current.year} borders={borders} setCurrent={setCurrent} />
        }
    }

    return (
        <div className="filterBubble">
            <a className="filterBubble_close" onClick={close}/>
            {selected && params.onReset ?
                <a className="filterBubble_reset" onClick={onReset}>{t('Сбросить')}</a>
            : null}
            <div className="filterBubble_content">
                <div className="filterBubble_body-calendar">
                    <div className="calendar">
                        {$head}
                        <Month month={current.month} year={current.year} pickDate={onPick} borders={borders} selected={selected} schedules={schedules} defaultStatus={defaultStatus}/>
                    </div>
                </div>
            </div>
        </div>)
}

function mapStateToProps(state, ownProps) {
    return {
        show   : state.calendar.show,
        params : state.calendar.params,
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        close: () => dispatch(close()),
        dispatch: dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
