import {types} from 'actions/my'

import Meta from 'models/meta'
import _ from "lodash"

const initialState = {
    albums: {
        portfolio : {
            isLoading : false,
            items   : [],
            pages   : 0,
            page    : 1,
        },
        wedding : {
            isLoading : false,
            items   : [],
            pages   : 0,
            page    : 1,
        },
        other : {
            isLoading : false,
            items   : [],
            pages   : 0,
            page    : 1,
        },
        hidden : {
            isLoading : false,
            items   : [],
            pages   : 0,
            page    : 1,
        },
    },
    albumsLoading : false,
    calcCity: {},
    calcCities: {},
    calcPro: {},
    discountNotes : {
        note1 : null,
        note2 : null,
    },
    hasCoupons: false,
    coupons: 0,
    cities: [],
    citiesNear: [],
    citiesPopular: [],
    citiesSearch: [],
    isDeletingCity: false,
    isDiscount: false,
    isLoadingCalcCity: false,
    isLoadingCalcCities: false,
    isLoadingCalcPro: false,
    isLoadingCities: false,
    isLoadingMediaStat: false,
    mediaStat : [],
    metaMediaStat : {},
    selectedCalcCities: '',
}

const MyReducer = (state = initialState, action) => {
    let cities
    let qs
    let albums
    switch (action.type) {
        case types.LOAD_CALC_PRO_OK:
            return {
                ...state,
                calcPro: action.data.prices,
                hasCoupons: action.data.has_coupons || false,
                isDiscount: action.data.is_discount || false,
                discountNotes: {
                    note1 : action.data.note1 || null,
                    note2 : action.data.note2 || null,
                },
                coupons: action.data.coupons || 0,
                isLoadingCalcPro: false,
            }
        case types.LOAD_CALC_PRO_REQUEST:
            return {
                ...state,
                isLoadingCalcPro: true,
            }
        case types.LOAD_CALC_PRO_ERROR:
            return {
                ...state,
                isLoadingCalcPro: false,
            }
        case types.LOAD_CITIES_OK:
            return {
                ...state,
                cities: action.data.billing,
                citiesPopular: action.data.popular,
                citiesNear: action.data.near,
                isLoadingCities: false,
            }
        case types.LOAD_CITIES_REQUEST:
            return {
                ...state,
                isLoadingCities: true,
            }
        case types.LOAD_CITIES_ERROR:
            return {
                ...state,
                isLoadingCities: false,
            }

// Search City
        case types.ADD_CITY_SEARCH_OK:
            return {
                ...state,
                citiesSearch: action.data.cities,
                isLoadingCities: false,
            }
        case types.ADD_CITY_SEARCH_REQUEST:
            return {
                ...state,
                isLoadingCities: true,
            }
        case types.ADD_CITY_SEARCH_ERROR:
            return {
                ...state,
                isLoadingCities: false,
            }

// Remove City
        case types.REMOVE_CITY_OK:
            cities = state.cities
            for (let i=0; cities[i]; i++) {
                if (cities[i].city && cities[i].city.id == action.city.city.id) {
                    cities.splice(i, 1)
                    break
                }
            }
            qs = []
            _.each(cities.filter(c => c.is_new), city => {
                qs.push('city[' + city.city.id + ']=' + city.price.label)
            })
            return {
                ...state,
                selectedCalcCities: qs.join('&'),
                cities: cities,
                isDeletingCity: false,
            }
        case types.REMOVE_CITY_REQUEST:
            return {
                ...state,
                isDeletingCity: true,
            }
        case types.REMOVE_CITY_ERROR:
            return {
                ...state,
                isDeletingCity: false,
            }

        case types.LOAD_CALC_CITY_OK:
            return {
                ...state,
                calcCity: action.data.prices,
                isLoadingCalcCity: false,
            }
        case types.LOAD_CALC_CITY_REQUEST:
            return {
                ...state,
                calcCity: {},
                isLoadingCalcCity: true,
            }
        case types.LOAD_CALC_CITY_ERROR:
            return {
                ...state,
                isLoadingCalcCity: false,
            }

        case types.LOAD_CALC_CITIES_OK:
            return {
                ...state,
                calcCities: action.data,
                isLoadingCalcCities: false,
            }
        case types.LOAD_CALC_CITIES_REQUEST:
            return {
                ...state,
                calcCities: {},
                isLoadingCalcCities: true,
            }
        case types.LOAD_CALC_CITIES_ERROR:
            return {
                ...state,
                isLoadingCalcCities: false,
            }

        case types.LOAD_CALC_CITY_SELECT:
            cities = state.cities
            let city = {
                city       : action.city,
                is_expired : false,
                is_new     : true,
                price      : action.price,
            }
            let index = _.findIndex(cities, {city: action.city})
            if (index > -1) {
                cities.splice(index, 1, city)
            } else {
                cities.push(city)
            }
            qs = []
            _.each(cities.filter(c => c.is_new), city => {
                qs.push('city[' + city.city.id + ']=' + city.price.label)
            })
            return {
                ...state,
                selectedCalcCities: qs.join('&'),
                cities: cities,
                isLoadingCities: false,
            }

// свои альбомы
        case types.LOAD_MYALBUMS_OK:
            albums = state.albums
            albums[action.category].page = action.data.meta.page
            albums[action.category].pages = action.data.meta.pages_count
            albums[action.category].items = albums[action.category].items.concat(action.data.albums)
            albums[action.category].isLoading = false

            return {
                ...state,
                albums: albums,
                albumsLoading : !state.albumsLoading,
            }
        case types.LOAD_MYALBUMS_REQUEST:
            albums = state.albums
            albums[action.category].isLoading = true
            if (action.page == 1) {
                albums[action.category].items = []
                albums[action.category].page = 1
                albums[action.category].pages = 0
            }
            return {
                ...state,
                albums: albums,
                albumsLoading : !state.albumsLoading,
            }
        case types.LOAD_MYALBUMS_ERROR:
            albums = state.albums
            albums[action.category].isLoading = false
            return {
                ...state,
                albums: albums,
                albumsLoading : !state.albumsLoading,
            }

// рейтинг фото
        case types.LOAD_MEDIASTAT_OK:
            return {
                ...state,
                mediaStat: action.page == 1 ? action.list : state.mediaStat.concat(action.list),
                metaMediaStat: action.meta,
                isLoadingMediaStat: false,
            };
        case types.LOAD_MEDIASTAT_REQUEST:
            return {
                ...state,
                mediaStat: action.page == 1 ? [] : state.mediaStat,
                metaMediaStat: action.page == 1 ? {} : state.metaMediaStat,
                isLoadingMediaStat: true,
            };
        case types.LOAD_MEDIASTAT_ERROR:
            return {
                ...state,
                mediaStat: [],
                metaMediaStat: {},
                isLoadingMediaStat: false,
            };

        default:
            return state
    }
}

export default MyReducer