import {types} from 'actions/siteSearch'

const initialState = {
    show: false,
    list: [],
    loading: false,
}


const SiteSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN:
            return {
                ...state,
                list: [],
                show: true,
                loading: false,
            }

        case types.CLOSE:
            return {
                ...state,
                list: [],
                show: false,
                loading: false,
            }

        case types.SEARCH_REQUEST:
            return {
                ...state,
                list: [],
                loading: true,
            }
        case types.SEARCH_OK:
            return {
                ...state,
                list: action.list,
                loading: false,
            }
        case types.SEARCH_ERROR:
            return {
                ...state,
                list: [],
                loading: false,
            }
        default:
            return state
    }
}
export default SiteSearchReducer