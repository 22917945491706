import {types} from 'actions/popup'
import _ from 'lodash'

import {types as authTypes} from 'actions/auth'
import {LOCATION_CHANGE} from 'react-router-redux'

const initialState =
    {
        component: null,
        show: false,
        stack: [],
        title: null,
        returnLocation: null,
        returnPopupScrollTop: null,
    }


const PopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN:
            let stack = state.stack
            let existIndex = -1
            if (action.key) {
                existIndex = _.findIndex(stack, {key: action.key})
            }
            if (existIndex !== -1) {
                stack.splice(existIndex, 1)
            }

            const popup = {
                component: action.component,
                isBackButton: action.isBackButton,
                returnLocation: action.returnLocation,
                title: action.title,
            }

            let returnPopupScrollTop = null
            if (state.stack.length) {
                const $popup = document.getElementById('popup')
                if ($popup) {
                    returnPopupScrollTop = $popup.scrollTop
                }
            }

            stack.push({
                ...popup,
                key: action.key,
                returnPopupScrollTop: returnPopupScrollTop,
                url: document.location.href,
            })

            return {
                ...state,
                stack: stack,
                ...popup,
                show: true,
                returnPopupScrollTop: null,
            }

        case types.CLOSE:
            const last = state.stack.pop() // remove last popup
            const current = state.stack.slice(-1)[0]

            return {
                ...state,
                show: !!current,
                stack: state.stack ? state.stack : [],
                component: current ? current.component : null,
                title: current ? current.title : null,
                returnLocation: current ? current.returnLocation : null,
                isBackButton: current ? current.isBackButton : null,
                returnPopupScrollTop: last ? last.returnPopupScrollTop : null,
            }
        case types.SET_RETURN_LOCATION:
            stack = state.stack
            existIndex = _.findIndex(stack, {key: action.key})
            stack[existIndex].returnLocation = action.returnLocation
            if (existIndex !== -1) {
                return {
                    ...state,
                    stack: stack,
                    returnLocation: state.component === stack[existIndex].component ? action.returnLocation : state.returnLocation,
                }
            } else {
                return state
            }
        case LOCATION_CHANGE:
        case authTypes.CHECK_AUTH_OK:
            const popupWithSameUrl = state.stack.filter(p => p.url===document.location.href).pop()
            if (popupWithSameUrl) {
                return {
                    ...state,
                    show: true,
                    stack: state.stack ? state.stack : [],
                    component: popupWithSameUrl ? popupWithSameUrl.component : null,
                    title: popupWithSameUrl ? popupWithSameUrl.title : null,
                    returnLocation: popupWithSameUrl ? popupWithSameUrl.returnLocation : null,
                    isBackButton: popupWithSameUrl ? popupWithSameUrl.isBackButton : null,
                    returnPopupScrollTop: last ? last.returnPopupScrollTop : null,
                }
            } else {
                return initialState
            }
        default:
            return state
    }
}
export default PopupReducer