import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import t from 't'

import Favorite from 'components/directory/favorite'
import Gallery from 'components/block/gallery'
import Link from 'components/block/link'
import MapOverlay from 'components/block/mapOverlay'
import SchemaOrg from 'components/block/schemaOrg'

const ShopCard = (props) => {
    const card = props.card
    let isActive = true
    let markers = _.map(card.branches, branch => {
        const marker = {
            lat: branch.coords.latitude,
            lng: branch.coords.longitude,
            address: branch.address,
            model: card,
            isActive: isActive,
        }
        isActive = false
        return marker
    })

    return (
        <div className={'card' + (card.isPro ? ' card-pro' : '')}>
            <SchemaOrg data={card.schema}/>
            <Favorite isFav={card.isFav(props.favorites)} toggleFavorite={props.toggleFavorite} modelId={card.id}/>
            <Link className="card_name" to={card} target={props.openInNewWindow===1?'_blank':'_self'}>{card.name}</Link>
            <Gallery media={card.media} link={card} link_target={props.openInNewWindow===1?'_blank':'_self'}/>
            <div className="card_location">
                <a className="card_address" onClick={() => props.showOnMap(markers, {
                    overlay: MapOverlay,
                    caption: card.name,
                    buttons: ['topage', 'contacts']
                })}>{card.firstBranch.address}</a>
            </div>
            {/* todo */}
            {/*<Link to={card.dresses.meta.total_count ? card.link + 'dress/' : card} className="button-border button-centered" target={props.openInNewWindow===1?'_blank':'_self'}>{card.more_text}</Link>*/}
            <Link to={card} className="button-border button-centered" target={props.openInNewWindow===1?'_blank':'_self'}>{t('Смотреть коллекции')}</Link>
        </div>
    )
}

ShopCard.propTypes = {
    card: PropTypes.object.isRequired,
    toggleFavorite: PropTypes.func.isRequired,
    favorites: PropTypes.array,
}

export default ShopCard
