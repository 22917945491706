import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import axios from 'axios'

const Notification = ({notification}) => {
    if (notification === null) {
        return null
    } else {
        return (
            <div className="notification">
                <div className="notification_text" dangerouslySetInnerHTML={{__html: notification.text}}></div>
                <a className="button-border notification_button" href={notification.btn_url}>{notification.btn_text}</a>
            </div>)
    }
}

Notification.propTypes = {
    user: PropTypes.object,
}

function mapStateToProps(state, ownProps) {
    return {
        user : state.auth ? state.auth.user : null,
        notification : state.auth && state.auth.user ? state.auth.user.notification : null,
        is_discount : state.main.is_discount,
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    let state = ownProps.state || {}
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
