import t from 't'
import _ from 'lodash'
import BaseModel from 'models/baseModel'

export default class Place extends BaseModel {
    get address() {
        let address = '';
        _.map(this.contacts, (contact) => {
            if (contact.key == 'address')
                address = contact.value
        })
        return address
    }

    get reducerName() {
        return 'place'
    }
    get entityType() {
        return 'places'
    }

    get cityName() {
        return this.city.name
    }

    getCoverUrl(size) {
        return this.cover_url[size] ? this.cover_url[size] : (typeof this.cover_url === 'string' ? this.cover_url : this.cover_url[Object.keys(this.cover_url)[0]])
    }

    get link() {
        return this.url
    }

    tabs(checkActivePage) {
        let tabs = [
            {
                text: t('Обзор'),
                to: this.link,
                active: checkActivePage(),
            },
            {
                text: t('Обсуждения'),
                to: this.link + 'comment/',
                active: checkActivePage('comment'),
            }
        ]

        if (this.reviews_count) {
            tabs.push({
                text: t('Оценки'),
                to: this.link + 'rating/',
                active: checkActivePage('rating'),
            })
        }

        return tabs
    }
}