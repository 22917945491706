import {types} from 'actions/payment'

import Meta from 'models/meta'
import _ from "lodash"
import t from 't'

const initialState = {
    providers: [],
    isProvidersLoading: false,
}

const providerName = {
    card          : t('Банковская карта'),
    cloudpayments : t('Банковская карта'),
    liqpay        : t('Банковская карта'),
    yandex        : t('Яндекс.Деньги'),
    robox         : t('Остальные способы оплаты'),
    instamojo     : t('Credit/debit card, Net banking, EMI, UPI'),
    qiwi          : t('Qiwi кошелёк'),
}

const PaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_PROVIDERS_OK:
            return {
                ...state,
                providers: action.data.providers.map((provider) => {
                    provider.title = providerName[provider.key]
                    return provider
                }),
                isProvidersLoading: false,
            }
        case types.LOAD_PROVIDERS_REQUEST:
            return {
                ...state,
                providers: [],
                isProvidersLoading: true,
            }
        case types.LOAD_PROVIDERS_ERROR:
            return {
                ...state,
                isProvidersLoading: false,
            }

        default:
            return state
    }
}

export default PaymentReducer