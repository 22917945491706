import React from 'react'
import PropTypes from 'prop-types'

const Favorite = (props) => {
    const onClick = (ev) => {
        props.toggleFavorite(props.modelId, props.isFav)
        if (props.onClick) {
            props.onClick()
        }
    }

    return (
        <a className={'favorite' + (props.isFav ? ' _fav' : '')} onClick={onClick}/>
    )
}

Favorite.propTypes = {
    isFav: PropTypes.bool.isRequired,
    modelId: PropTypes.number.isRequired,
    toggleFavorite: PropTypes.func.isRequired,
}

export default Favorite