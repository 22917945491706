import _ from 'lodash'
import {LOCATION_CHANGE} from 'react-router-redux'
import {types} from 'actions/follower'

import Follower from 'models/follower'
import Meta from 'models/meta'

const initialState =
    {
        list: [],
        loading: false,
        meta: {},
    }

const FollowerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_MORE_REQUEST:
        case types.LOAD_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case types.LOAD_LIST_OK:
            return {
                ...state,
                list: _.map(action.list, item => new Follower(item)),
                meta: new Meta(action.meta),
                loading: false
            }
        case types.LOAD_MORE_OK:
            return {
                ...state,
                list: _.union(state.list, _.map(action.list, item => new Follower(item))),
                meta: new Meta(action.meta),
                loading: false
            }

        case types.LOAD_MORE_ERROR:
        case types.LOAD_LIST_ERROR:
            return {
                ...state,
                loading: false,
            }

        case LOCATION_CHANGE:
            return initialState

        default:
            return state
    }
}
export default FollowerReducer