import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,
    LOAD_MORE_REQUEST: null,
    LOAD_MORE_OK: null,
    LOAD_MORE_ERROR: null,

    LOAD_MODEL_REQUEST: null,
    LOAD_MODEL_OK: null,
    LOAD_MODEL_ERROR: null,

    REQUEST_SEND: null,
    REQUEST_OK: null,
    REQUEST_ERROR: null,

    CHANGE_ACTIVE_SPEC: null,
}, 'user')

const getModelUrl = (id) => {
    return API_URL + '/v2/users/' + id
}

export const loadModel = (id, params = null, cancelToken = null, asPromise = false) => {
    if (asPromise !== true) {
        return function (dispatch) {
            dispatch({
                type: types.LOAD_MODEL_REQUEST
            })

            return axios.get(getModelUrl(id), {params: params, cancelToken: cancelToken, withCredentials: true})
                .then(response => {
                    dispatch({
                        meta: response.data.meta,
                        model: response.data.user,
                        type: types.LOAD_MODEL_OK,
                    })
                })
                .catch((e) => {
                    if ('development' === process.env.NODE_ENV) console.log(e)
                    dispatch({
                        type: types.LOAD_MODEL_ERROR
                    })
                })
        }
    } else {
        return new Promise(function(resolve, reject) {
            axios.get(getModelUrl(id), {params: params, cancelToken: cancelToken, withCredentials: true})
                .then(response => {
                    resolve(response.data.user)
                })
                .catch((e) => {
                    if ('development' === process.env.NODE_ENV) console.log(e)
                })
        })
    }
}

export const sendRequest = (userId, data, pageType) => {
    return function (dispatch) {
        dispatch({
            type: types.REQUEST_SEND
        })
        let postData = {
            date        : data.date,
            days        : data.days,
            services    : data.services,
            budget      : data.budget,
            name        : data.name,
            details     : data.details,
            page_type   : pageType == 'profile' ? 1 : 2,
            phone       : data.phone,
            whatsapp    : data.whatsapp,
            viber       : data.viber,
            telegram    : data.telegram,
            line        : data.line,
            viaPhone    : +data.viaPhone,
            viaWhatsApp : +data.viaWhatsApp,
            viaViber    : +data.viaViber,
            viaLine     : +data.viaLine,
            viaTelegram : +data.viaTelegram,
        }

        return new Promise(function(resolve, reject) {
            let url = getModelUrl(userId) + '/request?model_type=' + data.modelType + '&model_id=' + data.roleId + '&city_id=' + data.city.id
            axios.post(url, postData, {withCredentials: true})
                .then(response => {
                    dispatch({
                        type: types.REQUEST_OK,
                    })
                    let goalParams = {
                        vendor_type: 'user',
                        vendor_id: userId,
                        domain: SITE_DOMAIN
                    }
                    window.reachGoal('inquireUserSubmit', goalParams)
                    window.reachGoal('totalComUser', goalParams)
                    resolve(response.data)
                })
                .catch(() => {
                    dispatch({
                        type: types.REQUEST_ERROR
                    })
                })
        })
    }
}

export const switchSpec = (userId, filterKey, cancelToken) => {
    return function (dispatch) {
        dispatch({
            type: types.CHANGE_ACTIVE_SPEC,
            filterKey: filterKey
        })
        return axios.put(getModelUrl(userId) + '/switchSpec', {filterKey:filterKey}, {cancelToken: cancelToken, withCredentials: true})
            .then(response => {
                return response.data
            })
            .catch((e) => {
        })
    }
}