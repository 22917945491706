import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,
    LOAD_MORE_REQUEST: null,
    LOAD_MORE_OK: null,
    LOAD_MORE_ERROR: null,

    LOAD_MODEL_REQUEST: null,
    LOAD_MODEL_OK: null,
    LOAD_MODEL_ERROR: null,

    LOAD_COLLECTION_LIST_REQUEST: null,
    LOAD_COLLECTION_LIST_OK: null,
    LOAD_COLLECTION_LIST_ERROR: null,
    LOAD_COLLECTION_MORE_REQUEST: null,
    LOAD_COLLECTION_MORE_OK: null,
    LOAD_COLLECTION_MORE_ERROR: null,

    LOAD_MEDIA_LIST_REQUEST: null,
    LOAD_MEDIA_LIST_OK: null,
    LOAD_MEDIA_LIST_ERROR: null,
    LOAD_MEDIA_MORE_REQUEST: null,
    LOAD_MEDIA_MORE_OK: null,
    LOAD_MEDIA_MORE_ERROR: null,
}, 'album');

const MEDIA_PER_PAGE = 10

const getCollectionsUrl = (entityType, modelId) => {
    return API_URL + '/v2/' + entityType + '/' + modelId + '/collections'
}

const getMediaUrl = (modelId) => {
    return API_URL + '/v2/albums/' + modelId + '/media'
}

const getModelUrl = (modelId) => {
    return API_URL + '/v2/albums/' + modelId
}

const getUrl = (entityType, modelId) => {
    return API_URL + '/v2/' + entityType + '/' + modelId + '/albums'
}

export const loadAlbums = (entityType, modelId, params = {}) => {
    return function (dispatch, getState) {
        let typeRequest = types.LOAD_LIST_REQUEST
        let typeOk = types.LOAD_LIST_OK
        let typeError = types.LOAD_LIST_ERROR

        if (!params.page) {
            params.page = 1
        }

        // если у нас есть offset
        // то скорее всего мы подгрузили на странице немного альбомов, а теперь запрашиваем пачку большего размера
        // и по сути подгрузка второй (или более страницы) - это на самом деле первая страница с offset
        if (params.offset) {
            params.page--
        }

        // если страница первая, но есть offset, то значит уже есть подгруженые альбомы
        if (params.page > 1 || params.offset > 0) {
            typeRequest = types.LOAD_MORE_REQUEST
            typeOk = types.LOAD_MORE_OK
            typeError = types.LOAD_MORE_ERROR
        }

        dispatch({
            type: typeRequest
        });

        axios.get(getUrl(entityType, modelId), {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.albums,
                    meta: response.data.meta,
                    type: typeOk,
                })
            })
            .catch(() => {
                dispatch({
                    type: typeError
                })
            })
    }
};

export const loadCollections = (entityType, modelId, params = {}) => {
    return function (dispatch, getState) {
        let typeRequest = types.LOAD_COLLECTION_LIST_REQUEST
        let typeOk = types.LOAD_COLLECTION_LIST_OK
        let typeError = types.LOAD_COLLECTION_LIST_ERROR
        params.page = 1

        if (!params.page) {
            params.page = 1
        }

        if (params.page > 1) {
            typeRequest = types.LOAD_MORE_REQUEST
            typeOk = types.LOAD_MORE_OK
            typeError = types.LOAD_MORE_ERROR
        }

        dispatch({
            type: typeRequest
        });

        axios.get(getCollectionsUrl(entityType, modelId), {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.albums,
                    meta: response.data.meta,
                    type: typeOk,
                })
            })
            .catch(() => {
                dispatch({
                    type: typeError
                })
            })
    }
};

export const loadModel = (modelId, params = {}, woRequest) => {
    return function (dispatch) {
        let typeRequest = types.LOAD_MODEL_REQUEST
        let typeOk = types.LOAD_MODEL_OK
        let typeError = types.LOAD_MODEL_ERROR

        if (woRequest !== true) {
            dispatch({
                type: typeRequest
            })
        }

        axios.get(getModelUrl(modelId), {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    model: response.data.album,
                    type: typeOk,
                })
            })
            .catch(() => {
                dispatch({
                    type: typeError
                })
            })
    }
}

export const loadMedia = (modelId, params = {}) => {
    return function (dispatch, getState) {
        let typeRequest = types.LOAD_MEDIA_LIST_REQUEST
        let typeOk = types.LOAD_MEDIA_LIST_OK
        let typeError = types.LOAD_MEDIA_LIST_ERROR

        if (!params.per_page) {
            params.per_page = MEDIA_PER_PAGE
        }

        if (!params.page) {
            params.page = getState().album.media_meta ? getState().album.media_meta.nextPage : 1
        }

        // у нас нет пагинации альбома, так что можно использовать номер запрошеной страницы как индикатор подгрузки
        if (getState().album.media_meta && params.page>1) {
            typeRequest = types.LOAD_MEDIA_MORE_REQUEST
            typeOk = types.LOAD_MEDIA_MORE_OK
            typeError = types.LOAD_MEDIA_MORE_ERROR
        }

        dispatch({
            type: typeRequest
        })

        axios.get(getMediaUrl(modelId), {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.media,
                    meta: response.data.meta,
                    type: typeOk,
                })
            })
            .catch(() => {
                dispatch({
                    type: typeError
                })
            })
    }
}
