import _ from 'lodash'

import {types} from 'actions/comment'
import {types as mediaTypes} from 'actions/media'

import Comment from 'models/comment'
import Meta from 'models/meta'

const initialState = {
    list: [],
    loading: false,
    meta: {},

    opened_actions: null,
    opened_form: null,

    isPosting: false,
    loading_add_vote: false,
}

const appendComment = (list, newComment, level = 0) => {
    if (newComment.level === 0 || !newComment.parent_id) {
        list.push(newComment)
    } else {
        level++
        if (newComment.level === level) {
            list = _.map(list, comment => {
                if (comment.id === newComment.parent_id) {
                    if (!comment.comments) list.comments = []
                    comment.comments.push(newComment)
                }
                return comment
            })
        } else {
            list = _.map(list, comment => {
                if (comment.comments) {
                    comment.comments = appendComment(comment.comments, newComment, level)
                }
                return comment
            })
        }
    }
    return list
}

const CommentReducer = (state = initialState, action) => {
    switch (action.type) {
        case mediaTypes.LOAD_MODEL_REQUEST:
            return initialState

        case types.LOAD_LIST_REQUEST:
        case types.LOAD_MORE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case types.LOAD_LIST_OK:
            return {
                ...state,
                list: _.map(action.list, item => new Comment(item)),
                meta: new Meta(action.meta),
                loading: false
            }
        case types.LOAD_MORE_OK:
            return {
                ...state,
                list: _.union(state.list, _.map(action.list, item => new Comment(item))),
                meta: new Meta(action.meta),
                loading: false
            }

        case types.ADD_COMMENT_REQUEST:
            return {
                ...state,
                isPosting: true,
            }
        case types.ADD_COMMENT_ERROR:
            return {
                ...state,
                isPosting: false,
            }

        case types.ADD_COMMENT_OK:
            return {
                ...state,
                isPosting: false,
                opened_form: null,
                list: appendComment(state.list, new Comment(action.comment)),
            }

        case types.ADD_VOTE_REQUEST:
            return {
                ...state,
                opened_actions: null,
                loading_add_vote: action.commentId,
            }
        case types.ADD_VOTE_OK:
            return {
                ...state,
                list: _.map(state.list, comment => {
                    if (comment.id === action.commentId) {
                        comment.votes = action.votes
                        comment.my_vote = action.my_vote
                    }
                    return comment
                }),
                loading_add_vote: false,
            }
        case types.ADD_VOTE_ERROR:
            return {
                ...state,
                loading_add_vote: false,
            }
        case types.TOGGLE_ACTIONS:
            return {
                ...state,
                opened_actions: state.opened_actions === action.id ? null : action.id
            }

        case types.TOGGLE_FORM:
            return {
                ...state,
                opened_form: state.opened_form === action.id ? null : action.id,
                opened_actions: null,
            }

        case types.LOAD_LIST_ERROR:
        case types.LOAD_MORE_ERROR:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
}
export default CommentReducer