import React from 'react'
import PropTypes from 'prop-types'

import t from 't'
import LinkLight from 'components/block/linkLight'

const Page = (props) => {
    if (props.page) {
        if (props.useGet) {
            let url = document.location.href
            if (props.page > 1) {
                if (url.indexOf('page=') > -1) {
                    url = url.replace(/page=\d+/, 'page=' + props.page)
                } else {
                    if (url.indexOf('?') > -1) {
                        url += '&page=' + props.page
                    } else {
                        url += '?page=' + props.page
                    }
                }
            } else {
                url = url.replace(/page=\d+/, '').replace('&&', '&')
            }
            return <LinkLight to={url} className={"paginator_page" + (props.isCurrent ? ' _cur' : '')} onClick={props.callback?props.callback:null}>{props.page}</LinkLight>
        } else {
            return <a className={"paginator_page" + (props.isCurrent ? ' _cur' : '')}
                      onClick={(ev) => props.loading ? ev.preventDefault() : props.loadData(props.page)}>{props.page}</a>
        }
    } else {
        return <span className="paginator_page-dummy">…</span>
    }
}

const Pagination = (props) => {
    if (!props.meta || !props.meta.totalPages || props.meta.totalPages < 2) return null
    const totalPages = props.meta.totalPages
    const currentPage = props.meta.page

    let scaleClass = ''
    if (totalPages > 9999) {
        scaleClass = '-smallest'
    } else if (totalPages > 999) {
        scaleClass = '-small'
    }

    let pageNums = []
    if (totalPages <= 7) {
        for (let i = 1; i <= totalPages; i++) {
            pageNums.push(i)
        }
    } else {
        if (currentPage <= 4) {
            pageNums = [1, 2, 3, 4, 5, null, totalPages]
        } else if (currentPage > totalPages - 4) {
            pageNums = [1, null, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]
        } else {
            pageNums = [1, null, currentPage - 1, currentPage, currentPage + 1, null, totalPages]
        }
    }
    let pages = []
    for (let i = 0; (pageNums[i] || pageNums[i] === null); i++) {
        let page = pageNums[i]
        pages.push(<Page key={i} useGet={props.useGet} page={page} isCurrent={(page === currentPage)} callback={props.callback} loadData={() => {props.loadData(page)}}/>)
    }

    return (
        <div className="paginator">
            <div className={"paginator_pages" + scaleClass }>{pages}</div>
            <p className="paginator_text">{t('Страница {num} из {total}', {
                'num': currentPage,
                'total': totalPages
            })}</p>
        </div>
    )
}

Pagination.propTypes = {
    callback: PropTypes.func,
    loadData: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    meta: PropTypes.object,
    useGet: PropTypes.bool,
}

export default Pagination
