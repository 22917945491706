import _ from 'lodash'

import {types} from 'actions/wedding'

import Album from 'models/album'
import Meta from 'models/meta'

const initialState = {
    list: [],
    loading: false,
    meta: null,
    weddingType: null,
}

const WeddingReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.LOAD_LIST_OK:
            return {
                ...state,
                list: action.meta.page === 1 ? _.map(action.list, item => new Album(item)) : _.union(state.list, _.map(action.list, item => new Album(item))),
                loading: false,
                meta: new Meta(action.meta),
                weddingType: action.weddingType,
            }

        case types.LOAD_LIST_ERROR:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
}
export default WeddingReducer