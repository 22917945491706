import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import t from 't'
import _ from 'lodash'

import {putComponentToPopup} from 'actions/popup'
import {onVenueCall} from 'actions/venue'
import {viewInfo} from 'actions/viewInfo'

import Contact from 'containers/block/contact'
import Link from 'components/block/link'
import LinkLight from 'components/block/linkLight'

const MapOverlay = (props) => {
    let buttons = []
    const buttonClass = props.buttons.length > 1 ? 'location_button' : 'button-wide'
    _.map(props.buttons, (buttonName, i) => {
        if (buttonName === 'topage') {
            if (props.url) {
                buttons.push(<LinkLight key="button_topage" className={'button-border ' + buttonClass}
                                   to={props.url}>{t('Показать страницу')}</LinkLight>)
            } else {
                buttons.push(<Link key="button_topage" className={'button-border ' + buttonClass}
                                   to={props.model}>{t('Показать страницу')}</Link>)
            }
        } else if (buttonName === 'contacts') {
            if (props.phone && props.model.contactSchema.main_method_mobile == 'call') {
                buttons.push(<a key="button_contact" className={'button-contact ' + buttonClass} href={"tel:" + props.phone.replace(/[^\d\+]/g, '')} onClick={props.onVenueCall}>{t('Позвонить')}</a>)
            } else {
                const text = props.buttons.length > 1 ? t('Контакты') : t('Показать контакты')
                buttons.push(<a key="button_contact" className={'button-contact ' + buttonClass} onClick={props.showContacts}>{text}</a>)
            }
        }
    })
    let landmark = null
    if (props.model.landmark) {
        landmark = <b>{props.model.landmark}</b>
    }
    return (
        <div className="location">
            {buttons}
            <p className="location_address">{props.address}{landmark ?' – ' : null}{landmark}</p>
        </div>
    )
}


MapOverlay.propTypes = {
    model: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch, ownProps) {
    let model = ownProps.model
    const modelName = ownProps.model.reducerName
    const modelId = ownProps.model.shopId || ownProps.model.id
    return {
        dispatch: dispatch,
        showContacts: () => dispatch(putComponentToPopup(t('Контакты'), <Contact model={model} mapButtons={ownProps.buttons}/>, 'contact')),
        onVenueCall: () => {
            dispatch(viewInfo(modelName, modelId, {'phone': 1}))
                .then(() => {
                    onVenueCall(model)
                })
        }
    }
}

export default connect(null, mapDispatchToProps)(MapOverlay)
