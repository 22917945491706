import {types} from 'actions/blog'

import _ from 'lodash'

const initialState = {
    entry: null,
    list: [],
    loading: true,
};

const BlogReducer = (state = initialState, action) => {
    switch (action.type) {
        // СПИСОК ЗАПИСЕЙ
        case types.LOAD_LIST_OK:
            return {
                ...state,
                list: action.list,
                loading: false,
            };
        case types.LOAD_LIST_REQUEST:
            return {
                ...state,
                list: [],
                loading: true,
            };
        case types.LOAD_LIST_ERROR:
            return {
                ...state,
                list: [],
                loading: false,
            };

        // ЗАПИСЬ
        case types.LOAD_ENTRY_OK:
            return {
                ...state,
                entry: action.entry,
                loading: false,
            };
        case types.LOAD_ENTRY_REQUEST:
            return {
                ...state,
                entry: null,
                loading: true,
            };
        case types.LOAD_ENTRY_ERROR:
            return {
                ...state,
                entry: null,
                loading: false,
            };

        default:
            return state
    }
}
export default BlogReducer
