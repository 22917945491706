export const setTitles = (titles) => {
    document.title = titles.title
    let $robots = null
    let meta = document.getElementsByTagName("meta")
    for (let i = 0; i < meta.length; i++) {
        if (meta[i].name.toLowerCase() === "description") {
            meta[i].content = titles.description
        }
        if (meta[i].name.toLowerCase() === "keywords") {
            meta[i].content = titles.keywords
        }
        if (meta[i].name.toLowerCase() === "robots") {
            $robots = meta[i]
        }
    }
    let $canonical = null
    let links = document.getElementsByTagName("link")
    for (let i = 0; i < links.length; i++) {
        if (links[i].getAttribute("rel") === "canonical") {
            $canonical = links[i]
        }
    }
    if (titles.canonical) {
        console.log('canonical: ', titles.canonical)
        if (!$canonical) {
            const head = document.getElementsByTagName('head')[0]
            $canonical = document.createElement('link')
            $canonical.rel = 'canonical'
            head.appendChild($canonical)
        }
        $canonical.href = titles.canonical
    } else if ($canonical) {
        $canonical.parentNode.removeChild($canonical)
    }

    if (titles.robots) {
        console.log('meta robots: ', titles.robots)
        if (!$robots) {
            const head = document.getElementsByTagName('head')[0]
            $robots = document.createElement('meta')
            $robots.name = 'robots'
            head.appendChild($robots)
        }
        $robots.content = titles.robots
    } else if ($robots) {
        $robots.parentNode.removeChild($robots)
    }

    const oldQaScript = document.getElementById('qa')
    if (oldQaScript) {
        oldQaScript.remove()
    }
    if (titles.qa && titles.qa.length) {
        let qa = {
            '@context': "https://schema.org",
            '@type': "FAQPage",
            'mainEntity': []
        }
        console.log('qa: ', titles.qa)
        titles.qa.forEach(i => {
            qa['mainEntity'].push({
                '@type': "Question",
                'name': i.q,
                'acceptedAnswer': {
                    "@type": "Answer",
                    "text": i.a
                }
            })
        })
        var qaScript = document.createElement('script')
        qaScript.type = 'application/ld+json'
        qaScript.id = 'qa'
        qaScript.text = JSON.stringify(qa)
        document.querySelector('body').appendChild(qaScript)
    }
}