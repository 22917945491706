import React from 'react'
import {connect} from 'react-redux'
import t from 't'

import {close} from 'actions/phoneCountries'
import {getList, loadList} from 'actions/entity'

import BigLoading from 'components/block/bigLoading'

const Country = (props) => {
    let classname = [
        'flag16x11-' + props.country.iso,
        'ppPhoneCountries_country'
    ]
    if (props.isCurrent) {
        classname.push('_cur')
    }
    let countryName = props.country.name
    if (props.search) {
        let reg = new RegExp('(.*?)(' + props.search + ')(.*?)', 'i')
        countryName = countryName.replace(reg, "$1<b>$2<b>$3")
        countryName = countryName.split('<b>')
    }
    return (
        <a className={classname.join(' ')} onClick={() => props.selectCountry(props.country)}>
            <div className="ppPhoneCountries_countryName">
                {(typeof countryName).toLowerCase() == 'string' ?
                    countryName
                :
                    <span>{countryName[0]}<b>{countryName[1]}</b>{countryName[2]}</span>
                }
                <span className="ppPhoneCountries_countryCode">{props.country.phoneCode}</span>
            </div>
        </a>)
}

class PhoneCountriesContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            search : null,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.show && nextProps.show) {
            this.props.loadList()
        }
    }
    componentDidUpdate() {
        if (!this.props.show && this.state.search !== null) {
            this.setState({
                search : null,
            })
        }
    }

    selectCountry(country) {
        if (this.props.callback) {
            this.props.callback(country)
            this.props.close()
        }
    }

    search(partOfName) {
        this.setState({
            search : partOfName,
        })
    }

    render() {
        let self = this
        let state = this.state
        let props = this.props

        if (!props.show) {
            return null
        }
        const countries = []
        for (let i=0; props.countries[i]; i++) {
            let country = props.countries[i]
            if (state.search && !country.name.toLowerCase().includes(state.search.toLowerCase())) {
                continue
            }
            countries.push(<Country key={'country_' + country.id + '_' + i} country={country} isCurrent={props.current && country.id == props.current.id} selectCountry={() => self.selectCountry(country)} search={state.search}/>)
        }
        return (
            <div className="popup _open">
                <div className="popup_content">
                    <div className="ppSuggest">
                        <div className="ppSuggest_head">
                            <div className="ppSuggest_search">
                                <input className="ppSuggest_field" placeholder={t('Страна')} type="text" onKeyUp={(ev) => this.search(ev.target.value)}/>
                            </div>
                            <a className="ppSuggest_close" onClick={() => props.close()}>{t('Закрыть')}</a>
                        </div>
                        <div className="ppSuggest_items ppPhoneCountries_list">
                            {!props.countries.length ?
                                <BigLoading/>
                            : (countries.length ?
                                countries
                                :
                                <div className="ppSuggest_text">
                                    {t('К сожалению, по Вашему запросу ничего не найдено')}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>)
    }
}

function mapStateToProps(state, ownProps) {
    let listParam = {addFirstId: state.main.city.country.id}
    let entityParam = {languageAlias: state.main.locale.lang}
    return {
        countries: getList(state, 'country', listParam, entityParam),
        show: state.phoneCountries.show,
        current: state.phoneCountries.currentCountry,
        callback: state.phoneCountries.callback,
        listParam: listParam,
        entityParam: entityParam,
    }
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        close: () => dispatch(close()),
        dispatch: dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        loadList : () => {
            dispatchProps.dispatch(loadList('country', stateProps.listParam, stateProps.entityParam))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PhoneCountriesContainer)
