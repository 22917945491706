import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

const PopupDialog = (props) => {
    if (!props.component) return null
    return (
        <div className="ppDialog">
            <div className="ppDialog_scene">
                {props.caption ? <div className="ppDialog_caption">{props.caption}</div> : null}
                {props.component}
            </div>
        </div>)
}

PopupDialog.propTypes = {
    show: PropTypes.bool,
    caption: PropTypes.string,
    component: PropTypes.element,

    close: PropTypes.func,
}

export default PopupDialog