import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    OPEN: null,
    CLOSE: null,

    SEARCH_REQUEST: null,
    SEARCH_OK: null,
    SEARCH_ERROR: null,
}, 'siteSearch')

export const show = () => {
    return {
        type: types.OPEN,
    }
}

export const close = () => {
    return {
        type: types.CLOSE
    }
}

let timerId = 0
export const search = (text, category, cityId, cancelToken = null) => {
    return function (dispatch) {
        dispatch({
            type: types.SEARCH_REQUEST
        })

        if (timerId) {
            clearTimeout(timerId)
            timerId = 0
        }
        if (text == '') {
            dispatch({
                list: [],
                type: types.SEARCH_OK,
            })
        } else {
            timerId = setTimeout(function() {
                timerId = 0
                let params = {
                    name : text,
                    my_city : cityId,
                    order : 'my_city',
                }
                if (category.type === 'users') {
                    params.role_id = category.model.id
                    params.avatar_size = '56x40x1'
                } else {
                    params.type_id = category.model.id
                    params.cover_size = '56x40x1'
                }
                axios.get(API_URL + '/v2/' + category.type, {params: params, withCredentials: true, cancelToken: cancelToken})
                    .then(response => {
                        dispatch({
                            list: response.data[category.type],
                            type: types.SEARCH_OK,
                        })
                    })
                    .catch(() => {
                        dispatch({
                            type: types.SEARCH_ERROR
                        })
                    })
            }, 500)
        }
    }
}