import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,
    LOAD_MORE_REQUEST: null,
    LOAD_MORE_OK: null,
    LOAD_MORE_ERROR: null,

    LOAD_MODEL_REQUEST: null,
    LOAD_MODEL_OK: null,
    LOAD_MODEL_ERROR: null,

    LOAD_MARKERS_REQUEST: null,
    LOAD_MARKERS_OK: null,
    LOAD_MARKERS_ERROR: null,
}, 'place');

const PER_PAGE = 20;
const MARKERS_PER_PAGE = 100;

const getModelUrl = (id) => {
    return API_URL + '/v2/places/' + id
};

const getUrl = (directory, filters, page, per_page) => {
    return directory.apiUrl +
        "&cover_size=0x200x0,266x200x1" +
        "&page=" + page +
        "&per_page=" + per_page +
        "&seed=" + SEED +
        '&' + filters
};

export const loadData = (directory, filters = '', page=1, per_page=null) => {
    return function (dispatch) {
        dispatch({
            type: types.LOAD_LIST_REQUEST
        });
        if (!per_page) {
            per_page = PER_PAGE
        }
        return axios.get(getUrl(directory, filters, page, per_page), {withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.places,
                    meta: response.data.meta,
                    type: types.LOAD_LIST_OK,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_LIST_ERROR
                })
            })
    }
};

export const loadMoreData = (directory, filters = '') => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_MORE_REQUEST
        });

        const page = getState().place.place.meta.nextPage;

        return axios.get(getUrl(directory, filters, page, PER_PAGE), {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_MORE_OK,
                    list: response.data.places,
                    meta: response.data.meta,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_MORE_ERROR
                })
            })
    }
};

export const loadModel = (id, params = null) => {
    return function (dispatch) {
        dispatch({
            type: types.LOAD_MODEL_REQUEST
        });

        axios.get(getModelUrl(id), {params: params})
            .then(response => {
                dispatch({
                    type: types.LOAD_MODEL_OK,
                    model: response.data.place,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_MODEL_ERROR
                })
            })
    }
};

export const loadMarkers = (directory, filters = '', page=1) => {
    return function (dispatch) {
        dispatch({
            type: types.LOAD_MARKERS_REQUEST
        });
        axios.get(getUrl(directory, filters, page, MARKERS_PER_PAGE))
            .then(response => {
                dispatch({
                    type: types.LOAD_MARKERS_OK,
                    list: response.data.places,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_MARKERS_ERROR
                })
            })
    }
}