import {types} from 'actions/viewInfo'

import ViewInfo from 'models/viewInfo'

const initialState = {
    model : {},

    loading: true,
}

const ViewInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case types.LOAD_OK:
            return {
                ...state,
                model: new ViewInfo(action.model),
                loading: false
            }
        case types.LOAD_ERROR:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
}
export default ViewInfoReducer