import language from 'lib/translate/language'

const FORMS = {
    RU: {
        ONE: 0,
        FEW: 1,
        MANY: 2
    },
    EN: {
        ONE: 0,
        OTHER: 1
    },
    INDIAN: {
        ONE: 0,
        OTHER: 1
    },
    UK: {
        ONE: 0,
        FEW: 1,
        MANY: 2,
        OTHER: 3
    },
    PL: {
        ONE: 0,
        FEW: 1,
        MANY: 2,
        OTHER: 3
    },
    TH: {
        OTHER: 0
    },
}

var Plural = {
    form: function (n) {
        if (language.isRussian(LANG)) {
            if (this.isInt(n)) {
                return n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
            }

            return FORMS.RU.FEW
        }

        if (language.isEnglish(LANG)) {
            if (this.isInt(n) && n === 1) {
                return FORMS.EN.ONE
            }
            return FORMS.EN.OTHER
        }

        if (language.isIndian(LANG)) {
            if (this.isInt(n) && (n === 0 || n === 1)) {
                return FORMS.INDIAN.ONE
            }
            return FORMS.INDIAN.OTHER
        }

        if (language.isUkrainian(LANG)) {
            if (this.isInt(n)) {
                return n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
            }
            return FORMS.UK.OTHER
        }

        if (language.isPolish(LANG)) {
            if (this.isInt(n)) {
                return n === 1 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
            }
            return FORMS.PL.OTHER
        }

        if (language.isThai(LANG)) {
            return FORMS.TH.OTHER
        }
    },

    isInt: function (n) {
        return Number(n) === n && n % 1 === 0
    }
}

export default Plural
