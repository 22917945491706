import React from 'react'
import PropTypes from 'prop-types'
import t from 't'
import _ from 'lodash'
import Link from 'components/block/link'

class SubMenu extends React.Component {
    renderGroup(group, isMobile, location, favoritesByPath) {
        const links = _.map(group, (link, i) => {
            let count = ''
            let path = link.url ? decodeURI(link.url.replace(/https?:\/\/[^\/]+([^\?]+)(?:\?.*)?/, '$1')) : null
            if ('development' === process.env.NODE_ENV && path) {
                path = path.replace('http://gorko.ru:8000', 'https://msk.gorko.ru')
            }
            let myFavoritesCount = favoritesByPath[path] || 0
            if (myFavoritesCount) {
                count = <span className="subMenu_favorite">{myFavoritesCount}</span>
            }
            if (link.count) {
                count = <span className="subMenu_count">{link.count}</span>
            }
            if (isMobile && !link.is_redirect) {
                if (link.url) {
                    let parser = document.createElement('a')
                    parser.href = link.url
                    const isCurrent = location.pathname !== '/' && decodeURI(parser.pathname) === location.pathname.replace(/\d+\/.*$/, '')
                    return <Link key={'submenu' + i}
                                 className={'subMenu_link' + (isCurrent ? ' _cur' : '')}
                                 to={link.url}>{link.name}{count}</Link>
                } else {
                    return <a key={'submenu' + i} className="subMenu_link" onClick={link.onClick}>{link.name}{count}</a>
                }
            } else {
                if (link.is_redirect) {
                    return <a key={'submenu' + i} className="subMenu_link" href={link.url} target="_blank">{link.name}{count}</a>
                } else {
                    return <a key={'submenu' + i} className="subMenu_link" href={link.url}>{link.name}{count}</a>
                }
            }
        })
        return <div className="subMenu_group">{links}</div>
    }

    render() {
        let mobile, desktop
        const notice = <p className="subMenu_notice">{t('Разделы доступные только на полной версии сайта')}</p>

        if (this.props.mobile && this.props.mobile.length) {
            mobile = this.renderGroup(this.props.mobile, true, this.props.location, this.props.favoritesByPath)
        }
        if (this.props.desktop && this.props.desktop.length) {
            desktop = this.renderGroup(this.props.desktop, false, this.props.location, this.props.favoritesByPath)
        }
        return (
            <div className="subMenu">
                {mobile}
                {desktop ? notice : null}
                {desktop ? desktop : null}
            </div>
        )
    }
}

SubMenu.propTypes = {
    desktop: PropTypes.array,
    mobile: PropTypes.array,
    favoritesByPath: PropTypes.object,
}

export default SubMenu