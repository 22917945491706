import React from 'react'
import {connect} from 'react-redux'

import {close} from 'actions/popupLight'

import PopupLight from 'components/popupLight/popup'

class PopupLightContainer extends React.Component {
    render() {
        if (!this.props.component) return null
        return <PopupLight {...this.props}/>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        component: state.popupLight.component,
        show: state.popupLight.show,
    }
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        close: () => dispatch(close()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupLightContainer)