import React from 'react'
import PropTypes from 'prop-types'
import t from 't'
import _ from 'lodash'

import Favorite from "components/directory/favorite"
import Gallery from "components/block/gallery"
import Link from "components/block/link"
import SchemaOrg from 'components/block/schemaOrg'

const CarCard = (props) => {
    const card = props.card

    let medias = _.map(card.cars, (car) => {
        car.sizes = car.cover_size
        return car
    })

    return <div className={'card' + (card.isPro ? ' card-pro' : '')}>
        <SchemaOrg data={card.schema}/>
        <Favorite isFav={card.isFav(props.favorites)} toggleFavorite={props.toggleFavorite} modelId={card.id}/>
        <Link className="card_name" to={card.link}>{card.name}</Link>
        <Gallery media={medias} link={card.link + props.carDirectory.model.key + '/'}/>
        <Link to={card.link + props.carDirectory.model.key + '/'} className="button-border button-wide">{t('Смотреть все {autoType} – {count}', {'{autoType}': props.carDirectory.model.plural.toLowerCase(), '{count}': card.cars_count[props.carDirectory.model.key]})}</Link>
    </div>
}

CarCard.propTypes = {
    card: PropTypes.object.isRequired,
    carDirectory: PropTypes.object,
    favorites: PropTypes.array,
    showOnMap: PropTypes.func,
    toggleFavorite: PropTypes.func,
};

export default CarCard;
