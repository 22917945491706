import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

import Favorite from 'components/directory/favorite'

import Link from 'components/block/link'
import MapMarker from 'models/mapMarker'
import MapOverlay from 'components/block/mapOverlay'
import SchemaOrg from 'components/block/schemaOrg'

const PlaceCard = (props) => {
    const card = props.card
    return (
        <div className="card-half">
            <SchemaOrg data={card.schema}/>
            <Favorite isFav={card.isFav(props.favorites)} toggleFavorite={props.toggleFavorite} modelId={card.id}/>
            <Link to={card.link} target={props.openInNewWindow===1?'_blank':'_self'}><img className="card_photo" src={card.getCoverUrl('0x200x0')} alt=""/></Link>
            <Link className="card_name" to={card.link} target={props.openInNewWindow===1?'_blank':'_self'}>{card.name}</Link>
            <p className="card_onMap">
                <a onClick={() => props.showOnMap([new MapMarker({
                    model: card,
                    isActive: true,
                })], {overlay: MapOverlay})}>{t('На карте')}</a>
            </p>
        </div>
    )
}

PlaceCard.propTypes = {
    card: PropTypes.object.isRequired,
    showOnMap: PropTypes.func.isRequired,
    toggleFavorite: PropTypes.func.isRequired,
    favorites: PropTypes.array,
}


export default PlaceCard
