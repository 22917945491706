import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    OPEN: null,
    CLOSE: null,
}, 'notice')

export const notice = (text) => {
    return (dispatch) => {
        setTimeout(() => dispatch(close()), 3000)
        dispatch({
            type: types.OPEN,
            text: text,
        })
    }
}

export const close = () => {
    return {
        type: types.CLOSE
    }
}