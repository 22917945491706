import _ from 'lodash'

import {LOCATION_CHANGE} from 'react-router-redux'
import {types} from 'actions/media'
import {types as typesPopup} from 'actions/popup'

import Like from 'models/like'
import Media from 'models/media'
import Meta from 'models/meta'

const initialState = {
    list: [],
    loading: false,
    meta: null,
    model: {},
    index: [],
    isCircle: false,

    is_fullscreen: false,
    like_list: [],
    like_loading: false,
    like_meta: null,
}

const putModel = (media, index, model, dir) => {
    if (model[media.id]) {
        let indexOf = index.indexOf(media.id)
        if (indexOf > -1) {
            return true
        }
    } else {
        model[media.id] = new Media(media)
    }
    if (dir === 'prev') {
        index.unshift(media.id)
    } else {
        index.push(media.id)
    }
    return false
}

const prepareModelAndIndex = (state, data) => {
    let index = _.clone(state.index)
    let model = state.model
    if (model === null) {
        model = {}
    }
    let isCircle = false
    let check = false
    for (let i = data.prev.length - 1; data.prev[i]; i--) {
        check = putModel(data.prev[i], index, model, 'prev')
        isCircle = isCircle || check
    }
    putModel(data.current, index, model)
    for (let i = 0; data.next[i]; i++) {
        check = putModel(data.next[i], index, model)
        isCircle = isCircle || check
    }
    return {
        ...state,
        model: model,
        index: index,
        isCircle: isCircle,
    }
}

const MediaReducer = (state = initialState, action) => {
    let model
    switch (action.type) {
        case typesPopup.CLOSE:
            return {
                ...state,
                like_list: [],
                like_meta: {},
                is_fullscreen: false,
            }
        case types.RESET_LIST: // сброс галереи в попапе лимузинов
            return {
                ...state,
                list: [],
                meta: null,
            }

        case types.RESET_INDEX: // сброс индекса при входе в альбом
            return {
                ...state,
                index: [],
            }

        case types.LOAD_LIST_REQUEST:
        case types.LOAD_MORE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case types.LOAD_LIKE_LIST_REQUEST:
        case types.LOAD_LIKE_MORE_REQUEST:
            return {
                ...state,
                like_loading: true
            }
        case types.LOAD_MODEL_REQUEST:
            return {
                ...state,
                like_list: [],
                like_meta: {},
            }
        case types.LOAD_MODELS_REQUEST:
            return {
                ...state,
            }

        case types.LOAD_LIST_OK:
            return {
                ...state,
                list: _.map(action.list, item => new Media(item)),
                meta: new Meta(action.meta),
                loading: false
            }
        case types.LOAD_MORE_OK:
            return {
                ...state,
                list: _.union(state.list, _.map(action.list, item => new Media(item))),
                meta: new Meta(action.meta),
                loading: false
            }

        case types.LOAD_MODEL_OK:
            model = state.model
            model[action.model.id] = new Media(action.model)
            return {
                ...state,
                model: model
            }
        case types.LOAD_MODELS_OK:
            return prepareModelAndIndex(state, action.data)

        case types.LOAD_LIKE_LIST_OK:
            return {
                ...state,
                like_list: _.map(action.list, item => new Like(item)),
                like_meta: new Meta(action.meta),
                like_loading: false
            }
        case types.LOAD_LIKE_MORE_OK:
            return {
                ...state,
                like_list: _.union(state.like_list, _.map(action.list, item => new Like(item))),
                like_meta: new Meta(action.meta),
                like_loading: false
            }

        case types.TOGGLE_FULLSCREEN:
            return {
                ...state,
                is_fullscreen: !state.is_fullscreen
            }

        case types.LOAD_LIST_ERROR:
        case types.LOAD_MORE_ERROR:
            return {
                ...state,
                loading: false,
            }
        case types.LOAD_MODEL_ERROR:
        case types.LOAD_MODELS_ERROR:
            return {
                ...state,
            }
        case types.LOAD_LIKE_LIST_ERROR:
        case types.LOAD_LIKE_MORE_ERROR:
            return {
                ...state,
                like_loading: false,
            }

        case types.LIKE_OK:
            model = state.model
            model[action.mediaId] = new Media(
                {
                    ...model[action.mediaId],
                    likes_count: action.count,
                    my_like: action.myLike
                }
            )
            return {
                ...state,
                model: model,
                like_loading: false,
            }
        case types.LIKE_REQUEST:
            return {
                ...state,
                like_loading: true,
            }
        case types.LIKE_ERROR:
            return {
                ...state,
                like_loading: false,
            }

        case LOCATION_CHANGE:
            if (action.payload.pathname.indexOf('album') === -1) { // если мы вышли из просмотра альбомов, то нужно сбросить индекс
                return {
                    ...state,
                    index: [],
                }
            } else {
                return state
            }

        default:
            return state
    }
}
export default MediaReducer