import _ from 'lodash'

import BaseModel from 'models/baseModel'
import User from 'models/user'
import Media from 'models/media'

export default class Car extends BaseModel {
    constructor(data) {
        if (data.user) {
            data.user = new User(data.user)
        }
        if (data.media) {
            data.media = _.map(data.media, (media) => new Media(media))
        }
        super(data)
    }

    get modelName() {
        return 'Car'
    }

    get reducerName() {
        return 'car'
    }
    get entityType() {
        return 'cars'
    }
    get coverUrl() {
        return this.cover_url;
    }
    get pmLink() {
        return this.user ? this.user.pmLink : null
    }

    get link() {
        return this.url
    }

    getCoverUrl(size) {
        return this.cover_url[size] ? this.cover_url[size] : (typeof this.cover_url === 'string' ? this.cover_url : this.cover_url[Object.keys(this.cover_url)[0]])
    }
    getPic(size) {
        return this.cover_url[size] ? this.cover_url[size] : (typeof this.cover_url === 'string' ? this.cover_url : this.previewUrl[Object.keys(this.cover_url)[0]])
    }

    get previewUrl() {
        return this.cover_url
    }

    previewWidth(size) {
        return this.cover_size.preview[size] && this.cover_size.preview[size].width ? this.cover_size.preview[size].width : null
    }

    previewHeight(size) {
        return this.cover_size.preview[size] && this.cover_size.preview[size].height ? this.cover_size.preview[size].height : null
    }
}