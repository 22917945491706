const setGlobal = (site, locale, seed) => {
    if (typeof window !== 'undefined') {
        window.SITE_NAME = site.name
        window.SITE_URL = site.url
        window.LANG = locale.lang
        window.SEED = seed
        console.log('LANG: ', window.LANG)
    } else {
        global.SITE_NAME = site.name
        global.SITE_URL = site.url
        global.LANG = locale.lang
        global.SEED = seed
    }
}

export default setGlobal