import _ from 'lodash'

import {types} from 'actions/album'

import Album from 'models/album'
import Media from 'models/media'
import Meta from 'models/meta'

const initialState = {
    list: [],
    loading: false,
    meta: null,
    model: null,

    collection_list: [],
    collection_meta: null,
    media_list: [],
    media_loading: false,
    media_meta: null,
}

const AlbumReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_LIST_REQUEST:
        case types.LOAD_MORE_REQUEST:
        case types.LOAD_COLLECTION_LIST_REQUEST:
        case types.LOAD_COLLECTION_MORE_REQUEST:
        case types.LOAD_MODEL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.LOAD_MEDIA_LIST_REQUEST:
        case types.LOAD_MEDIA_MORE_REQUEST:
            return {
                ...state,
                media_loading: true
            }

        case types.LOAD_LIST_OK:
            return {
                ...state,
                list: _.map(action.list, item => new Album(item)),
                meta: new Meta(action.meta),
                loading: false
            }
        case types.LOAD_MORE_OK:
            return {
                ...state,
                list: _.union(state.list, _.map(action.list, item => new Album(item))),
                meta: new Meta(action.meta),
                loading: false
            }

        case types.LOAD_COLLECTION_LIST_OK:
            return {
                ...state,
                collection_list: _.map(action.list, item => new Album(item)),
                collection_meta: new Meta(action.meta),
                loading: false
            }
        case types.LOAD_COLLECTION_MORE_OK:
            return {
                ...state,
                collection_list: _.union(state.collection_list, _.map(action.list, item => new Album(item))),
                collection_meta: new Meta(action.meta),
                loading: false
            }

        case types.LOAD_MODEL_OK:
            return {
                ...state,
                model: new Album(action.model),
                loading: false
            }

        case types.LOAD_MEDIA_LIST_OK:
            return {
                ...state,
                media_list: _.map(action.list, item => new Media(item)),
                media_meta: new Meta(action.meta),
                media_loading: false
            }
        case types.LOAD_MEDIA_MORE_OK:
            return {
                ...state,
                media_list: _.union(state.media_list, _.map(action.list, item => new Media(item))),
                media_meta: new Meta(action.meta),
                media_loading: false
            }

        case types.LOAD_LIST_ERROR:
        case types.LOAD_MORE_ERROR:
        case types.LOAD_COLLECTION_LIST_ERROR:
        case types.LOAD_COLLECTION_MORE_ERROR:
        case types.LOAD_MODEL_ERROR:
            return {
                ...state,
                loading: false,
            }

        case types.LOAD_MEDIA_LIST_ERROR:
        case types.LOAD_MEDIA_MORE_ERROR:
            return {
                ...state,
                media_loading: false,
            }

        default:
            return state
    }
}
export default AlbumReducer