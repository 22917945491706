import React from 'react'
import {connect} from 'react-redux'

import PopupDialog from 'components/popupDialog/popupDialog'

class PopupDialogContainer extends React.Component {
    render() {
        if (!this.props.component) return null
        return <PopupDialog {...this.props}/>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        component : state.popupDialog.component,
        caption   : state.popupDialog.caption,
        show      : state.popupDialog.show,
    }
}

export default connect(mapStateToProps)(PopupDialogContainer)