import BaseModel from 'models/baseModel'

import User from 'models/user'

export default class Like extends BaseModel {
    constructor(data) {
        if (data.user) {
            data.user = new User(data.user)
        }
        super(data)
    }
}