import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

class CallButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            opened: false,
        }
    }


    toggle() {
        this.setState({
            opened: !this.state.opened
        })
    }

    render() {
        const state = this.state
        const props = this.props
        const phone = props.directory.inquire.phone
        if (!phone && !props.directory.inquire.inquire) {
            return null
        }

        if (!state.opened) {
            return (
                <a className="callButtons_toggle" onClick={this.toggle.bind(this)}></a>
            )
        } else {
            return (
                <div className="callButtons">
                    <p className="callButtons_caption">{t('Нужна помощь в выборе?')}</p>
                    <div className="callButtons_blocks">
                    {props.directory.inquire.inquire ?
                        <div className="callButtons_block">
                            <a className="button-pink button-wide" onClick={() => props.openRequest(null)}>{t('Быстрый запрос')}</a>
                            <p className="callButtons_notice">{t('Мы вам перезвоним')}</p>
                        </div>
                    : null}
                    {phone ?
                        <div className="callButtons_block">
                            <a className="callButton-gray" href={'tel:+' + phone.replace(/[^\d]/g, '')}>{phone}</a>
                            <p className="callButtons_notice">{t('Позвоните нам')}</p>
                        </div>
                    : null}
                    </div>
                    <a className="callButtons_close" onClick={this.toggle.bind(this)}></a>
                </div>
            )
        }
    }
}

CallButton.propTypes = {
    directory: PropTypes.object.isRequired,
    openRequest: PropTypes.func,
}

export default CallButton