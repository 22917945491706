import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,
    LOAD_MORE_REQUEST: null,
    LOAD_MORE_OK: null,
    LOAD_MORE_ERROR: null,

    ADD_COMMENT_REQUEST: null,
    ADD_COMMENT_OK: null,
    ADD_COMMENT_ERROR: null,

    ADD_VOTE_REQUEST: null,
    ADD_VOTE_OK: null,
    ADD_VOTE_ERROR: null,

    TOGGLE_ACTIONS: null,
    TOGGLE_FORM: null,
}, 'comment')

const PER_PAGE = 20

const getUrl = (apiPath, modelId) => {
    return API_URL + '/v2/' + apiPath + '/' + modelId + '/comments'
}

const getVoteUrl = (modelId) => {
    return API_URL + '/v2/comments/' + modelId + '/votes'
}

export const loadData = (contentType, modelId, params = {}) => {
    return function (dispatch, getState) {
        let typeRequest = types.LOAD_LIST_REQUEST
        let typeOk = types.LOAD_LIST_OK
        let typeError = types.LOAD_LIST_ERROR

        if (!params.per_page) {
            params.per_page = PER_PAGE
        }

        if (!params.page && getState().comment.meta && getState().comment.meta.page) {
            typeRequest = types.LOAD_MORE_REQUEST
            typeOk = types.LOAD_MORE_OK
            typeError = types.LOAD_MORE_ERROR
        }

        if (!params.page) {
            params.page = getState().comment.meta ? getState().comment.meta.nextPage : 1
        }

        dispatch({
            type: typeRequest
        })

        axios.get(getUrl(contentType, modelId), {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.comments,
                    meta: response.data.meta,
                    type: typeOk,
                })
            })
            .catch(() => {
                dispatch({
                    type: typeError
                })
            })
    }
}

export const toggleActions = (modelId) => {
    return function (dispatch) {
        dispatch({
            type: types.TOGGLE_ACTIONS,
            id: modelId
        })
    }
}

export const toggleForm = (modelId) => {
    return function (dispatch) {
        dispatch({
            type: types.TOGGLE_FORM,
            id: modelId
        })
    }
}

export const addComment = (contentType, modelId, commentId, text) => {
    return function (dispatch) {
        dispatch({
            type: types.ADD_COMMENT_REQUEST
        })
        let postData = {text: text}
        if (commentId) {
            postData.parent_id = commentId
        }
        axios.post(getUrl(contentType, modelId), postData, {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.ADD_COMMENT_OK,
                    comment: response.data,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.ADD_COMMENT_ERROR
                })
            })
    }
}

export const addVote = (modelId, value) => {
    return function (dispatch) {
        dispatch({
            type: types.ADD_VOTE_REQUEST,
            commentId: modelId,
        })
        axios.post(getVoteUrl(modelId), {value: value}, {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.ADD_VOTE_OK,
                    commentId: modelId,
                    votes: response.data.votes,
                    my_vote: value,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.ADD_VOTE_ERROR
                })
            })
    }
}
