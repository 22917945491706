import {types} from 'actions/city'

import _ from 'lodash'
import City from 'models/city'

const initialState =
    {
        list: [],
        neighbors: [],
    };

const CityReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_OK:
            return {
                ...state,
                list: _.map(action.cities, city => new City(city)),
                neighbors: _.map(action.neighbors, city => new City(city)),
            };
        case types.LOAD_REQUEST:
        case types.LOAD_ERROR:
            return state;
        default:
            return state
    }
}

export default CityReducer