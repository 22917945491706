import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

const EmptyFavorites = (props) => {
    return (
        <div className="pFavorites-empty">
            <p className="pFavorites_text">{t('Нет избранного в категории «{directoryName}». Используйте кнопку со звездочкой, чтобы не терять понравившееся в каталоге.', {'directoryName': props.directoryName})}</p>
            <span className="favorite"/>
            <span className="favorite _fav"/>
            <span className="favorite"/>
        </div>
    )
}

EmptyFavorites.propTypes = {
}

export default EmptyFavorites