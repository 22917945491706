import {types} from 'actions/notice'

const initialState = {
    text: null,
    show: false,
}

const NoticeReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN:
            return {
                ...state,
                show: true,
                text: action.text,
            }
        case types.CLOSE:
            return initialState
        default:
            return state
    }
}
export default NoticeReducer