import {combineReducers} from 'redux'
import _ from 'lodash'

import album from 'reducers/album'
import media from 'reducers/media'
import review from 'reducers/review'
import viewInfo from 'reducers/viewInfo'

import {types} from 'actions/place'

import Meta from 'models/meta'
import Place from 'models/place'
import MapMarker from 'models/mapMarker'

const initialState = {
    list: [],
    loading: false,
    meta: null,
    model: null,

    markers: [],
}

function place(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_LIST_REQUEST:
            return {
                ...state,
                list: [],
                loading: true,
            }
        case types.LOAD_MORE_REQUEST:
        case types.LOAD_MODEL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case types.LOAD_LIST_OK:
            return {
                ...state,
                list: _.map(action.list, item => new Place(item)),
                meta: new Meta(action.meta),
                loading: false
            }

        case types.LOAD_MORE_OK:
            return {
                ...state,
                list: _.union(state.list, _.map(action.list, item => new Place(item))),
                meta: new Meta(action.meta),
                loading: false
            }

        case types.LOAD_MODEL_OK:
            return {
                ...state,
                model: new Place(action.model),
                loading: false
            }

        case types.LOAD_MARKERS_OK:
            return {
                ...state,
                markers: _.map(action.list, item => new MapMarker({
                    model: new Place(item)
                })),
                loading: false
            }

        case types.LOAD_MORE_ERROR:
        case types.LOAD_LIST_ERROR:
        case types.LOAD_MODEL_ERROR:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
}

const PlaceReducer = combineReducers({
    place,
    album,
    media,
    viewInfo,
    review,
})

export default PlaceReducer