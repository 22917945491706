import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import t from 't'
import _ from 'lodash'

export const ColorButton = ({filter, onToggle}) => {
    let text = filter.name
    let classname = {
        filter : true,
    }
    if (filter.selected.length) {
        if (filter.selected.length > 1) {
            text = filter.name + ' (' + filter.selected.length + ')'
        } else {
            const selectedItem = _.find(filter.items, {'key': filter.selected[0]})
            if (!_.isEmpty(selectedItem))
                text = selectedItem.text
        }
        classname._active = true
    }
    return <a className={classNames(classname)} onClick={() => onToggle(filter.label)}>{text}</a>
}


ColorButton.propTypes = {
    filter: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
}

export default ColorButton
