import React from 'react'
import PropTypes from 'prop-types'
import t from 't'
import _ from 'lodash'

import Footer from 'components/filter/bubble/footer'
import Header from 'components/filter/bubble/header'
import Suggest from 'components/block/suggest'

import classNames from 'classnames'

export const MultiselectBubble = (props) => {
    let filter = props.filter
    if (!filter.items && filter.type === "suggest") {
        filter.items = []
    }
    if (!filter.items.length  && filter.type !== "suggest") {
        return null
    }

    let suggest = (filter.type == "suggest") ? <Suggest placeholder={filter.placeholder} onSuggest={filter.onSuggest} text={text}/> : null

    let items = []
    let counts = 0
    _.map(filter.items, (item) => {
        let className = {
            'filter_item-checkbox' : true,
        }
        if (filter.changes.indexOf(item.key) >= 0) {
            className._selected = true
            counts++
        }
        let groupItems = []
        if (item.items && item.items.length) {
            _.map(item.items, (item) => {
                let subClassName = {
                    'filter_item-checkbox' : true,
                }
                if (filter.changes.indexOf(item.key) >= 0) {
                    subClassName._selected = true
                    className._hasSelected = true
                    counts++
                }
                groupItems.push(<a
                    key={filter.label + '_' + item.key}
                    className={classNames(subClassName)}
                    onClick={() => props.onChange(filter, item.key)}>{item.text}</a>)
            })
        }

        items.push(<a
            key={filter.label + '_' + item.key}
            className={classNames(className)}
            onClick={() => props.onChange(filter, item.key)}>{item.text}</a>)

        if (groupItems.length) {
            let groupClassName = {
                filter_group : true,
            }
            if (item.items.length > 3) {
                if (!filter.expanded || filter.expanded.indexOf(item.key) < 0) {
                    groupClassName._collapsed = true
                }
            }
            items.push(
                <div key={filter.label + '_group_' + item.key} className={classNames(groupClassName)}>
                    {groupItems}
                    {groupClassName._collapsed ?
                        <p className="filter_expander">
                            <a onClick={() => props.expandFilterGroup(filter.label, item.key)}>{t('Все ({count})', {'{count}':item.items.length})}</a>
                        </p>
                    : null}
                </div>)
        }
    })

    return (
        <div className="filterBubble">
            <div className="filterBubble_content">
                <a className="filterBubble_close" onClick={() => props.onToggle(props.filter.label)}/>
                {counts ?
                    <a className="filterBubble_reset" onClick={() => props.resetFilter(props.filter.label)}>{t('Сбросить')}</a>
                : null}
                <Header caption={filter.name} counts={counts}/>
                {props.filter.changed ?
                    <Footer label={props.filter.label} applyFilter={props.applyFilter} onToggle={props.onToggle} goal={props.goal}/>
                : null}
                {suggest}
                <div className="filterBubble_body">{items}</div>
            </div>
        </div>)
}

MultiselectBubble.propTypes = {
    filter: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    applyFilter: PropTypes.func.isRequired,
    expandFilterGroup: PropTypes.func,
    goal: PropTypes.string,
}

export default MultiselectBubble