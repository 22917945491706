import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import cx from 'classnames'


export default class Scroller extends React.Component {
    componentDidUpdate() {
        ReactDOM.findDOMNode(this).scrollLeft = 0
    }

    render() {
        let props = this.props
        let classname = {
            'scroller': true,
            '_wide': props.isWide
        }
        if (props.className) {
            classname[props.className] = true
        }
        return (
            <div className={cx(classname)}>
                {props.children}
            </div>)
    }
}

Scroller.propTypes = {
    children: PropTypes.array.isRequired,
    className: PropTypes.string,
    isWide: PropTypes.bool,
}
