import React from 'react'
import PropTypes from 'prop-types'
import t from 't'
import dayjs from "dayjs"

const CarPrice = ({cost, rateBefore, rateAfter, min}) => {
    return (
        <p className="carPrice">
            <span className="carPrice_cost">
                {cost}
                {rateBefore ?
                    <span className="carPrice_text">{t('до {time}',{'{time}':rateBefore})}</span>
                : null}
                {rateAfter ?
                    <span className="carPrice_text">{t('после {time}',{'{time}':rateAfter})}</span>
                : null}
            </span>
            {min ?
                <span className="carPrice_min">{min} <span className="carPrice_text">{t('— мин.аренда')}</span></span>
            : null}
        </p>)
}

const CarPrices = ({car, className, dateClassName}) => {
    const prices = car.viewer_price_in_list
    let rows = []
    let rate = prices.tariff_rate
    if (rate && !prices.tariff_prices_raw[1]) {
        rate = null
    }

    if (prices.tariff_prices) {
        const date = prices.date || undefined
        rows.push(<div key="prices_date" className={dateClassName} dangerouslySetInnerHTML={{ __html: t('Стоимость на {date}', {'{date}': '<b>' + dayjs(date).format('D MMMM') + '</b>'}) }}/>)
        if (prices.tariff_prices_raw[0]) {
            rows.push(<CarPrice key="price_before" cost={prices.tariff_prices[0]} rateBefore={rate ? rate : null} min={prices.tariff_prices_min[0]}/>)
        }
        if (rate && prices.tariff_prices.length === 2 && prices.tariff_prices_raw[1]) {
            rows.push(<CarPrice key="price_after" cost={prices.tariff_prices[1]} rateAfter={rate} min={prices.tariff_prices_min[1]}/>)
        }
    } else if (prices.price.price_display) {
        rows.push(<CarPrice key="price" cost={prices.price.price_display} min={car.params.param_time ? car.params.param_time.value + '+1' : null}/>)
    }

    return rows.length ? <div className={className}>{rows}</div> : null
}

CarPrices.propTypes = {
    car : PropTypes.object.isRequired,
    className : PropTypes.string.isRequired,
    dateClassName : PropTypes.string.isRequired,
}

export default CarPrices
