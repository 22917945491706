import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

const LanguageLink = ({site}) => <a href={site.url}
                                    className={'ppLanguage' + (site.language_alias === LANG ? ' _cur' : '')}>{site.language_name_original}</a>
const SiteLink = ({site}) => <a href={site.url}
                                className={'ppLanguage_country-' + site.country_iso.toLowerCase()}>{site.country_name}</a>

const LanguagesBlock = ({languages}) => {
    if (languages.length < 2) return null
    return <div className="ppLanguages_list">
        {languages.map(site => <LanguageLink site={site} key={site.language_alias}/>)}
    </div>
}

const Language = (props) => {
    return (
        <div className="ppLanguages">
            <LanguagesBlock languages={props.languages}/>
            {props.sites.length > 1 ?
                <p className="ppLanguage_caption">{t('Сайты в других странах')}</p>
            : null}
            {props.sites.map(site => <SiteLink site={site} key={site.site_domain}/>)}
        </div>
    )
}

Language.propTypes = {
    languages: PropTypes.array,
    sites: PropTypes.array,
}

export default Language