import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

import Link from 'components/block/link'

const NeighborCaption = () => <p className="ppCities_caption">{t('Соседние города')}</p>
const CityLink = ({city, callback}) => {
    if (callback) {
        return <a className="ppCities_city" onClick={() => callback(city)}>{city.name}</a>
    } else {
        return <a href={city.link} className="ppCities_city">{city.name}</a>
    }
}

const CityList = (props) => {
    return <div className="ppCities">
        <input className="ppCities_search" defaultValue="" type="text" placeholder={t('Название города')}
               onChange={(event) => {
                   if (event.target.value.length > 2) props.search(event.target.value)
               }}/>
        <div className="ppCities_results">
            {props.cities && props.cities.length ? props.cities.map(city => <CityLink city={city} key={city.id} callback={props.callback}/>) : null}
            {props.neighborCities && props.neighborCities.length ? <NeighborCaption/> : null}
            {props.neighborCities && props.neighborCities.length ? props.neighborCities.map(city => <CityLink city={city} key={city.id} callback={props.callback}/>) : null}
        </div>
    </div>
}

CityList.propTypes = {
    cities: PropTypes.array,
    neighborCities: PropTypes.array,
    search: PropTypes.func.isRequired,
    callback: PropTypes.func,
}

export default CityList