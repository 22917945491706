import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import t from 't'
import _ from 'lodash'

export const SelectButton = ({filter, onToggle}) => {
    let text = filter.name
    const countSelected = filter.selected.length
    let classname = {
        filter : true,
    }
    if (countSelected) {
        if (countSelected > 1) {
            text = filter.name + ' (' + countSelected + ')'
        } else {
            const selectedItem = _.find(filter.items, {'key': filter.selected[0]})
            if (!_.isEmpty(selectedItem))
                text = selectedItem.text
        }
        classname._active = true
    }
    return <a className={classNames(classname)} onClick={() => onToggle(filter.label)}>{text}</a>
}

SelectButton.propTypes = {
    filter: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
}

export default SelectButton