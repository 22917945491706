import React from 'react'
import PropTypes from 'prop-types'

var text = ''
var timer = {
    id : 0,
    delay : 1000,
}

const Suggest = (props) => {
    let onSuggest = (searchText) => {
        if (text == searchText) return false
        if (timer.id) {
            clearTimeout(timer.id)
        }
        text = searchText
        if (text) {
            timer.id = setTimeout(() => {
                props.onSuggest(searchText)
            }, timer.delay)
        }
    }
    return (
        <div className="suggest">
            <input className="suggest_search" type="text" defaultValue="" onChange={(ev) => {onSuggest(ev.target.value)}} onKeyUp={(ev) => {onSuggest(ev.target.value)}} placeholder={props.placeholder} />
        </div>)
}

Suggest.propTypes = {
    onSuggest: PropTypes.func,
    placeholder: PropTypes.string,
    text: PropTypes.string,
}

export default Suggest