import React from 'react'
import PropTypes from 'prop-types'

const SchemaOrg = ({data}) => {
    return <script type="application/ld+json">{JSON.stringify(data)}</script>
}

SchemaOrg.propTypes = {
    data: PropTypes.object.isRequired
}

export default SchemaOrg
