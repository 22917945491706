import {types} from 'actions/phoneCountries'
import _ from 'lodash'

const initialState = {
    show: false,
    current: null,
    callback: null,
}

const PhoneCountriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SHOW:
            return {
                ...state,
                show: true,
                current: action.current,
                callback: action.callback || null,
            }
        case types.CLOSE:
            return {
                ...state,
                show: false,
                current: null,
                callback: null,
            }
        default:
            return state
    }
}
export default PhoneCountriesReducer