import React from 'react'
import PropTypes from 'prop-types'

const Picture = ({src, width, height, className, onLoad, alt}) => {
    let $img = <img className={className} src={src} width={width} height={height} alt={alt||''} onLoad={onLoad}/>
    if (src && src.indexOf('googleusercontent') > -1) {
        return <picture>
            <source type="image/webp" srcSet={src + '-rw'}/>
            {$img}
        </picture>
    } else {
        return $img
    }
}

Picture.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    onLoad: PropTypes.func,
}

export default Picture
