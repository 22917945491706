import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_REQUEST: null,
    LOAD_OK: null,
    LOAD_ERROR: null,
}, 'mywed')

export const load = () => {
    return function(dispatch, getState) {
        dispatch({
            type: types.LOAD_REQUEST
        })

        let params = {
            city_id: getState().main.city.id,
            per_page: 7,
        }
        axios.get(API_URL + '/v2/mywed/', {params:params, withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_OK,
                    list: response.data.mywed_profs,
                    meta: response.data.meta,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_ERROR
                })
            })
    }
}
