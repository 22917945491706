import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {switchBranch, loadContacts} from 'actions/contact'
import {putComponentToPopup} from 'actions/popup'
import {viewInfo, logClick} from 'actions/viewInfo'

import BigLoading from 'components/block/bigLoading'
import Contact from 'components/block/contact'
import Map from 'components/block/map'
import {getTitles} from "actions/titles"

class ContactContainer extends React.Component {
    componentDidMount() {
        if (typeof this.props.model === 'undefined' || !this.props.model || this.props.model.id !== this.props.routerModelId) {
            this.props.loadData()
        }
    }

    render() {
        if (this.props.loading) return <BigLoading/>
        return <Contact {...this.props}/>
    }
}

function mapStateToProps(state, ownProps) {
    let entityType = ''
    let modelName = ''
    let routerModelId
    let model = {...state.contact.model}
    if (ownProps.entity) {
        entityType = ownProps.entityType
        modelName = ownProps.modelName
        routerModelId = ownProps.entity.id
    } else {
        entityType = ownProps.model.entityType
        modelName = ownProps.model.reducerName
        model.reducerName = modelName
        routerModelId = ownProps.model.shopId ? +ownProps.model.shopId : +ownProps.model.id
    }
    const reducerName = modelName
    // todo вынести viewInfo
    let containerState = state[reducerName]

    return {
        authUser: state.auth.user,
        contact: state.contact,
        city: state.main.city,
        entityType: entityType,
        isAuth: state.auth.isAuth,
        loading: state.contact.loading,
        model: model,
        modelName: modelName,
        viewInfo: containerState.viewInfo,
        routerModelId: routerModelId,
    }
}
function mapDispatchToProps(dispatch, ownProps) {
    let modelName = ''
    let modelId
    if (ownProps.entity) {
        modelName = ownProps.modelName
        modelId = ownProps.entity.id
    } else {
        modelName = ownProps.model.reducerName
        modelId = ownProps.model.shopId || ownProps.model.id
    }
    return {
        logClick: (contactId) => dispatch(logClick(contactId)),
        setFeedback: (feedback) => dispatch(viewInfo(modelName, modelId, {'feedback':feedback})),
        showOnMap: (header, markers, params) => dispatch(putComponentToPopup(header, <Map markers={markers} params={params} onBalloon={(branchNum) => dispatch(switchBranch(branchNum))} buttons={ownProps.mapButtons}/>, 'map')),
        switchBranch: (branchNum) => dispatch(switchBranch(branchNum)),
        dispatch: dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    const dispatch = dispatchProps.dispatch
    let entityType = ''
    let modelName = ''
    let modelId
    let commission
    if (ownProps.entity) {
        entityType = ownProps.entityType
        modelName = ownProps.modelName
        modelId = ownProps.entity.id
        commission = ownProps.entity.commission
    } else {
        entityType = ownProps.model.entityType
        modelName = ownProps.model.reducerName
        modelId = ownProps.model.shopId || ownProps.model.id
        commission = ownProps.model.commission
    }
    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        loadData: () => dispatch(loadContacts(entityType, modelName, modelId, stateProps.city.id))
            .then( () => dispatch(viewInfo(modelName, modelId, {'phone':1})) )
            .then( () => {
                window.reachGoal('showContacts', {vendor_type: modelName, vendor_id: modelId, domain: SITE_DOMAIN})
                if (commission) {
                    if (entityType === 'cars') {
                        if (commission === 4 || commission === 5) {
                            window.reachGoal('showContactsPayPerCallCar', {
                                vendor_type: modelName,
                                vendor_id: modelId,
                                domain: SITE_DOMAIN
                            })
                            window.reachGoal('totalComCar', {
                                vendor_type: modelName,
                                vendor_id: modelId,
                                domain: SITE_DOMAIN
                            })
                        }
                    } else if (entityType === 'users') {
                        if (commission === 4 || commission === 5) {
                            window.reachGoal('showContactsPayPerCallUser', {
                                vendor_type: modelName,
                                vendor_id: modelId,
                                domain: SITE_DOMAIN
                            })
                            window.reachGoal('totalComUser', {
                                vendor_type: modelName,
                                vendor_id: modelId,
                                domain: SITE_DOMAIN
                            })
                        }
                    } else {
                        const goal = ((commission === 4 || commission === 5) ? 'showContactsPayPerCallVenue' : 'showComVenueContacts')
                        window.reachGoal(goal, {
                            vendor_type: modelName,
                            vendor_id: modelId,
                            domain: SITE_DOMAIN
                        })
                        if (goal === 'showComVenueContacts') {
                            window.reachGoal('totalComVenue', {
                                vendor_type: modelName,
                                vendor_id: modelId,
                                domain: SITE_DOMAIN
                            })
                        }
                    }
                }
            } ),
    }
}

ContactContainer.propTypes = {
    entity: PropTypes.object,
    entityType: PropTypes.string,
    modelName: PropTypes.string,
}


export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ContactContainer)
