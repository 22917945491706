/* global require module */

import _ from 'lodash'
import Dispatcher from "flux/lib/Dispatcher"

var AppDispatcher = _.extend(new Dispatcher(), {
  registerHandler: function(actionType, callback) {
    if (!actionType) {
      throw "ActionType is undefined!!!";
    }
    this.register(function(payload) {
      if (payload.actionType === actionType) {
        callback(payload);
      }
    });
  }
});

export default AppDispatcher
