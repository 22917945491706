import _ from 'lodash'

const Fluxable = {
    mounted: false,

    componentDidMount: function () {
        _.forEach(this.watchStores, function (store) {
            store.addChangeListener(() => this._onChange())
        }.bind(this))

        if (_.isFunction(this.myDidMount)) {
            this.myDidMount()
        }
        this.mounted = true
    },

    componentWillUnmount: function () {
        _.forEach(this.watchStores, function (store) {
            store.removeChangeListener(() => this._onChange())
        }.bind(this))

        if (_.isFunction(this.myWillUnmount)) {
            this.myWillUnmount()
        }
        this.mounted = false
    },

    _onChange: function () {
        var state = this.getStateFromStores()

        setTimeout(function () {
            if (this.mounted) {
                this.setState(state)
            }
        }.bind(this), 0)
    },

}

export default Fluxable