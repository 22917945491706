export default class MapMarker {
    constructor(data) {
        if (data.model) {
            const model = data.model.model || data.model
            if (typeof model.coordinate !== 'undefined') { // v3/venue
                if (model.coordinate) {
                    this.lat = model.coordinate.latitude
                    this.lng = model.coordinate.longitude
                }
            } else {
                this.lat = model.coords.latitude
                this.lng = model.coords.longitude
            }
            this.address = model.address || model.name
            this.model = {...model}
            if (model.reducerName !== undefined) {
                this.model.reducerName = model.reducerName
            }
        } else {
            this.lat = data.lat
            this.lng = data.lng
            this.address = data.address
            this.model = null
        }
        this.isActive = data.isActive || false
    }
}