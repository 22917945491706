import {combineReducers} from 'redux'
import _ from 'lodash'

import media from 'reducers/media'
import viewInfo from 'reducers/viewInfo'

import {types} from 'actions/car'

import Meta from 'models/meta'
import Car from 'models/car'

const initialState = {
    list: [],
    links: [],
    loading: false,
    meta: null,

    car_list: [],
    car_meta: null,
    limousine_list: [],
    limousine_meta: null,
}

function car(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_LIST_REQUEST:
            return {
                ...state,
                list: [],
                loading: true,
            }
        case types.LOAD_MORE_REQUEST:
        case types.LOAD_CAR_LIST_REQUEST:
        case types.LOAD_CAR_MORE_REQUEST:
        case types.LOAD_LIMOUSINE_LIST_REQUEST:
        case types.LOAD_LIMOUSINE_MORE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case types.LOAD_LIST_OK:
            return {
                ...state,
                list: _.map(action.list, item => new Car(item)),
                links: action.links,
                meta: new Meta(action.meta),
                loading: false
            }
        case types.LOAD_MORE_OK:
            return {
                ...state,
                list: _.union(state.list, _.map(action.list, item => new Car(item))),
                meta: new Meta(action.meta),
                loading: false
            }

        case types.LOAD_CAR_LIST_OK:
            return {
                ...state,
                car_list: _.map(action.list, item => new Car(item)),
                car_meta: new Meta(action.meta),
                loading: false
            }
        case types.LOAD_CAR_MORE_OK:
            return {
                ...state,
                car_list: _.union(state.car_list, _.map(action.list, item => new Car(item))),
                car_meta: new Meta(action.meta),
                loading: false
            }

        case types.LOAD_LIMOUSINE_LIST_OK:
            return {
                ...state,
                limousine_list: _.map(action.list, item => new Car(item)),
                limousine_meta: new Meta(action.meta),
                loading: false
            }
        case types.LOAD_LIMOUSINE_MORE_OK:
            return {
                ...state,
                limousine_list: _.union(state.limousine_list, _.map(action.list, item => new Car(item))),
                limousine_meta: new Meta(action.meta),
                loading: false
            }

        case types.LOAD_LIST_ERROR:
        case types.LOAD_MORE_ERROR:
        case types.LOAD_CAR_LIST_ERROR:
        case types.LOAD_CAR_MORE_ERROR:
        case types.LOAD_LIMOUSINE_LIST_ERROR:
        case types.LOAD_LIMOUSINE_MORE_ERROR:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
}

const CarReducer = combineReducers({
    car,
    media,
    viewInfo,
})

export default CarReducer