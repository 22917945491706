import React from 'react'
import PropTypes from 'prop-types'
import SubMenu from 'components/main/subMenu'
import Link from 'components/block/link'
import t from 't'

const MenuButton = (props) => {
    let menuName = props.menu.name
    let isCurrent = props.isCurrent
    let image = null
    if (props.menu.id === 'user') {
        if (props.isAuth) {
            image = <img src={props.authUser.avatar} className="mainMenu_avatar" width="42" height="42" alt=""/>
        } else {
            image = <span className="mainMenu_avatar-empty" onClick={props.authPopup}/>
            menuName = t('Войти')
            isCurrent = false
        }
    }
    if (props.menu.url) {
        return (
            <Link to={props.menu.url}
                className={'mainMenu_button-' + props.menu.icon + (isCurrent ? ' _cur' : '')}>
                {menuName}
            </Link>)
    } else {
        return (
            <a key="a1"
                className={'mainMenu_button-' + props.menu.icon + (isCurrent ? ' _cur' : '')}
                onClick={() => props.selectMenuGroup(props.menu.id)}>
                {image}
                {menuName}
                {props.count ? <span className="mainMenu_count">{props.count}</span> : null}
            </a>)
    }
}

MenuButton.propTypes = {
    authPopup: PropTypes.func,
    authUser: PropTypes.object,
    count: PropTypes.number,
    isAuth: PropTypes.bool,
    isCurrent: PropTypes.bool,
    menu: PropTypes.object.isRequired,
    selectMenuGroup: PropTypes.func.isRequired,
}

export default MenuButton
