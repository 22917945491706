import {LOCATION_CHANGE} from 'react-router-redux'
import {types} from 'actions/popupDialog'
import _ from 'lodash'

const initialState = {
    caption   : null,
    component : null,
    show      : false,
}

const PopupDialogReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN:
            return {
                ...state,
                caption   : action.caption,
                component : action.component,
                show      : true,
            }
        case types.CLOSE:
            return {
                ...state,
                caption   : null,
                component : null,
                show      : false,
            }
        case LOCATION_CHANGE:
            return initialState
        default:
            return state
    }
}
export default PopupDialogReducer