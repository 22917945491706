/**
 * Process translations
 *
 * @param message the message to be translated
 * @param params array of parameters (number, placeholders)
 * @param dictionary instance of dictionary
 * @return translated string
 */
import _ from 'lodash'
import plural from 'lib/translate/plural'

const translateProcess = (message, params, dictionary) => {

    // try to translate string
    var translation = dictionary && !_.isUndefined(dictionary[message]) ? dictionary[message] : '';
    if (_.isUndefined(params)) {
        params = 0;
    }
    if (process.env.NODE_ENV === 'development' && !translation) {
        translation = message
    }

    // declare numeric param
    var num = 0;

    // extract number from params
    if (Number(params) || params === 0) {
        params = {'n': params}; // param is numeric, convert to object key for convenience
    }

    if (Number(params.n) || params.n === 0) {
        num = params.n;
    }

    // split translation into pieces
    var chunks = translation.split('|');

    // if translation doesn't contain # but does contain |, treat it as simple choice format
    if (chunks.length > 1) {
        var index = plural.form(num);
        translation = chunks[index];
    }

    // replace placeholder/replacements
    if (typeof(params === 'Object')) {
        for (var key in params) {
            translation = translation.split('{' + key.replace('{', '').replace('}', '') + '}').join(params[key]);
        }
    }

    return translation;
};

const translator = (text, data) => {
    // кажется этот код был для серверного рендера, но он не работает с переходом на webpack 5
    // if (typeof window === 'undefined') var window = global
    return translateProcess(text, data, window.LANG_T)
}

export default translator
