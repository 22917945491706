import React from 'react'
import PropTypes from "prop-types"
import {connect} from 'react-redux'
import t from 't'

import {close, search as doSearch} from 'actions/siteSearch'
import {putComponentToPopup} from 'actions/popup'

import Categories from 'components/siteSearch/categories'
import axios from "axios"
import _ from "lodash"

let scrollTop = null

const lockBody = (isLocked) => {
    if (!isLocked) {
        let $body = document.body || document.getElementsByTagName('body')[0]
        $body.style.overflow = 'initial'
        let $html = document.html || document.getElementsByTagName('html')[0]
        $html.style.overflow = 'initial'
        if (scrollTop) {
            $html.scrollTop = scrollTop
            scrollTop = null
        }
    } else {
        let $html = document.html || document.getElementsByTagName('html')[0]
        let $body = document.body || document.getElementsByTagName('body')[0]
        scrollTop = $html.scrollTop
        $body.style.overflow = 'hidden'
        $html.style.overflow = 'hidden'
    }
}

const Item = (props) => {
    const item = props.item
    let name = item.name
    if (props.search) {
        let reg = new RegExp('(.*?)(' + props.search + ')(.*?)', 'i')
        name = name.replace(reg, "$1<b>$2<b>$3")
        name = name.split('<b>')
    }
    let text = item.city.name
    let url
    let avatar
    if (props.category.type === 'users') {
        avatar = item.avatar_url
        url = item.profile_url
    } else {
        avatar = item.cover_url
        url = item.url
    }
    if (avatar.includes('no_avatar.png') || avatar.includes('no_photo.png')) {
        avatar = null
    }
    return (
        <a className="siteSearch_item" href={url}>
            {avatar ?
                <img className="siteSearch_avatar" src={avatar} width={56} height={40} alt=""/>
            :
                <span className="siteSearch_avatar"/>
            }
            <div className="siteSearch_info">
                <p className="siteSearch_name">
                    {(typeof name).toLowerCase() == 'string' ?
                        name
                    :
                        <span>{name[0]}<b>{name[1]}</b>{name[2]}</span>
                    }
                </p>
                <p className="siteSearch_text">{text}</p>
            </div>
        </a>)
}

class SiteSearchContainer extends React.Component {
    constructor(props) {
        super(props)

        let category = props.directory
        if (!category) {
            for (let i = 0; props.categories[i]; i++) {
                if (props.categories[i].type === 'restaurants' || props.categories[i].type === 'venues') {
                    category = props.categories[i]
                    break
                }
            }
        }
        this.state = {
            text     : '',
            category : category,
        }
    }

    componentDidMount() {
        lockBody(false)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextProps.show && this.props.show) { // скрытие попапа
            this.setState({
                text : '',
            })
            lockBody(false)
        }
        if (nextProps.show && !this.props.show) { // открытие попапа
            lockBody(true)
        }
        if (nextProps.directory !== this.props.directory) {
            this.setState({
                category: nextProps.directory,
            })
        }
        return true
    }

    typing(text) {
        let state = this.state
        let props = this.props

        if (state.text == text) {
            return
        }
        state.text = text
        props.doSearch(text, state.category, props.city.id)
        this.setState({
            text : state.text,
        })
    }

    changeCategory(category) {
        let state = this.state
        let props = this.props
        props.doSearch(state.text, category, props.city.id)
        this.setState({
            category: category,
        })
    }

    render() {
        const self = this
        const props = self.props
        const state = self.state

        if (!props.show) return null
        let items = props.list.map((item, i) => {
            return <Item key={i} item={item} search={state.text} category={state.category}/>
        })

        let content = null
        if (items.length) {
            content = <div className="siteSearch_items">{items}</div>
        } else if (props.loading) {
            content = <div className="siteSearch_loading"/>
        } else if (state.text.length) {
            content = <div className="siteSearch_notFound">{t('Ничего не найдено')}</div>
        }
        return (
            <div className={"siteSearch" + (state.text.length ? ' _wContent' : '')}>
                <a className="siteSearch_bgClose" onClick={props.close}/>
                <div className="siteSearch_panel">
                    <input autoFocus className="siteSearch_input" type="text" placeholder={t('Искать на {site_name}')} onInput={(ev) => self.typing(ev.target.value)}/>
                    <span className="siteSearch_toggler"/>
                    <a className="siteSearch_close" onClick={props.close}/>
                </div>
                <div className="siteSearch_content">
                    <div className="siteSearch_category">{t('Искать в разделе:')} <a className="siteSearch_changeCategory" onClick={() => props.openCategories(props.categories, state.category, (category) => self.changeCategory(category))}>{state.category.model.plural}</a></div>
                    {content}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        show: state.siteSearch.show,
        loading: state.siteSearch.loading,
        list: state.siteSearch.list,
        city: state.main.city,
        categories: state.main.directory,
        directory: ownProps.directory,
    }
}
let cancelSource = []
function mapDispatchToProps(dispatch, ownProps) {
    const CancelToken = axios.CancelToken
    return {
        doSearch: (text, category, cityId) => {
            if (!_.isEmpty(cancelSource)) {
                cancelSource.cancel()
            }
            cancelSource = CancelToken.source()
            dispatch(doSearch(text, category, cityId, cancelSource.token))
        },
        openCategories: (list, current, onSelect) => dispatch(putComponentToPopup(null, <Categories list={list} current={current} onSelect={onSelect}/>)),
        close: () => dispatch(close()),
        dispatch: dispatch,
    }
}

SiteSearchContainer.propTypes = {
    directory: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteSearchContainer)