import _ from 'lodash'
import t from 't'
import BaseModel from 'models/baseModel'

import Album from 'models/album'
import Car from 'models/car'
import Media from 'models/media'
import Contest from 'models/contest'

export default class User extends BaseModel {
    constructor(data) {
        if (data.portfolio) {
            data.portfolio = new Album(data.portfolio)
        }
        if (data.catalog_media) {
            data.catalog_media = _.map(data.catalog_media, (media) => new Media(media))
        }
        if (data.catalogMediaIds) {
            data.catalog_media = _.map(data.catalogMediaIds, (media) => {
                return {id: media, isImg: true}
            })
        }
        if (data.contests) {
            data.contests = _.map(data.contests, (contest) => {
                return {
                    ...contest,
                    media: new Media(contest.media),
                    contest: new Contest(contest.contest),
                    isShowOnVendorPage: contest.status==='completed' && contest.place_value<=10
                }
            })
        }
        if (data.cars_auto) {
            data.cars = _.map(data.cars_auto, (car) => new Car(car))
        } else if (data.cars_limousine) {
            data.cars = _.map(data.cars_limousine, (car) => new Car(car))
        }
        super(data)
    }

    get reducerName() {
        return 'user'
    }
    get entityType() {
        return 'users'
    }

    get type() {
        return this.role
    }

    get address() {
        let address = ''
        _.map(this.contacts, (contact) => {
            if (contact.key === 'address')
                address = contact.value
        })
        return address
    }

    get avatar() {
        return this.avatar_url
    }
    get avatarUrl() {
        return this.avatar_url
    }
    getCoverUrl(size) {
        return this.avatar_url[size] ? this.avatar_url[size] : (typeof this.avatar_url === 'string' ? this.avatar_url : this.avatar_url[Object.keys(this.avatar_url)[0]])
    }

    get canFeedback() {
        return this.can_feedback
    }

    get catalogMedia() {
        return this.catalog_media
    }

    get cityName() {
        return this.city.name
    }

    get isVendor() {
        return this.role.type==='pro'
    }
    get isPro() {
        return this.top
    }

    getGlobalContest(cityId) {
        if (!this.global_contest) return null

        let result
        if (this.global_contest[cityId]) result = this.global_contest[cityId]
        if (this.global_contest.global) {
            if (!result || this.global_contest.global.position <= result.position) {
                result = this.global_contest.global
            }
        }
        return result ? result : null
    }

    get pmLink() {
        return this.pm_url
    }

    get link() {
        return this.profileUrl
    }

    get specLink() {
        return this.spec_url || this.profileUrl
    }

    get profileUrl() {
        return this.profile_url
    }

    get reviewsCount() {
        return this.reviews_counts ? this.reviews_counts.total : 0
    }

    get reviewsCounts() {
        return this.reviews_counts
    }

    get textMediaCounts() {
        const imageText = this.photos_count ? t('{n} фотография|{n} фотографии|{n} фотографий', {'n': this.photos_count}) : ''
        const videoText = this.videos_count ? t('{n} видео|{n} видео|{n} видео', {'n': this.videos_count}) : ''
        if (this.photos_count && this.videos_count) {
            return t('{countImageText} и {countVideoText}', {
                '{countImageText}': imageText,
                '{countVideoText}': videoText
            })
        } else {
            return this.videos_count ? videoText : imageText
        }
    }

    tabs(checkActivePage) {
        const baseLink = this.link.replace(/https?:\/\/[^\/]+/, '')
        let tabs = [
            {
                text: t('Обзор'),
                to: baseLink,
                active: checkActivePage(),
            }
        ]
        if (this.is_admin) return tabs
        if (this.reviewsCount) {
            tabs.push({
                text: t('Отзывы'),
                to: baseLink + 'rating/',
                active: checkActivePage('rating'),
            })
        }
        if (this.role.key !== 'car' && this.media_count) {
            tabs.push({
                text: t('Фото и видео'),
                to: baseLink + 'reports/',
                active: checkActivePage('reports'),
            })
        }
        if (this.role.type==='nevesta') {
            if (_.filter(this.contests, contest => contest.status === 'completed').length) {
                tabs.push({
                    text: t('Конкурсы'),
                    to: baseLink + 'contests/',
                    active: checkActivePage('contests'),
                })
            }
        } else if (_.filter(this.contests, contest => contest.status === 'completed').length) {
            tabs.push({
                text: t('Конкурсы'),
                to: baseLink + 'contests/',
                active: checkActivePage('contests'),
            })
        }
        if (this.cars_count) {
            if (this.cars_count['auto']) {
                tabs.push({
                    text: t('Автомобили'),
                    to: baseLink + 'auto/',
                    active: checkActivePage('auto'),
                })
            }
            if (this.cars_count['limousine']) {
                tabs.push({
                    text: t('Лимузины'),
                    to: baseLink + 'limousine/',
                    active: checkActivePage('limousine'),
                })
            }
        }
        return tabs
    }
}