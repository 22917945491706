import {combineReducers} from 'redux'
import _ from 'lodash'

import album from 'reducers/album'
import media from 'reducers/media'
import review from 'reducers/review'
import viewInfo from 'reducers/viewInfo'

import {types} from 'actions/user'

import Meta from 'models/meta'
import User from 'models/user'

const initialState = {
    list: [],
    links: [],
    loading: false,
    isSpecSwitching: false,
    meta: null,
    model: null,
}

function user(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_LIST_REQUEST:
            return {
                ...state,
                list: [],
                loading: true,
            }

        case types.LOAD_MORE_REQUEST:
        case types.LOAD_MODEL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case types.LOAD_LIST_OK:
            return {
                ...state,
                list: _.map(action.list, item => new User(item)),
                links: action.links,
                meta: new Meta(action.meta),
                loading: false
            }
        case types.LOAD_MORE_OK:
            return {
                ...state,
                list: _.union(state.list, _.map(action.list, item => new User(item))),
                meta: new Meta(action.meta),
                loading: false
            }

        case types.LOAD_MODEL_OK:
            return {
                ...state,
                model: new User(action.model),
                loading: false,
                isSpecSwitching: false,
            }
        case types.CHANGE_ACTIVE_SPEC:
            let model = state.model
            if (model && model.specs) {
                model.specs = _.map(model.specs, spec => {
                    spec.is_active = spec.spec.filter_key === action.filterKey
                    return spec
                })
            }
            return {
                ...state,
                isSpecSwitching: true,
                model: model
            }

        case types.LOAD_LIST_ERROR:
        case types.LOAD_MORE_ERROR:
        case types.LOAD_MODEL_ERROR:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
}

const UserReducer = combineReducers({
    user,
    album,
    media,
    review,
    viewInfo
})

export default UserReducer