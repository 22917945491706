import axios from 'axios'
import keyMirror from 'lib/keyMirror'
import _ from 'lodash'

export const types = keyMirror({
    LOAD_ENTITY_REQUEST: null,
    LOAD_ENTITY_OK: null,
    LOAD_ENTITY_ERROR: null,

    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,
}, 'entity')

const entityApiMap = {
    car: "go",
    country: "go",
    img: "go",
}

export const VENUE_PER_PAGE = 20
export const VENDOR_PER_PAGE = 20
export const VENDOR_SPEC_FILTER_NAME = 'service'
export const VENUE_SPEC_FILTER_NAME = 'event'

const ttl = 86400000 // 86400 sec
const ttlLoading = 5000 // 5 sec

export const isLoading = (state, entityName, entityId, entityParam) => {
    entityName = entityName.toLowerCase()
    let paramKey = getParamKey(entityParam)
    if (state.entity && state.entity.loading[entityName] && state.entity.loading[entityName][paramKey]) {
        return state.entity.loading[entityName][paramKey][entityId] || false
    } else {
        return false
    }
}

export const getEntity = (state, entityName, entityId, entityParam) => {
    entityName = entityName.toLowerCase()
    let paramKey = getParamKey(entityParam)
    if (state.entity && state.entity.entity[entityName] && state.entity.entity[entityName][paramKey] && typeof state.entity.entity[entityName][paramKey][entityId] != "undefined") {
        if (new Date().getTime() - state.entity.entity[entityName][paramKey][entityId]._time < ttl) {
            return state.entity.entity[entityName][paramKey][entityId]
        } else {
            return null
        }
    } else {
        return null
    }
}

export const isLoadingList = (state, entityName, listParam) => {
    entityName = entityName.toLowerCase()
    let list = getRawList(state, entityName, listParam)
    return list && list.list === false
}

const getRawList = (state, entityName, listParam) => {
    let paramKey = getParamKey(listParam)
    if (state.entity && state.entity.list[entityName] && state.entity.list[entityName][paramKey]) {
        return state.entity.list[entityName][paramKey]
    } else {
        return {list: null}
    }
}

export const getList = (state, entityName, listParam, entityParam, isWithPreviousPages = false) => {
    entityName = entityName.toLowerCase()
    let list = getRawList(state, entityName, listParam)
    let out = [];
    if (!list || !list.list || !list._time || new Date().getTime() - list._time > ttl) {
        out = []
    } else {
        out = _.map(list.list, entityId => getEntity(state, entityName, entityId, entityParam))
    }
    if (isWithPreviousPages && listParam.page && listParam.page > 1) {
        for (let page = listParam.page - 1; page > 0; page--) {
            let prevParam = Object.assign({}, listParam)
            prevParam.page = page
            let list = getRawList(state, entityName, prevParam)
            if (!list || !list.list) {
                break
            }
            out = _.map(list.list, entityId => getEntity(state, entityName, entityId, entityParam)).concat(out)
        }
    }
    return out
}

export const getMeta = (state, entityName, listParam, isUsePreviousMetaIfEmpty = false) => {
    entityName = entityName.toLowerCase()
    let list = getRawList(state, entityName, listParam)
    if (!list || !list.list || !list._time || new Date().getTime() - list._time > ttl) {
        if (isUsePreviousMetaIfEmpty && listParam.page && listParam.page > 1) {
            let prevParam = Object.assign({}, listParam)
            prevParam.page--
            list = getRawList(state, entityName, prevParam)
            if (list && list.meta) {
                return list.meta
            }
        }
        return null
    }
    return list.meta
}

export const getParamKey = (param) => {
    let paramKey = []
    if (param) {
        _.each(param, (value, key) => {
            paramKey.push(key + "=" + value)
        })
        paramKey.sort()
    }
    return paramKey.join('&')
}

export const loadEntity = (entityName, entityIds, entityParam = {}, insertInto = {}) => {
    return function(dispatch, getState) {
        if (!_.isArray(entityIds)) {
            entityIds = [entityIds]
        }

        entityName = entityName.toLowerCase()

        entityIds = _.filter(entityIds, entityId => {
            let entity = getEntity(getState(), entityName, entityId, entityParam)
            let loading = isLoading(getState(), entityName, entityId, entityParam)
            return !loading && (!entity || (new Date().getTime() - entity._time > ttl))
        })

        if (_.isEmpty(entityIds)) {
            return new Promise((resolve) => resolve(false))
        }

        dispatch({
            entityName: entityName,
            entityIds: entityIds,
            entityParam: entityParam,
            type: types.LOAD_ENTITY_REQUEST
        })

        let params = _.map(entityParam, (value, key) => {
            return 'entity[' + key + ']=' + (_.isArray(value) ? value.join(',') : value)
        })
        params.sort()

        return axios.get(API_URL + '/' + (entityApiMap[entityName] || 'v3') + '/' + entityName + '/' + entityIds.join(',') + (params?'?' + params.join('&'):''))
            .then(response => {
                return dispatch({
                    entityIds: entityIds,
                    entity: response.data.entity,
                    entityParam: entityParam,
                    entityName: entityName,
                    insertInto: insertInto,
                    type: types.LOAD_ENTITY_OK,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                return dispatch({
                    entityName: entityName,
                    entityParam: entityParam,
                    entityId: entityIds,
                    type: types.LOAD_ENTITY_ERROR
                })
            })
    }
}

export const loadList = (entityName, listParam = {}, entityParam = {}, isWithCredentials = false) => {
    return function(dispatch, getState) {
        entityName = entityName.toLowerCase()

        let list = getRawList(getState(), entityName, listParam)
        if (list.list!==null && (new Date().getTime() - list._time < (list.list===false?ttlLoading:ttl))) {
            if (list.list) {
                dispatch(loadEntity(entityName, list.list, entityParam))
            }
            return new Promise((resolve) => resolve(false))
        }

        dispatch({
            entityName: entityName,
            listParam: listParam,
            entityParam: entityParam,
            type: types.LOAD_LIST_REQUEST
        })

        let params = _.map(listParam, (value, key) => {
            return 'list[' + key + ']=' + (_.isArray(value) ? value.join(',') : value)
        })
        _.each(entityParam, (value, key) => {
            params.push('entity[' + key + ']=' + (_.isArray(value) ? value.join(',') : value))
        })
        params.sort()

        return axios.get(API_URL + '/' + (entityApiMap[entityName] || 'v3') + '/' + entityName + (params?'?' + params.join('&'):''), {withCredentials: isWithCredentials})
            .then(response => {
                return dispatch({
                    entity: response.data.entity,
                    entityName: entityName,
                    entityParam: entityParam,
                    list: response.data.list,
                    listParam: listParam,
                    meta: response.data.meta,
                    type: types.LOAD_LIST_OK,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                return dispatch({
                    entityName: entityName,
                    entityParam: entityParam,
                    listParam: listParam,
                    type: types.LOAD_LIST_ERROR
                })
            })
    }
}
