import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import t from 't'

import {skipActual} from 'actions/actual'
import {close} from 'actions/popupDialog'

const PopupDialogActual = (props) => {
    const text = t('Вы давно не актуализировали свободные даты. Перейдите в раздел <a href="{link}">"Календарь занятости"</a> и обновите даты, чтобы убрать это уведомление.', {'{link}': SITE_URL + props.locale.url + '/my/freeDates/'})
    return (
        <div className="pdActual">
            <p className="ppDialog_text" dangerouslySetInnerHTML={{__html: text}}/>
            <div className="ppDialog_buttons">
                <a className="button-gray ppDialog_button" onClick={props.close}>{t('Закрыть')}</a>
                <a className="button-border ppDialog_button" href="/my/freeDates/">{t('Обновить даты')}</a>
            </div>
        </div>)
}
function mapStateToProps(state, ownProps) {
    return {
        locale : state.main.locale,
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        close: () => {
            skipActual().then(res => dispatch(close()))
        },
        dispatch: dispatch,
    }
}

PopupDialogActual.propTypes = {
}


export default connect(mapStateToProps, mapDispatchToProps)(PopupDialogActual)
