import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    SHOW: null,
    CLOSE: null,
}, 'calendar')

export const show = (params) => {
    return function (dispatch) {
        dispatch({
            type   : types.SHOW,
            params : params,
        })
    }
}

export const close = () => {
    return function (dispatch) {
        dispatch({
            type : types.CLOSE,
        })
    }
}
