import React from 'react'
import {connect} from 'react-redux'


const Hint = (props) => {
    if (!props.component) return null
    return (
        <div className="hint">
            <div className="hint_content">
                {props.component}
            </div>
        </div>)
}

function mapStateToProps(state, ownProps) {
    return {
        component: state.hint.component,
    }
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Hint)