import {types} from 'actions/calendar'

const initialState = {
    show   : false,
    params : {},
}

const CalendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SHOW:
            return {
                ...state,
                show   : true,
                params : action.params,
            }

        case types.CLOSE:
            return {
                ...state,
                show   : false,
                params : {},
            }
        default:
            return state
    }
}

export default CalendarReducer
