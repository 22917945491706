import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_MODEL_REQUEST: null,
    LOAD_MODEL_OK: null,
    LOAD_MODEL_ERROR: null,
    SWITCH_BRANCH: null,
}, 'contacts')


export const switchBranch = (branchNum) => {
    return function (dispatch, getState) {
        dispatch({
            type: types.SWITCH_BRANCH,
            branchNum: branchNum
        })
    }
}

const getUrl = (entityType, modelId) => {
    return API_URL + '/v2/' + entityType + '/' + modelId
}

export const loadContacts = (entityType, modelName, modelId, cityId) => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_MODEL_REQUEST
        })

        let params = {
            embed: [],
            fields: ['coords']
        }
        if (entityType === 'users') {
            params.fields.push('busy')
            params.fields.push('has_free_date')
            params.city_id = cityId
        }
        if (entityType === 'restaurants') {
            params.embed.push('user')
            params.embed.push('contacts')
            params.fields.push('address')
        }
        if (entityType === 'venues') {
            params.embed.push('user')
            params.embed.push('contacts')
            params.fields.push('address')
        }
        if (entityType === 'cars') {
            params.embed.push('user')
            params.embed.push('contacts')
        }
        if (entityType === 'shops') {
            params.embed.push('branches')
            params.embed.push('user')
            params.embed.push('contacts')
            params.set_city = getState().main.city.id
        }
        params.embed = params.embed.join(',')
        params.fields = params.fields.join(',')

        return axios.get(getUrl(entityType, modelId), {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    entityType: entityType,
                    model: response.data[modelName],
                    type: types.LOAD_MODEL_OK,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_MODEL_ERROR
                })
            })
    }
}

export const contactKeysOrder = ['email','url','telegram','whatsapp','viber','phone','line']

let contactKeysCache = null
let contactKeysWaitList = []
export const getContactKeys = () => {
    return new Promise(function(resolve, reject) {
        if (contactKeysCache !== null) {
            resolve(contactKeysCache)
        } else {
            if (!contactKeysWaitList.length) {
                axios.get(API_URL + '/v2/contacts', {withCredentials: true})
                .then(res => {
                    let availableContactKeys = [...contactKeysOrder, 'address', 'landmark', 'latitude', 'longitude']
                    for (let i=0; res.data.contacts[i]; i++) {
                        let contactInfo = res.data.contacts[i]
                        if (availableContactKeys.includes(contactInfo.key)) {
                            if (contactKeysCache === null) {
                                contactKeysCache = {}
                            }
                            contactKeysCache[contactInfo.key] = contactInfo
                        }
                    }
                    if (contactKeysWaitList.length) {
                        contactKeysWaitList.forEach(callback => {
                            callback(contactKeysCache)
                        })
                        contactKeysWaitList = []
                    }
                })
            }
            contactKeysWaitList.push(resolve)
        }
    })
}
