import axios from 'axios'
import keyMirror from 'lib/keyMirror'
import _ from 'lodash'


export const types = keyMirror({
    CLOSE: null,

    LOAD_REQUEST: null,
    LOAD_OK: null,
    LOAD_ERROR: null,
}, 'doerSuggest')

export const close = () => {
    return function (dispatch, getState) {
        dispatch({
            type: types.CLOSE
        })
    }
}

export const loadDoers = (doerType, roleIds, partOfName, timer) => {
    return function (dispatch, getState) {
        function sendRequest() {
            let params = [
                'name=' + partOfName,
                'order=my_city',
                'role_id=' + roleIds.join(','),
            ]
            axios.get(API_URL + '/v2/' + doerType + '?' + params.join('&'), {withCredentials: true})
                .then(response => {
                    dispatch({
                        doers: response.data[doerType],
                        type: types.LOAD_OK,
                    })
                })
                .catch(() => {
                    dispatch({
                        type: types.LOAD_ERROR
                    })
                })
        }

        dispatch({
            type: types.LOAD_REQUEST
        })
        if (timer != undefined) {
            if (partOfName == timer.partOfName) return false

            if (timer.id) {
                clearTimeout(timer.id)
            }
            timer.partOfName = partOfName
            if (timer.partOfName == '') {
                dispatch({
                    doers: [],
                    type: types.LOAD_OK,
                })
                return
            }
            timer.id = setTimeout(() => {
                sendRequest()
                timer.id = 0
            }, timer.delay)
        } else {
            sendRequest()
        }
    }
}