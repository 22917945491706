import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    OPEN: null,
    CLOSE: null,
}, 'hint')

export const putComponentToHint = (component) => {
    return {
        type: types.OPEN,
        component: component,
    }
}

export const close = () => {
    return {
        type: types.CLOSE
    }
}