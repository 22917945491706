import _ from 'lodash'
import translator from "lib/translate/translator"

const t = (text, vars) => {
    if (typeof vars == 'undefined') {
        vars = []
    }
    vars['site_name'] = typeof SITE_NAME !== 'undefined' ? SITE_NAME : ''
    vars = _.mapKeys(vars, function (value, key) {
        if (key.charAt(0) == '{') {
            key = key.replace('{', '').replace('}', '')
        }
        return key
    })
    text = translator(text, vars)
    return text
}
export default t