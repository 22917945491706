import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    OPEN: null,
    CLOSE: null,
}, 'popupDialog')

export const putComponentToPopupDialog = (caption, component) => {
    return {
        type      : types.OPEN,
        component : component,
        caption   : caption,
    }
}

export const close = () => {
    return {
        type: types.CLOSE
    }
}