import React from 'react'
import PropTypes from 'prop-types'
import t from't'

const FilterHeader = (props) => {
    return (
        <div className="filterBubble_head">
            {props.caption}
            {props.counts ?
                <span className="filterBubble_counter" >{props.counts}</span>
            : null}
        </div>)
}

FilterHeader.propTypes = {
    caption: PropTypes.string.isRequired,
    counts: PropTypes.number,
}

export default FilterHeader