import {types} from 'actions/share'

import {LOCATION_CHANGE} from 'react-router-redux'

const initialState =
    {
        image: null,
        text: null,
        title: null,
        url: null,
        show: false,
    }

const ShareReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN:
            return {
                ...state,
                show: true,
                image: action.image,
                text: action.text,
                title: action.title,
                url: action.url,
            }
        case types.CLOSE:
        case LOCATION_CHANGE:
            return initialState
        default:
            return state
    }
}
export default ShareReducer