import axios from 'axios'
import keyMirror from "lib/keyMirror"

import {loadInterface} from 'actions/main'

export const types = keyMirror({
    ADD_FAVORITE_REQUEST: null,
    ADD_FAVORITE_OK: null,
    ADD_FAVORITE_ERROR: null,
    REMOVE_FAVORITE_REQUEST: null,
    REMOVE_FAVORITE_OK: null,
    REMOVE_FAVORITE_ERROR: null,

    FAVORITE_DIFF: null,
}, 'favorite')

export const toggleFavorite = (directoryType, modelName, modelId, isFav) => {
    return function (dispatch) {
        return dispatch(isFav ? removeFavorite(directoryType, modelName, modelId) : addFavorite(directoryType, modelName, modelId))
            .then((status) => {
                dispatch({
                    type: types.FAVORITE_DIFF,
                    path: decodeURI(document.location.pathname).replace(/\d+\/(?:\w+\/)?/,''),
                    diff: status > 0 ? 1 : -1,
                })
                return new Promise((resolve) => {
                    resolve(status)
                })
            })
    }
}

const addFavorite = (directoryType, modelName, modelId) => {
    return function (dispatch) {
        dispatch({
            type: types.ADD_FAVORITE_OK,
            modelName: modelName,
            modelId: modelId
        })
        axios.post(API_URL + '/v2/' + directoryType + '/' + modelId + '/favorites', {}, {withCredentials: true})
        .then(() => {
        })
        .catch(() => {
            dispatch({
                type: types.ADD_FAVORITE_ERROR
            })
        })
        return new Promise((resolve) => {
            resolve(1)
        })
    }
}
const removeFavorite = (directoryType, modelName, modelId) => {
    return function (dispatch) {
        dispatch({
            type: types.REMOVE_FAVORITE_OK,
            modelName: modelName,
            modelId: modelId
        })
        axios.delete(API_URL + '/v2/' + directoryType + '/' + modelId + '/favorites', {withCredentials: true})
        .then(() => {
        })
        .catch(() => {
            dispatch({
                type: types.REMOVE_FAVORITE_ERROR
            })
        })
        return new Promise((resolve) => {
            resolve(0)
        })
    }
}
