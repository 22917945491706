import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,
    LOAD_MORE_REQUEST: null,
    LOAD_MORE_OK: null,
    LOAD_MORE_ERROR: null,

    LOAD_MODEL_REQUEST: null,
    LOAD_MODEL_OK: null,
    LOAD_MODEL_ERROR: null,

    LOAD_MODELS_REQUEST: null,
    LOAD_MODELS_OK: null,
    LOAD_MODELS_ERROR: null,

    LOAD_LIKE_LIST_REQUEST: null,
    LOAD_LIKE_LIST_OK: null,
    LOAD_LIKE_LIST_ERROR: null,
    LOAD_LIKE_MORE_REQUEST: null,
    LOAD_LIKE_MORE_OK: null,
    LOAD_LIKE_MORE_ERROR: null,

    TOGGLE_FULLSCREEN: null,

    LIKE_REQUEST: null,
    LIKE_OK: null,
    LIKE_ERROR: null,

    RESET_LIST: null,
    RESET_INDEX: null,
}, 'media')

const getLikeListUrl = (id) => {
    return API_URL + '/v2/media/' + id + '/likes'
}

const getModelUrl = (id) => {
    return API_URL + '/v2/media/' + id
}

const getUrl = (entityType, modelId) => {
    return API_URL + '/v2/' + entityType + '/' + modelId + '/media'
}

export const loadData = (direcory, modelId, params = null) => {
    return function (dispatch, getState) {
        let typeRequest = types.LOAD_LIST_REQUEST
        let typeOk = types.LOAD_LIST_OK
        let typeError = types.LOAD_LIST_ERROR

        if (!params.page) {
            params.page = 1
        }

        // если у нас есть offset
        // то скорее всего мы подгрузили на странице немного моделей, а теперь запрашиваем пачку большего размера
        // и по сути подгрузка второй (или более страницы) - это на самом деле первая страница с offset
        if (params.offset) {
            params.page--
        }

        // если страница первая, но есть offset, то значит уже есть подгруженые альбомы
        if (params.page > 1 || params.offset > 0) {
            typeRequest = types.LOAD_MORE_REQUEST
            typeOk = types.LOAD_MORE_OK
            typeError = types.LOAD_MORE_ERROR
        }

        dispatch({
            type: typeRequest
        })

        axios.get(getUrl(direcory.type, modelId), {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.media,
                    meta: response.data.meta,
                    type: typeOk,
                })
            })
            .catch(() => {
                dispatch({
                    type: typeError
                })
            })
    }
}

export const loadModel = (id, params = null) => {
    return function (dispatch) {
        dispatch({
            type: types.LOAD_MODEL_REQUEST
        })

        return axios.get(getModelUrl(id), {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    meta: response.data.meta,
                    model: response.data.media,
                    type: types.LOAD_MODEL_OK,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_MODEL_ERROR
                })
            })
    }
}

export const loadModels = (id, params = null) => {
    return function (dispatch, getState) {
        const PREV_LIMIT = 3
        const NEXT_LIMIT = 5
        const index = getState().media.index
        const curIndex = index.indexOf(id)
        if (index.length && curIndex > -1) {
            if (curIndex>0) {
                params.prev_limit = 0
            } else {
                params.prev_limit = params.prev_limit || PREV_LIMIT
            }
            if (index.length - curIndex > 1) {
                params.next_limit = 0
            } else {
                params.next_limit = params.next_limit || NEXT_LIMIT
            }
        } else {
            params.prev_limit = PREV_LIMIT
            params.next_limit = NEXT_LIMIT
        }

        // console.log('neighbors', params.prev_limit, id, params.next_limit);
        if (!params.prev_limit && !params.next_limit) return null

        dispatch({
            type: types.LOAD_MODELS_REQUEST
        })

        let path = 'media'
        if (params.type.indexOf('feed_') === 0) {
            path = 'mediaFeed'
            params.type = params.type.replace('feed_', '')
        } else {
            params['cycle'] = 1
        }

        return axios.get(API_URL + '/v2/' + path + '/' + id + '/neighbor', {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    data: response.data,
                    type: types.LOAD_MODELS_OK,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_MODELS_ERROR
                })
            })
    }
}

export const loadLikeList = (modelId, params = null) => {
    return function (dispatch, getState) {
        let typeRequest = types.LOAD_LIKE_LIST_REQUEST
        let typeOk = types.LOAD_LIKE_LIST_OK
        let typeError = types.LOAD_LIKE_LIST_ERROR

        if (getState().media.like_meta) {
            typeRequest = types.LOAD_LIKE_MORE_REQUEST
            typeOk = types.LOAD_LIKE_MORE_OK
            typeError = types.LOAD_LIKE_MORE_ERROR
            params['page'] = getState().media.like_meta.nextPage
        }

        if (!params.page) {
            params.page = getState().media.like_meta ? getState().media.like_meta.nextPage : 1
        }
        // если у нас есть offset
        // то скорее всего мы подгрузили на странице немного моделей, а теперь запрашиваем пачку большего размера
        // и по сути подгрузка второй (или более страницы) - это на самом деле первая страница с offset
        if (params.offset) {
            params.page--
        }

        dispatch({
            type: typeRequest
        })

        axios.get(getLikeListUrl(modelId), {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.likes,
                    meta: response.data.meta,
                    type: typeOk,
                })
            })
            .catch(() => {
                dispatch({
                    type: typeError
                })
            })
    }
}

export const toggleFullscreen = () => {
    return function (dispatch) {
        dispatch({
            type: types.TOGGLE_FULLSCREEN
        })
    }
}

export const countView = (mediaId) => {
    return function (dispatch) {
        axios.post(API_URL + '/v2/media/' + mediaId + '/countView', {}, {withCredentials: true})
            .then((response) => {
            })
            .catch(() => {
            })
    }
}

export const resetList = () => (dispatch) => {
    dispatch({type: types.RESET_LIST})
    return Promise.resolve()
}

export const resetIndex = () => (dispatch) => {
    dispatch({type: types.RESET_INDEX})
    return Promise.resolve()
}

export const toggleLike = (mediaId, isLike) => isLike ? removeLike(mediaId) : addLike(mediaId)

const addLike = (mediaId) => {
    return function (dispatch) {
        dispatch({
            type: types.LIKE_REQUEST
        })
        axios.post(API_URL + '/v2/media/' + mediaId + '/likes', {}, {withCredentials: true})
            .then((response) => {
                dispatch({
                    type: types.LIKE_OK,
                    mediaId: mediaId,
                    count: response.data.count,
                    myLike: true,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LIKE_ERROR
                })
            })
    }
}
const removeLike = (mediaId) => {
    return function (dispatch) {
        dispatch({
            type: types.LIKE_REQUEST
        })
        axios.delete(API_URL + '/v2/media/' + mediaId + '/likes', {withCredentials: true})
            .then((response) => {
                dispatch({
                    type: types.LIKE_OK,
                    mediaId: mediaId,
                    count: response.data.count,
                    myLike: false,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LIKE_ERROR
                })
            })
    }
}

export const stopAllVideos = (indexes, list) => {
    for (let i=0; indexes[i]; i++) {
        let media = list[indexes[i]]
        if (!media.video_link) {
            continue
        }
        let playerType = null
        if (media.video_link.indexOf('vimeo.com') >= 0) {
            playerType = 'vimeo'
        } else if (media.video_link.indexOf('youtube.com') >= 0) {
            playerType = 'youtube'
        } else if (media.video_link.indexOf('vk.com') >= 0) {
            playerType = 'vk'
        }
        let $media = document.querySelector('.ppMedia_media-video[data-index="' + i + '"]')
        if ($media !== null) {
            togglePlay($media, playerType, false)
        }
    }
}

export const togglePlay = ($media, playerType, play) => {
    const $player = $media.getElementsByTagName('iframe')[0]
    if (play !== true && play !== false) {
        play = $media.classList.contains('_overlayed')
    }
    if (play && $media.classList.contains('_overlayed')) {
        if (playerType === 'vimeo') {
            $player.contentWindow.postMessage(JSON.stringify({
                method: 'play',
                value: 1,
            }), '*')
        } else if (playerType === 'youtube') {
            $player.contentWindow.postMessage(JSON.stringify({
                event: 'command',
                func: 'playVideo',
                args: '',
            }), "*")
        }
        $media.classList.remove('_overlayed')
    } else if (!play && !$media.classList.contains('_overlayed')) {
        if (playerType === 'vimeo') {
            $player.contentWindow.postMessage(JSON.stringify({
                method: 'pause',
                value: 1,
            }), '*')
        } else if (playerType === 'youtube') {
            $player.contentWindow.postMessage(JSON.stringify({
                event: 'command',
                func: 'stopVideo',
                args: '',
            }), "*")
        }
        $media.classList.add('_overlayed')
    }
}