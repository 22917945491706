import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import CityList from 'components/city/list'

import {close} from 'actions/popup'
import {searchCities} from 'actions/city'

class CityListContainer extends React.Component {
    componentDidMount() {
        this.props.loadData()
    }

    render() {
        return <CityList {...this.props}/>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        cities: state.city.list,
        neighborCities: state.city.neighbors,
    }
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        loadData: (city = ownProps.city) => dispatch(searchCities(null, city)),
        search: (name, city = ownProps.city) => dispatch(searchCities(name, city)),
        callback: ownProps.callback ?
            (city) => {
                dispatch(ownProps.callback(city))
                dispatch(close())
            } : null,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CityListContainer))
