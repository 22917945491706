const PhotoPresenter = {
  key: function(data) {
    return "photo-" + this.id(data);
  },

  id: function(data) {
    return data.id;
  },

  url: function(data) {
    return data.original_url || data.url;
  },

  thumbUrl: function(data) {
    return data.thumb_url;
  },
}
export default PhotoPresenter
