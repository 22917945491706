import axios from 'axios'
import keyMirror from "lib/keyMirror"
import _ from 'lodash'

import {searchCities} from 'actions/city'

export const types = keyMirror({
    CHECK_AUTH_REQUEST: null,
    CHECK_AUTH_OK: null,
    CHECK_AUTH_ERROR: null,

    AUTH_NO_USER: null,
    AUTH_START: null,

    LOAD_METHODS_REQUEST: null,
    LOAD_METHODS_OK: null,
    LOAD_METHODS_ERROR: null,

    MODERATION_REQUEST_REQUEST: null,
    MODERATION_REQUEST_OK: null,
    MODERATION_REQUEST_ERROR: null,
}, 'auth')

const oauthWindow = {
    width: 720,
    height: 560
}

export const logout = () => {
    return function (dispatch) {
        axios.post(API_URL + '/v2/auth/logout', {}, {withCredentials: true})
            .then(response => {
                window.location.reload()
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
            })
    }
}

export const getMethods = () => {
    return (dispatch) => {
        dispatch({
            type: types.LOAD_METHODS_REQUEST
        })
        axios.get(API_URL + '/v2/auth/methods', {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_METHODS_OK,
                    methods: response.data.methods,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_METHODS_ERROR
                })
            })
    }
}

export const auth = (method) => {
    return (dispatch, getState) => {
        const params = objectToQueryString(method.oAuthParams)
        openAuthWindow(dispatch, getState, method.oAuthUrl + params, method)
    }
}

export const register = () => {
    return (dispatch, getState) => {
        const data = getState().auth.social_data
        const _domain = '.' + getState().main.site.domain
        if (data.city) {
            dispatch(searchCities(data.city, false))
                .then(() => {
                    data.city = getState().city && getState().city.list && getState().city.list.length ? getState().city.list[0] : null
                    console.log('social_data:', getState().auth.social_data, 'city:', data.city)
                    let cookie = 'gorkoRegData=' + encodeURIComponent(JSON.stringify(data)) + '; domain=' + _domain + '; path=/; secure; max-age=1800'
                    console.log('set cookie:', cookie)
                    document.cookie = cookie
                    document.location.href = getState().main.site.url + getState().main.locale.url_not_empty + '/login/registration/'
                })
        } else {
            console.log(data);
            let cookie = 'gorkoRegData=' + encodeURIComponent(JSON.stringify(data)) + '; domain=' + _domain + '; path=/; secure; max-age=1800'
            console.log('set cookie:', cookie)
            document.cookie = cookie
            document.location.href = getState().main.site.url + getState().main.locale.url_not_empty + '/login/registration/'
        }
    }
}


const openAuthWindow = (dispatch, getState, url, method) => {
    const width = oauthWindow.width
    const height = oauthWindow.height
    const left = Math.round((screen.width / 2) - (width / 2))
    const top = Math.round((screen.height / 2) - (height / 2))

    const windowParams = `width=${width},height=${height},resizable=yes,scrollbars=yes,status=yes,top=${top},left=${left}`
    let okWindow = window.open(url, '_blank', windowParams)

    let timer = setInterval(function () {
        if (okWindow.closed) {
            clearInterval(timer)

            const params = getAndRemoveAuthData(getState().main.site.domain)
            if (!_.isEmpty(params)) {
                if (params.access_token) {
                    getUserData(dispatch, method, params)
                } else {
                    if (params.error === 'access_denied') {
                        //     `[OAuth. Access Denied] — ${key}`,
                    } else if (params.error === 'invalid_request') {
                        //     '[OAuth. Missing Token. VK Token Expired]',
                    } else {
                        //     `[OAuth. Missing Token. Unknown Reason] — ${key}`,
                    }
                }
            } else {
                //     `[OAuth. Window Closed] — ${key}`,
            }
        }
    }, 1000)
}

const getAndRemoveAuthData = (siteDomain) => {
    const _domain = '.' + siteDomain
    const cookie_ = getCookie('gorkoAuthData')
    let result_ = {}
    if (cookie_) {
        const data_ = decodeURIComponent(cookie_)
        if (_.isObject(data_)) {
            result_ = data_
        } else {
            if (!_.isEmpty(data_)) {
                try {
                    result_ = JSON.parse(data_)
                } catch (e) {
                    // 'ERROR PARSE JSON'
                }
            }
        }
    }

    document.cookie = 'gorkoAuthData=; domain=' + _domain + '; path=/; secure; expires=-1'

    return result_
}

// возвращает cookie с именем name, если есть, если нет, то undefined
const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ))
    return matches ? decodeURIComponent(matches[1]) : undefined
}

const getUserData = (dispatch, method, params) => {
    if (params.key === method.key) {
        dispatch({
            type: types.CHECK_AUTH_REQUEST
        })
        axios.get(getAuthApiUrl(params.key, params.access_token, (method.key === 'ml' ? {uid: params.x_mailru_vid} : null)), {withCredentials: true})
            .then(response => {
                if (response.data.user) {
                    dispatch({
                        type: types.CHECK_AUTH_OK,
                        user: response.data.user,
                        social_key: method.key
                    })
                } else {
                    if (method.key === 'ml') {
                        dispatch({
                            type: types.AUTH_NO_USER,
                            social_data: {
                                key: method.key,
                                auth: 'access_token=' + params.access_token,
                            },
                            social_key: method.key
                        })
                    } else {
                        getUserDataFromSN(method, params, (social_data) => dispatch({
                            type: types.AUTH_NO_USER,
                            social_data: social_data,
                            social_key: method.key
                        }))
                    }
                }
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.CHECK_AUTH_ERROR
                })
            })
    } else {
        // 'params.key not equal method.key'
    }
}

const objectToQueryString = (params) => {
    return Object.keys(params).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&')
}

const getAuthApiUrl = (key, access_token, params) => {
    if (params) {
        params.access_token = access_token
        return API_URL + '/v2/auth/login?socialNetwork=' + key + '&params=' + encodeURIComponent(objectToQueryString(params))
    }
    return API_URL + '/v2/auth/login?socialNetwork=' + key + '&access_token=' + access_token
}

const getUserDataFromSN = (method, params, callback) => {
    if (method.key === 'vk') {
        let script = document.createElement('SCRIPT')
        script.src = method.apiUrl + '?' + objectToQueryString(method.apiParams(params)) + '&callback=vkGetUserData'
        document.getElementsByTagName('head')[0].appendChild(script)
        window.vkGetUserData = (result) => {
            const data = result.response[0]
            console.log('vkData',
                'social_key', method.key,
                'auth', ('access_token=' + params.access_token),
                'name', (data.first_name + ' ' + data.last_name),
                'first_name', data.first_name,
                'last_name', data.last_name,
                'avatar_url', (data.photo_id ? data.photo_200 : ''),
                'email', (data.email || ''),
                'gender', (data.sex === 2 ? 1 : 2),
                'city', (data.city && data.city.title ? data.city.title : ''),
            )
            callback({
                social_key: method.key,
                auth: 'access_token=' + params.access_token,
                name: data.first_name + ' ' + data.last_name,
                first_name: data.first_name,
                last_name: data.last_name,
                avatar_url: data.photo_id ? data.photo_200 : '',
                email: (data.email || ''),
                gender: data.sex === 2 ? 1 : 2,
                city: data.city && data.city.title ? data.city.title : '',
            })
        }
    } else {
        axios.get(method.apiUrl, {params: method.apiParams(params)})
            .then(response => {
                let data = response.data
                switch (params.key) {
                    case 'ml':
                        data = data.response[0]
                        callback({
                            social_key: method.key,
                            auth: 'access_token=' + params.access_token,
                        })
                        break
                    case 'fb':
                        callback({
                            social_key: method.key,
                            auth: 'access_token=' + params.access_token,
                            name: data.first_name + ' ' + data.last_name,
                            first_name: data.first_name,
                            last_name: data.last_name,
                            avatar_url: data.picture.data.is_silhouette ? '' : data.picture.data.url,
                            email: data.email,
                            gender: data.gender === 'male' ? 1 : 2,
                            city: '',
                        })
                        break
                    case 'odn':
                        callback({
                            social_key: method.key,
                            auth: 'access_token=' + params.access_token,
                            name: data.first_name + ' ' + data.last_name,
                            first_name: data.first_name,
                            last_name: data.last_name,
                            avatar_url: data.photo_id ? data.pic_3 : '',
                            email: data.email,
                            gender: data.gender === 'male' ? 1 : 2,
                            city: data.location.city,
                        })
                        break
                    case 'goo':
                        callback({
                            social_key: method.key,
                            auth: 'access_token=' + params.access_token,
                            name: data.name.givenName + ' ' + data.name.familyName,
                            first_name: data.name.givenName,
                            last_name: data.name.familyName,
                            avatar_url: data.image && !data.image.isDefault ? data.image.url : '',
                            email: data.emails[0].value,
                            gender: data.gender === 'male' ? 1 : 2,
                            city: '',
                        })
                        break
                }
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
            })
    }
}

export const moderationRequest = () => {
    return function(dispatch, getState) {
        dispatch({
            type: types.MODERATION_REQUEST_REQUEST
        })
        axios.post(API_URL + '/v2/moderations', {},{withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.MODERATION_REQUEST_OK,
                    data: response.data,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.MODERATION_REQUEST_ERROR
                })
            })
    }
}

export const sendPhoneForSms = (phone, iso, hash) => {
    return new Promise(function (resolve, reject) {
        axios.get(API_URL + '/v2/auth/login?socialNetwork=phone&phone=' + phone + '&iso=' + iso, {
            withCredentials: true,
            headers: {
                'x-hash': hash,
            }
        })
            .then(response => {
                resolve(response.data)
            })
    })
}

export const sendSmsCode = (phone, iso, code) => {
    return new Promise(function (resolve, reject) {
        axios.get(API_URL + '/v2/auth/login?socialNetwork=phone&phone=' + phone + '&iso=' + iso + '&code=' + code, {withCredentials: true})
            .then(response => {
                resolve(response.data)
            })
    })
}

export const authBySms = (data, phone) => {
    return (dispatch) => {
        if (data.user) {
            dispatch({
                type: types.CHECK_AUTH_OK,
                user: data.user,
                social_key: 'phone'
            })
        } else {
            dispatch({
                type: types.AUTH_NO_USER,
                social_data: {name: phone},
                social_key: 'phone'
            })
        }
    }
}

export const authStart = () => {
    return (dispatch) => {
        dispatch({
            type: types.AUTH_START,
        })
    }
}
