import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import {close} from 'actions/popup'

import Popup from 'components/popup/popup'

let scrollTop = null

const lockBody = (isLocked) => {
    if (!isLocked) {
        let $body = document.body || document.getElementsByTagName('body')[0]
        $body.style.overflow = 'initial'
        let $html = document.html || document.getElementsByTagName('html')[0]
        $html.style.overflow = 'initial'
        if (scrollTop) {
            $html.scrollTop = scrollTop
            scrollTop = null
        }
    } else {
        let $html = document.html || document.getElementsByTagName('html')[0]
        let $body = document.body || document.getElementsByTagName('body')[0]
        scrollTop = $html.scrollTop
        $body.style.overflow = 'hidden'
        $html.style.overflow = 'hidden'
    }
}

class PopupContainer extends React.Component {
    componentDidMount() {
        lockBody(false)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.show && !this.props.show) { // скрытие попапа
            lockBody(false)
        }
        if (!prevProps.show && this.props.show) { // открытие попапа
            lockBody(true)
        }
    }

    render() {
        if (!this.props.component) return null
        return <Popup {...this.props}/>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        component: state.popup.component,
        isBackButton: state.popup.isBackButton,
        returnLocation: state.popup.returnLocation,
        returnPopupScrollTop: state.popup.returnPopupScrollTop,
        show: state.popup.show,
        title: state.popup.title,
    }
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        dispatch: dispatch,
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    const dispatch = dispatchProps.dispatch
    console.debug('popup block', {popup_return_location: stateProps.returnLocation})
    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        closePopup: () => {
            if (stateProps.returnLocation !== null) {
                if (stateProps.returnLocation !== false) {
                    dispatch(close())
                    ownProps.history.push(stateProps.returnLocation)
                }
            } else {
                dispatch(close())
            }
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(PopupContainer))