import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_REQUEST: null,
    LOAD_OK: null,
    LOAD_ERROR: null,
}, 'titles')

export const getTitles = (url) => {
    // для тестирования сеошки на деве нужно использовать реплейс на msk.gorko.ru
    if ('development' === process.env.NODE_ENV) {
        url = url.replace(':8000', '')
        url = url.replace('gorko.ru', 'msk.gorko.ru')
    }
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_REQUEST
        })
        if (getState().titles.cache && getState().titles.cache[url]) {
            return dispatch({
                type: types.LOAD_OK,
                titles: getState().titles.cache[url],
                url: url,
            })
        } else {
            return axios.get(API_URL + '/v2/interface/titles?url=' + encodeURIComponent(url), {withCredentials: true})
                .then(response => {
                    dispatch({
                        type: types.LOAD_OK,
                        titles: {
                            title: response.data.title,
                            keywords: response.data.keywords,
                            description: response.data.description,
                            h1: response.data.h1,
                            h1text: response.data.h1text,
                            bottom: response.data.bottom,
                            vk_code: response.data.vk_code,
                            canonical: response.data.canonical,
                            robots: response.data.robots,
                            qa: response.data.qa,
                        },
                        url: url,
                    })
                })
                .catch((e) => {
                    if ('development' === process.env.NODE_ENV) console.log(e)
                    dispatch({
                        type: types.LOAD_ERROR
                    })
                })
        }
    }
}

