import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,
}, 'wedding')

const PER_PAGE = 10

const getUrl = (type, filters) => {
    return API_URL + '/v2/albums?wedding_type=' + type + (filters ? '&' + filters : '')
}

export const loadWeddings = (type, filters, params = {}) => {
    return function (dispatch, getState) {
        if (!params.page) {
            params.page = 1
        }

        if (!params.per_page) {
            params.per_page = PER_PAGE
        }

        params.preview_size = '190x195x1,120x120x1'
        params.embed = 'medias(limit:3)'

        dispatch({
            type: types.LOAD_LIST_REQUEST
        })

        axios.get(getUrl(type, filters), {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.albums,
                    meta: response.data.meta,
                    type: types.LOAD_LIST_OK,
                    weddingType: type,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_LIST_ERROR
                })
            })
    }
}
