window.SITE_DOMAIN = document.location.hostname.replace(/^(?:[^\.]+\.)?([^\.]{4,}\.\w{2,3}(?:\.\w{2,3})?)(?:\:\d+)?$/, '$1')
window.LANG_T = window.LANG_T || {}
window.API_URL = 'https://api.' + window.SITE_DOMAIN + '/api'

window.IS_CSS_LOADED = false

import {getCookie} from 'lib/cookie'

const cdnDomain = getCookie('cdn1') ? 'cdn1' : 'cdn2'
window.CDN = ('production' === process.env.NODE_ENV ? document.location.protocol + '//' + cdnDomain + '.' + SITE_DOMAIN : document.location.protocol + '//' + window.SITE_DOMAIN + ':8000')
__webpack_public_path__ = window.CDN + '/mobile/assets/'

"use strict"

import React from 'react'
import ReactDOM from 'react-dom'
import {createStore, applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import reducers from 'reducers'
import thunk from 'redux-thunk'
import { createBrowserHistory as createHistory } from 'history'
import {ConnectedRouter, routerMiddleware} from 'react-router-redux'
// import _ from 'lodash'
// import dayjs from 'dayjs''

import Routes from 'routes/index'
import Error50x from 'components/error/50x'

import {loadApp} from 'actions/main'
import axios from 'axios'

import setGlobal from 'lib/setGlobal'

// models
// initialStore пока не нужен
// import Car from 'models/car'
// import City from 'models/city'
// import Directory from 'models/directory'
// import Meta from 'models/meta'
// import Place from 'models/place'
// import Restaurant from 'models/restaurant'
// import Room from 'models/room'
// import Shop from 'models/shop'
// import User from 'models/user'
// import Venue from 'models/venue'


import {GoogleGtag, YandexMetrika, facebookPixel, metaPixel, mailRu, vk, tiktok} from 'lib/counters'

// initialStore пока не нужен
// const prepareModel = (value) => {
//     let out = value
//     switch (value._class) {
//         case 'City':
//             out = new City(value)
//             break
//         case 'Directory':
//             out = new Directory(value)
//             break
//         case 'Car':
//             out = new Car(value)
//             break
//         case 'Meta':
//             out = new Meta(value)
//             break
//         case 'Place':
//             out = new Place(value)
//             break
//         case 'Restaurant':
//             out = new Restaurant(value)
//             break
//         case 'Room':
//             out = new Room(value)
//             break
//         case 'Shop':
//             out = new Shop(value)
//             break
//         case 'User':
//             out = new User(value)
//             break
//         case 'Venue':
//             out = new Venue(value)
//             break
//     }
//     return out
// }
// const prepareStore = (reducer, data) => {
//     if (['car', 'place', 'restaurant', 'shop', 'user', 'venue'].indexOf(reducer) >= 0) {
//         if (typeof data[reducer].meta === 'object' && data[reducer].meta) {
//             data[reducer].meta = new Meta(data[reducer].meta)
//         }
//         _.each(data[reducer].list, (iterableValue, iterableKey) => {
//             if (typeof iterableValue === 'object' && iterableValue && iterableValue._class) {
//                 data[reducer].list[iterableKey] = prepareModel(iterableValue)
//             }
//         })
//     } else {
//         _.each(data, (value, key) => {
//             if (typeof value === 'object') {
//                 if (value && value._class) {
//                     data[key] = prepareModel(value)
//                 } else {
//                     _.each(value, (iterableValue, iterableKey) => {
//                         if (typeof iterableValue === 'object' && iterableValue && iterableValue._class) {
//                             value[iterableKey] = prepareModel(iterableValue)
//                         }
//                     })
//                 }
//             }
//         })
//     }
//     return data
// }

const loadCss = () => {
    if (!window.IS_CSS_LOADED) {
        let ss = document.styleSheets
        let cssPath = __webpack_public_path__ + 'main.' + __GIT_REVISION__ + '.css'
        for (let i = 0, max = ss.length; i < max; i++) {
            if (ss[i].href === cssPath) {
                window.IS_CSS_LOADED = true
                return
            }
        }
        let link = document.createElement("link");
        link.rel = "stylesheet"
        link.href = cssPath
        console.log('Load Css: ' + cssPath);
        document.getElementsByTagName("head")[0].appendChild(link)
        window.IS_CSS_LOADED = true
    }
}

const history = createHistory()
// Get the current location.
const location = history.location
// Listen for changes to the current location.
const unListen = history.listen((location, action) => {
    // location is an object like window.location
    let url = location.pathname + (location.search||'')
    console.log(action, url, location.state)
    if ('development' !== process.env.NODE_ENV) {
        if (typeof window.ajaxHit === 'function') window.ajaxHit(url)
    }
})

const mediaReturnUrl = getCookie('media_returnUrl')
if (mediaReturnUrl) {
    window.__RETURN_LOCATION__ = mediaReturnUrl
    console.log('__RETURN_LOCATION__', window.__RETURN_LOCATION__)
    document.cookie = 'media_returnUrl=; domain=.' + window.SITE_DOMAIN + '; path=/; secure; expires=-1'
}
const localStorageEntityKey = 'reduxStateEntity'
let initEntity = localStorage.getItem(localStorageEntityKey)
initEntity = initEntity ? JSON.parse(initEntity) : {}
let initialStore = {}
if (false && initEntity) {
    if (!initialStore.entity) {
        initialStore.entity = {
            entity: {},
            list: {},
            loading: {},
        }
    }
    if (initEntity.entity) {
        initialStore.entity.entity = initEntity.entity
    }
    if (initEntity.list) {
        initialStore.entity.list = initEntity.list
    }
}

// if (false) { // initialStore пока не нужен
//     const initialStore = window.__PRELOADED_STATE__ || {}
//     _.each(initialStore, (data, reducer) => {
//         initialStore[reducer] = prepareStore(reducer, data)
//     })
//     if (initialStore.main && initialStore.main.site && initialStore.main.locale) {
//         setGlobal(initialStore.main.site, initialStore.main.locale)
//         dayjs.locale(LANG)
//     }
//     if (initialStore.main && initialStore.main.lang_t) {
//         window.LANG_T = initialStore.main.lang_t
//     }
//     delete window.__PRELOADED_STATE__
// }

const store = createStore(reducers, initialStore, applyMiddleware(routerMiddleware(history), thunk))
store.subscribe(()=>{
    localStorage.setItem(localStorageEntityKey, JSON.stringify(store.getState().entity))
})
store.dispatch(loadApp(document.location.href, true)).then(v => {
    if ('production' === process.env.NODE_ENV) {
        // loadCss()
        setTimeout(() => {
            GoogleGtag(store.getState().main.site.gtag)
            YandexMetrika(store.getState().main.site.metrika_id, store.getState().main.site.yaParams, store.getState().auth, false)
        }, 3500)
        setTimeout(() => {
            window.LIRU_ID = store.getState().main.site.liru_id
            window.liveinternetHit(document.referrer)
            if (typeof window !== 'undefined' && store.getState().titles.vk_code) {
                (window.Image ? (new Image()) : document.createElement('img')).src = 'https://vk.com/rtrg?r=' + store.getState().titles.vk_code
            }
            if (store.getState().main.site.domain === 'gorko.ru') {
                mailRu()
                vk()
                tiktok()
            } else {
                facebookPixel(store.getState().auth)
                if (store.getState().main.site.domain === 'centerwedding.com') {
                    metaPixel()
                }
            }
        }, 5000)
    }

    if (store.getState().main.error && store.getState().main.error.type === 'redirect') {
        window.location.replace(store.getState().main.error.location)
    } else {
        ReactDOM.render(
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <Routes/>
                </ConnectedRouter>
            </Provider>,
            document.getElementById('content')
        )
    }
}).then(v => {
    if (store.getState().main.site.domain === 'centerwedding.com' || store.getState().main.site.domain === 'wedding.net') {
        // для правильных кук
        axios.get(API_URL + '/v2/interface/lang?lang=' + store.getState().main.locale.lang, {withCredentials: true})
    }
}).catch(e => {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Error50x domain={document.location.host} exception={e}/>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('content')
    )
})
