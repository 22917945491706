import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import LinkLight from "components/block/linkLight"

const LinksItem = ({link, onClick}) => {
    return <LinkLight className="catalog_links_item" to={link.url} onClick={onClick}><span
        className="catalog_links_link">{link.title}</span> {link.count}</LinkLight>
}

const LinksGroup = ({group, onClick}) => {
    if (!group) {
        return null
    }
    let links = []
    _.map(group.list, (link, i) => {
        links.push(<LinksItem key={'link' + i} link={link} onClick={onClick}/>)
    })
    return (
        <div className="catalog_links_group">
            <div className="catalog_links_title">{group.title}</div>
            <div className="catalog_links_items">{links}</div>
        </div>)
}

class BottomLinks extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !this.props.links.length
    }

    render() {
        let {links, onClick} = this.props
        if (!links || !links.length) {
            return null
        }
        let groups = []
        _.map(links, (group, i) => {
            if (group && group.list && group.list.length) {
                groups.push(<LinksGroup key={'group' + i} group={group} onClick={onClick}/>)
            }
        })
        if (groups.length) {
            return <div className="catalog_links">{groups}</div>
        } else {
            return null
        }
    }
}

BottomLinks.propTypes = {
    links: PropTypes.array,
    onClick: PropTypes.func,
}

export default BottomLinks