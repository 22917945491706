import {combineReducers} from 'redux'
import _ from 'lodash'

import media from 'reducers/media'
import viewInfo from 'reducers/viewInfo'

import {types} from 'actions/dress'

import Meta from 'models/meta'
import Dress from 'models/dress'

const initialState = {
    list: [],
    links: [],
    loading: false,
    meta: null,
    model: null,
    shopId: null,
}

function dress(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_LIST_REQUEST:
            return {
                ...state,
                list: [],
                loading: true,
            }
        case types.LOAD_MORE_REQUEST:
        case types.LOAD_MODEL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case types.LOAD_LIST_OK:
            return {
                ...state,
                list: _.map(action.list, item => new Dress(item)),
                links: action.links,
                meta: new Meta(action.meta),
                loading: false,
                shopId: action.shopId
            }
        case types.LOAD_MORE_OK:
            return {
                ...state,
                list: _.union(state.list, _.map(action.list, item => new Dress(item))),
                meta: new Meta(action.meta),
                loading: false,
                shopId: action.shopId
            }

        case types.LOAD_MODEL_OK:
            return {
                ...state,
                model: new Dress(action.model),
                loading: false
            }
        case types.LOAD_LIST_ERROR:
        case types.LOAD_MORE_ERROR:
        case types.LOAD_MODEL_ERROR:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
}

const DressReducer = combineReducers({
    dress,
    media,
    viewInfo,
})

export default DressReducer