import BaseModel from 'models/baseModel'
import User from 'models/user'

export default class ShopBranch extends BaseModel {
    constructor(data, shopData) {
        data.name = data.name || shopData.name
        data.shopId = shopData.id
        data.url = shopData.url
        data.user = shopData.user ? new User(shopData.user) : null
        super(data)
    }

    get reducerName() {
        return 'shop'
    }

    get entityType() {
        return 'shops'
    }

    get pmLink() {
        return this.user.pmLink
    }

    get link() {
        return this.url
    }
}