import React from 'react'
import PropTypes from "prop-types"
import _ from 'lodash'

import Link from 'components/block/linkLight'
import Picture from "components/block/picture"

class LazyImageContainer extends React.Component {
    componentDidMount() {
        if (!this.props.img) {
            this.props.loadEntity(this.props.id)
        }
    }

    render() {
        const img = this.props.img

        let width = this.props.width
        let height = this.props.height

        const pixelRatio = typeof window.devicePixelRatio !== 'undefined' ? window.devicePixelRatio : 1

        let src = img ? img.url : null
        if (img) {
            if (!width) {
                width = Math.round(img.width * height / img.height)
            } else if (!height) {
                height = Math.round(img.height * width / img.width)
            }
        }
        if (!width) {
            width = Math.round(height * 4 / 3)
        }

        if (img && img.isUpload) {
            let params = [
                'w' + Math.round(width * pixelRatio),
                'h' + Math.round(height * pixelRatio),
            ]
            if (this.props.isCrop) {
                params.push('n')
            }
            if (pixelRatio >= 2) {
                params.push('l80')
            } else {
                params.push('l90')
            }
            params.push('e31')
            src = src + '=' + params.join('-')
        }

        const link = this.props.link
        let $img = <Picture className={this.props.className} src={src} width={width||null} height={height||null} alt={this.props.alt||''}/>
        if (link === false) {
            if (img && img.isVideo) {
                $img = <a className={this.props.classNameLink ? this.props.classNameLink + "-video" : ""}
                          onClick={() => this.props.showVideo(img.videoLink)}>
                    {$img}
                </a>
            }
        } else {
            $img = <Link to={link} className={this.props.classNameLink ? this.props.classNameLink + (img && img.isVideo ? "-video" : "") : null}
                         target={this.props.linkTarget}>
                {$img}
            </Link>
        }

        return $img
    }
}

LazyImageContainer.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string.isRequired,
    classNameLink: PropTypes.string,
    height: PropTypes.number,
    id: PropTypes.number.isRequired,
    img: PropTypes.object,
    isCrop: PropTypes.bool.isRequired,
    isForceVisible: PropTypes.bool.isRequired,
    link: PropTypes.any.isRequired,
    linkTarget: PropTypes.string,
    loadEntity: PropTypes.func.isRequired,
    showVideo: PropTypes.func.isRequired,
    width: PropTypes.number,
}

export default LazyImageContainer
