import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_REQUEST: null,
    LOAD_OK: null,
    LOAD_ERROR: null,
}, 'viewinfo')

let cache

const saveToCache = (userId, contentType, contentId, model) => {
    if (!cache[userId]) {
        cache[userId] = {}
    }
    if (!cache[userId][contentType]) {
        cache[userId][contentType] = {}
    }
    if (!cache[userId][contentType][contentId]) {
        cache[userId][contentType][contentId] = model
    } else {
        for (let field in model) {
            cache[userId][contentType][contentId][field] = model[field]
        }
    }
    localStorage.setItem('viewInfo', JSON.stringify(cache));
    return model
}

const getFromCache = (userId, contentType, contentId) => {
    if (!cache) {
        cache = localStorage.getItem('viewInfo')
        if (cache) {
            cache = JSON.parse(cache)
        } else {
            cache = {}
        }
    }
    let dtCurrent = Math.round(new Date().getTime()/1000);
    let dtCreate = cache['dtCreate'] ? +cache['dtCreate'] : dtCurrent;
    let dtExpired = dtCreate + (86400 * 7);
    if (dtExpired < dtCurrent) {
        localStorage.removeItem('viewInfo');
        cache = {};
        dtCreate = dtCurrent;
    }
    if (dtCreate === dtCurrent) {
        cache['dtCreate'] = dtCurrent;
    }
    if (cache[userId] && cache[userId][contentType] && cache[userId][contentType][contentId]) {
        return cache[userId][contentType][contentId]
    } else {
        return false
    }
}

export const viewInfo = (contentType, contentId, param) => {
    if ('development' === process.env.NODE_ENV) console.log('viewInfo', contentType, contentId, param);
    return function (dispatch, getState) {
        let data = {
            content_type : contentType,
            content_id : contentId
        }
        if (param) {
            if ( (typeof param) === 'string') {
                data.param = param
                data.value = 1
            } else {
                for (let paramName in param) if (param.hasOwnProperty(paramName)) {
                    data.param = paramName
                    break
                }
                data.value = param[data.param]
            }
        }

        let userId = getState().auth.isAuth ? getState().auth.user.id : 'guest'
        let result = getFromCache(userId, contentType, contentId)

        if (result && (!param || result[data.param] == data.value)) {
            dispatch({
                model: result,
                type: types.LOAD_OK,
            })
            return new Promise((resolve) => {
                resolve(getState) // getState нужен, чтобы отрендерить контакты в попап
            })
        } else {
            dispatch({
                type: types.LOAD_REQUEST
            })

            return axios.post(API_URL + '/v2/viewInfos/', JSON.stringify(data), {withCredentials : true})
                .then(response => {
                    dispatch({
                        model: saveToCache(userId, contentType, contentId, response.data.view_info),
                        type: types.LOAD_OK,
                    })
                    return new Promise((resolve) => {
                        resolve(getState) // getState нужен, чтобы отрендерить контакты в попап
                    })
                })
                .catch((e) => {
                    if ('development' === process.env.NODE_ENV) console.log(e)
                    dispatch({
                        type: types.LOAD_ERROR
                    })
                })
        }
    }
}

export const logClick = (contactId) => {
    if ('development' === process.env.NODE_ENV) console.log('viewInfos/click', contactId);
    return function (dispatch, getState) {
        let data = {
            contact_id : contactId
        }

        return axios.post(API_URL + '/v2/viewInfos/click', JSON.stringify(data), {withCredentials : true})
            .then(response => {
                if (response.data.goal) reachGoal(response.data.goal)
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
            })
    }
}
