import React from 'react'
import PropTypes from 'prop-types'

const Popup = (props) => {
    if (!props.component) return null
    return (
        <div className={'popup' + (props.show ? ' _open' : '')}>
            {props.title !== null ?
                <div className="popup_header">
                    {props.isBackButton ? <a className="popup_back" onClick={props.closePopup}/> : null}
                    <span className="popup_title"> {props.title} </span>
                    {props.isBackButton ? null : <a className="popup_close" onClick={props.closePopup}/>}
                </div>
            : null}
            <div className="popup_content" id="popup" ref={(element) => {
                if (element) {
                    if (props.returnPopupScrollTop) {
                        element.scrollTo(0, props.returnPopupScrollTop)
                        console.debug('popup scroll to ' + props.returnPopupScrollTop);
                    } else {
                        console.debug('popup scroll to top');
                        element.scrollTo(0, 0)
                    }
                }
            }}>
                {props.component}
            </div>
        </div>)
}

Popup.propTypes = {
    closeLink: PropTypes.string,
    component: PropTypes.element,
    returnPopupScrollTop: PropTypes.number,
    title: PropTypes.string,
    show: PropTypes.bool,
}

export default Popup