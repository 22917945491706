import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import Auth from 'components/auth/auth'

import {auth, getMethods, register} from 'actions/auth'
import {close} from "actions/popup"

class AuthContainer extends React.Component {
    componentDidMount() {
        this.props.getMethods()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.need_register && this.props.current_social_key) {
            this.props.close()
        }
    }

    render() {
        return <Auth {...this.props}/>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        current_social_key: state.auth.current_social_key,
        methods: state.auth.methods,
        need_register: state.auth.need_register,
        social_data: state.auth.social_data,
    }
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        getMethods: () => dispatch(getMethods()),
        onClick: (method) => dispatch(auth(method)),
        register: () => dispatch(register()),
        close: () => dispatch(close()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthContainer))
