import React from 'react'
import {connect} from 'react-redux'
import cx from 'classnames'
import t from 't'

import {check} from 'actions/phoneEdit'
import {show as showCountries} from 'actions/phoneCountries'

class PhoneEditContainer extends React.Component {
    constructor(props) {
        super(props)
        let phoneCode = props.country.phoneCode || props.country.phone_code || ''
        let phone = props.phone || ''
        if (phone) {
            if ((typeof props.phone).toLowerCase() == 'number') {
                phone = '+' + phone
            }
            phone = phone.replace(phoneCode, '')
        }
        this.state = {
            timerId : 0,
            phone   : phone,
            country : props.country,
            focus   : false,
        }
    }

    checkPhone(phone, force, country) {
        let state = this.state
        let props = this.props

        if (state.phone == phone && !force) {
            return
        }
        if (country === undefined) {
            country = state.country
        }
        state.phone = phone
        if (state.timerId) {
            clearTimeout(state.timerId)
        }
        if (state.phone !== '') {
            state.timerId = setTimeout(() => {
                props.check(country.iso, state.phone).then((result) => {
                    if (props.onCheck) {
                        props.onCheck(result.ok, result.error)
                    }
                })
            }, 500)
        }
        if (props.onChange) {
            let phoneCode = state.country.phoneCode || state.country.phone_code || ''
            props.onChange(phoneCode + state.phone, state.country)
        }
        this.setState({
            phone   : state.phone,
            timerId : state.timerId,
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.phone != this.props.phone) {
            this.setState({phone:prevProps.phone})
        }
        if (prevState.country.id != this.state.country.id) {
            this.checkPhone(this.state.phone, true)
        }
    }

    componentDidMount() {
        if (this.state.phone) {
            this.checkPhone(this.state.phone, true)
        }
    }

    selectCountry(country) {
        this.setState({
            country: country,
        })
        this.checkPhone(this.state.phone, true, country)
    }

    setFocus(focus) {
        this.setState({focus : focus})
    }

    render() {
        let self = this
        let state = this.state
        let props = this.props

        let classname = {
            'phoneEdit' : true,
        }
        if (props.className) {
            classname[props.className] = true
        }
        if (state.focus) {
            classname._focus = true
        }
        let phoneCode = state.country.phoneCode || state.country.phone_code || null
        let placeholder = props.placeholder ?? t('Телефон') + ' *'
        return (
            <div className={cx(classname)}>
                <a className={"flag16x11-" + state.country.iso + " phoneEdit_country"} onClick={() => props.showCountries(state.country, self.selectCountry.bind(self))}>{state.country.name}</a>
                <div className="phoneEdit_field">
                    <span className="phoneEdit_code">{phoneCode}</span>
                    <input type="text" className="phoneEdit_number" defaultValue={state.phone} onChange={(ev) => self.checkPhone(ev.target.value)} placeholder={placeholder}/>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        country: ownProps?.country ?? state.main.city.country,
    }
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        check: (iso, phone) => dispatch(check(iso, phone)),
        showCountries : (currentCountry, callback) => {
            dispatch(showCountries(currentCountry, callback))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneEditContainer)
