import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

const Error40x = (props) => {
    const numbers = [
        Math.floor(props.number / 100),
        Math.floor(props.number / 10 % 10),
        Math.floor(props.number % 10),
    ]
    const text = {
        404 : {
            name : t('Страница не найдена'),
            text : t('Страница была удалена или не существовала вовсе'),
        },
    }
    return (
        <div className="pError-40x">
            <div className="pError_number">
                {numbers[0]}
                {numbers[1] == 0 ?
                    <span className="pError_car">0</span>
                : numbers[1]}
                {numbers[2]}
            </div>
            <p className="pError_name">{text[props.number].name}</p>
            <div className="pError_text">{text[props.number].text}</div>
            <a className="button" href="/">{t('Вернуться на главную')}</a>
            <div className="pError_wheel"></div>
        </div>
    )
};

Error40x.propTypes = {
    number: PropTypes.number,
}

export default Error40x

