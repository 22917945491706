import React from 'react'
import {Route, Redirect} from 'react-router'

import _ from 'lodash'
// import DirectoryList from 'containers/directoryList'

import * as carActions from 'actions/car'
import * as dressActions from 'actions/dress'
import * as shopActions from 'actions/shop'
import * as placeActions from 'actions/place'

const CarPageContainer = React.lazy(() => import('containers/carPageContainer'))
const CarRentalContainer = React.lazy(() => import('containers/carRental'))
const CarRentalListContainer = React.lazy(() => import('containers/carRentalListContainer'))
const DirectoryList = React.lazy(() => import('containers/directoryList'))
const DressContainer = React.lazy(() => import('containers/dress'))
const LoftPageContainer = React.lazy(() => import('containers/loftPageContainer'))
const PlaceContainer = React.lazy(() => import('containers/place'))
const ShopContainer = React.lazy(() => import('containers/shop'))
const UserPageContainer = React.lazy(() => import('containers/userPageContainer'))
const VendorListContainer = React.lazy(() => import('containers/vendorListContainer'))
const VenueSlugProxyContainer = React.lazy(() => import('containers/venueSlugProxyContainer'))
const VenueListContainer = React.lazy(() => import('containers/venueListContainer'))
const VenuePageContainer = React.lazy(() => import('containers/venuePageContainer'))

// const CarList = React.lazy(() => import('components/car/list'))
// const DressList = React.lazy(() => import('components/dress/list'))
// const PlaceList = React.lazy(() => import('components/place/list'))
// const ShopList = React.lazy(() => import('components/shop/list'))

import CarList from 'components/car/list'
import DressList from 'components/dress/list'
import PlaceList from 'components/place/list'
import ShopList from 'components/shop/list'
import Application from "containers/application"

const directoryRoutes = (directory, langUrl) => _.map(directory, directory => {
    let actions
    let reducerName
    let Container = DirectoryList
    let ModelContainer
    let ListComponent
    let FriendlyUrlContainer = null
    switch (directory.type) {
        case 'cars':
            ListComponent = CarList
            ModelContainer = CarPageContainer
            actions = carActions
            reducerName = 'car'
            break
        case 'dress':
            ListComponent = DressList
            ModelContainer = DressContainer
            actions = dressActions
            reducerName = 'dress'
            break
        case 'places':
            ListComponent = PlaceList
            ModelContainer = PlaceContainer
            actions = placeActions
            reducerName = 'place'
            break
        case 'shops':
            ListComponent = ShopList
            ModelContainer = ShopContainer
            actions = shopActions
            reducerName = 'shop'
            break
        case 'users':
            ListComponent = null
            ModelContainer = UserPageContainer
            actions = null
            reducerName = 'user'
            Container = VendorListContainer
            if (directory.model.key === 'car') {
                Container = CarRentalListContainer
                ModelContainer = CarRentalContainer
            }
            break
        case 'restaurants':
            ListComponent = null
            if (directory.model.key === 'loft') {
                ModelContainer = LoftPageContainer
            } else {
                ModelContainer = VenuePageContainer
            }
            reducerName = 'restaurant'
            Container = VenueListContainer
            FriendlyUrlContainer = VenueSlugProxyContainer
            break
        case 'venues':
            ListComponent = null
            ModelContainer = VenuePageContainer
            reducerName = 'venue'
            Container = VenueListContainer
            FriendlyUrlContainer = VenueSlugProxyContainer
            break
        default:
            ListComponent = VendorListContainer
            ModelContainer = UserPageContainer
            actions = null
            reducerName = 'user'
            break
    }

    return [
        <Application path={directory.path} exact={true} directory={directory}>
            <Route path={directory.path} exact={true} key={directory.key} render={(props) => (
                <Container component={ListComponent} actions={actions} directory={directory}
                           reducerName={reducerName} queryString={props.location.search.replace('?', '')}/>
            )}/>
        </Application>,
        <Application path={directory.path + ':friendlyUrl(\\D[^\\/\\"\\\'\\;\\:]+)/'} exact={true}
                     directory={directory}>
            <Route path={directory.path + ':friendlyUrl(\\D[^\\/\\"\\\'\\;\\:]+)/'} exact={true}
                   key={directory.key} render={(props) => (
                FriendlyUrlContainer ? <FriendlyUrlContainer directory={directory} reducerName={reducerName}
                                                             queryString={props.location.search.replace('?', '')}
                                                             slug={props.match.params.friendlyUrl.toLowerCase()}/> :
                    <Container component={ListComponent} actions={actions} directory={directory}
                               reducerName={reducerName} queryString={props.location.search.replace('?', '')}/>
            )}/>
        </Application>,
        <Application path={directory.path + ':id/favorites/'}>
            <Route path={directory.path + ':id/favorites/'} render={(props) => (
                <Redirect to={directory.path + props.match.params.id + '/'}/>
            )}/>
        </Application>,
        directory.type === 'shops' && directory.model.key === 'dress'
            ? <Application path={directory.path.replace(/(.*\/)[^\/]*\/$/, `$1${directory.model.url}`) + '/:id/'} key={directory.key + '_model'}>
                <Route path={directory.path.replace(/(.*\/)[^\/]*\/$/, `$1${directory.model.url}`) + '/:id/'} key={directory.key + '_model'}
                       render={(props) => <ModelContainer directory={directory} reducerName={reducerName}
                                                          id={props.match.params.id}/>}/>
            </Application>
            : <Application path={directory.path + ':id(\\d+)/'} key={directory.key + '_model'}>
                <Route path={directory.path + ':id(\\d+)/'} key={directory.key + '_model'}
                       render={(props) => <ModelContainer directory={directory} reducerName={reducerName}
                                                          id={props.match.params.id}/>}/>
            </Application>,
    ]
})

export default directoryRoutes