import {types} from 'actions/mywed'

import Meta from 'models/meta'

const initialState = {
    list: [],
    loading: true,
    meta: null,
};

const MyWedReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_OK:
            return {
                ...state,
                list: action.list,
                loading: false,
                meta: new Meta(action.meta),
            };
        case types.LOAD_REQUEST:
            return {
                ...state,
                list: [],
                loading: true,
                meta: null,
            };
        case types.LOAD_ERROR:
            return {
                ...state,
                list: [],
                loading: false,
                meta: null,
            };

        default:
            return state
    }
}
export default MyWedReducer