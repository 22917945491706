import {combineReducers} from 'redux'

import album from 'reducers/album'
import viewInfo from 'reducers/viewInfo'
import review from 'reducers/review'

const initialState = {
}


function restaurant(state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}

const RestaurantReducer = combineReducers({
    restaurant,
    album,
    review,
    viewInfo
})

export default RestaurantReducer