import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    OPEN: null,
    CLOSE: null,
    SET_RETURN_LOCATION: null,
}, 'popup')

// returnLocation может быть false - это означает, что он придет в будущем и пока не нужно обрабатывать закрытие попапа
export const putComponentToPopup = (title, component, key = null, returnLocation = null, isBackButton = false) => {
    return {
        type: types.OPEN,
        component: component,
        key: key,
        title: title,
        returnLocation: returnLocation,
        isBackButton: isBackButton,
    }
}

export const setReturnLocation = (key, returnLocation) => {
    return {
        type: types.SET_RETURN_LOCATION,
        key: key,
        returnLocation: returnLocation,
    }
}

export const close = () => {
    return {
        type: types.CLOSE
    }
}