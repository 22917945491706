import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,
    LOAD_MORE_REQUEST: null,
    LOAD_MORE_OK: null,
    LOAD_MORE_ERROR: null,

    LOAD_MODEL_REQUEST: null,
    LOAD_MODEL_OK: null,
    LOAD_MODEL_ERROR: null,

    // LOAD_ROOM_LIST_REQUEST: null,
    // LOAD_ROOM_LIST_OK: null,
    // LOAD_ROOM_LIST_ERROR: null,

    LOAD_MARKERS_REQUEST: null,
    LOAD_MARKERS_OK: null,
    LOAD_MARKERS_ERROR: null,
}, 'shop')

const MEDIA_LIMIT = 10
const PER_PAGE = 20

const getModelUrl = (id) => {
    return API_URL + '/v2/shops/' + id
}

// const getRoomsUrl = (id) => {
//     return API_URL + '/v2/restaurants/' + id + '/rooms'
// }

const getUrl = (cityId, directory, filters, page, per_page) => {
    return directory.apiUrl +
        "&fields=media,dresses" +
        "&set_city=" + cityId +
        "&embed=branches" +
        "&media_limit=" + MEDIA_LIMIT +
        "&preview_size=0x200x0,266x200x1" +
        "&page=" + page +
        "&per_page=" + per_page +
        "&seed=" + SEED +
        '&' + filters
}

export const loadData = (directory, filters = '', page=1, per_page=null) => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_LIST_REQUEST
        })
        if (!per_page) {
            per_page = PER_PAGE
        }
        return axios.get(getUrl(getState().main.city.id, directory, filters, page, per_page), {withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.shops,
                    meta: response.data.meta,
                    type: types.LOAD_LIST_OK,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_LIST_ERROR
                })
            })
    }
}

export const loadMoreData = (directory, filters = '') => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_MORE_REQUEST
        })

        const page = getState().shop.shop.meta.nextPage

        return axios.get(getUrl(getState().main.city.id, directory, filters, page, PER_PAGE), {withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.shops,
                    meta: response.data.meta,
                    type: types.LOAD_MORE_OK,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_MORE_ERROR
                })
            })
    }
}

export const loadModel = (id, params = null) => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_MODEL_REQUEST
        })

        params.set_city = getState().main.city.id

        axios.get(getModelUrl(id), {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    model: response.data.shop,
                    type: types.LOAD_MODEL_OK,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_MODEL_ERROR
                })
            })
    }
}

export const loadMarkers = (directory, filters = '') => {
    return function (dispatch, getState) {
        dispatch({
            type: types.LOAD_MARKERS_REQUEST
        });
        const cityId = getState().main.city.id
            axios.get(directory.apiUrl + "&set_city=" + cityId + "&embed=all_branches&page=1&per_page=1000&" + filters)
            .then(response => {
                dispatch({
                    type: types.LOAD_MARKERS_OK,
                    list: response.data.shops,
                    cityId: cityId,
                })
            })
            .catch(() => {
                dispatch({
                    type: types.LOAD_MARKERS_ERROR
                })
            })
    }
};