import t from 't'

import BaseModel from 'models/baseModel'
import Car from 'models/car'
import Restaurant from 'models/restaurant'
import Shop from 'models/shop'
import User from 'models/user'
import Place from 'models/place'
import Venue from 'models/venue'

export default class Album extends BaseModel {
    constructor(data) {
        if (data.belong_to) {
            switch (data.belong_to.class) {
                case 'User':
                    data.belong_to.model = new User(data.belong_to.model)
                    data.belong_to.type = 'users'
                    break
                case 'Car':
                    data.belong_to.model = new Car(data.belong_to.model)
                    data.belong_to.type = 'cars'
                    break
                case 'Place':
                    data.belong_to.model = new Place(data.belong_to.model)
                    data.belong_to.type = 'places'
                    data.url = data.belong_to.model.url
                    break
                case 'Restaurant':
                    data.belong_to.model = new Restaurant(data.belong_to.model)
                    data.belong_to.type = 'restaurants'
                    break
                case 'Shop':
                    data.belong_to.model = new Shop(data.belong_to.model)
                    data.belong_to.type = 'shops'
                    break
                case 'Venue':
                    data.belong_to.model = new Venue(data.belong_to.model)
                    data.belong_to.type = 'venues'
                    break
            }
        }
        super(data)
    }

    get modelName() {
        return 'Album'
    }

    get categoryTitle() {
        switch (this.category.name) {
            case 'wedding':
                return t('Свадебный отчёт')
            default:
                return null
        }
    }

    get countLinkedAuthors() {
        if (this.linked_authors)
            return this.linked_authors.length
    }

    get countLinkedDoers() {
        if (this.linked_doers)
            return this.linked_doers.length
    }

    get countLinkedRestaurants() {
        if (this.linked_restaurants)
            return this.linked_restaurants.length
    }

    get countLinkedVenues() {
        if (this.linked_venues)
            return this.linked_venues.length
    }

    get countLinkedCars() {
        if (this.linked_cars)
            return this.linked_cars.length
    }

    get countLinkedPlaces() {
        if (this.linked_places)
            return this.linked_places.length
    }

    get link() {
        return this.url
    }

    get isHideUserInfo() {
        if (this.belong_to) {
            return ['Restaurant', 'Venue', 'Place', 'Shop'].indexOf(this.belong_to.class)>-1
        }
        return false
    }
}