import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

const PopupLight = (props) => {
    if (!props.component) return null
    return (
        <div className="popupLight">
            <div className="popupLight_scene">
                {props.component}
                <a className="button-border button-centered popupLight_close" onClick={props.close}>{t('Закрыть')}</a>
            </div>
        </div>)
}

PopupLight.propTypes = {
    show: PropTypes.bool,
    component: PropTypes.element,

    close: PropTypes.func,
}

export default PopupLight