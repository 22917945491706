import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    TOGGLE_ACTIVE: null,
}, 'search')

export const searchActivate = () => {
    return function (dispatch) {
        dispatch({
            type: types.TOGGLE_ACTIVE,
            active: true,
        })
    }
}

export const searchDeactivate = () => {
    return function (dispatch) {
        dispatch({
            type: types.TOGGLE_ACTIVE,
            active: false,
        })
    }
}

