import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_LIST_REQUEST: null,
    LOAD_LIST_OK: null,
    LOAD_LIST_ERROR: null,

    LOAD_ENTRY_REQUEST: null,
    LOAD_ENTRY_OK: null,
    LOAD_ENTRY_ERROR: null,
}, 'blog')


export const loadEntry = (entryId) => {
    return function(dispatch, getState) {
        dispatch({
            type: types.LOAD_ENTRY_REQUEST
        })

        axios.get(API_URL + '/v2/blog/' + entryId, {withCredentials: true})
            .then(response => {
                dispatch({
                    entry: response.data.blog,
                    type: types.LOAD_ENTRY_OK,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_ENTRY_ERROR
                })
            })
    }
}

export const loadList = () => {
    return function(dispatch, getState) {
        dispatch({
            type: types.LOAD_LIST_REQUEST
        })

        axios.get(API_URL + '/v2/blog/', {withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.blogs,
                    type: types.LOAD_LIST_OK,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_LIST_ERROR
                })
            })
    }
}
