import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import Language from 'components/language/list'

import {loadData} from 'actions/language'

class LanguageContainer extends React.Component {
    componentDidMount() {
        this.props.loadData()
    }

    render() {
        return <Language {...this.props}/>
    }
}

function mapStateToProps(state, ownProps) {
    return {
        languages: state.language.languages,
        sites: state.language.sites,
    }
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        loadData: () => dispatch(loadData()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageContainer))
