import BaseModel from 'models/baseModel'

export default class Meta extends BaseModel {
    get countPages() { // скорее всего не используется и заменена на totalPages
        return this._data.pages_count
    }

    get totalPages() {
        return this._data.pages_count
    }

    get isLastPage() {
        return this.page === this.countPages || this.countPages === 0
    }

    get nextPage() {
        return this.page + 1
    }

    get totalCount() {
        return this.total_count
    }
}