import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_REQUEST: null,
    LOAD_OK: null,
    LOAD_ERROR: null,
}, 'mediaFeed')


export const loadFeed = (mediaType, feedType) => {
    return function (dispatch, getState) {
        const state = getState().mediaFeed[mediaType][feedType]
        if (state.loading || (state.meta && state.meta.isLastPage)) {
            return false
        }
        let type = {
            REQUEST: types.LOAD_REQUEST,
            OK: types.LOAD_OK,
            ERROR: types.LOAD_ERROR,
        }

        let size
        let embed = ['user']
        if (feedType === 'best') {
            size = '145x145x1,460x310x1'
            embed.push('mediaFeed')
        } else {
            size = '300x0x0'
        }
        dispatch({
            type: type.REQUEST,
            mediaType: mediaType,
            feedType: feedType,
        })

        let page = state.meta ? state.meta.nextPage : 1
        let perPage = 20
        let offset = 0
        if (feedType === 'best') {
            if (page === 1) {
                perPage = 15
            } else {
                perPage = 12
                offset = 3
            }
        }
        let params = {
            embed: embed.join(','),
            feed_type: feedType,
            fields: 'url',
            media_type: mediaType,
            offset: offset,
            page: page,
            per_page: perPage,
            preview_size: size,
        }

        axios.get(API_URL + '/v2/mediaFeed/', {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    list: response.data.media,
                    meta: response.data.meta,
                    type: type.OK,
                    mediaType: mediaType,
                    feedType: feedType,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: type.ERROR,
                    mediaType: mediaType,
                    feedType: feedType,
                })
            })
    }
}