import _ from 'lodash'
import BaseModel from 'models/baseModel'

import Media from 'models/media'

export default class Room extends BaseModel {
    constructor(data) {
        if (data.media) {
            data.media = _.map(data.media, (media) => new Media(media))
        }
        super(data)
    }

    get sceneId() {
        return this.scenes ? JSON.parse(this.scenes)[0] : ''
    }
}