import axios from 'axios'
import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_COMMUNITIES_REQUEST: null,
    LOAD_COMMUNITIES_OK: null,
    LOAD_COMMUNITIES_ERROR: null,

    LOAD_TOPICS_REQUEST: null,
    LOAD_TOPICS_OK: null,
    LOAD_TOPICS_ERROR: null,
    RESET_TOPICS_LIST: null,
    TOGGLE_TOPIC_TOOLTIP: null,
}, 'forum')

const TOPICS_PER_PAGE = 10

export const loadCommunities = () => {
    return function(dispatch, getState) {
        dispatch({
            type: types.LOAD_COMMUNITIES_REQUEST
        })

        axios.get(API_URL + '/v2/communities', {params: {per_page: 100}, withCredentials: true})
            .then(response => {
                dispatch({
                    communities: response.data.communities,
                    moderators: response.data.moderators,
                    groups: response.data.groups,
                    type: types.LOAD_COMMUNITIES_OK,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_COMMUNITIES_ERROR
                })
            })
    }
}

export const followCommunity = (slug) => { // для дальней отметки новых тем
    return function(dispatch, getState) {
        axios.post(API_URL + '/v2/communities/' + slug + '/follow', {}, {withCredentials: true})
            .then(response => {
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
            })
    }
}

export const resetTopicsList = () => {
    return function(dispatch, getState) {
        dispatch({
            type: types.RESET_TOPICS_LIST
        })
    }
}

export const toggleTopicTooltip = (topicId) => {
    return function(dispatch, getState) {
        dispatch({
            type: types.TOGGLE_TOPIC_TOOLTIP,
            topicId: topicId,
        })
    }
}

export const loadTopics = (slug, params = {}) => {
    return function(dispatch, getState) {
        dispatch({
            type: types.LOAD_TOPICS_REQUEST
        })

        if (!params.per_page) {
            params.per_page = TOPICS_PER_PAGE
        }

        if (getState().forum.topicsMeta) {
            params.page = getState().forum.topicsMeta.nextPage
        }

        axios.get(API_URL + '/v2/communities/' + slug + '/topics', {params: params, withCredentials: true})
            .then(response => {
                dispatch({
                    langUrlNotEmpty: getState().main.locale.url_not_empty,
                    meta: response.data.meta,
                    topics: response.data.topics,
                    type: types.LOAD_TOPICS_OK,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_TOPICS_ERROR
                })
            })
    }
}
