import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import t from 't'

import {close} from 'actions/popup'
import {authBySms, authStart, sendPhoneForSms, sendSmsCode} from 'actions/auth'

import PhoneEdit from 'containers/block/phoneEdit'

let awaitTimer = 0
const WAIT_UNTIL_CODE = 60
const Auth = (props) => {
    if (!props.methods) return null

    const [phone, setPhone] = useState(null)
    const [iso, setIso] = useState(null)
    const [phoneError, setPhoneError] = useState(null)
    const [code, setCode] = useState(null)
    const [codeError, setCodeError] = useState(null)
    const [codeAwait, setCodeAwait] = useState(0)
    const [phoneState, setPhoneState] = useState('empty')

    const onCheckPhone = (isOk, error) => {
        if (isOk) {
            setPhoneError(false)
        } else {
            setPhoneError(error)
        }
    }
    const onPhoneChange = (value, country) => {
        setPhone(value)
        setIso(country.iso)
        setPhoneError(null)
    }
    const tickAwaitCode = () => {
        setCodeAwait(seconds => {
            if (seconds > 0) {
                return seconds - 1
            } else {
                clearInterval(awaitTimer)
                awaitTimer = 0
                return seconds
            }
        })
    }
    const changePhoneState = (state) => {
        if (state === 'code') {
            sendPhoneForSms(phone, iso, props.hash)
            .then((data) => {
                reachGoal('authorization_request')
                setPhoneState(state)
                const $code = document.querySelector('[field="code"]')
                $code.value = ''
                $code.focus()
                setCode(null)
                setCodeError(data.error)
                setCodeAwait(data.interval || WAIT_UNTIL_CODE)
            })
        } else {
            clearInterval(awaitTimer)
            awaitTimer = 0
            setPhoneState(state)
        }
    }
    const sendCode = () => {
        if (!code) {
            setCodeError(true)
            return
        }
        sendSmsCode(phone, iso, code)
        .then(data => {
            if (data.error) {
                setCodeError(data.error)
            } else {
                reachGoal('authorization_phone')
                props.authBySms(data, phone)
            }
        })
    }
    const changeCode = (code) => {
        setCodeError(!code)
        setCode(code)
    }
    const authStart = () => {
        changePhoneState('empty')
        props.authStart()
    }

    useEffect(() => {
        if (codeAwait == WAIT_UNTIL_CODE && !awaitTimer) {
            awaitTimer = setInterval(tickAwaitCode, 1000)
        }
    }, [codeAwait])

    let $socials = props.methods.map(social => <a key={social.key} onClick={() => props.onClick(social)}
                                                 className={'authPopup_social-' + social.key + (props.current_social_key && props.current_social_key !== social.key ? ' _disabled' : '')} social={social.name}/>)
    const text = {
        anotherWay    : t('Выбрать другой способ входа'),
        awaitCode     : t('Получить код повторно можно через {seconds} сек.', {'{seconds}' : '<b>' + codeAwait + '</b>'}),
        caption       : t('Вход на {site_name}'),
        changeNumber  : t('Исправить номер'),
        code          : t('Код из смс'),
        codeRefresh   : t('Получить код повторно'),
        continue      : t('Продолжить'),
        createAccount : t('Создать аккаунт'),
        enter         : t('Войти'),
        mailError     : t('Вы еще не входили с помощью этого аккаунта mail.ru, регистрация нового аккаунта через mail.ru невозможна'),
        newAccount    : t('Вы еще не входили с помощью аккаунта'),
        newAccountPhone: t('Вы еще не входили с помощью телефона'),
        phoneEnter    : t('или войдите по номеру телефона'),
        phoneNumber   : t('Номер телефона'),
        sendedTo      : t('Отправили код на {phone}', {'{phone}' : '<span class="authPopup_sent_phone">' + phone + '</span>'}),
        socialEnter   : t('Войдите, используя свой аккаунт в социальной сети.'),
    }

    if (props.need_register) {
        return (<div className="authPopup">
                <a className="popup_close" onClick={props.close}/>
                <div className="authPopup_register">
                    <div className={'authPopup_social-' + props.current_social_key}/>
                    {props.current_social_key === 'ml' ?
                        <p className="authPopup_text">{text.mailError}</p>
                    :
                        <Fragment>
                            <p className="authPopup_text">{props.current_social_key === 'phone' ? text.newAccountPhone : text.newAccount} <span className="authPopup_user">{props.social_data.name}</span></p>
                            <a className="button-big" onClick={props.register}>{text.createAccount}</a>
                        </Fragment>
                    }
                </div>
                <div className="authPopup_block">
                    <a className="authPopup_return" onClick={() => authStart()}>{text.anotherWay}</a>
                </div>
            </div>)
    } else {
        return (
            <div className="authPopup">
                <a className="popup_close" onClick={props.close}/>
                <h2 className="authPopup_caption">{text.caption}</h2>
                {phoneState !== 'code' ?
                    <Fragment>
                        <p className="authPopup_text">{text.socialEnter}</p>
                        <div className="authPopup_socials">{$socials}</div>
                        {props.isPhoneAuth ?
                            <div className="authPopup_block">
                                <p className="authPopup_text">{text.phoneEnter}</p>
                                <PhoneEdit className={phoneError ? ' _error' : null} onChange={(phone, iso) => onPhoneChange(phone, iso)} onCheck={(isOk, error) => onCheckPhone(isOk, error)} phone={phone} placeholder={text.phoneNumber}/>
                                {phoneError ? <div className="authPopup_error">{phoneError}</div> : null}
                                {phoneError === false ? <a className="button-big" onClick={() => changePhoneState('code')}>{text.continue}</a> : null}
                            </div>
                        : null}
                    </Fragment>
                :
                    <Fragment>
                        <p className="authPopup_sent_text" dangerouslySetInnerHTML={{__html: text.sendedTo}}/>
                        <p className="authPopup_change"><a onClick={() => changePhoneState('empty')}>{text.changeNumber}</a></p>
                        <div className={"field authPopup_code" + (codeError ? ' _error' : '')}>
                            <input className={"field_value" + (code ? ' _filled' : '')} onInput={(ev) => changeCode(ev.target.value)} field="code" autoComplete="one-time-code"/>
                            <span className="field_placeholder">{text.code}</span>
                        </div>
                        {codeError?.length ? <div className="authPopup_error">{codeError}</div> : null}
                        <a className="button-big" onClick={() => sendCode()}>{text.enter}</a>
                        {codeAwait > 0 ?
                            <p className="authPopup_await" dangerouslySetInnerHTML={{__html: text.awaitCode}}/>
                        :
                            <p className="authPopup_refresh"><a onClick={() => changePhoneState('code')}>{text.codeRefresh}</a></p>
                        }
                        <div className="authPopup_block">
                            <a className="authPopup_return" onClick={() => changePhoneState('empty')}>{text.anotherWay}</a>
                        </div>
                    </Fragment>
                }
            </div>)
    }
}

Auth.propTypes = {
    methods: PropTypes.array.isRequired,
    need_register: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    current_social_key: PropTypes.string,
    social_data: PropTypes.object,
}

function mapStateToProps(state, ownProps) {
    return {
        isPhoneAuth : state?.main?.site?.is_phone_auth,
        hash: state.main.hash,
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        close: () => dispatch(close()),
        authBySms: (data, phone) => dispatch(authBySms(data, phone)),
        authStart: () => dispatch(authStart()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
