import React from 'react'
import PropTypes from 'prop-types'

import dayjs from 'dayjs'

export const DateButton = ({filter, showCalendar}) => {
    let text = filter.name
    let classname = ['filter']
    if (filter.selected.length) {
        const curYear = (new Date()).getFullYear()
        const selected = dayjs(filter.selected, 'YYYY-MM-DD')
        const year = selected.format('YYYY')
        if (curYear != year) {
            text = selected.format('D MMMM, YYYY')
        } else {
            text = selected.format('D MMMM')
        }
        classname.push('_active')
    }

    const onClick = (ev) => {
        showCalendar(filter.selected.length ? filter.selected[0] : null)
        reachGoal('microgoal_date', null, ['ya'], 'gorko.ru')
    }

    return <a className={classname.join(' ')} onClick={onClick}>{text}</a>
}

DateButton.propTypes = {
    filter: PropTypes.object.isRequired,
    showCalendar: PropTypes.func.isRequired,
}

export default DateButton