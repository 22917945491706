import axios from 'axios'
import _ from 'lodash'
import t from 't'

import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    LOAD_FILTERS_REQUEST: null,
    LOAD_FILTERS_OK: null,
    LOAD_FILTERS_ERROR: null,

    RESET_FILTERS: null,

    LOAD_SUGGEST_REQUEST: null,
    LOAD_SUGGEST_OK: null,
    LOAD_SUGGEST_ERROR: null,

    DATE_CHANGE: null,

    TOGGLE_SHOW: null,
    CHANGE: null,
    APPLY: null,
    RESET: null,

    SET_QUERYSTRING: null,

    SET_FILTERS: null,

    CHECK_OVERLAY: null,

    EXPAND: null
}, 'filters')


let scrollY = 0
export const filterOpened = () => {
    return function (dispatch, getState) {
        let show = !!getState().filters.opened
        let $html = document.html || document.getElementsByTagName('html')[0]
        let $body = document.body || document.getElementsByTagName('body')[0]
        if (show) {
            scrollY = window.scrollY
            $html.classList.add('filter_open')
            $body.classList.add('filter_open')
            window.scrollTo(0, scrollY)
        } else {
            $html.classList.remove('filter_open')
            $body.classList.remove('filter_open')
            window.scrollTo(0, scrollY)
        }
    }
}

export const loadWeddingFilters = (queryString) => {
    return function (dispatch, getState) {
        const CITIES_PER_PAGE = 10

        let cityId = null
        _.each(queryString.replace('?', '').split('&'), (qItem) => {
            if (qItem) {
                const splited = qItem.split('=')
                if (splited[0] === 'city_id') {
                    cityId = splited[1]
                }
            }
        })
        new Promise(resolve => {
            let city = getState().main.city
            if (cityId) {
                resolve(
                    axios.get(API_URL + '/v2/cities/' + cityId, {withCredentials: true})
                        .then(response => response.data.city)
                )
            }
            resolve(city)
        }).then(city => {
            const initUrl = API_URL + '/v2/cities?per_page=' + CITIES_PER_PAGE + '&fields=url&-id=' + city.id + '&region_id=' + city.region_id

            dispatch({
                type: types.LOAD_FILTERS_REQUEST
            })

            axios.get(initUrl, {withCredentials: true})
                .then(response => {
                    let cities = [{key: -1, text: t('Все города')}, {key: city.id, text: city.name}]
                    _.each(response.data.cities, (city) => cities.push({key: city.id, text: city.name}))
                    let filters = [
                        {
                            name: t("Все города"),
                            type: "suggest",
                            label: "city_id",
                            items: cities,
                            placeholder: t("Поиск по городам"),
                            onSuggest: (text) => {
                                dispatch({
                                    type: types.LOAD_SUGGEST_REQUEST,
                                    label: "city_id",
                                })

                                axios.get(API_URL + '/v2/cities?name=' + text + '&per_page=' + CITIES_PER_PAGE,
                                    {withCredentials: true}
                                )
                                    .then(response => {
                                        let result = [{key: -1, text: t('Все города')}]
                                        for (let city of response.data.cities) {
                                            result.push({
                                                key: city.id,
                                                text: city.name,
                                            })
                                        }
                                        dispatch({
                                            label: "city_id",
                                            type: types.LOAD_SUGGEST_OK,
                                            result: result,
                                            queryString: queryString || '',
                                        })
                                    })
                                    .catch((e) => {
                                        if ('development' === process.env.NODE_ENV) console.log(e)
                                        dispatch({
                                            type: types.LOAD_SUGGEST_ERROR,
                                            label: "city_id",
                                        })
                                    })
                            },
                        }, {
                            name: t("Все даты"),
                            type: "select",
                            label: "months",
                            items: [
                                {
                                    key: -1,
                                    text: "Все месяцы",
                                }, {
                                    key: 1,
                                    text: t("Январь"),
                                }, {
                                    key: 2,
                                    text: t("Февраль"),
                                }, {
                                    key: 3,
                                    text: t("Март"),
                                }, {
                                    key: 4,
                                    text: t("Апрель"),
                                }, {
                                    key: 5,
                                    text: t("Май"),
                                }, {
                                    key: 6,
                                    text: t("Июнь"),
                                }, {
                                    key: 7,
                                    text: t("Июль"),
                                }, {
                                    key: 8,
                                    text: t("Август"),
                                }, {
                                    key: 9,
                                    text: t("Сентябрь"),
                                }, {
                                    key: 10,
                                    text: t("Октябрь"),
                                }, {
                                    key: 11,
                                    text: t("Ноябрь"),
                                }, {
                                    key: 12,
                                    text: t("Декабрь"),
                                },
                            ]
                        },
                    ]

                    dispatch({
                        type: types.LOAD_FILTERS_OK,
                        filters: filters,
                        queryString: queryString || ''
                    })
                })
                .catch((e) => {
                    if ('development' === process.env.NODE_ENV) console.log(e)
                    dispatch({
                        type: types.LOAD_FILTERS_ERROR
                    })
                })
        })
    }
}

export const resetFilters = () => {
    return function (dispatch) {
        dispatch({
            type: types.RESET_FILTERS
        })
    }
}

export const loadFilters = (directory, queryString) => {
    return function (dispatch, getState) {
        const cityId = getState().main.city.id

        if (directory.filters) {
            dispatch({
                type: types.LOAD_FILTERS_OK,
                filters: directory.filters,
                friendlyUrls: directory.friendly_urls,
                baseUrl: directory.url,
                queryString: queryString,
                loadedKey: directory.key
            })
        } else {
            dispatch({
                type: types.LOAD_FILTERS_REQUEST
            })
            return axios.get(API_URL + '/v2/directory/filters?city_id=' + cityId + '&type=' + directory.type + '&type_id=' + directory.model.id,
                {withCredentials: true}
            )
                .then(response => {
                    dispatch({
                        type: types.LOAD_FILTERS_OK,
                        filters: response.data.filters,
                        friendlyUrls: response.data.friendly_urls,
                        baseUrl: response.data.base_url,
                        queryString: queryString,
                        loadedKey: directory.key
                    })
                })
                .catch((e) => {
                    if ('development' === process.env.NODE_ENV) console.log(e)
                    dispatch({
                        type: types.LOAD_FILTERS_ERROR
                    })
                })
        }
    }
}

export const toggleShow = (label,  params) => {
    return function (dispatch) {
        dispatch({
            type: types.TOGGLE_SHOW,
            params : params,
            label: label,
        })
    }
}

export const changeFilters = (filter, value) => {
    return function (dispatch) {
        dispatch({
            type   : types.CHANGE,
            filter : filter,
            value  : value,
        })
        return Promise.resolve(filter)
    }
}

export const applyFilter = (label, params) => {
    return function (dispatch, getState) {
        dispatch({
            type   : types.APPLY,
            label  : label,
            params : params,
        })
        return Promise.resolve()
    }
}

export const setFilterQueryString = (queryString) => {
    return function (dispatch, getState) {
        dispatch({
            type   : types.SET_QUERYSTRING,
            queryString  : queryString
        })
        return Promise.resolve()
    }
}

export const resetFilter = (label) => {
    return function (dispatch, getState) {
        dispatch({
            type: types.RESET,
            label: label,
        })
        return Promise.resolve()
    }
}

export const setWeddingDate = (date) => {
    return function (dispatch) {
        dispatch({
            type    : types.DATE_CHANGE,
            date    : date,
            changed : true,
        })
        return axios.put(API_URL + '/v2/directory/filterDate', {date: date}, {withCredentials: true})
            .then((response) => {
                dispatch({
                    type    : types.DATE_CHANGE,
                    date    : date,
                    changed : false,
                })
            })
            .catch(() => {
            })
    }
}

export const expandFilterGroup = (label, key) => {
    return function (dispatch) {
        dispatch({
            type: types.EXPAND,
            label: label,
            key: key,
        })
    }
}