import React from 'react'
import t from 't'

const Projects = (props) => {
    return (
        <div className="projects">
            {props.site.show_mywed ? <p className="projects_link"><a href="https://mywed.com/" target="_blank" rel="noopener">mywed.com</a></p> : null}
            {props.site.show_mywed ? <p className="projects_link"><a href="https://wedding.net/" target="_blank" rel="noopener">wedding.net</a></p> : null}

            {props.site.show_plusmedia ?
                <div className="projects_group">
                    <p className="projects_caption">{t('Разработка')}</p>
                    <p className="projects_link"><a href="http://plusmedia.ru/" target="_blank" rel="noopener">Plusmedia</a></p>
                </div>
            : null}
            {props.site.show_liderpoiska ?
                <div className="projects_group">
                    <p className="projects_caption">{t('SEO и реклама')}</p>
                    <p className="projects_link"><a href="http://liderpoiska.ru/" target="_blank" rel="noopener">{t('Лидер поиска')}</a></p>
                </div>
            : null}
            {props.site.show_fmf ?
                <div className="projects_group">
                    <p className="projects_caption">{t('SEO и реклама')}</p>
                    <p className="projects_link"><a href="https://fmf.co.th/" target="_blank" rel="noopener">{t('FMF')}</a></p>
                </div>
            : null}
        </div>)
}

export default Projects
