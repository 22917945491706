import _ from 'lodash'
import BaseModel from 'models/baseModel'

import User from 'models/user'

export default class Comment extends BaseModel {
    constructor(data) {
        if (data.comments) {
            data.comments = _.map(data.comments, item => new Comment(item))
        }
        if (data.user) {
            data.user = new User(data.user)
        }
        super(data)
    }

    get backDate() {
        return this.back_date
    }

    get myVote() {
        return this.my_vote
    }
}