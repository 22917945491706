import {combineReducers} from 'redux'
import { routerReducer } from 'react-router-redux'

import main from 'reducers/main'
import auth from 'reducers/auth'
import city from 'reducers/city'
import favorite from 'reducers/favorite'
import filters from 'reducers/filters'
import follower from 'reducers/follower'
import forum from 'reducers/forum'
import language from 'reducers/language'
import mywed from 'reducers/mywed'
import notice from 'reducers/notice'
import search from 'reducers/search'
import titles from 'reducers/titles'
import share from 'reducers/share'

import entity from 'reducers/entity'

import album from 'reducers/album'
import blog from 'reducers/blog'
import calendar from 'reducers/calendar'
import comment from 'reducers/comment'
import contact from 'reducers/contact'
import doerSuggest from 'reducers/doerSuggest'
import hint from 'reducers/hint'
import media from 'reducers/media'
import mediaFeed from 'reducers/mediaFeed'
import my from 'reducers/my'
import myalbum from 'reducers/myalbum'
import payment from 'reducers/payment'
import popup from 'reducers/popup'
import popupDialog from 'reducers/popupDialog'
import popupLight from 'reducers/popupLight'
import phoneCountries from 'reducers/phoneCountries'
import siteSearch from 'reducers/siteSearch'
import staticPage from 'reducers/staticPage'
import wedding from 'reducers/wedding'

import car from 'reducers/car'
import dress from 'reducers/dress'
import place from 'reducers/place'
import restaurant from 'reducers/restaurant'
import shop from 'reducers/shop'
import user from 'reducers/user'
import venue from 'reducers/venue'

const rootReducer = combineReducers({
    main,
    auth,
    city,
    favorite,
    filters,
    follower,
    forum,
    language,
    mywed,
    notice,
    search,
    titles,
    share,

    entity,

    album,
    blog,
    calendar,
    comment,
    contact,
    doerSuggest,
    hint,
    media,
    mediaFeed,
    my,
    myalbum,
    payment,
    popup,
    popupDialog,
    popupLight,
    phoneCountries,
    siteSearch,
    staticPage,
    wedding,

    car,
    dress,
    place,
    restaurant,
    shop,
    user,
    venue,

    router: routerReducer
});

export default rootReducer