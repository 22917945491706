import {combineReducers} from 'redux'

import album from 'reducers/album'
import viewInfo from 'reducers/viewInfo'
import review from 'reducers/review'

import {types} from 'actions/venue'

const initialState = {
    schedule: null,
    scheduleRooms: null,
}

function venue(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_SCHEDULE_REQUEST:
        case types.LOAD_SCHEDULE_ERROR:
            return {
                ...state,
                schedule: null,
            }
        case types.LOAD_SCHEDULE_OK:
            let scheduleRooms = {}
            action.rooms.forEach(r => {
                scheduleRooms[r.id] = r.dates
            })
            return {
                ...state,
                schedule: action.schedule,
                scheduleRooms: scheduleRooms,
            }

        default:
            return state
    }
}

const VenueReducer = combineReducers({
    venue,
    album,
    review,
    viewInfo
})

export default VenueReducer