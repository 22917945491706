import BaseModel from 'models/baseModel'
import Album from 'models/album'
import User from 'models/user'

export default class Media extends BaseModel {
    constructor(data) {
        if (data.album) {
            data.album = new Album(data.album)
        }
        if (data.user) {
            data.user = new User(data.user)
        }
        data.views++
        super(data)
    }

    get modelName() {
        return 'Media'
    }

    get likesCount() {
        return this.likes_count
    }

    get link() {
        if (document.location.pathname === '/') return this.url // костыль для главной, где нужно чтобы все медии открывались в своем городе
        return this.url.indexOf(document.location.origin)===0 ? this.url : this.static_url
    }

    get albumLink() {
        return this.link.replace(this.id, '').replace(/\/\/$/, '/')
    }

    get previewUrl() {
        return this.preview_url
    }

    getPic(size) {
        return this.preview_url[size] ? this.preview_url[size] : (typeof this.preview_url === 'string' ? this.preview_url : this.previewUrl[Object.keys(this.preview_url)[0]])
    }

    previewWidth(size) {
        return this.sizes.preview[size] && this.sizes.preview[size].width ? this.sizes.preview[size].width : null
    }

    previewHeight(size) {
        return this.sizes.preview[size] && this.sizes.preview[size].height ? this.sizes.preview[size].height : null
    }
}
