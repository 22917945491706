import {types, getParamKey, isLoading, isLoadingList} from 'actions/entity'

import _ from 'lodash'

const initialState = { // при изменении - внести правки и в index.js
    entity: {},
    list: {},
    loading: {},
}

function setLoading(state, entityName, entityIds, entityParam) {
    let paramKey = getParamKey(entityParam)
    _.each(entityIds, (entityId) => {
        if (!state.loading[entityName]) {
            state.loading[entityName] = {}
        }
        if (!state.loading[entityName][paramKey]) {
            state.loading[entityName][paramKey] = {}
        }
        state.loading[entityName][paramKey][entityId] = true
    })
    return state
}

function fillEntity(state, entityName, entities, entityParam, insertInto = {}) {
    let paramKey = getParamKey(entityParam)
    _.each(entities, (entity, entityKey) => {
        if (state.loading[entityName] && state.loading[entityName][paramKey]) {
            state.loading[entityName][paramKey][entityKey] = false
        }
        if (!entity) {
            return
        }
        if (!state.entity[entityName]) {
            state.entity[entityName] = {}
        }
        if (!state.entity[entityName][paramKey]) {
            state.entity[entityName][paramKey] = {}
        }
        state.entity[entityName][paramKey][entityKey] = {...entity, _time: new Date().getTime()}
        if (insertInto && insertInto.entityName && insertInto.entityId && insertInto.field) {
            const index = state.entity[insertInto.entityName.toLowerCase()][insertInto.entityParamKey][insertInto.entityId][insertInto.field].indexOf(entityKey)
            if (index >= 0) {
                state.entity[insertInto.entityName.toLowerCase()][insertInto.entityParamKey][insertInto.entityId][insertInto.field][index] = entity
            }

        }
    })
    return state
}

function setLoadingList(state, entityName, listParam) {
    let paramKey = getParamKey(listParam)
    if (!state.list[entityName]) {
        state.list[entityName] = {}
    }
    if (!state.list[entityName][paramKey]) {
        state.list[entityName][paramKey] = {}
    }
    state.list[entityName][paramKey] = {list: false, _time: new Date().getTime()}
    return state
}

function fillList(state, entityName, listParam, entityParam, list, meta, entity) {
    state = fillEntity(state, entityName, entity, entityParam)
    let paramKey = getParamKey(listParam)
    if (!state.list[entityName]) {
        state.list[entityName] = {}
    }
    if (!state.list[entityName][paramKey]) {
        state.list[entityName][paramKey] = {}
    }
    state.list[entityName][paramKey] = {list: list, meta: meta, _time: new Date().getTime()}
    return state
}

const EntityReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_ENTITY_OK:
            const missingEntities = _.reduce(action.entityIds, (result, id) => {
                if (!action.entity || !action.entity[id]) {
                    result[id] = null
                }
                return result
            }, {})
            return {...state, ...fillEntity({...state}, action.entityName, {...action.entity, ...missingEntities}, action.entityParam, action.insertInto)}
        case types.LOAD_ENTITY_REQUEST:
            return {...state, ...setLoading({...state}, action.entityName, action.entityIds, action.entityParam)}
        case types.LOAD_ENTITY_ERROR:
            let newState = {...state}
            _.each(action.entityIds, entityId => {
                if (isLoading(action.entityName, entityId, action.entityParam)) {
                    newState = {...newState, ...fillEntity({...newState}, action.entityName, {entityId: null}, action.entityParam)}
                }
            })
            return newState

        case types.LOAD_LIST_OK:
            return {...state, ...fillList({...state}, action.entityName, action.listParam, action.entityParam, action.list, action.meta, action.entity)}
        case types.LOAD_LIST_REQUEST:
            return {...state, ...setLoadingList({...state}, action.entityName, action.listParam)}
        case types.LOAD_LIST_ERROR:
            let newListState = {...state}
            _.each(action.entityIds, entityId => {
                if (isLoadingList(action.entityName, action.listParam)) {
                    newListState = {...newListState, ...fillList({...newListState}, action.entityName, action.listParam, {},null, null, [])}
                }
            })
            return newListState
        default:
            return state
    }
}
export default EntityReducer
