import React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from "react-visibility-sensor"
import Picture from "components/block/picture"

class SimpleImg extends React.Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef()
        this.state = {
            visible: false,
            width: 0,
            height: 0,
        }
    }

    componentDidMount() {
        if (this.ref && this.ref.current) {
            let img = this.props.simpleImg

            let size = {}

            if (this.props.isAutoWidth) {
                size.width = this.ref.current.clientWidth
                size.height = Math.round(size.width*img.height/img.width)
                if (this.props.maxAutoRatio) {
                    size.height = Math.min(size.height, size.width/this.props.maxAutoRatio)
                }
            } else if (this.props.isAutoHeight) {
                size.height = this.ref.current.clientHeight
                size.width = Math.round(size.height*img.width/img.height)
                if (this.props.maxAutoRatio) {
                    size.width = Math.min(size.width, size.height/this.props.maxAutoRatio)
                }
            }

            this.setState(size)
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (nextState.visible && !this.state.visible) || (nextState.width !== !this.state.width)
    }

    render() {
        let simpleImg = this.props.simpleImg
        let className = this.props.className
        const isAutoSize = this.props.isAutoWidth || this.props.isAutoHeight
        let width = isAutoSize ? this.state.width : this.props.width
        let height = isAutoSize ? this.state.height : this.props.height

        if (isAutoSize && !width) {
            return <img ref={this.ref} src={null} className={className} alt=""/>
        }

        if (!width && !height) {
            return <img src={null} className={className} alt=""/>
        }

        let isCrop = this.props.isCrop
        const isNoHeightInTag = this.props.isNoHeightInTag

        const pixelRatio = typeof window.devicePixelRatio !== 'undefined' ? window.devicePixelRatio : 1

        if (width && !height) {
            height = Math.round(simpleImg.height * width / simpleImg.width)
        } else if (!width && height) {
            width = Math.round(simpleImg.width * height / simpleImg.height)
        }

        let params = [
            'w' + Math.round(width * pixelRatio),
            'h' + Math.round(height * pixelRatio)
        ]
        if (isCrop) {
            params.push('n')
        }
        if (pixelRatio >= 2) {
            params.push('l80')
        } else {
            params.push('l90')
        }
        params.push('e31')
        let src = simpleImg.url + '=' + params.join('-')

        if (this.props.isForceVisible) {
            return <Picture src={src} className={className} width={width} height={isNoHeightInTag ? null : height} alt=""/>
        } else {
            return <VisibilitySensor partialVisibility={true} onChange={(isVisible) => {
                this.setState({visible: isVisible})
            }}
                                     active={!this.state.visible}
                                     offset={{bottom: -200, top: -50, left: -100, right: -100}}>
                {({isVisible}) => {
                    if (isVisible) {
                        return <Picture className={className} width={width} height={isNoHeightInTag ? null : height} src={src}/>
                    } else {
                        return <img src={null} className={className} width={1} height={height} alt=""/>
                    }
                }}
            </VisibilitySensor>
        }
    }
}

SimpleImg.propTypes = {
    className: PropTypes.string,
    height: PropTypes.number,
    isAutoHeight: PropTypes.bool,
    isAutoWidth: PropTypes.bool,
    isCrop: PropTypes.bool.isRequired,
    isForceVisible: PropTypes.bool,
    isNoHeightInTag: PropTypes.bool,
    maxAutoRatio: PropTypes.number,
    simpleImg: PropTypes.object.isRequired,
    width: PropTypes.number,
}

export default SimpleImg