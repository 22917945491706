import React from 'react'
import PropTypes from 'prop-types'

import ColorBubble from 'components/filter/bubble/color'
import ColorButton from 'components/filter/buttons/color'
import DateButton from 'components/filter/buttons/date'
import FavoritesButton from 'components/filter/buttons/favorites'
import MapButton from 'components/filter/buttons/map'
import MultiselectBubble from 'components/filter/bubble/multiselect'
import SelectBubble from 'components/filter/bubble/select'
import SelectButton from 'components/filter/buttons/select'
import SelectionButton from 'components/filter/buttons/selection'

let offsetY = 0
let binded = false
let $main = null
let $dummy = null

const getScrollTop = () => {
    // копипаста со стековерфлоу
    let supportPageOffset = window.pageYOffset !== undefined;
    let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
    return supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
}

class Filters extends React.Component {
    checkOffset () {
        $main = document.getElementById('filters_area')
        if (!$main) {
            return false
        }

        $dummy = null
        offsetY = $main.getBoundingClientRect().top + getScrollTop()
        if (!binded) {
            window.addEventListener('scroll', (ev) => {
                if ($main.classList.contains('_dontCheck')) {
                    return
                }
                if (!$dummy) {
                    $dummy = document.getElementById('filters_dummy')
                    if ($dummy) {
                        $dummy.style.height = $main.clientHeight + 'px'
                    }
                }
                if (getScrollTop() > offsetY) {
                    $main.classList.add('_sticky')
                } else {
                    $main.classList.remove('_sticky')
                }
            })
            binded = true
        }
    }

    componentDidMount() {
        this.checkOffset()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.filters !== prevProps.filters && this.props?.filters?.list?.length) {
            this.checkOffset()
        }
    }

    render() {
        const props = this.props
        if (!props?.filters?.list?.length) {
            return <div className="filters"/>
        }
        let filters = []
        let bubble = null
        let isHasSelection = false
        props.filters.list.forEach(filter => {
            if (!isHasSelection && filter?.items) {
                for (let i=0; filter.items[i]; i++) {
                    if (filter.items[i].is_has_site_selection && filter.selected.includes(filter.items[i].key)) {
                        isHasSelection = true
                        break
                    }
                }
            }
            let isShow = props.filters.opened == filter.label
            switch (filter.type) {
                case 'date':
                    return filters.push(<DateButton key={filter.label} filter={filter} showCalendar={props.showCalendar}/>)
                case 'color':
                    if (isShow) {
                        bubble = <ColorBubble
                            filter={filter}
                            onToggle={props.toggleShowFilters}
                            onChange={props.changeFilters}
                            applyFilter={props.applyFilter}
                            resetFilter={props.resetFilter}/>
                    }
                    return filters.push(<ColorButton key={filter.label} filter={filter} onToggle={props.toggleShowFilters}/>)
                default:
                    if (filter.disabled) {
                        return null
                    }
                    if (isShow) {
                        let goal = null
                        if (filter.label == 'price') {
                            goal = 'microgoal_average_price'
                        } else if (filter.label == 'capacity') {
                            goal = 'microgoal_capacity'
                        } else if (filter.label == 'district') {
                            goal = 'microgoal_location'
                        } else if (filter.label == 'type') {
                            goal = 'microgoal_type_place'
                        } else if (filter.label == 'event') {
                            goal = 'microgoal_type_event'
                        } else if (filter.label == 'features') {
                            goal = 'microgoal_type_feature'
                        }
                        if (filter.multiple) {
                            bubble = <MultiselectBubble
                                filter={filter}
                                onToggle={props.toggleShowFilters}
                                onChange={props.changeFilters}
                                applyFilter={props.applyFilter}
                                resetFilter={props.resetFilter}
                                expandFilterGroup={props.expandFilterGroup}
                                goal={goal}/>
                        } else {
                            bubble = <SelectBubble
                                filter={filter}
                                onToggle={props.toggleShowFilters}
                                onChange={props.changeFilters}
                                goal={goal}/>
                        }
                    }
                    return filters.push(<SelectButton key={filter.label} filter={filter} onToggle={props.toggleShowFilters}/>)
            }
        })

        return (
            <div className="filters">
                <div className="filters_area" id="filters_area">
                    {isHasSelection ? <SelectionButton/> : null}
                    {props.showAllOnMap ? <MapButton showAllOnMap={props.showAllOnMap}/> : null}
                    <FavoritesButton />
                    {filters}
                </div>
                <div className="filters_dummy" id="filters_dummy"></div>
                {props.showAllOnMap ? <MapButton className="_sticked" showAllOnMap={props.showAllOnMap}/> : null}
                {bubble}
            </div>
        )
    }
}

Filters.propTypes = {
    filters: PropTypes.object.isRequired,
    applyFilter: PropTypes.func.isRequired,
    toggleShowFilters: PropTypes.func.isRequired,
    changeFilters: PropTypes.func.isRequired,
    expandFilterGroup: PropTypes.func,
    showAllOnMap: PropTypes.func,
}

export default Filters