import {LOCATION_CHANGE} from 'react-router-redux'
import {types} from 'actions/hint'

const initialState = {
    component: null,
}

const HintReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN:
            return {
                ...state,
                component: action.component,
            }
        case types.CLOSE:
            return {
                ...state,
                component: null,
            }
        default:
            return state
    }
}
export default HintReducer