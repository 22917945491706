import {types} from 'actions/staticPage'

import _ from 'lodash'

const initialState = {
    entry: null,
    loading: true,
};

const StaticPageReducer = (state = initialState, action) => {
    switch (action.type) {
        // ЗАПИСЬ
        case types.LOAD_ENTRY_OK:
            return {
                ...state,
                entry: action.entry,
                loading: false,
            };
        case types.LOAD_ENTRY_REQUEST:
            return {
                ...state,
                entry: [],
                loading: true,
            };
        case types.LOAD_ENTRY_ERROR:
            return {
                ...state,
                entry: [],
                loading: false,
            };

        default:
            return state
    }
}
export default StaticPageReducer
