import _ from "lodash"

import {types} from 'actions/myalbum'

const initialState = {
    logs : [],
    metaLogs: {},
    isLoadingLogs: false,
}

const MyAlbumReducer = (state = initialState, action) => {
    switch (action.type) {
// журнал статистики у альбомов
        case types.LOAD_LOGS_OK:
            return {
                ...state,
                logs: action.list,
                metaLogs: action.meta,
                loadingLogs: false,
            };
        case types.LOAD_LOGS_REQUEST:
            return {
                ...state,
                logs: [],
                metaLogs: {},
                loadingLogs: true,
            };
        case types.LOAD_LOGS_ERROR:
            return {
                ...state,
                logs: [],
                metaLogs: {},
                loadingLogs: false,
            };
        default:
            return state
    }
}

export default MyAlbumReducer