import _ from 'lodash'

class Language {
    static supportedLanguages() {
        return [
            this.bengali(),
            this.english(),
            this.gujarati(),
            this.hindi(),
            this.kannada(),
            this.malayalam(),
            this.marathi(),
            this.polish(),
            this.punjabi(),
            this.russian(),
            this.tamil(),
            this.telugu(),
            this.thai(),
            this.ukrainian()
        ]
    }

    static indianLanguages() {
        return [
            this.bengali(),
            this.gujarati(),
            this.hindi(),
            this.kannada(),
            this.malayalam(),
            this.marathi(),
            this.punjabi(),
            this.tamil(),
            this.telugu(),
        ]
    }

    static russian() {
        return 'ru'
    }

    static isRussian(language) {
        return !!(this.isValid(language) && language.toLowerCase() === this.russian())
    }

    static english() {
        return 'en'
    }

    static isEnglish(language) {
        return !!(this.isValid(language) && language.toLowerCase() === this.english())
    }

    static ukrainian() {
        return 'uk'
    }

    static isUkrainian(language) {
        return !!(this.isValid(language) && language.toLowerCase() === this.ukrainian())
    }

    static hindi() {
        return 'hi'
    }
    static bengali() {
        return 'bn'
    }
    static telugu() {
        return 'te'
    }
    static marathi() {
        return 'mr'
    }
    static tamil() {
        return 'ta'
    }
    static kannada() {
        return 'kn'
    }
    static gujarati() {
        return 'gu'
    }
    static malayalam() {
        return 'ml'
    }
    static punjabi() {
        return 'pa'
    }

    static isIndian(language) {
        return !!(this.isValid(language) && _.includes(Language.indianLanguages(), language.toLowerCase()))
    }

    static polish() {
        return 'pl'
    }

    static isPolish(language) {
        return !!(this.isValid(language) && language.toLowerCase() === this.polish())
    }

    static thai() {
        return 'th'
    }

    static isThai(language) {
        return !!(this.isValid(language) && language.toLowerCase() === this.thai())
    }

    static isValid(language) {
        return !!(language && _.isString(language) && _.includes(Language.supportedLanguages(), language.toLowerCase()))
    }
}

export default Language