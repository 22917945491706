import BaseModel from 'models/baseModel'

export default class Contest extends BaseModel {
    constructor(data) {
        super(data)
    }

    get link() {
        return this.url
    }
}
