import axios from 'axios'

export const check = (iso, phone) => {
    return function (dispatch) {
        return new Promise(function(resolve, reject) {
            let params = ['phone=' + encodeURIComponent(phone)]
            if (iso) {
                params.push('iso=' + iso)
            }
            let url = API_URL + '/v2/tools/phoneCheck?' + params.join('&')
            axios.get(url, {withCredentials: true})
                .then(response => {
                    resolve(response.data)
                })
                .catch(() => {
                    dispatch({
                        type: types.REQUEST_ERROR
                    })
                })
        })
    }
}
