import {types} from 'actions/main'
import {types as authTypes} from 'actions/auth'
import {LOCATION_CHANGE} from 'react-router-redux'
import dayjs from 'dayjs'
import ru from 'dayjs/locale/ru'
import en from 'dayjs/locale/en'
import uk from 'dayjs/locale/uk'
import hi from 'dayjs/locale/hi'
import bn from 'dayjs/locale/bn'
import te from 'dayjs/locale/te'
import mr from 'dayjs/locale/mr'
import ta from 'dayjs/locale/ta'
import kn from 'dayjs/locale/kn'
import gu from 'dayjs/locale/gu'
import ml from 'dayjs/locale/ml'
import pa from 'dayjs/locale/en' // todo pa
import pl from 'dayjs/locale/pl'
import th from 'dayjs/locale/th'
import _ from 'lodash'
import City from 'models/city'
import Directory from 'models/directory'
import t from 't'
import setGlobal from 'lib/setGlobal'

const initialState =
    {
        banner: [],
        channel: null,
        city: null,
        countries : [],
        currency: null,
        currentMenuGroup: '',
        directory: null, // init must be null
        error: null,
        is_discount: false,
        isHelpLoading: false,
        isMenuExpanded: false,
        lang_t: {},
        locale: {},
        menu: [],
        seed: 1,
        site: {},
        title: '',
        hash: '',
    }

const checkPathname = (state, item, pathname) => {
    const cityInPath = state.site.is_city_in_path ? '/' + state.city.domain : ''
    return item.url && pathname!=='/' && item.url.replace(/https?:\/\/[^\/]+/, '').replace(cityInPath, '') === decodeURI(pathname).replace(/[\w\-\_]+\/.*$/, '')
}

const getCurrentMenu = (state, menu, location) => {
    let current = ''
    const cityInPath = state.site.is_city_in_path ? '/' + state.city.domain : ''
    const pathname = location.pathname.replace(cityInPath, '')
    if (pathname === '/') return ''
    _.each(menu, (item, index) => {
        if (current || !item.is_mobile) {
            return false
        }
        if (checkPathname(state, item, location.pathname)) {
            current = 'menu' + index
        } else {
            _.each(item.submenu, (item) => {
                if (checkPathname(state, item, location.pathname)) {
                    current = 'menu' + index
                }
            })
        }
    })
    return current
}

const myTitles = {
    'account'    : t('Связанные аккаунты'),
    'billingPro' : t('PRO-аккаунт'),
    'billing'    : t('Баланс'),
    'cities'     : t('Дополнительные города'),
    'freeDates'  : t('Поиск заказов'),
    'notify'     : t('Настройка оповещений'),
    'photoalbum' : t('Фото и видео'),
    'price'      : t('Стоимость услуг'),
    'rating'     : t('Отзывы'),
    'settings'   : t('Основные настройки'),
    'specs'      : t('Специализации'),
    'stats'      : t('Статистика'),
}

const getCurrentTitle = (state, menu, location) => {
    let title = t('{site_name}')
    if (location) {
        if (location.pathname.includes('/pm/')) {
            return t('Переписка')
        } else if (location.pathname.includes('/my')) {
            if (location.pathname.match(/\/my(\/|)$/g)) {
                return myTitles['settings']
            }
            for (let path in myTitles) if (myTitles.hasOwnProperty(path)) {
                if (location.pathname.includes('/my/' + path)) {
                    return myTitles[path]
                }
            }
        }
        const pathname = location.pathname
        const flatMenu = _.flatMap(menu, (item) => item.submenu ? item.submenu : item);
        let currentMenu = _.filter(flatMenu, (item) => checkPathname(state, item, pathname))
        if (currentMenu && currentMenu.length) {
            title = currentMenu[0].name
        }
    }
    return title
}
var t0, t1
const MainReducer = (state = initialState, action) => {
    if ('development' === process.env.NODE_ENV) {
        // t0 = performance.now()
        // console.log(action.type, t0 - t1);
        // t1 = performance.now()
    }
    switch (action.type) {
// interface
        case types.LOAD_INTERFACE_OK:
            const menu = _.map(action.site.menu_extended, (menu, i) => {
                menu.id = 'menu' + i
                return menu
            })
            setGlobal(action.site, action.locale, action.seed)
            // Change dayjs locale globaly
            dayjs.locale(LANG==='pa'?'en':LANG)
            return {
                ...state,
                banner: action.banner,
                city: new City(action.city),
                locale: action.locale,
                currency: action.currency,
                is_discount: action.is_discount,
                site: action.site,
                menu: menu,
                directory: _.map(action.directory, (item) => new Directory(item)),
                error: action.error,
                seed: action.seed,
                channel: action.channel,
                hash: action.hash,
            }
        case types.LOAD_INTERFACE_REQUEST:
        case types.LOAD_INTERFACE_ERROR:
            return state
// lang_t
        case types.LOAD_LANG_T:
            let lang_t = action.data
            lang_t['{site_name}'] = '{site_name}'
            if (typeof window !== 'undefined') {
                window.LANG_T = lang_t
            } else {
                global.LANG_T = lang_t
            }
            return {
                ...state,
                lang_t: lang_t,
                title: getCurrentTitle(state, state.menu, action.location), // нужно после определения списка LANG_T
            }
// directory
        case types.LOAD_DIRECTORY_STRUCTURE_REQUEST:
            return {
                ...state,
                directory: null,
            }
        case types.LOAD_DIRECTORY_STRUCTURE_OK:
            return {
                ...state,
                directory: _.map(action.directory, (item) => new Directory(item))
            }
        case types.LOAD_DIRECTORY_STRUCTURE_ERROR:
            return state
// other
        case types.TOGGLE_MENU:
            return {
                ...state,
                isMenuExpanded: !state.isMenuExpanded,
                currentMenuGroup: getCurrentMenu(state, state.menu, action.location),
            }
        case types.SELECT_MENU_GROUP:
            return {
                ...state,
                currentMenuGroup: action.id,
            }

        case authTypes.CHECK_AUTH_OK:
            return {
                ...state,
                currentMenuGroup: '',
                isMenuExpanded: false,
            }

        case LOCATION_CHANGE:
            if (typeof window !== 'undefined') {
                return {
                    ...state,
                    title: getCurrentTitle(state, state.menu, action.payload),
                    isMenuExpanded: false,
                }
            } else {
                return {...state}
            }

// help
        case types.LOAD_HELP_REQUEST:
            return {
                ...state,
                isHelpLoading: true,
            }
        case types.LOAD_HELP_OK:
            return {
                ...state,
                isHelpLoading: false,
            }
        case types.LOAD_HELP_ERROR:
            return {
                state,
                isHelpLoading: false,
            }

        default:
            return state
    }
}

export default MainReducer
