import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
import _ from 'lodash'

import BaseModel from 'models/baseModel'
import Car from 'models/car'
import Media from 'models/media'
import Place from 'models/place'
import Restaurant from 'models/restaurant'
import Shop from 'models/shop'
import User from 'models/user'

export default class Review extends BaseModel {
    constructor(data) {
        if (data.user) {
            data.user = new User(data.user)
        }
        if (data.media) {
            data.media = _.map(data.media, (media) => new Media(media))
        }
        if (data.doer) {
            switch (data.doer_type) {
                case 'User':
                    data.doer = new User(data.doer)
                    break
                case 'Restaurant':
                    data.doer = new Restaurant(data.doer)
                    break
                case 'Car':
                    data.doer = new Car(data.doer)
                    break
                case 'Place':
                    data.doer = new Place(data.doer)
                    break
                case 'Shop':
                    data.doer = new Shop(data.doer)
                    break
            }
        }
        super(data)
    }

    get key() {
        return 'review_' + this.id
    }

    get date() {
        return dayjs(this.created_at * 1000).fromNow()
    }

    get mark() {
        return this.mark1 > 0 ? 'good' : (this.mark1 < 0 ? 'bad' : 'neutral')
    }

    get averageMark() {
        if (this.mark2 && this.mark3) {
            let mark = (this.mark1 + this.mark2 + this.mark3)/3
            return mark > 0 ? 'good' : (mark < 0 ? 'bad' : 'neutral')
        } else {
            return this.mark
        }
    }

    get shortText() {
        return this.short_text
    }

    get link() {
        return this.doer.link + 'rating/' + '#' + this.key
    }
}