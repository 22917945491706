import keyMirror from 'lib/keyMirror'

export const types = keyMirror({
    SHOW: null,
    CLOSE: null,
}, 'phoneCountries')

export const show = (currentCountry, callback) => {
    return function (dispatch) {
        dispatch({
            type: types.SHOW,
            current: currentCountry,
            callback: callback,
        })
    }
}

export const close = () => {
    return function (dispatch) {
        dispatch({
            type: types.CLOSE,
        })
    }
}
