import {types} from 'actions/doerSuggest'
import _ from 'lodash'

const initialState = {
    doers: [],
    isLoading: false,
}

const DoerSuggestReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_REQUEST:
            return {
                ...state,
                doers: [],
                isLoading: true,
            }
        case types.LOAD_OK:
            return {
                ...state,
                doers: action.doers,
                isLoading: false,
            }
        case types.LOAD_ERROR:
            return {
                ...state,
                doers: [],
                isLoading: false,
            }

        case types.CLOSE:
            return {
                ...state,
                doers: [],
            }
        default:
            return state
    }
}
export default DoerSuggestReducer