import axios from 'axios'
import keyMirror from 'lib/keyMirror'
import React from "react"
import t from 't'

import {loadModel as loadAlbum} from 'actions/album'

export const types = keyMirror({
    LOAD_LOGS_REQUEST: null,
    LOAD_LOGS_OK: null,
    LOAD_LOGS_ERROR: null,
}, 'myalbum');

export const fakeUpload = (file, params) => {
    return new Promise((resolve, reject) => {
        // Create an image
        let img = document.createElement('img')
        // Create a file reader
        let reader = new FileReader()
        // Set the image once loaded into file reader
        reader.onload = (ev) => {
            img.src = ev.target.result
            img.onload = (ev2) => {
                let canvas = document.createElement("canvas")
                let ctx = canvas.getContext("2d")
                ctx.drawImage(img, 0, 0)

                let src = {
                    width  : img.width,
                    height : img.height,
                    ratio  : img.width / img.height,
                }
                let dst = {
                    width  : src.width,
                    height : src.height,
                    ratio  : src.ratio,
                    crop   : 0,
                }

                if (params && params.size) {
                    let size = params.size.split('x')
                    if (size[0] !== undefined) {
                        dst.width = +size[0]
                    }
                    if (size[1] !== undefined) {
                        dst.height = +size[1]
                    }
                    if (size[2] !== undefined) {
                        dst.crop = +size[2]
                    }
                }
                if (dst.width == 0) {
                    dst.width = dst.height * src.ratio
                }
                dst.ratio = dst.width / dst.height

                let width = src.width
                let height = src.height

                if ( (dst.ratio > 1 && src.ratio > 1 && dst.crop) ||
                     (dst.ratio > 1 && src.ratio <= 1 && !dst.crop) ||
                     (dst.ratio <= 1 && src.ratio >= 1 && dst.crop) ||
                     (dst.ratio <= 1 && src.ratio < 1 && !dst.crop) ) {
                    height = dst.height
                    width = height * src.ratio
                } else {
                    width = dst.width
                    height = width / src.ratio
                }

                canvas.width = dst.width
                canvas.height = dst.height
                let x = 0
                let y = 0
                if (dst.crop === 0) {
                    canvas.width = Math.min(canvas.width, width)
                    canvas.height = Math.min(canvas.height, height)
                } else {
                    x = (canvas.width - width) / 2
                    y = (canvas.height - height) / 2
                }
                ctx.drawImage(img, x, y, width, height)
                resolve({
                    url    : canvas.toDataURL("image/png"),
                    width  : canvas.width,
                    height : canvas.height,
                    source : {...src},
                })
            }
        }
        // Load files into file reader
        reader.readAsDataURL(file)
    })
}

const createIfNeed = (data) => {
    return new Promise(function(resolve, reject) {
        if (data.id) {
            resolve(data)
        } else {
            const albumType = data.is_wedding ? 'wedding' : 'other'
            axios.post(API_URL + '/v2/albums/', {type:albumType}, {withCredentials: true})
                .then(response => {
                    let album = response.data.album
                    if (album.is_wedding) {
                        album.dt_shoot = data.dt_shoot
                        album.spouses  = data.spouses
                    } else {
                        album.title = data.title
                    }
                    album.medias = data.medias
                    resolve(album)
                })
                .catch(() => {
                    console.error('Error while creating album')
                })
        }
    })
}

const uploadPhotos = (album, data, indexes) => {
    return new Promise(function(resolve, reject) {
        if (!indexes.length) {
            resolve(album)
            return
        }
        axios.post(API_URL + '/v2/albums/' + album.id + '/media', data, {withCredentials: true})
            .then(response => {
                response.data.media.forEach((media, i) => {
                    album.medias[indexes[i]] = media
                })
                resolve(album)
            })
            .catch((error ) => {
                console.error(error )//'Error while uploading photo')
            })
    })
}

const uploadVideos = (album, data, indexes) => {
    return new Promise(function(resolve, reject) {
        if (!indexes.length) {
            resolve(album)
            return
        }
        let count = data.length
        data.forEach((video, i) => {
            let params = {
                albumId     : album.id,
                embed       : video.url,
                description : video.description,
            }
            if (video?.provider == 'vimeo') {
                params.provider = 'vimeo'
                params.thumb = video.thumb
                params.width = video.width
                params.height = video.height
                params.duration = video.duration
            }
            axios.post(API_URL + '/v2/embed/', params, {withCredentials: true})
                .then(response => {
                    album.medias[indexes[i]] = response.data.media
                    count--
                    if (count <= 0) {
                        resolve(album)
                    }
                })
                .catch(() => {
                    console.error('Error while uploading video', video)
                    count--
                    if (count <= 0) {
                        resolve(album)
                    }
                })
        })
    })
}

export const deleteMedias = (album, indexes) => {
    return new Promise(function(resolve, reject) {
        let count = indexes.length
        if (!count) {
            resolve(album)
        }
        indexes.reverse().forEach((index) => {
            axios.delete(API_URL + '/v2/media/' + album.medias[index].id, {withCredentials: true})
                .then(response => {
                    album.medias.splice(index, 1)
                    count--
                    if (count <= 0) {
                        resolve(album)
                    }
                })
                .catch(() => {
                    console.error('Error while deleting media')
                    count--
                    if (count <= 0) {
                        resolve(album)
                    }
                })
        })
    })
}

const updateMedia = (album) => {
    return new Promise(function(resolve, reject) {
        let photos = new FormData()
        let videos = []
        let indexes = {
            photo:  [],
            video:  [],
            delete: [],
        }
        album.medias.forEach((media, i) => {
            if (media.deleted) {
                indexes.delete.push(i)
            } else if (media.file && !media.error) {
                photos.append(indexes.photo.length, media.file)
                indexes.photo.push(i)
            } else if (!media.id && media.video) {
                videos.push(media.video)
                indexes.video.push(i)
            }
        })
        uploadPhotos(album, photos, indexes.photo)
        .then((album) => {
            return uploadVideos(album, videos, indexes.video)
        })
        .then((album) => {
            return deleteMedias(album, indexes.delete)
        })
        .then(() => resolve(album))
    })
}

export const save = (album) => {
    return new Promise(function(resolve, reject) {
        createIfNeed(album)
        .then(updateMedia)
        .then((album) => {
            let params = {}
            if (album.is_wedding) {
                params.spouse1 = album.spouses.spouse1
                params.spouse2 = album.spouses.spouse2
                params.date = album.dt_shoot
            } else {
                params.title = album.title
            }
            params.media_ids = album.medias.map((media) => media.id)
            axios.put(API_URL + '/v2/albums/' + album.id, params, {withCredentials: true})
                .then(response => {
                    resolve(response.data.album)
                })
                .catch(() => {
                    console.error('Error while saving album')
                })
        })
    })
}

const doerTypesToDoerType = {
    'users'       : 'user',
    'venues'      : 'venue',
    'restaurants' : 'restaurant',
    'cars'        : 'car',
    'places'      : 'place',
}

export const addDoer = (albumId, group, doerType, doer) => {
    return function (dispatch) {
        let roleId
        if (doer.role && doer.role.id) {
            roleId = doer.role.id
        } else if (doer.type && doer.type.id) {
            roleId = doer.type.id
        }
        let data = {
            type: doerTypesToDoerType[doerType],
            model_role: roleId,
            custom: '',
            is_author: +(group == 'authors'),
        }
        if (doer.id) {
            data.model_id = doer.id
        } else {
            data.custom = doer.name
        }
        axios.post(API_URL + '/v2/albums/' + albumId + '/links', JSON.stringify(data), {withCredentials: true})
            .then(response => {
                dispatch(loadAlbum(albumId, {
                    fields : 'spouses,my_link_confirm,album_review',
                    embed  : 'medias',
                    preview_size: '460x460x1',
                }, true))
            })
            .catch(() => {
            })
    }
}

export const removeDoer = (albumId, doer) => {
    return function(dispatch, getState) {
        axios.delete(API_URL + '/v2/albums/' + albumId + '/links/' + doer.id, {withCredentials: true})
            .then(response => {
                dispatch(loadAlbum(albumId, {
                    fields : 'spouses,my_link_confirm,album_review',
                    embed  : 'medias',
                    preview_size: '460x460x1',
                }, true))
            })
            .catch(() => {
            })
    }
}

let text = {
    error403 : t('Это видео запрещено встраивать. Если это ваше видео, необходимо изменить настройку "Where can this video be embedded?"')
}

export const checkVideo = (url) => {
    return new Promise(function(resolve, reject) {
        if (url == '') {
            resolve({
                url   : null,
                cover : null,
                error : false,
            })
            return
        }

        const vimeoMatch = url.match(/vimeo\.com\/(\d+)/)
        if (vimeoMatch) {
            axios.get('https://vimeo.com/api/oembed.json?url=http%3A//vimeo.com/' + vimeoMatch[1] + '&width=1920')
            .then(response => {
                const data = response.data
                let result = {
                    provider    : 'vimeo',
                    url         : url,
                    cover       : data.thumbnail_url || null,
                    error       : (data.response && data.response == 'error') ? true : false,
                    width       : data.width,
                    height      : data.height,
                    duration    : data.duration,
                    description : data.description || data.title || null,
                }
                resolve(result)
            })
            .catch((error) => {
                let result = {
                    url         : url,
                    cover       : null,
                    error       : true,
                    description : null,
                }
                if (error.response.status == 403) {
                    result.errorText = text.error403
                }
                resolve(result)
                console.error('Error while uploading video')
            })
        } else {
            let params = {
                embed : url
            }
            axios.post(API_URL + '/v2/tools/embedCheck', params, {withCredentials: true})
            .then(response => {
                const data = response.data
                let result = {
                    url   : url,
                    cover : data.info && data.info.thumb || null,
                    error : (data.response && data.response == 'error') ? true : false,
                    description: data.info && data.info.description || null,
                }
                resolve(result)
            })
            .catch(() => {
                console.error('Error while uploading video')
            })
        }
    })
}


export const loadLogs = (albumId, page, perPage) => {
    return function(dispatch, getState) {
        dispatch({
            type: types.LOAD_LOGS_REQUEST
        })

        if (!page) {
            page = 1
        }
        if (!perPage) {
            perPage = 100
        }
        let params = {
            embed        : 'user',
            page         : page,
            per_page     : perPage,
        }
        axios.get(API_URL + '/v2/albums/' + albumId + '/logs', {params:params, withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_LOGS_OK,
                    list: response.data.logs,
                    meta: response.data.meta,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_LOGS_ERROR
                })
            })
    }
}


export const deleteAlbum = (albumId) => {
    return new Promise(function(resolve, reject) {
        axios.delete(API_URL + '/v2/albums/' + albumId, {withCredentials: true})
            .then(response => {
                resolve(true)
            })
            .catch(() => {
            })
    })
}

export const sendReview = (albumId, text) => {
    return new Promise(function(resolve, reject) {
        let params = {
            album_review : text,
        }
        axios.put(API_URL + '/v2/albums/' + albumId + '/review', JSON.stringify(params), {withCredentials: true})
            .then(response => {
                resolve(true)
            })
            .catch(() => {
            })
    })
}

export const confirmLink = (albumId, linkedId, confirmed, reason) => {
    return new Promise(function(resolve, reject) {
        let params = {
            confirmed : confirmed,
        }
        if (confirmed == -1 && reason) {
            params.reason = reason
        }
        axios.put(API_URL + '/v2/albums/' + albumId + '/links?modelId=' + linkedId, JSON.stringify(params), {withCredentials: true})
            .then(response => {
                resolve(true)
            })
            .catch(() => {
            })
    })
}
