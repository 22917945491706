import _ from 'lodash'

import {types} from 'actions/mediaFeed'

import Media from 'models/media'
import Meta from 'models/meta'

const initialState = {
    photos: {
        best: {
            list: [],
            meta: null,
            loading: false,
        },
        discussed: {
            list: [],
            meta: null,
            loading: false,
        },
        favorites: {
            list: [],
            meta: null,
            loading: false,
        },
        my: {
            list: [],
            meta: null,
            loading: false,
        },
        new: {
            list: [],
            meta: null,
            loading: false,
        },
        popular: {
            list: [],
            meta: null,
            loading: false,
        },
    },
    videos: {
        best: {
            list: [],
            meta: null,
            loading: false,
        },
        discussed: {
            list: [],
            meta: null,
            loading: false,
        },
        favorites: {
            list: [],
            meta: null,
            loading: false,
        },
        my: {
            list: [],
            meta: null,
            loading: false,
        },
        new: {
            list: [],
            meta: null,
            loading: false,
        },
        popular: {
            list: [],
            meta: null,
            loading: false,
        },
    },
}

const MediaFeedReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_OK:
            let newState = {...state}
            newState[action.mediaType][action.feedType] = {
                list: action.meta.page === 1 ? _.map(action.list, item => new Media(item)) : _.union(newState[action.mediaType][action.feedType].list, _.map(action.list, item => new Media(item))),
                meta: new Meta(action.meta),
                loading: false,
            }
            return newState
        case types.LOAD_REQUEST:
            newState = {...state}
            newState[action.mediaType][action.feedType] = {
                ...newState[action.mediaType][action.feedType],
                loading: true,
            }
            return newState
        case types.LOAD_ERROR:
            newState = {...state}
            newState[action.mediaType][action.feedType] = {
                ...newState[action.mediaType][action.feedType],
                loading: false,
            }
            return newState

        default:
            return state
    }
}
export default MediaFeedReducer
