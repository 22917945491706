import React from 'react'
import {connect} from 'react-redux'
import t from 't'
import _ from 'lodash'

import VisibilitySensor from 'react-visibility-sensor'

import {openHelp, goToFullVersion, loadHelp} from 'actions/main'
import {putComponentToPopup} from 'actions/popup'

import Language from 'containers/language'
import Projects from 'components/main/projects'

import Link from 'components/block/linkLight'

const Footer = (props) => {
    const visitors = props.site.visitors.value
    const visitors_display = props.site.visitors.display
    return (<div className="footer">
        <div className="footer_top">
            { visitors > 1000000
                ?
                <p className="footer_text" dangerouslySetInnerHTML={
                    {
                        __html: t('{site_name} — сервис для подготовки к свадьбе') + '. ' + t('<b>{count}</b> человек посетил {site_name} за последний месяц.|<b>{count}</b> человека посетило {site_name} за последний месяц.|<b>{count}</b> человек посетило {site_name} за последний месяц.', {
                            0: visitors,
                            '{count}': visitors_display
                        })
                    }
                }/>
                :
                <p className="footer_text">{t('{site_name} — сервис для подготовки к свадьбе')}</p>
            }
            {props.languageText ?
                <p className="footer_lang"><a onClick={props.changeLanguage}>{props.languageText}</a>
                </p> : null}
            <a className="button-border" onClick={() => props.goToFullVersion()}>{t('Перейти на полную версию')}</a>
        </div>
        <div className="footer_bottom">
            <p className="footer_links">
                {props.site.show_helpscout ? <VisibilitySensor partialVisibility={false} offset={{bottom: 0, top: 0, left: 0, right: 0}}>
                    {({isVisible}) => {
                        if (isVisible) {
                            setTimeout(() => props.loadHelp(), 2500)
                        }
                        return <a className="footer_link" onClick={props.openHelp}>{t('Задать вопрос')}</a>
                    }}</VisibilitySensor>
                    : (props.site.show_admin_email ? <a className="footer_link"
                                                        href={'mailto:' + props.site.email_admin}>{t('Задать вопрос')}</a> : null)
                }
                {props.site.show_blog ?
                    <Link className="footer_link" to={props.site.url + props.langUrlNotEmpty + '/blog/'}>{t('Блог')}</Link> : null}
                <Link className="footer_link" to={props.site.url + props.langUrlNotEmpty + '/page/payment/'}>{t('Платные сервисы')}</Link>
            </p>
            <p className="footer_links">
                <Link className="footer_link" to={props.site.url + props.langUrlNotEmpty + '/page/privacy/'}>{t('Конфиденциальность')}</Link>
                {props.site.show_refund ? <Link className="footer_link" to={props.site.url + props.langUrlNotEmpty + '/page/refund/'}>{t('Правила возврата')}</Link> : null}
            </p>
            <div className="footer_socials">
                {_.map(props.site.social_networks, social => <a className={'footer_social-' + social.key}
                                                                href={social.url} key={social.key} target="_blank" rel="noopener"/>)}
                {props.site.show_mywed || props.site.show_plusmedia || props.site.show_liderpoiska || props.site.show_fmf ?
                    <a className="footer_social-other" onClick={props.projects}>{t('Другие проекты')}</a>
                : null}
            </div>
        </div>
    </div>)
}

function mapStateToProps(state) {
    return {
        languageText: state.main.locale.text,
        langUrlNotEmpty: state.main.locale.url_not_empty,
        site: state.main.site,
    }
}
function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeLanguage: () => dispatch(putComponentToPopup(t('Страна и язык'), <Language/>)),
        goToFullVersion: () => dispatch(goToFullVersion()),
        loadHelp: () => dispatch(loadHelp()),
        openHelp: () => dispatch(openHelp()),
        dispatch: dispatch,
    }
}
function mergeProps(stateProps, dispatchProps, ownProps) {
    const dispatch = dispatchProps.dispatch
    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        projects: () => dispatch(putComponentToPopup(t('Другие проекты'), <Projects site={stateProps.site}/>)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Footer)
