import React from 'react'
import PropTypes from 'prop-types'
import t from 't'

const EmptyList = (props) => {
    return (
        <div className="noResults">
            <p className="noResults_text">{props.text || t('Ничего не найдено')}</p>
        </div>
    )
}

EmptyList.propTypes = {
    text: PropTypes.string
}

export default EmptyList