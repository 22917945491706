import _ from 'lodash'

export default class BaseModel {
    constructor(data) {
        this._data = data;
        _.each(this._data, (value, key) => {
            this[key] = value;
        });
        this._class = this.constructor.name;
    }

    isFav(favorites) {
        return favorites.indexOf(this.id) !== -1;
    }

    get pmLink() {
        return this.user ? this.user.pmLink : null
    }
}