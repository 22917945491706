import BaseModel from 'models/baseModel'

export default class Community extends BaseModel {
    get modelName() {
        return 'Community'
    }

    get link() {
        return SITE_URL + this.path
    }
    get path() {
        if (this.url) {
            return '/forum/' + this.url + '/'
        } else if (this.city) {
            return '/forum/' + this.city.domain + '/'
        }
        return '/forum/'
    }

    get slug() {
        return this.url ? this.url : (this.city.domain ? this.city.domain : '')
    }
}
