import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import t from 't'

import BottomLinks from 'components/directory/bottomLinks'
import CallButton from 'components/directory/callButton'
import Card from 'components/dress/card'
import CuttedText from 'components/block/cuttedText'
import EmptyFavorites from 'components/block/emptyFavorites'
import EmptyList from 'components/block/emptyList'
import Filters from 'components/filter/filters'
import Pagination from 'components/block/pagination'
import ShowMore from 'components/block/showMore'
import BigLoading from 'components/block/bigLoading'

import Link from 'components/block/link'

class DressList extends React.Component {
    render() {
        let props = this.props
        const isFavPage = props.queryString.indexOf('is_fav=1') !== -1
        const shopDirectory = _.filter(props.directoryList, (directory) => directory.type === 'shops' && directory.model && directory.model.key === 'dress').pop()
        return (
            <div className='main'>
                <div className="catalog">
                    <Link className="catalog_switcher" to={shopDirectory.url}>{t('Свадебные салоны')}</Link>
                    {!isFavPage ?
                        <Filters filters={props.filters} changeFilters={props.changeFilters}
                                 toggleShowFilters={props.toggleShowFilters}
                                 showCalendar={props.showCalendar}
                                 applyFilter={props.applyFilter}
                                 resetFilter={props.resetFilter}
                                 expandFilterGroup={props.expandFilterGroup}/>
                        : null}
                    {props.h1 ? <h1>{props.h1}</h1> : null}
                    {props.h1text ?
                        <CuttedText text={props.h1text}/>
                        : null}
                    <div className="cards">
                        {props.list.length ? _.map(props.list, function (model) {
                            return (
                                <Card key={model.id} card={model} allFavorites={props.allFavorites}
                                      toggleDressFavorite={props.toggleDressFavorite}/>
                            )
                        }) : props.loading ? <BigLoading/> : null}
                    </div>
                    {props.list.length ?
                        <ShowMore meta={props.meta} loadData={props.loadMoreData} loading={props.loading}/> : null}
                    {props.list.length ?
                        <Pagination meta={props.meta} loadData={props.loadData} loading={props.loading}
                                    useGet={true}/> : null}

                    {props.links.length ?
                        <BottomLinks links={props.links}/>
                        : null}
                    {props.bottom ?
                        <div className="catalog_text" dangerouslySetInnerHTML={{__html: props.bottom}}/>
                        : null}

                    {props.meta && props.meta.totalPages === 0 ? isFavPage ?
                        <EmptyFavorites directoryName={props.directory.model.plural}/> : <EmptyList/> : null}

                    {props.directory.inquire ?
                        <CallButton phone={props.directory.inquire.phone} inquire={props.directory.inquire.inquire}/>
                        : null}
                </div>
            </div>
        )
    }
}

DressList.propTypes = {
    directory: PropTypes.object.isRequired,
    allFavorites: PropTypes.object,
    list: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loadData: PropTypes.func.isRequired,
    loadMoreData: PropTypes.func.isRequired,
    meta: PropTypes.object,
    toggleDressFavorite: PropTypes.func.isRequired,
}

export default DressList

