import _ from 'lodash'

import Photo from "presenters/PhotoPresenter"
import PhotoViewerActions from "actions/PhotoViewerActions"
import PhotoViewerStore from "stores/PhotoViewerStore"
import Fluxable from "behaviours/Fluxable"
import React from "react"

class PhotoViewer extends React.Component {
    constructor(props) {
        super(props)
        this.watchStores = [PhotoViewerStore]
        Object.assign(this, Fluxable)
        this.state = {...this.getStateFromStores(), closeMovementProgress: 0}
    }

    getStateFromStores() {
        return {
            on: PhotoViewerStore.isOn(),
            photos: PhotoViewerStore.getPhotos(),
            initialPhoto: PhotoViewerStore.getInitialPhoto()
        }
    }

    render() {
        if (!this.state.on) {
            return <div/>
        }

        return <div className="photo-viewer" onClick={(e) => this.handleCloseClick(e)} style={this.getStyle()}>
            <i className="icon-search-remove" onClick={(e) => this.handleCloseClick(e)}/>
            <div className="swiper-container swiper-container-v">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div key={Photo.id(this.state.initialPhoto)}>
                            <img src={Photo.url(this.state.initialPhoto)} onClick={(e) => this.handleImageClick(e)}
                                 alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    handleCloseClick(e) {
        PhotoViewerActions.off()
        e.stopPropagation()
    }

    handleImageClick(e) {
        e.stopPropagation()
    }

    getStyle() {
        var opacity = 0.75 * (1 - 2 * this.state.closeMovementProgress)
        return {
            background: "rgba(0, 0, 0, " + opacity + ")"
        }
    }
}

export default PhotoViewer